angular
    .module('annexaApp')
        .controller('AutomatismsController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'QuerysModals', 'QueryFactory', 'CustomFieldFactory', '$q', '$http',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, QuerysModals, QueryFactory, CustomFieldFactory, $q, $http) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
                
                $scope.filter = [
                	{ id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
                	{ id: 'actionCatalog', type: 'select', order: 2, label: 'global.literals.action', dataType: 'LOCAL', callAux: true, data: new SortedArray(AdminFactory.actionCatalogs, $scope.languageColumn).sort(), addAll: true, nameProperty: $scope.languageColumn},
    				{ id: 'active', type: 'checkbox', order: 3, label: 'global.literals.active'}
                ]
                $scope.additionalColumns = [
                	{ id: 'actionCatalog.'+$scope.languageColumn, title: $filter('translate')('global.literals.action'), sortable: false},
                	{ id: 'query.'+$scope.languageColumn, title: $filter('translate')('global.literals.query'), sortable: false },
    				{ id: 'active', column: new BooleanColumn($filter, 'global.literals.active', 'active'), sortable: false }
                ]
                
                $scope.newAutomatism = angular.copy(CommonAdminModals.automatismNew);
                if($scope.newAutomatism && $scope.newAutomatism.annexaFormly && $scope.newAutomatism.annexaFormly.fields && $scope.newAutomatism.annexaFormly.fields.length > 0){
                	if($scope.newAutomatism.annexaFormly.fields[0] && $scope.newAutomatism.annexaFormly.fields[0].fieldGroup){
                		var queryField = $linq($scope.newAutomatism.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'query'");
                		if(queryField){
                			queryField.templateOptions.options = new SortedArray(AdminFactory.allQueries, $scope.languageColumn).sort();
                		}
                		var actionCatalogField = $linq($scope.newAutomatism.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'actionCatalog'");
                		if(actionCatalogField){
                			actionCatalogField.templateOptions.options = new SortedArray(AdminFactory.actionCatalogs, $scope.languageColumn).sort()
                		}
                	}
                }
                
                $scope.newElement = function () {
                    var modal = undefined;
                    modal = angular.copy($scope.newAutomatism);
                    modal.title = 'global.querys.automatisms.titleNew';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    AnnexaFormlyFactory.showModal('modalNewAutomatism', modal, $scope.saveAutomatism, false);
                };
                
                $scope.saveAutomatism = function(){
        	 		if (this.annexaFormly.form.$valid) {
        	 			var calculateCFTGroups = function(newGroups){
	                    	var groups = [];
	                    	if(newGroups){
	                    		_.forEach(newGroups, function(ng){
	                    			_.forEach(ng.groups, function(g){
	                    				g.groups = calculateCFTGroups(g.newGroups); 
	                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
	                    				groups.push(g);
	                    			})
	                    		})
	                    	}
	                    	return groups;
	                    }
                    	
                    	var self = this;
                    	var data = this.annexaFormly.model.modal_body;
                    	var sendData = {
                			language1:((data && data.language1)?data.language1:'NotTranslated'),
                			language2:((data && data.language2)?data.language2:'NotTranslated'),
                			language3:((data && data.language3)?data.language3:'NotTranslated'),
                			description:((data && data.description)?data.description:undefined),
                			active:((data && data.active)?true:false),
                			query:((data && data.query)?{id:data.query}:undefined),
                			queryParameters:[],
                			columnsTypes:[],
                			actionCatalog:((data && data.actionCatalog)?{id:data.actionCatalog}:undefined),
                			automatismActionparameters:[],
                			objectId:undefined,
                			origType:((data.origType)?data.origType:undefined),
                			dossierCustomFields:[],
                			dossierTransactionCustomFields:[],
                			documentCustomFields:[],
                			relateds:[]
                    	}
                    	var jsons = {};
                    	if(data.actionCatalog){
    						var acat = $linq(AdminFactory.actionCatalogs).firstOrDefault(undefined,"x => x.id == "+data.actionCatalog);
    						if(acat && acat.acronym === 'generateDocumentFromTramTemplate' && data.objectIdTramTemplate){
    							sendData.objectId = data.objectIdTramTemplate;
    							if(self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0] && self.annexaFormly.fields[0].fieldGroup){
    								var autactparamField = $linq(self.annexaFormly.fields[0].fieldGroup).singleOrDefault(undefined, "x => x.key == 'automatismActionparameters'");
    								if(autactparamField && autactparamField.fieldGroup){
    									var autactparamTramTemplateField = $linq(autactparamField.fieldGroup).singleOrDefault(undefined, "x => x.key == 'tramsTemplate'");
    									if(autactparamTramTemplateField && autactparamTramTemplateField.data && autactparamTramTemplateField.data.acparameter && autactparamTramTemplateField.data.acparameter.finalParameterType === "JSONArray"){
    										jsons['tramsTemplate'] = {value:autactparamTramTemplateField.data.object, array:true};
    									}
    								}
    								
    							}
    						}else if(acat && (acat.acronym === 'startDossier' || acat.acronym === 'updateDossier') && data.objectIdProc){
    							sendData.objectId = data.objectIdProc;
    						} 
                    	}
                    	
                    	var cronField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'cronExpression'");
                        if(cronField && cronField.data && cronField.data.cronExpression ){
                            sendData.cronSchedule = {cronExpression:cronField.data.cronExpression};
                        }
                    	if(data.queryParametersShow && data.queryParametersOrig && data.queryParametersOrig.length > 0){
                    		_.forEach(data.queryParametersOrig, function(qp){
                    			if(data.queryParameters && data.queryParameters[qp.alias] && qp.id){
                    				sendData.queryParameters.push({parameter:{id:qp.id} , parameterValue:data.queryParameters[qp.alias]});
                    			}else if(data.queryParameters && data.queryParameters[qp.alias+'_BOOLEAN'] && qp.id){
                    				sendData.queryParameters.push({parameter:{id:qp.id} , parameterValue:data.queryParameters[qp.alias+'_BOOLEAN']});
                    			}else if(data.queryParameters && data.queryParameters[qp.alias+'_BOOLEAN_NULL'] && qp.id){
                    				sendData.queryParameters.push({parameter:{id:qp.id} , parameterValue:data.queryParameters[qp.alias+'_BOOLEAN_NULL']});
                    			}
                            });
                    	}
                    	if(data.queryColumnsTypesShow && data.queryColumnsOrig && data.queryColumnsOrig.length > 0){
                    		_.forEach(data.queryColumnsOrig, function(col){
                    			if(data.columnsTypes && data.columnsTypes[col]){
                    				sendData.columnsTypes.push({name:col , columnType:data.columnsTypes[col]});
                    			}
                            });                   		
                    	}
                    	var acpsOK = true;
                    	if(data.actionCatalogParametersShow &&  data.actionCatalogParametersOrig && data.actionCatalogParametersOrig.length > 0){
                    		var addJSONS = function(jsons, acpToAdd, acpsOK, automatismActionparameters, type){
                    			if(acpsOK && type && acpToAdd && acpToAdd.parameter && acpToAdd.parameter.acronym && jsons && automatismActionparameters){
                    				var acpValue = jsons[acpToAdd.parameter.acronym];
                    				if(acpValue){
	                    				if(type === 'JSONArray' && acpValue.array){
	                    					if(acpValue.value){
	                    						_.forEach(acpValue.value, function(acpVal){
	                    							if(acpVal.type && !acpVal.value){
	                    								acpsOK = false;
	                    							}
	                    						});
	                    						if(acpsOK){
	                    							acpToAdd.parameterValue = JSON.stringify(acpValue.value);
	                    							acpToAdd.parameterType = type;
	                    							automatismActionparameters.push(acpToAdd);
	                    						}
	                    					}
	                    				}else if(type === 'JSON' && !acpValue.array){
	                    					if(acpVal.type && !acpVal.value){
                								acpsOk = false;
                							}else{
		                    					acpToAdd.parameterValue = JSON.stringify(acpValue.value);
	                							acpToAdd.parameterType = type;
	                							automatismActionparameters.push(acpToAdd);
                							}
	                    				}
                    				}
                    			}
                    		}
                    		_.forEach(data.actionCatalogParametersOrig, function(acp){
                    			if(data.automatismActionparameters && data.automatismActionparameters[acp.acronym]){
                    				if(acp && acp.finalParameterType && (acp.finalParameterType === "JSONArray" || acp.finalParameterType === "JSON")){
                    					addJSONS(jsons, {parameter:{id:acp.id, acronym:acp.acronym}, parameterValue:undefined, parameterType:acp.finalParameterType}, acpsOK, sendData.automatismActionparameters, acp.finalParameterType);
                    				}else{
	                    				if(data.automatismActionparameters[acp.acronym+'ParamColumnType']){
	                    					sendData.automatismActionparameters.push({parameter:{id:acp.id} , parameterValue:data.automatismActionparameters[acp.acronym] , parameterType:data.automatismActionparameters[acp.acronym+'ParamColumnType'] });
	                    				}else{
	                    					acpsOK = false;
	                    				}
                    				}
                    			}else if(acp && acp.finalParameterType && (acp.finalParameterType === "JSONArray" || acp.finalParameterType === "JSON")){
                    				addJSONS(jsons, {parameter:{id:acp.id, acronym:acp.acronym}, parameterValue:undefined, parameterType:acp.finalParameterType}, acpsOK, sendData.automatismActionparameters, acp.finalParameterType);
                    			}
                            });                    		
                    	}
                    	var cfsOK = true;
                    	if(data.dossierCustomFieldsShow &&  data.dossierCustomFieldsOrig && data.dossierCustomFieldsOrig.length > 0){
                    		_.forEach(data.dossierCustomFieldsOrig, function(acp){
                    			if(acp && acp.customField && acp.customField.frontendType === 'CF_GROUP'){
                    				acp.groups = calculateCFTGroups(acp.newGroups);
                    				sendData.dossierCustomFields.push({customField:{id:acp.customField.id} , groups:acp.groups});
                    			}else{
	                    			if(data.dossierCustomFields && data.dossierCustomFields[acp.customField.templateTag]){
	                    				if(data.dossierCustomFields[acp.customField.templateTag+'ParamColumnType']){
	                    					sendData.dossierCustomFields.push({customField:{id:acp.customField.id} , customFieldValue:data.dossierCustomFields[acp.customField.templateTag] , customFieldType:data.dossierCustomFields[acp.customField.templateTag+'ParamColumnType'] });
	                    				}else{
	                    					cfsOK = false;
	                    				}
	                    			}
                    			}
                            });                    		
                    	}
                    	if(data.dossierTransactionCustomFieldsShow &&  data.dossierTransactionCustomFieldsOrig && data.dossierTransactionCustomFieldsOrig.length > 0){
                    		_.forEach(data.dossierTransactionCustomFieldsOrig, function(acp){
                    			if(acp && acp.customField && acp.customField.frontendType === 'CF_GROUP'){
                    				acp.groups = calculateCFTGroups(acp.newGroups);
                    				sendData.dossierTransactionCustomFields.push({customField:{id:acp.customField.id} , groups:acp.groups});
                    			}else{
                    				if(data.dossierTransactionCustomFields && data.dossierTransactionCustomFields[acp.customField.templateTag]){
	                    				if(data.dossierTransactionCustomFields[acp.customField.templateTag+'ParamColumnType']){
	                    					sendData.dossierTransactionCustomFields.push({customField:{id:acp.customField.id} , customFieldValue:data.dossierTransactionCustomFields[acp.customField.templateTag] , customFieldType:data.dossierTransactionCustomFields[acp.customField.templateTag+'ParamColumnType'] });
	                    				}else{
	                    					cfsOK = false;
	                    				}
	                    			}
                    			}
                            });
                    	}
                    	if(data.documentCustomFieldsShow &&  data.documentCustomFieldsOrig && data.documentCustomFieldsOrig.length > 0){
                    		_.forEach(data.documentCustomFieldsOrig, function(acp){
                    			if(acp && acp.customField && acp.customField.frontendType === 'CF_GROUP'){
                    				acp.groups = calculateCFTGroups(acp.newGroups);
                    				sendData.documentCustomFields.push({customField:{id:acp.customField.id} , groups:acp.groups});
                    			}else{
	                    			if(data.documentCustomFields && data.documentCustomFields[acp.customField.templateTag]){
	                    				if(data.documentCustomFields[acp.customField.templateTag+'ParamColumnType']){
	                    					sendData.documentCustomFields.push({customField:{id:acp.customField.id} , customFieldValue:data.documentCustomFields[acp.customField.templateTag] , customFieldType:data.documentCustomFields[acp.customField.templateTag+'ParamColumnType'] });
	                    				}else{
	                    					cfsOK = false;
	                    				}
	                    			}
                    			}
                            });                    		
                    	}
                    	var relatedsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relateds'");
                        if(relatedsField && relatedsField.data && relatedsField.data.relateds && relatedsField.data.relateds.length > 0) {
                        	_.forEach(relatedsField.data.relateds, function(related){
                        		sendData.relateds.push({viewOrder: related.viewOrder, relatedAutomatism:{id:related.relatedAutomatism.id}});
                        	});
                        }
                    	if(!acpsOK){
                    		self.alerts.length = 0;
                    		self.alerts.push({ msg: $filter('translate')('global.querys.automatisms.typeRequired') });
                    	}else if(!cfsOK){
                    		self.alerts.length = 0;
                    		self.alerts.push({ msg: $filter('translate')('global.querys.automatisms.typeRequiredCF') });
    	                }else{
	                        RestService.insert('./api/automatism', sendData).then(function (dataGet) {
	                        	$rootScope.$broadcast('annexaAdminBaseReloadTable');
	                        	self.close();
	                        }).catch(function (error) {
	                        	self.alerts.length = 0;
	                        	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.literals.errorCreate') });
	                        });
                    	}
                    }
                };
                
                $scope.viewElement = function (id) {
                    if(id) {
                    	var promises = [];
                        promises.push(RestService.findOne('Automatism', id));
                        promises.push($http({method: 'GET',url: './api/automatism_related/actual/'+id}));
                        $q.all(promises).then(function(data) {
                    		var openModal = function(originalData, originalRelatedData, dcf, dtcf){
                    			var modal = angular.copy(CommonAdminModals.automatismNew);
                    			if(modal && modal.annexaFormly && modal.annexaFormly.fields && modal.annexaFormly.fields.length > 0){
                    				if(modal.annexaFormly.fields[0] && modal.annexaFormly.fields[0].fieldGroup){
                    					var queryField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'query'");
                    					if(queryField){
                    						queryField.templateOptions.options = new SortedArray(AdminFactory.allQueries, $scope.languageColumn).sort();
                    					}
                    					var actionCatalogField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'actionCatalog'");
                    					if(actionCatalogField){
                    						actionCatalogField.templateOptions.options = new SortedArray(AdminFactory.actionCatalogs, $scope.languageColumn).sort()
                    					}
                    				}
                    			}
                    			modal.originalData = originalData;
                    			modal.originalRelatedData = angular.copy(originalRelatedData);
                    			modal.annexaFormly.model = {modal_body:{}};
                    			if(modal.originalData){
                    				modal.annexaFormly.model.modal_body.active = ((modal.originalData.active)?true:false);
                    				modal.annexaFormly.model.modal_body.description = modal.originalData.description;
                    				modal.annexaFormly.model.modal_body.language1 = modal.originalData.language1;
                    				modal.annexaFormly.model.modal_body.language2 = modal.originalData.language2;
                    				modal.annexaFormly.model.modal_body.language3 = modal.originalData.language3;
                    				modal.annexaFormly.model.modal_body.language3 = modal.originalData.language3;
                    				if(modal.originalData.actionCatalog && modal.originalData.actionCatalog.acronym){
                    					if(modal.originalData.actionCatalog.acronym === 'generateDocumentFromTramTemplate'){
                    						modal.annexaFormly.model.modal_body.objectIdTramTemplate = modal.originalData.objectId;
                    						modal.annexaFormly.model.modal_body.objectIdTramTemplateOld = modal.originalData.objectId;
                    					}else if(modal.originalData.actionCatalog.acronym === 'startDossier' || modal.originalData.actionCatalog.acronym === 'updateDossier'){
                    						modal.annexaFormly.model.modal_body.objectIdProc = modal.originalData.objectId;
                    						modal.annexaFormly.model.modal_body.objectIdProcOld = modal.originalData.objectId;
                    					}
                    				}
                    				modal.annexaFormly.model.modal_body.origType = modal.originalData.origType;
                    				if(modal.originalData.cronSchedule && modal.originalData.cronSchedule.cronExpression){
                    					modal.annexaFormly.model.modal_body.cronExpression = modal.originalData.cronSchedule.cronExpression;
                    				}
                    				if(modal.originalData.query && modal.originalData.query.id){
                    					modal.annexaFormly.model.modal_body.query = modal.originalData.query.id;
                    					modal.annexaFormly.model.modal_body.queryOld = modal.originalData.query.id;
                    					modal.annexaFormly.model.modal_body.queryParametersOrig = [];
                    					modal.annexaFormly.model.modal_body.queryParameters = {};
                    					var queryParametersField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParameters'");
                    					var columnsTypesField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'columnsTypes'");
                    					var qPOIds = [];
                    					if(modal.originalData.query.parameters && modal.originalData.query.parameters.length > 0){
                    						queryParametersField.fieldGroup.push({
                    							key: 'labelParams',
                    							type: 'annexaLabelRow',
                    							className: 'col-sm-12',
                    							templateOptions: {
                    								label: '',
                    								value: ''
                    							},
                    							data: {
                    								informed: true,
                    								row: true,
                    								colClass: ' col-sm-12',
                    								labelClass: 'label-strong text',
                    								breakLine: true
                    							},
                    							controller: function ($scope, $filter) {
                    								$scope.to.label = $filter("translate")("global.querys.automatisms.queryParameters");
                    							}
                    						});
                    						_.forEach(modal.originalData.query.parameters, function(param){
                    							if(param.queryParameter && param.queryParameter.id && param.queryParameter.alias){
                    								modal.annexaFormly.model.modal_body.queryParametersOrig.push({id:param.queryParameter.id, alias:param.queryParameter.alias});
                    								qPOIds.push(param.queryParameter.id);
                    							}
                    						});
                    						if(modal.annexaFormly.model.modal_body.queryParametersOrig && modal.annexaFormly.model.modal_body.queryParametersOrig.length > 0){
                    							var parameters = $linq(modal.originalData.query.parameters).orderBy("x => x.viewOrder").toArray();
                    							_.forEach(parameters, function(param){
                    								if(param && param.queryParameter){
                    									queryParametersField.fieldGroup.push(QueryFactory.createQueryParameterField(param, false, false, 'col-sm-4'));
                    								}
                    							});
                    						}
                    					}
                    					if(modal.originalData.queryParameters && modal.originalData.queryParameters.length > 0){
                    						_.forEach(modal.originalData.queryParameters, function(qp){
                    							var param = undefined;
                    							if(modal.originalData && modal.originalData.query && modal.originalData.query.parameters && qp.parameter && qp.parameter.id){
                    								param = $linq(modal.originalData.query.parameters).firstOrDefault(undefined, "x => x.queryParameter && x.queryParameter.id =="+qp.parameter.id);
                    							}
                    							if(param && param.queryParameter && param.queryParameter.queryParameterType && qp.parameter && qp.parameter.alias && qp.parameter.id && _.contains(qPOIds,qp.parameter.id)){
                    								if(param.queryParameter.queryParameterType == 'DATE' && qp.parameterValue) {
                    									modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias] = new Date(qp.parameterValue);
                    								}else if((param.queryParameter.queryParameterType == 'INTEGER' || param.queryParameter.queryParameterType == 'CURRENT_USER') && qp.parameterValue){
                    									modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias] =  parseInt(qp.parameterValue);
                    								}else if(param.queryParameter.queryParameterType == 'DOUBLE'&& qp.parameterValue){
                    									var valueLiteral_parts = (qp.parameterValue).split('.');
                    									if(valueLiteral_parts && valueLiteral_parts.length > 0){
                    										if(valueLiteral_parts[1]){
                    											modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                    										}else{
                    											modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias] =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                    										}
                    									}
                    								}else if(param.queryParameter.queryParameterType == 'BOOLEAN'){
                    									if(qp.parameterValue && qp.parameterValue != '-1'){
                    										modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias+'_BOOLEAN'] = qp.parameterValue;
                    									}
                    								}else if(param.queryParameter.queryParameterType == 'BOOLEAN_NULL'){
                    									if(qp.parameterValue && qp.parameterValue != '-1'){
                    										modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias+'_BOOLEAN_NULL'] = qp.parameterValue;
                    									}else{
                    										modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias+'_BOOLEAN_NULL'] = undefined;
                    									}
                    								}else if(qp.parameterValue){
                    									modal.annexaFormly.model.modal_body.queryParameters[qp.parameter.alias] = qp.parameterValue;
                    								}
                    							}
                    						});
                    					}
                    					modal.annexaFormly.model.modal_body.queryParametersShow = ((modal.annexaFormly.model.modal_body.queryParametersOrig && modal.annexaFormly.model.modal_body.queryParametersOrig.length > 0)?true:false);
                    					modal.annexaFormly.model.modal_body.queryColumnsOrig = [];
                    					modal.annexaFormly.model.modal_body.columnsTypes= {};
                    					if(modal.originalData.columnsTypes && modal.originalData.columnsTypes.length > 0){
                    						var entitiesOptions = angular.copy(AdminFactory.projectEntities);
                    						columnsTypesField.fieldGroup.push({
                    							key: 'labelColumnTypes',
                    							type: 'annexaLabelRow',
                    							className: 'col-sm-12',
                    							templateOptions: {
                    								label: '',
                    								value: ''
                    							},
                    							data: {
                    								informed: true,
                    								row: true,
                    								colClass: ' col-sm-12',
                    								labelClass: 'label-strong text',
                    								breakLine: true
                    							},
                    							controller: function ($scope, $filter) {
                    								$scope.to.label = $filter("translate")("global.querys.automatisms.columnsTypes");
                    							}
                    						});
                    						_.forEach(modal.originalData.columnsTypes, function(ct){
                    							if(ct.name){
                    								modal.annexaFormly.model.modal_body.queryColumnsOrig.push(ct.name);
                    							}
                    							if(ct.name && ct.columnType){
                    								modal.annexaFormly.model.modal_body.columnsTypes[ct.name] = ct.columnType;
                    							}
                    							columnsTypesField.fieldGroup.push({
                    								key: ct.name,
                    								type: 'annexaSelectRow',
                    								className: 'col-sm-4',
                    								templateOptions: {
                    									optionsAttr: 'bs-options',
                    									ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    									label: ct.name,
                    									valueProp: 'id',
                    									labelProp: 'name',
                    									placeholder: '',
                    									options: [],
                    									required: true
                    								},
                    								controller:['$scope', 'AdminFactory', 'Language', function($scope, AdminFactory, Language) {
                    									$scope.to.options = entitiesOptions;
                    								}],
                    								data: {
                    									row: true,
                    									informed: true,
                    									colClass: ' col-xs-12',
                    									labelClass: 'label-strong',
                    									clear: function($event,model,key, $select) {
                    										$event.stopPropagation();
                    										model[key] = undefined;
                    										if($select) {
                    											$select.selected = undefined;
                    											$select.search = undefined;
                    										}
                    									}
                    								}
                    							});
                    						});
                    					}
                    					modal.annexaFormly.model.modal_body.queryColumnsTypesShow = ((modal.annexaFormly.model.modal_body.queryColumnsOrig && modal.annexaFormly.model.modal_body.queryColumnsOrig.length > 0)?true:false);
                    				}
                    				if(modal.originalData.actionCatalog && modal.originalData.actionCatalog.id){
                    					var automatismActionparametersField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'automatismActionparameters'");
                    					modal.annexaFormly.model.modal_body.actionCatalog = modal.originalData.actionCatalog.id;
                    					modal.annexaFormly.model.modal_body.actionCatalogOld = modal.originalData.actionCatalog.id;
                    					modal.annexaFormly.model.modal_body.actionCatalogParametersOrig = [];
                    					modal.annexaFormly.model.modal_body.automatismActionparameters = {};
                    					var aCPOIds = [];
                    					if(modal.originalData.actionCatalog.parameters){
                    						var aCParams = $linq(modal.originalData.actionCatalog.parameters).orderBy("x => x.viewOrder").toArray() 
                    						_.forEach(aCParams, function(param){
                    							if(param.id && param.acronym){
                    								modal.annexaFormly.model.modal_body.actionCatalogParametersOrig.push({id:param.id, acronym:param.acronym, finalParameterType:param.finalParameterType});
                    								if(!param.finalParameterType || (param.finalParameterType && param.finalParameterType !== 'JSONArray' && param.finalParameterType !== 'JSON')){
                    									var fieldACP = undefined;
                    									var fieldACPExtra = undefined;
                    									if((modal.originalData.actionCatalog.acronym === 'sendMail' && (param.acronym === 'subject' || param.acronym === 'body')) || (modal.originalData.actionCatalog.acronym === 'createUnguidedDossierTransaction' && param.acronym === 'comment') || (modal.originalData.actionCatalog.acronym === 'createGuidedDossierTransaction'  && param.acronym === 'comment') || (modal.originalData.actionCatalog.acronym === 'updateDossier' && param.acronym === 'comments')){
                    										fieldACP = {
                    												key: param.acronym,
                    												type: 'annexaTextAreaRow',
                    												className: 'col-sm-4',
                    												templateOptions: {
                    													required: ((param.required)?true:false),
                    													type: 'text',
                    													rows: 5,
                    													maxLength: 5000,
                    													label: param[$scope.languageColumn]
                    												},
                    												data: {
                    													row: true,
                    													colClass: ' col-xs-12',
                    													labelClass: 'label-strong'
                    												}
                    										};	
                    										fieldACPExtra = {
                    												key: param.acronym+"ParamColumnTypeExtraField",
                    												type: 'annexaLabelRow',
                    												className: 'col-sm-12',
                    												templateOptions: {
                    													label: '',
                    													value: ''
                    												},
                    												data: {
                    													informed: true,
                    													row: true,
                    													colClass: ' col-sm-12',
                    													labelClass: 'label-strong small',
                    													breakLine: true
                    												}
                    										};	
                    									}else{
                    										fieldACP = {
                    												key: param.acronym,
                    												type: 'annexaInputRow',
                    												className: 'col-sm-4',
                    												templateOptions: {
                    													required: ((param.required)?true:false),
                    													type: 'text',
                    													label: param[$scope.languageColumn]
                    												},
                    												data: {
                    													row: true,
                    													colClass: ' col-xs-12',
                    													labelClass: 'label-strong'
                    												}
                    										};
                    									}
                    									var fieldACPPCT = {
                    											key: param.acronym+"ParamColumnType",
                    											type: 'annexaSelectRow',
                    											className: 'col-sm-4',
                    											templateOptions: {
                    												optionsAttr: 'bs-options',
                    												ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    												label: param[$scope.languageColumn]+" "+$filter("translate")("global.literals.type"),
                    												valueProp: 'id',
                    												labelProp: 'name',
                    												placeholder: '',
                    												options: [],
                    												required: ((param.required)?true:false)
                    											},
                    											controller:['$scope', 'Language', function($scope, Language) {
                    												var options = [];
                    												if(param.parameterType === 'String'){
                    													options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
                    													options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
                    												}else{
                    													options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
                    												}
                    												options.push({id:param.parameterType, name:param.parameterType});
                    												$scope.to.options = options;
                    											}],
                    											data: {
                    												row: true,
                    												informed: true,
                    												colClass: ' col-xs-12',
                    												labelClass: 'label-strong',
                    												clear: function($event,model,key, $select) {
                    													$event.stopPropagation();
                    													model[key] = undefined;
                    													if($select) {
                    														$select.selected = undefined;
                    														$select.search = undefined;
                    													}
                    												}
                    											},
                    											hideExpression: function ($viewValue, $modelValue, scope) {
                    												if(param && !param.required && param.acronym){
                    													var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+param.acronym+"ParamColumnType'");
                    													if(cronField && cronField.templateOptions){
                    														cronField.templateOptions.required = ((scope.model && scope.model[param.acronym])?true:false);
                    													}
                    												}
                    												return false;
                    											}
                    									};
                    									var fieldACPPFCT = {
                    											key: param.acronym+"ParamFinalColumnType",
                    											type: 'annexaLabelRow',
                    											className: 'col-sm-4 m-b-md',
                    											templateOptions: {
                    												label: '',
                    												value: ''
                    											},
                    											data: {
                    												informed: true,
                    												row: true,
                    												colClass: ' col-sm-12',
                    												labelClass: 'label-strong small',
                    												breakLine: true
                    											},
                    											controller: function ($scope, $filter, Language) {
                    												$scope.to.label = param[Language.getActiveColumn()]+" "+$filter("translate")("global.querys.automatisms.finaltype");
                    												$scope.to.value = param.finalParameterType;
                    											}
                    									};
                    									if(modal && modal.originalData && modal.originalData.actionCatalog && modal.originalData.actionCatalog.acronym === 'startDossier' && (param.acronym === 'registerEntry' || param.acronym === 'dossier' || param.acronym === 'relatedDossierRelation' || param.acronym === 'task')){
                    										fieldACP.hideExpression = function ($viewValue, $modelValue, scope) {
                    											if(scope.$parent && scope.$parent.model && scope.$parent.model.actionCatalog){
                    												var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                    												if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                    													var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.$parent.model.actionCatalog);
                    													if(sd && sd.acronym === 'startDossier' && scope.$parent.model.origType && 
                    															((scope.$parent.model.origType === 'REGISTER' && param.acronym === 'registerEntry') ||
                    																	(scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'dossier') ||	
                    																	(scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'relatedDossierRelation') ||
                    																	(scope.$parent.model.origType === 'TASK' && param.acronym === 'task'))){
                    														return false;
                    													}else{
                    														scope.model[param.acronym] = undefined;
                    														return true;
                    													}
                    												}else{
                    													scope.model[param.acronym] = undefined;
                    													return true;
                    												}
                    											}else{
                    												scope.model[param.acronym] = undefined;
                    												return true;
                    											}
                    										}
                    										fieldACPPCT.hideExpression = function ($viewValue, $modelValue, scope) {
                    											if(param && !param.required && param.acronym){
                    												var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+param.acronym+"ParamColumnType'");
                    												if(cronField && cronField.templateOptions){
                    													cronField.templateOptions.required = ((scope.model && scope.model[param.acronym])?true:false);
                    												}
                    											}
                    											if(scope.$parent && scope.$parent.model && scope.$parent.model.actionCatalog){
                    												var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                    												if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                    													var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.$parent.model.actionCatalog);
                    													if(sd && sd.acronym === 'startDossier' && scope.$parent.model.origType && 
                    															((scope.$parent.model.origType === 'REGISTER' && param.acronym === 'registerEntry') ||
                    																	(scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'dossier') ||	
                    																	(scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'relatedDossierRelation') ||
                    																	(scope.$parent.model.origType === 'TASK' && param.acronym === 'task'))){
                    														return false;
                    													}else{
                    														return true;
                    													}
                    												}else{
                    													return true;
                    												}
                    											}else{
                    												return true;
                    											}
                    										}
                    										fieldACPPFCT.hideExpression = function ($viewValue, $modelValue, scope) {
                    											if(scope.$parent && scope.$parent.model && scope.$parent.model.actionCatalog){
                    												var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                    												if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                    													var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.$parent.model.actionCatalog);
                    													if(sd && sd.acronym === 'startDossier' && scope.$parent.model.origType && 
                    															((scope.$parent.model.origType === 'REGISTER' && param.acronym === 'registerEntry') ||
                    																	(scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'dossier') ||	
                    																	(scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'relatedDossierRelation') ||
                    																	(scope.$parent.model.origType === 'TASK' && param.acronym === 'task'))){
                    														return false;
                    													}else{
                    														return true;
                    													}
                    												}else{
                    													return true;
                    												}
                    											}else{
                    												return true;
                    											}
                    										}
                    									}
                    									automatismActionparametersField.fieldGroup.push(fieldACP);
                    									automatismActionparametersField.fieldGroup.push(fieldACPPCT);
                    									automatismActionparametersField.fieldGroup.push(fieldACPPFCT);
                    									if(fieldACPExtra){
                    										automatismActionparametersField.fieldGroup.push(fieldACPExtra);
                    									}
                    									aCPOIds.push(param.id);
                    								}else{
                    									fieldACP = {
                						                    key: param.acronym,
                						                    type: 'annexaComponent',
                						                    className: 'col-sm-12',
                						                    templateOptions: {
                						                        type: 'annexa-automatism-json'
                						                    },
                			                                data: {
                			                                    value: undefined,
                			                                    acparameter:param,
                			                                    actionCatalog: modal.originalData.actionCatalog,
                			                                    model: modal.annexaFormly.model.modal_body
                			                                }
                										};
                    									automatismActionparametersField.fieldGroup.push(fieldACP);
                    									aCPOIds.push(param.id);
                    								}
                    							}
                    						});
                    					}
                    					if(modal.originalData.automatismActionparameters){
                    						_.forEach(modal.originalData.automatismActionparameters, function(param){
                    							if(param.parameter && param.parameter.acronym && param.parameterValue && param.parameterType && param.parameter.id && _.contains(aCPOIds,param.parameter.id)){
                    								modal.annexaFormly.model.modal_body.automatismActionparameters[param.parameter.acronym] = param.parameterValue;
                    								modal.annexaFormly.model.modal_body.automatismActionparameters[param.parameter.acronym+'ParamColumnType'] = param.parameterType;
                    								if(param.parameter.finalParameterType && (param.parameter.finalParameterType === 'JSONArray' || param.parameter.finalParameterType === 'JSON')){
                    									var auxField = $linq(automatismActionparametersField.fieldGroup).firstOrDefault(undefined, "x => x.key == '"+param.parameter.acronym+"'");
                    									if(auxField && auxField.data){
                    										auxField.data.value = param.parameterValue;
                    									}
                                    					
                    								}
                    							}
                    							
                    						});
                    					}
                    					modal.annexaFormly.model.modal_body.actionCatalogParametersShow = ((modal.annexaFormly.model.modal_body.actionCatalogParametersOrig && modal.annexaFormly.model.modal_body.actionCatalogParametersOrig.length > 0)?true:false);
                    				}
                    				if(modal.originalData.actionCatalog && (modal.originalData.actionCatalog.acronym === 'startDossier' || modal.originalData.actionCatalog.acronym === 'updateDossier') && modal.originalData.objectId && dcf && dcf.length > 0){
                    					var dossierCustomFieldsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'dossierCustomFields'");
                    					modal.annexaFormly.model.modal_body.dossierCustomFieldsOrig = [];
                    					modal.annexaFormly.model.modal_body.dossierCustomFields = {};
                    					if(dcf){
                    						dossierCustomFieldsField.fieldGroup.push({
                    							key: 'labeldossierCustomFieldsField',
                    							type: 'annexaLabelRow',
                    							className: 'col-sm-12',
                    							templateOptions: {
                    								label: '',
                    								value: ''
                    							},
                    							data: {
                    								informed: true,
                    								row: true,
                    								colClass: ' col-sm-12',
                    								labelClass: 'label-strong text',
                    								breakLine: true
                    							},
                    							controller: function ($scope, $filter) {
                    								$scope.to.label = $filter("translate")("global.literals.customFieldsDossier");
                    							}
                    						});
                    						var dcfOrder = $linq(dcf).orderBy("x => x.viewOrder").toArray()
            				                _.forEach(dcfOrder, function(field) {
            				                	modal.annexaFormly.model.modal_body.dossierCustomFieldsOrig.push(field);
            				                	if(field && field.customField && field.customField.frontendType === 'CF_GROUP'){
            				                		dossierCustomFieldsField.fieldGroup.push({
            	                                        key: field.customField.templateTag+'Label',
            	                                        type: 'annexaLabel',
            	                                        className: 'col-sm-12',
            	                                        templateOptions: {
            	                                            label: field.customField[$scope.languageColumn],
            	                                            value:''
            	                                        },
            	                                        data: {
            	                                            informed: true,
            	                                            row: true,
            	                                            colClass: ' col-sm-12',
            	                                            labelClass: 'label-strong text'
            	                                        }
            	                                    });
            				                		var groupData = { 
        				                    			extra:{
        				                    				seeSelectedLinked:true,
        				            						valuesFromLinkedCustomFields:{
        				            							parentId:modal.originalData.objectId, 
        				            							objectParent:"PROCEDURE", 
        				            							inConfig:true
        				            						}
        				                    			}
        				                    		};
            				                		dossierCustomFieldsField.fieldGroup.push({
            		                                    key: field.customField.templateTag,
            		                                    type: 'annexaComponent',
            		                                    className: 'col-sm-12',
            		                                    templateOptions: {
            		                                        type: 'annexa-object-custom-fields-group-automatism'
            		                                    },
            		                                    data: {
            		                                    	groupData:groupData,
            		                                    	customField:field
            		                                    }
            		                                });
            				                	}else{
	            				                	dossierCustomFieldsField.fieldGroup.push({
	                										key: field.customField.templateTag,
	                										type: 'annexaInputRow',
	                										className: 'col-sm-6',
	                										templateOptions: {
	                											required: ((field.required)?true:false),
	                											type: 'text',
	                											label: field.customField[$scope.languageColumn]
	                										},
	                										data: {
	                											row: true,
	                											colClass: ' col-xs-12',
	                											labelClass: 'label-strong'
	                										}
	                								});
	            				                	dossierCustomFieldsField.fieldGroup.push({
	                										key: field.customField.templateTag+"ParamColumnType",
	                										type: 'annexaSelectRow',
	                										className: 'col-sm-6 m-b-md',
	                										templateOptions: {
	                											optionsAttr: 'bs-options',
	                											ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                											label: field.customField[$scope.languageColumn]+" "+$filter("translate")("global.literals.type"),
	                											valueProp: 'id',
	                											labelProp: 'name',
	                											placeholder: '',
	                											options: [],
	                											required: ((field.required)?true:false)
	                										},
	                										controller:['$scope', 'Language', function($scope, Language) {
	                											var options = [];
	                											if(field && field.customField && field.customField.backendType === 'STRING'){
	                												options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
	                												options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
	                											}else{
	                												options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
	                											}
	                											options.push({id:field.customField.backendType, name:field.customField.backendType});
	                											$scope.to.options = options;
	                										}],
	                										data: {
	                											row: true,
	                											informed: true,
	                											colClass: ' col-xs-12',
	                											labelClass: 'label-strong',
	                											clear: function($event,model,key, $select) {
	                												$event.stopPropagation();
	                												model[key] = undefined;
	                												if($select) {
	                													$select.selected = undefined;
	                													$select.search = undefined;
	                												}
	                											}
	                										},
	                										hideExpression: function ($viewValue, $modelValue, scope) {
	                											if(field && !field.required && field.customField && field.customField.templateTag){
	                												var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+field.customField.templateTag+"ParamColumnType'");
	                												if(cronField && cronField.templateOptions){
	                													cronField.templateOptions.required = ((scope.model && scope.model[field.customField.templateTag])?true:false);
	                												}
	                											}
	                											return false;
	                										}
	                								});
            				                	}
            				                });
            				                if(modal.originalData.dossierCustomFields){
                        						_.forEach(modal.originalData.dossierCustomFields, function(field){
                        							if(field.customField && field.customField.templateTag && field.customFieldValue && field.customFieldType){
                        								modal.annexaFormly.model.modal_body.dossierCustomFields[field.customField.templateTag] = field.customFieldValue;
                        								modal.annexaFormly.model.modal_body.dossierCustomFields[field.customField.templateTag+"ParamColumnType"] = field.customFieldType;
                        							}
                        							
                        						});
                        					}
                    					}
                    					modal.annexaFormly.model.modal_body.dossierCustomFieldsShow = true;
                    				}   
                    				if(modal.originalData.actionCatalog && modal.originalData.actionCatalog.acronym === 'startDossier' && modal.originalData.objectId && dtcf && dtcf.length > 0){
                    					var dossierTransactionCustomFieldsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'dossierTransactionCustomFields'");
                    					modal.annexaFormly.model.modal_body.dossierTransactionCustomFieldsOrig = [];
                    					modal.annexaFormly.model.modal_body.dossierTransactionCustomFields = {};
                    					if(dtcf){
                    						dossierTransactionCustomFieldsField.fieldGroup.push({
                    							key: 'labeldossierTransactionCustomFieldsField',
                    							type: 'annexaLabelRow',
                    							className: 'col-sm-12',
                    							templateOptions: {
                    								label: '',
                    								value: ''
                    							},
                    							data: {
                    								informed: true,
                    								row: true,
                    								colClass: ' col-sm-12',
                    								labelClass: 'label-strong text',
                    								breakLine: true
                    							},
                    							controller: function ($scope, $filter) {
                    								$scope.to.label = $filter("translate")("global.literals.customFieldsDossierTransaction");
                    							}
                    						});
                    						var dtcfOrder = $linq(dtcf).orderBy("x => x.viewOrder").toArray()
            				                _.forEach(dtcfOrder, function(field) {
            				                	modal.annexaFormly.model.modal_body.dossierTransactionCustomFieldsOrig.push(field);
                								if(field && field.customField && field.customField.frontendType === 'CF_GROUP'){
                									dossierTransactionCustomFieldsField.fieldGroup.push({
            	                                        key: field.customField.templateTag+'Label',
            	                                        type: 'annexaLabel',
            	                                        className: 'col-sm-12',
            	                                        templateOptions: {
            	                                            label: field.customField[$scope.languageColumn],
            	                                            value:''
            	                                        },
            	                                        data: {
            	                                            informed: true,
            	                                            row: true,
            	                                            colClass: ' col-sm-12',
            	                                            labelClass: 'label-strong text'
            	                                        }
            	                                    });
            				                		var groupData = { 
        				                    			extra:{
        				                    				seeSelectedLinked:true,
        				            						valuesFromLinkedCustomFields:{
        				            							parentId:modal.originalData.objectId, 
        				            							objectParent:"PROCEDURE", 
        				            							inConfig:true
        				            						}
        				                    			}
        				                    		};
            				                		dossierTransactionCustomFieldsField.fieldGroup.push({
            		                                    key: field.customField.templateTag,
            		                                    type: 'annexaComponent',
            		                                    className: 'col-sm-12',
            		                                    templateOptions: {
            		                                        type: 'annexa-object-custom-fields-group-automatism'
            		                                    },
            		                                    data: {
            		                                    	groupData:groupData,
            		                                    	customField:field
            		                                    }
            		                                });
            				                	}else{
	            				                	dossierTransactionCustomFieldsField.fieldGroup.push({
	            										key: field.customField.templateTag,
	            										type: 'annexaInputRow',
	            										className: 'col-sm-6',
	            										templateOptions: {
	            											required: ((field.required)?true:false),
	            											type: 'text',
	            											label: field.customField[$scope.languageColumn]
	            										},
	            										data: {
	            											row: true,
	            											colClass: ' col-xs-12',
	            											labelClass: 'label-strong'
	            										}
		            								});
	                    							dossierTransactionCustomFieldsField.fieldGroup.push({
		            										key: field.customField.templateTag+"ParamColumnType",
		            										type: 'annexaSelectRow',
		            										className: 'col-sm-6 m-b-md',
		            										templateOptions: {
		            											optionsAttr: 'bs-options',
		            											ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		            											label: field.customField[$scope.languageColumn]+" "+$filter("translate")("global.literals.type"),
		            											valueProp: 'id',
		            											labelProp: 'name',
		            											placeholder: '',
		            											options: [],
		            											required: ((field.required)?true:false)
		            										},
		            										controller:['$scope', 'Language', function($scope, Language) {
		            											var options = [];
		            											if(field && field.customField && field.customField.backendType === 'STRING'){
		            												options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
		            												options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
		            											}else{
		            												options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
		            											}
		            											options.push({id:field.customField.backendType, name:field.customField.backendType});
		            											$scope.to.options = options;
		            										}],
		            										data: {
		            											row: true,
		            											informed: true,
		            											colClass: ' col-xs-12',
		            											labelClass: 'label-strong',
		            											clear: function($event,model,key, $select) {
		            												$event.stopPropagation();
		            												model[key] = undefined;
		            												if($select) {
		            													$select.selected = undefined;
		            													$select.search = undefined;
		            												}
		            											}
		            										},
		            										hideExpression: function ($viewValue, $modelValue, scope) {
		            											if(field && !field.required && field.customField && field.customField.templateTag){
		            												var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+field.customField.templateTag+"ParamColumnType'");
		            												if(cronField && cronField.templateOptions){
		            													cronField.templateOptions.required = ((scope.model && scope.model[field.customField.templateTag])?true:false);
		            												}
		            											}
		            											return false;
		            										}
		            								});
            				                	}
	        				                });
	        				                if(modal.originalData.dossierTransactionCustomFields){
	                    						_.forEach(modal.originalData.dossierTransactionCustomFields, function(field){
	                    							if(field.customField && field.customField.templateTag && field.customFieldValue && field.customFieldType){
	                    								modal.annexaFormly.model.modal_body.dossierTransactionCustomFields[field.customField.templateTag] = field.customFieldValue;
	                    								modal.annexaFormly.model.modal_body.dossierTransactionCustomFields[field.customField.templateTag+"ParamColumnType"] = field.customFieldType;
	                    							}
	                    							
	                    						});
	                    					}
                    					}
                    					modal.annexaFormly.model.modal_body.dossierTransactionCustomFieldsShow = true;
                    				}
                    				if(modal.originalData.actionCatalog && modal.originalData.actionCatalog.acronym === 'generateDocumentFromTramTemplate' && modal.originalData.objectId && dcf && dcf.length > 0){
                    					var documentCustomFieldsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
                    					modal.annexaFormly.model.modal_body.documentCustomFieldsOrig = [];
                    					modal.annexaFormly.model.modal_body.documentCustomFields = {};
                    					if(dcf){
                    						documentCustomFieldsField.fieldGroup.push({
                    							key: 'labeldocumentCustomFieldsField',
                    							type: 'annexaLabelRow',
                    							className: 'col-sm-12',
                    							templateOptions: {
                    								label: '',
                    								value: ''
                    							},
                    							data: {
                    								informed: true,
                    								row: true,
                    								colClass: ' col-sm-12',
                    								labelClass: 'label-strong text',
                    								breakLine: true
                    							},
                    							controller: function ($scope, $filter) {
                    								$scope.to.label = $filter("translate")("global.literals.customFieldsDocument");
                    							}
                    						});
                    						var dcfOrder = $linq(dcf).orderBy("x => x.viewOrder").toArray()
            				                _.forEach(dcfOrder, function(field) {
            				                	modal.annexaFormly.model.modal_body.documentCustomFieldsOrig.push(field);
            				                	if(field && field.customField && field.customField.frontendType === 'CF_GROUP'){
            				                		documentCustomFieldsField.fieldGroup.push({
            	                                        key: field.customField.templateTag+'Label',
            	                                        type: 'annexaLabel',
            	                                        className: 'col-sm-12',
            	                                        templateOptions: {
            	                                            label: field.customField[$scope.languageColumn],
            	                                            value:''
            	                                        },
            	                                        data: {
            	                                            informed: true,
            	                                            row: true,
            	                                            colClass: ' col-sm-12',
            	                                            labelClass: 'label-strong text'
            	                                        }
            	                                    });
            				                		var templateAux = $linq(AdminFactory.tramTemplates).firstOrDefault(undefined, "x => x.id == "+modal.originalData.objectId);
            				                		var groupData = { 
        				                    			extra:{
        				                    				seeSelectedLinked:true,
        				            						valuesFromLinkedCustomFields:{
        				            							parentId:((templateAux && templateAux.documentType && templateAux.documentType.id)?templateAux.documentType.id:undefined), 
        				            							objectParent:"DOCUMENT_TYPE", 
        				            							inConfig:true
        				            						}
        				                    			}
        				                    		};
            				                		documentCustomFieldsField.fieldGroup.push({
            		                                    key: field.customField.templateTag,
            		                                    type: 'annexaComponent',
            		                                    className: 'col-sm-12',
            		                                    templateOptions: {
            		                                        type: 'annexa-object-custom-fields-group-automatism'
            		                                    },
            		                                    data: {
            		                                    	groupData:groupData,
            		                                    	customField:field
            		                                    }
            		                                });
            				                	}else{
            				                		documentCustomFieldsField.fieldGroup.push({
            				                			key: field.customField.templateTag,
            				                			type: 'annexaInputRow',
            				                			className: 'col-sm-6',
            				                			templateOptions: {
            				                				required: ((field.required)?true:false),
            				                				type: 'text',
            				                				label: field.customField[$scope.languageColumn]
            				                			},
            				                			data: {
            				                				row: true,
            				                				colClass: ' col-xs-12',
            				                				labelClass: 'label-strong'
            				                			}
            				                		});
            				                		documentCustomFieldsField.fieldGroup.push({
            				                			key: field.customField.templateTag+"ParamColumnType",
            				                			type: 'annexaSelectRow',
            				                			className: 'col-sm-6 m-b-md',
            				                			templateOptions: {
            				                				optionsAttr: 'bs-options',
            				                				ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            				                				label: field.customField[$scope.languageColumn]+" "+$filter("translate")("global.literals.type"),
            				                				valueProp: 'id',
            				                				labelProp: 'name',
            				                				placeholder: '',
            				                				options: [],
            				                				required: ((field.required)?true:false)
            				                			},
            				                			controller:['$scope', 'Language', function($scope, Language) {
            				                				var options = [];
            				                				if(field && field.customField && field.customField.backendType === 'STRING'){
            				                					options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
            				                					options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
            				                				}else{
            				                					options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
            				                				}
            				                				options.push({id:field.customField.backendType, name:field.customField.backendType});
            				                				$scope.to.options = options;
            				                			}],
            				                			data: {
            				                				row: true,
            				                				informed: true,
            				                				colClass: ' col-xs-12',
            				                				labelClass: 'label-strong',
            				                				clear: function($event,model,key, $select) {
            				                					$event.stopPropagation();
            				                					model[key] = undefined;
            				                					if($select) {
            				                						$select.selected = undefined;
            				                						$select.search = undefined;
            				                					}
            				                				}
            				                			},
            				                			hideExpression: function ($viewValue, $modelValue, scope) {
            				                				if(field && !field.required && field.customField && field.customField.templateTag){
            				                					var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+field.customField.templateTag+"ParamColumnType'");
            				                					if(cronField && cronField.templateOptions){
            				                						cronField.templateOptions.required = ((scope.model && scope.model[field.customField.templateTag])?true:false);
            				                					}
            				                				}
            				                				return false;
            				                			}
            				                		});
            				                	}
            				                });
            				                if(modal.originalData.documentCustomFields){
                        						_.forEach(modal.originalData.documentCustomFields, function(field){
                        							if(field.customField && field.customField.templateTag && field.customFieldValue && field.customFieldType){
                        								modal.annexaFormly.model.modal_body.documentCustomFields[field.customField.templateTag] = field.customFieldValue;
                        								modal.annexaFormly.model.modal_body.documentCustomFields[field.customField.templateTag+"ParamColumnType"] = field.customFieldType;
                        							}
                        							
                        						});
                        					}
                    					}
                    					modal.annexaFormly.model.modal_body.documentCustomFieldsShow = true;
                    				}   
                    			}
                    			var relatedsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relateds'");
                                if(relatedsField) {
                                	relatedsField.data.relateds = ((originalRelatedData)?originalRelatedData:[]);
                                	relatedsField.data.actualId = ((originalData && originalData.id)?originalData.id:undefined);
                                }
                    			AnnexaFormlyFactory.showModal('modalNewAdminBase', modal, $scope.updateAutomatism, false);
                    		}
                    		var originalData = JSOG.decode(data[0].data)
                    		var originalRelatedData = JSOG.decode(data[1].data)
                    		if(originalData && originalData.actionCatalog && originalData.actionCatalog.acronym === 'startDossier' && originalData.objectId){
                    			AdminFactory.getAutomatismCustomFields(originalData.objectId).then(function (data){
                    				var cfsDAux = [];
                    				if(data && data.dossierCustomFields){
                    					_.forEach(data.dossierCustomFields, function(dcf){
                    						if(dcf && dcf.customField && dcf.customField.frontendType === 'CF_GROUP'){
                    							if(originalData.dossierCustomFields && originalData.dossierCustomFields.length > 0){
                    								var origd = $linq(originalData.dossierCustomFields).firstOrDefault(undefined, "x => x.customField.id == "+dcf.customField.id);
                    								if(origd && origd.groups && origd.groups.length > 0 ){
                    									dcf.groups = origd.groups;
                    								}
                    							}
                    						}
                    						cfsDAux.push(dcf);
                    					});
                    				}
                    				var cfsDTAux = [];
                    				if(data && data.dossierTransactionCustomFields){
                    					_.forEach(data.dossierTransactionCustomFields, function(dcf){
                    						if(dcf && dcf.customField && dcf.customField.frontendType === 'CF_GROUP'){
                    							if(originalData.dossierTransactionCustomFields && originalData.dossierTransactionCustomFields.length > 0){
                    								var origd = $linq(originalData.dossierTransactionCustomFields).firstOrDefault(undefined, "x => x.customField.id == "+dcf.customField.id);
                    								if(origd && origd.groups && origd.groups.length > 0 ){
                    									dcf.groups = origd.groups;
                    								}
                    							}
                    						}
                    						cfsDTAux.push(dcf);
                    					});
                    				}
                    				openModal(originalData, originalRelatedData, cfsDAux,cfsDTAux);
                                }).catch(function (error) {
                                	openModal(originalData, originalRelatedData);
                                });
                    		}else if(originalData && originalData.actionCatalog && originalData.actionCatalog.acronym === 'generateDocumentFromTramTemplate' && originalData.objectId){
                    			AdminFactory.getAutomatismDocumentCustomFields(originalData.objectId).then(function (data){
                    				var cfsAux = [];
                    				if(data && data.documentCustomFields){
                    					_.forEach(data.documentCustomFields, function(dcf){
                    						if(dcf && dcf.customField && dcf.customField.frontendType === 'CF_GROUP'){
                    							if(originalData.documentCustomFields && originalData.documentCustomFields.length > 0){
                    								var origd = $linq(originalData.documentCustomFields).firstOrDefault(undefined, "x => x.customField.id == "+dcf.customField.id);
                    								if(origd && origd.groups && origd.groups.length > 0 ){
                    									dcf.groups = origd.groups;
                    								}
                    							}
                    						}
                    						cfsAux.push(dcf);
                    					});
                    				}
                    				openModal(originalData, originalRelatedData,cfsAux);
                                }).catch(function (error) {
                                	openModal(originalData, originalRelatedData);
                                });
                    		
                    		}else if(originalData && originalData.actionCatalog && originalData.actionCatalog.acronym === 'updateDossier' && originalData.objectId){
                    			AdminFactory.getAutomatismCustomFields(originalData.objectId).then(function (data){
                    				var cfsDAux = [];
                    				if(data && data.dossierCustomFields){
                    					_.forEach(data.dossierCustomFields, function(dcf){
                    						if(dcf && dcf.customField && dcf.customField.frontendType === 'CF_GROUP'){
                    							if(originalData.dossierCustomFields && originalData.dossierCustomFields.length > 0){
                    								var origd = $linq(originalData.dossierCustomFields).firstOrDefault(undefined, "x => x.customField.id == "+dcf.customField.id);
                    								if(origd && origd.groups && origd.groups.length > 0 ){
                    									dcf.groups = origd.groups;
                    								}
                    							}
                    						}
                    						cfsDAux.push(dcf);
                    					});
                    				}
                    				openModal(originalData, originalRelatedData, cfsDAux,undefined);
                                }).catch(function (error) {
                                	openModal(originalData, originalRelatedData);
                                });
                    		}else{
                    			openModal(originalData, originalRelatedData);
                    		}
                        });
                    }
                };
        	   
                $scope.updateAutomatism = function() {
                	if (this.annexaFormly.form.$valid && this.originalData) {
                		var self = this;
                    	var data = this.annexaFormly.model.modal_body;
                    	var sendData = angular.copy(this.originalData);
                    	sendData.language1 = ((data && data.language1)?data.language1:'NotTranslated');
                    	sendData.language2 = ((data && data.language2)?data.language2:'NotTranslated');
                    	sendData.language3 = ((data && data.language3)?data.language3:'NotTranslated');
                    	sendData.description = ((data && data.description)?data.description:undefined);
                    	sendData.active = ((data && data.active)?true:false);
                    	sendData.query = ((data && data.query)?{id:data.query}:undefined);
                    	sendData.actionCatalog = ((data && data.actionCatalog)?{id:data.actionCatalog}:undefined);
                    	var jsons = {};
                    	if(data.actionCatalog){
    						var acat = $linq(AdminFactory.actionCatalogs).firstOrDefault(undefined,"x => x.id == "+data.actionCatalog);
    						if(acat && acat.acronym === 'generateDocumentFromTramTemplate' && data.objectIdTramTemplate){
    							sendData.objectId = data.objectIdTramTemplate;
    							if(self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0] && self.annexaFormly.fields[0].fieldGroup){
    								var autactparamField = $linq(self.annexaFormly.fields[0].fieldGroup).singleOrDefault(undefined, "x => x.key == 'automatismActionparameters'");
    								if(autactparamField && autactparamField.fieldGroup){
    									var autactparamTramTemplateField = $linq(autactparamField.fieldGroup).singleOrDefault(undefined, "x => x.key == 'tramsTemplate'");
    									if(autactparamTramTemplateField && autactparamTramTemplateField.data && autactparamTramTemplateField.data.acparameter && autactparamTramTemplateField.data.acparameter.finalParameterType === "JSONArray"){
    										jsons['tramsTemplate'] = {value:autactparamTramTemplateField.data.object, array:true};
    									}
    								}
    								
    							}
    						}else if(acat && (acat.acronym === 'startDossier' || acat.acronym === 'updateDossier' ) && data.objectIdProc){
    							sendData.objectId = data.objectIdProc;
    						} 
                    	}
                    	sendData.origType = ((data && data.origType)?data.origType:undefined);
                    	var cronField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'cronExpression'");
                        if(cronField && cronField.data && cronField.data.cronExpression ){
                            if(sendData.cronSchedule && sendData.cronSchedule.id){
                            	sendData.cronSchedule.cronExpression = cronField.data.cronExpression;
                            }else{
                            	sendData.cronSchedule = {cronExpression:cronField.data.cronExpression};	
                            }
                        }else{
                        	sendData.cronSchedule = undefined;	
                        }
                    	if(data.queryParametersShow && data.queryParametersOrig && data.queryParametersOrig.length > 0){
                    		var queryParamteres = [];
                    		_.forEach(data.queryParametersOrig, function(qp){
                    			var qpToAdd = undefined;
                    			if(sendData.queryParameters && sendData.queryParameters.length > 0 && qp.id){
                    				qpToAdd = $linq(sendData.queryParameters).firstOrDefault(undefined, "x => x.parameter && x.parameter.id == "+qp.id);
                    			}
                    			if(qpToAdd){
                    				if(data.queryParameters && qp.alias && data.queryParameters[qp.alias]){
                    					qpToAdd.parameterValue = data.queryParameters[qp.alias];
                    					queryParamteres.push(qpToAdd);
                    				}else if(data.queryParameters && qp.alias && data.queryParameters[qp.alias+'_BOOLEAN']){
                    					qpToAdd.parameterValue = data.queryParameters[qp.alias+'_BOOLEAN'];
                    					queryParamteres.push(qpToAdd);
                        			}else if(data.queryParameters && qp.alias && data.queryParameters[qp.alias+'_BOOLEAN_NULL']){
                        				qpToAdd.parameterValue = data.queryParameters[qp.alias+'_BOOLEAN_NULL'];
                    					queryParamteres.push(qpToAdd);
                        			}
                    			}else if(data.queryParameters && qp.alias && data.queryParameters[qp.alias] && qp.id){
                    				queryParamteres.push({parameter:{id:qp.id} , parameterValue:data.queryParameters[qp.alias]});
                				}else if(data.queryParameters && qp.alias && data.queryParameters[qp.alias+'_BOOLEAN'] && qp.id){
                					queryParamteres.push({parameter:{id:qp.id} , parameterValue:data.queryParameters[qp.alias+'_BOOLEAN']});
                    			}else if(data.queryParameters && qp.alias && data.queryParameters[qp.alias+'_BOOLEAN_NULL'] && qp.id){
                    				queryParamteres.push({parameter:{id:qp.id} , parameterValue:data.queryParameters[qp.alias+'_BOOLEAN_NULL']});
                    			}
                            });
                    		sendData.queryParameters = queryParamteres;
                    	}else{
                    		sendData.queryParameters = [];
                    	}
                    	if(data.queryColumnsTypesShow && data.queryColumnsOrig && data.queryColumnsOrig.length > 0){
                    		var columnsTypes = [];
                    		_.forEach(data.queryColumnsOrig, function(col){
                    			var colToAdd = undefined;
                    			if(sendData.columnsTypes && sendData.columnsTypes.length > 0){
                    				colToAdd = $linq(sendData.columnsTypes).firstOrDefault(undefined, "x => x.name == '"+col+"'");
                    			}
                    			if(colToAdd){
                    				if(data.columnsTypes && data.columnsTypes[col]){
                    					colToAdd.columnType = data.columnsTypes[col];
                    					columnsTypes.push(colToAdd);
                    				}
                    			}else if(data.columnsTypes && data.columnsTypes[col]){
                    				columnsTypes.push({name:col , columnType:data.columnsTypes[col]});
                    			}
                            });    
                    		sendData.columnsTypes = columnsTypes;
                    	}else{
                    		sendData.columnsTypes = [];
                    	}
                    	var acpsOK = true;
                    	if(data.actionCatalogParametersShow &&  data.actionCatalogParametersOrig && data.actionCatalogParametersOrig.length > 0){
                    		var addJSONS = function(jsons, acpToAdd, acpsOK, automatismActionparameters, type){
                    			if(acpsOK && type && acpToAdd && acpToAdd.parameter && acpToAdd.parameter.acronym && jsons && automatismActionparameters){
                    				var acpValue = jsons[acpToAdd.parameter.acronym];
                    				if(acpValue){
	                    				if(type === 'JSONArray' && acpValue.array){
	                    					if(acpValue.value){
	                    						_.forEach(acpValue.value, function(acpVal){
	                    							if(acpVal.type && !acpVal.value){
	                    								acpsOK = false;
	                    							}
	                    						});
	                    						if(acpsOK){
	                    							acpToAdd.parameterValue = JSON.stringify(acpValue.value);
	                    							acpToAdd.parameterType = type;
	                    							automatismActionparameters.push(acpToAdd);
	                    						}
	                    					}
	                    				}else if(type === 'JSON' && !acpValue.array){
	                    					if(acpVal.type && !acpVal.value){
                								acpsOk = false;
                							}else{
		                    					acpToAdd.parameterValue = JSON.stringify(acpValue.value);
	                							acpToAdd.parameterType = type;
	                							automatismActionparameters.push(acpToAdd);
                							}
	                    				}
                    				}
                    			}
                    		}
                    		var automatismActionparameters = [];
                    		_.forEach(data.actionCatalogParametersOrig, function(acp){
                    			var acpToAdd = undefined;
                    			if(sendData.automatismActionparameters && sendData.automatismActionparameters.length > 0 && acp.id){
                    				acpToAdd = $linq(sendData.automatismActionparameters).firstOrDefault(undefined, "x => x.parameter && x.parameter.id == "+acp.id);
                    			}
                    			if(acpToAdd){
                    				if(acpToAdd.parameter && acpToAdd.parameter.finalParameterType && (acpToAdd.parameter.finalParameterType === "JSONArray" || acpToAdd.parameter.finalParameterType === "JSON")){
                    					addJSONS(jsons, acpToAdd, acpsOK, automatismActionparameters, acpToAdd.parameter.finalParameterType);
                    				}else{
                    					if(data.automatismActionparameters && data.automatismActionparameters[acp.acronym]){
                    						if(data.automatismActionparameters[acp.acronym+'ParamColumnType']){
                    							acpToAdd.parameterValue = data.automatismActionparameters[acp.acronym];
                    							acpToAdd.parameterType = data.automatismActionparameters[acp.acronym+'ParamColumnType'];
                    							automatismActionparameters.push(acpToAdd);
                    						}else{
                    							acpsOK = false;
                    						}
                    					}
                    				}
                    			}else if(data.automatismActionparameters && data.automatismActionparameters[acp.acronym]){
                    				if(acp && acp.finalParameterType && (acp.finalParameterType === "JSONArray" || acp.finalParameterType === "JSON")){
                    					addJSONS(jsons, {parameter:{id:acp.id, acronym:acp.acronym}, parameterValue:undefined, parameterType:acp.finalParameterType}, acpsOK, automatismActionparameters, acp.finalParameterType);
                    				}else{
                    					if(data.automatismActionparameters[acp.acronym+'ParamColumnType']){
                    						automatismActionparameters.push({parameter:{id:acp.id} , parameterValue:data.automatismActionparameters[acp.acronym] , parameterType:data.automatismActionparameters[acp.acronym+'ParamColumnType'] });
                    					}else{
                    						acpsOK = false;
                    					}
                    				}
                    			}else if(acp && acp.finalParameterType && (acp.finalParameterType === "JSONArray" || acp.finalParameterType === "JSON")){
                    				addJSONS(jsons, {parameter:{id:acp.id, acronym:acp.acronym}, parameterValue:undefined, parameterType:acp.finalParameterType}, acpsOK, automatismActionparameters, acp.finalParameterType);
                    			}
                            });                    		
                    		sendData.automatismActionparameters = automatismActionparameters;
                    	}else{
                    		sendData.automatismActionparameters = [];
                    	}
                    	var dcfOK = true;
                    	var calculateCFTGroups = function(newGroups){
	                    	var groups = [];
	                    	if(newGroups){
	                    		_.forEach(newGroups, function(ng){
	                    			_.forEach(ng.groups, function(g){
	                    				g.groups = calculateCFTGroups(g.newGroups); 
	                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
	                    				groups.push(g);
	                    			})
	                    		})
	                    	}
	                    	return groups;
	                    }
                    	if(data.dossierCustomFieldsShow &&  data.dossierCustomFieldsOrig && data.dossierCustomFieldsOrig.length > 0){
                    		var dossierCustomFields = [];
                    		_.forEach(data.dossierCustomFieldsOrig, function(acp){
                    			if(acp && acp.customField && acp.customField.frontendType === 'CF_GROUP'){
                    				acp.groups = calculateCFTGroups(acp.newGroups);
                    				dossierCustomFields.push({customField:{id:acp.customField.id} , groups:acp.groups});
                    			}else{
			                    	var acpToAdd = undefined;
	                    			if(sendData.dossierCustomFields && sendData.dossierCustomFields.length > 0 && acp.customField && acp.customField.id){
	                    				acpToAdd = $linq(sendData.dossierCustomFields).firstOrDefault(undefined, "x => x.customField && x.customField.id == "+acp.customField.id);
	                    			}
	                    			if(acpToAdd){
	                    				if(data.dossierCustomFields && data.dossierCustomFields[acp.customField.templateTag]){
	                    					if(data.dossierCustomFields[acp.customField.templateTag+'ParamColumnType']){
		                    					acpToAdd.customFieldValue = data.dossierCustomFields[acp.customField.templateTag];
			                    				acpToAdd.customFieldType = data.dossierCustomFields[acp.customField.templateTag+'ParamColumnType'];
			                    				dossierCustomFields.push(acpToAdd);
		                    				}else{
		                    					dcfOK = false;
		                    				}
	                    				}
	                    			}else if(data.dossierCustomFields && data.dossierCustomFields[acp.customField.templateTag]){
	                    				if(data.dossierCustomFields[acp.customField.templateTag+'ParamColumnType']){
	                    					dossierCustomFields.push({customField:{id:acp.customField.id} , customFieldValue:data.dossierCustomFields[acp.customField.templateTag] , customFieldType:data.dossierCustomFields[acp.customField.templateTag+'ParamColumnType'] });
	                    				}else{
	                    					dcfOK = false;
	                    				}
	                    			}
                    			}
                            });                    		
                    		sendData.dossierCustomFields = dossierCustomFields;
                    	}else{
                    		sendData.dossierCustomFields = [];
                    	}
                    	if(data.dossierTransactionCustomFieldsShow &&  data.dossierTransactionCustomFieldsOrig && data.dossierTransactionCustomFieldsOrig.length > 0){
                    		var dossierTransactionCustomFields = [];
                    		_.forEach(data.dossierTransactionCustomFieldsOrig, function(acp){
                    			if(acp && acp.customField && acp.customField.frontendType === 'CF_GROUP'){
                    				acp.groups = calculateCFTGroups(acp.newGroups);
                    				dossierTransactionCustomFields.push({customField:{id:acp.customField.id} , groups:acp.groups});
                    			}else{
		                    		var acpToAdd = undefined;
	                    			if(sendData.dossierTransactionCustomFields && sendData.dossierTransactionCustomFields.length > 0 && acp.customField && acp.customField.id){
	                    				acpToAdd = $linq(sendData.dossierTransactionCustomFields).firstOrDefault(undefined, "x => x.customField && x.customField.id == "+acp.customField.id);
	                    			}
	                    			if(acpToAdd){
	                    				if(data.dossierTransactionCustomFields && data.dossierTransactionCustomFields[acp.customField.templateTag]){
	                    					if(data.dossierTransactionCustomFields[acp.customField.templateTag+'ParamColumnType']){
		                    					acpToAdd.customFieldValue = data.dossierTransactionCustomFields[acp.customField.templateTag];
			                    				acpToAdd.customFieldType = data.dossierTransactionCustomFields[acp.customField.templateTag+'ParamColumnType'];
			                    				dossierTransactionCustomFields.push(acpToAdd);
		                    				}else{
		                    					dcfOK = false;
		                    				}
	                    				}
	                    			}else if(data.dossierTransactionCustomFields && data.dossierTransactionCustomFields[acp.customField.templateTag]){
	                    				if(data.dossierTransactionCustomFields[acp.customField.templateTag+'ParamColumnType']){
	                    					dossierTransactionCustomFields.push({customField:{id:acp.customField.id} , customFieldValue:data.dossierTransactionCustomFields[acp.customField.templateTag] , customFieldType:data.dossierTransactionCustomFields[acp.customField.templateTag+'ParamColumnType'] });
	                    				}else{
	                    					dcfOK = false;
	                    				}
	                    			}
                    			}
                            });                    		
                    		sendData.dossierTransactionCustomFields = dossierTransactionCustomFields;
                    	}else{
                    		sendData.dossierTransactionCustomFields = [];
                    	}
                    	if(data.documentCustomFieldsShow &&  data.documentCustomFieldsOrig && data.documentCustomFieldsOrig.length > 0){
                    		var documentCustomFields = [];
                    		_.forEach(data.documentCustomFieldsOrig, function(acp){
                    			if(acp && acp.customField && acp.customField.frontendType === 'CF_GROUP'){
                    				acp.groups = calculateCFTGroups(acp.newGroups);
                    				documentCustomFields.push({customField:{id:acp.customField.id} , groups:acp.groups});
                    			}else{
	                    			var acpToAdd = undefined;
	                    			if(sendData.documentCustomFields && sendData.documentCustomFields.length > 0 && acp.customField && acp.customField.id){
	                    				acpToAdd = $linq(sendData.documentCustomFields).firstOrDefault(undefined, "x => x.customField && x.customField.id == "+acp.customField.id);
	                    			}
	                    			if(acpToAdd){
	                    				if(data.documentCustomFields && data.documentCustomFields[acp.customField.templateTag]){
	                    					if(data.documentCustomFields[acp.customField.templateTag+'ParamColumnType']){
		                    					acpToAdd.customFieldValue = data.documentCustomFields[acp.customField.templateTag];
			                    				acpToAdd.customFieldType = data.documentCustomFields[acp.customField.templateTag+'ParamColumnType'];
			                    				documentCustomFields.push(acpToAdd);
		                    				}else{
		                    					dcfOK = false;
		                    				}
	                    				}
	                    			}else if(data.documentCustomFields && data.documentCustomFields[acp.customField.templateTag]){
                    					if(data.documentCustomFields[acp.customField.templateTag+'ParamColumnType']){
	                    					documentCustomFields.push({customField:{id:acp.customField.id} , customFieldValue:data.documentCustomFields[acp.customField.templateTag] , customFieldType:data.documentCustomFields[acp.customField.templateTag+'ParamColumnType'] });
	                    				}else{
	                    					dcfOK = false;
	                    				}
	                    			}
                    			}
                            });                    		
                    		sendData.documentCustomFields = documentCustomFields;
                    	}else{
                    		sendData.documentCustomFields = [];
                    	}
                    	if(!acpsOK){
                    		self.alerts.length = 0;
                    		self.alerts.push({ msg: $filter('translate')('global.querys.automatisms.typeRequired') });
                    	}else if(!dcfOK){
                    		self.alerts.length = 0;
                    		self.alerts.push({ msg: $filter('translate')('global.querys.automatisms.typeRequiredCF') });
                    	}else{
	                        RestService.update('./api/automatism/'+sendData.id, sendData).then(function (dataGet) {
	                        	$rootScope.$broadcast('annexaAdminBaseReloadTable');
	                        	self.close();
	                        }).catch(function (error) {
	                        	self.alerts.length = 0;
	                        	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.literals.errorCreate') });
	                        });
                    	}
                	}
                };
    }]);