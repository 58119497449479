/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('TransactionTypeController',['$q', '$http','$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'AnnexaModalFactory', 'apiAdmin', 'AdminFactory', 'dialogs', 'DialogsFactory', 'GlobalDataFactory', 'CommonService', 'HelperService',
        function($q, $http, $scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, AnnexaModalFactory, apiAdmin, AdminFactory, dialogs, DialogsFactory, GlobalDataFactory, CommonService, HelperService) {
        $scope.showLoadingdiv = false;

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');


        $scope.dtDefTransactionTypes = new TableData('tram','TransactionType','./api/common/byfiler','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','orderView',[[1, 'asc']]);

        var transactionTypeNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var transactionTypeColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                   new ActionButton('global.literals.see','editTransactionType([data])','fa-eye'),
                                                   [new ActionButton('global.literals.remove','deleteTransactionType([data])')]);

        $scope.tableTransactionTypes = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('orderView').withOption('width', '5%').withTitle($filter('translate')('global.literals.order')),
                DTColumnBuilder.newColumn(transactionTypeNameColumn.getColumn()).withTitle(transactionTypeNameColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('width', '12%').withOption('className', 'text-center').withTitle(transactionTypeColumn.getTitle()).notSortable().renderWith(transactionTypeColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: { langColumn: Language.getActiveColumn()},
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = { langColumn: Language.getActiveColumn()};
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.transaction_type') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-transactionType','fa fa-plus','global.commonAdmin.modal.transactionType.titleNew',undefined,'openNewTransactionType'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-transactionType','fa fa-upload','global.literals.import',undefined,'importTransactionType')
                ];
            }
        })

        HeaderService.changeState($state.current);

        $scope.saveTransactionType = function() {
            var data = this.annexaFormly.model.modal_body;
			var dataColumn1 = ((data && data.column1)?angular.copy(data.column1):{});
            var phases = [];
            if(dataColumn1.phases && dataColumn1.phases.length > 0){
                _.forEach(dataColumn1.phases, function(item){
                    phases.push({ tramitationPhase: { id:item}});
                });
                dataColumn1.phases = phases;
            }
            var tramitationTypes = [];
            if(dataColumn1.tramitationTypes && dataColumn1.tramitationTypes.length > 0){
                _.forEach(dataColumn1.tramitationTypes, function(item){
                	tramitationTypes.push({ tramitationType: { id:item}});
                });
                dataColumn1.tramitationTypes = tramitationTypes;
            }
            if(dataColumn1.active_1 != undefined) {
            	dataColumn1.active = dataColumn1.active_1;
            }
            if(dataColumn1.subprocedures && dataColumn1.subprocedures.length > 0) {
            	dataColumn1.subprocedures = listObject(dataColumn1.subprocedures, true, 'subprocedure');
            }
			var dataCustomFields = [];
			if(this.annexaFormly.fields && this.annexaFormly.fields.length > 0 && this.annexaFormly.fields[0].fieldGroup
			&& this.annexaFormly.fields[0].fieldGroup.length > 1 &&  this.annexaFormly.fields[0].fieldGroup[1].fieldGroup 
			&&  this.annexaFormly.fields[0].fieldGroup[1].fieldGroup.length > 0 && this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data
			&& this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields){
				dataCustomFields = angular.copy(this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields);
			}
			var transactionType = {transactionType: dataColumn1, customFields:dataCustomFields};
			var modal = this;
            if(!(dataColumn1.transactionSubtype == 0 || dataColumn1.transactionSubtype == 1 || dataColumn1.transactionSubtype == 7) && dataColumn1.startType){
            	DialogsFactory.error($filter('translate')('global.literals.transactionSubtypeNotInitial'));
            }else if(dataColumn1.transactionSubtype != 0 && dataColumn1.transactionSubtype != 2 && dataColumn1.transactionSubtype != 3 && dataColumn1.transactionSubtype != 10 && dataColumn1.finallyType){
                DialogsFactory.error($filter('translate')('global.literals.transactionSubtypeNotFinal'));
            }else{
	            CommonAdminService.insertOrUpdateTransactionType(transactionType).then(function(data) {
	                modal.close();
	                $scope.doSearchFilter();
	            }).catch(function(error) {
	                console.log("ERROR");
	            });
            }
        };
        
        
        $scope.saveTransactionTypeEdit = function(modify, property) {
            var modal = this;
            var valid = true;
            var model = angular.copy(modal.data);
            var phases = [];
            var tramitationTypes = [];
            var modifyPhases = false;
            var modifyTramitationTypes = false;
            if(property != 'subprocedures') {
            	modal.data.subprocedures = modal.subproceduresAux;
            }
            
            if(property == 'language1') {
                var languages = $rootScope.app.languagedef;
                var column = "language";
                if (languages) {
                    angular.forEach(languages, function (value2, key2) {
                        if (modal.data && !modal.data[value2.column]) {
                            valid = false;
                        }
                    });
                }
            } else if(property == 'subprocedures') {
            	modal.data[property] = modify;
            	model[property] = listObject(modify, true, 'subprocedure');
            } else if(property == 'tramitationTypes'){
            	modifyTramitationTypes = true;
                _.forEach(modify, function(item){
                	tramitationTypes.push({ tramitationType: { id:item.id}});
                });
               
                model[property] = tramitationTypes;
                modal.data[property] = modify;
            } else {
                if(property == 'phases'){
                	modifyPhases = true;
                    _.forEach(modify, function(item){
                        phases.push({ tramitationPhase: { id:item.id}});
                    });
                   
                    model[property] = phases;
                    modal.data[property] = modify;
                }else{
                    model[property] = modify;
                    modal.data[property] = modify;
                }
            }
            if(!modifyPhases && model.phases){
            	_.forEach(model.phases, function(item){
                    phases.push({ tramitationPhase: { id:item.id}});
                });
                model.phases = phases;
            }
            if(!modifyTramitationTypes && model.tramitationTypes){
            	_.forEach(model.tramitationTypes, function(item){
            		tramitationTypes.push({ tramitationType: { id:item.id}});
                });
                model.tramitationTypes = tramitationTypes;
            }
            if(!(modal.data.transactionSubtype == 0 || modal.data.transactionSubtype == 1 || modal.data.transactionSubtype == 7) && modal.data.startType){
            	return $filter('translate')('global.literals.transactionSubtypeNotInitial');
            }else if(modal.data.transactionSubtype != 0 && modal.data.transactionSubtype != 2 && modal.data.transactionSubtype != 3 && modal.data.transactionSubtype != 10 && modal.data.finallyType){
            	return $filter('translate')('global.literals.transactionSubtypeNotFinal');
            }else{
	            if(valid) {
	                CommonAdminService.insertOrUpdateTransactionType({transactionType:model}, ((property != 'subprocedures')?modal.subproceduresAux:undefined))
	                    .then(function (data) {
	                        modal.transactionTypeAux = {
	                            language1: modal.data.language1,
	                            language2: modal.data.language2,
	                            language3: modal.data.language3,
	                            tramitationTypes: modal.data.tramitationTypes,
	                            finallyType: modal.data.finallyType,
	                            startType: modal.data.startType,
	                            countDays: modal.data.countDays,
	                            phases: modal.data.phases,
	                            days: modal.data.days,
	                            transactionSubtype: modal.data.transactionSubtype,
	                            expireType: modal.data.expireType,
	                            allowUserChangeMaxDays:modal.data.allowUserChangeMaxDays,
	                            allowUserChangeCountDays:modal.data.allowUserChangeCountDays,
	                            activityGia: modal.data.activityGia
	                        };
	                    $scope.doSearchFilter();
	                }).catch(function (error) {
	                    console.log("ERROR");
	                });
	            }else{
	                return $filter('translate')('global.validation.required');
	            }
            }
        };
        $scope.cancelTransactionTypeEdit = function() {
            var modal = this;
            if(modal && modal.transactionTypeAux){
                modal.data.language1 = modal.transactionTypeAux.language1;
                modal.data.language2 = modal.transactionTypeAux.language2;
                modal.data.language3 = modal.transactionTypeAux.language3;
                modal.data.tramitationTypes = modal.transactionTypeAux.tramitationTypes;
                modal.data.finallyType = modal.transactionTypeAux.finallyType;
                modal.data.startType = modal.transactionTypeAux.startType;
                modal.data.countDays = modal.transactionTypeAux.countDays;
                modal.data.phases = modal.transactionTypeAux.phases;
                modal.data.days = modal.transactionTypeAux.days;
                modal.data.transactionSubtype = modal.transactionTypeAux.transactionSubtype;
                modal.data.expireType = modal.transactionTypeAux.expireType;
                modal.data.allowUserChangeMaxDays = modal.transactionTypeAux.allowUserChangeMaxDays;
                modal.data.allowUserChangeCountDays = modal.transactionTypeAux.allowUserChangeCountDays;
                modal.data.activityGia = modal.activityGia;
            }
        };

        $scope.deleteTransactionType = function(id) {
            if(id) {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.deleteTransactionType'),
                    $filter('translate')('global.literals.confirmDeleteTransactionTypeMessage'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );

                dlg.result.then(function (btn) {
                    CommonAdminService.deleteTransactionType(id).then(function (data) {
                        $scope.doSearchFilter();
                    }).catch(function (error) {
                        var err = dialogs.error(
                            $filter('translate')('DIALOGS_ERROR_MSG'),
                            $filter('translate')(error.data.message),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                    });
                }, function (btn) {
                });


            }
        };

        $scope.canEdit = true;
        
        $scope.editTransactionType = function(id) {
        	CommonAdminService.haveProcedureTransactionType(id)
				.then(function (data) {
					if(data) {
						$scope.canEdit = false;
					} else {
						$scope.canEdit = true;
					}
					var promises = [];
					promises.push(RestService.findOne('TransactionType', id));
					promises.push($http({url: './api/transactiontypes/transactionTypesCustomFields/'+id, method: 'GET'}));
                    $q.all(promises).then(function(data) {
	                    	var calculateRequiredStringGroups = function(groups){
	                    		if(groups && groups.length > 0){
	                    			_.forEach(groups, function(group){
	                    				group.requiredString = 'OPTIONAL';
	                                    if(group.required){
	                                    	group.requiredString = 'REQUIRED';
	                                    }else if(group.requiredForEndDossier){
	                                    	group.requiredString = 'REQUIRED_TO_END';
	                                    }else if(group.noEditable) {
	                                    	group.requiredString = 'NO_EDITABLE';
	                                    }
	                                    if(group.groups && group.groups.length > 0){
	                                    	calculateRequiredStringGroups(group.groups);
	                                    }
	                    			});
	                    		}
	                    	}
		                    var modal = angular.copy(CommonAdminModals.transactionTypeEdit);
		                    var customFieldsType = [];
		                    modal.activeColumn = Language.getActiveColumn();
                            modal.yesNoList = apiAdmin.yesNoBoolean;
		                    modal.expirationTypeList = apiAdmin.expirationTypeList;
		                    modal.transactionSubtypesList = new SortedArray(apiAdmin.transactionSubtypes, 'order').sort();
		                    modal.subproceduresList = $linq(GlobalDataFactory.procedures).where("x => x.procedureType == 'SUBPROCEDURE' || x.procedureType == 'PROCEDURE_SUBPROCEDURE'").orderBy("x => x." + modal.activeColumn).toArray();
		                    modal.data = JSOG.decode(data[0].data);
							var cfs = JSOG.decode(data[1].data);
							angular.forEach(cfs, function (value, key) {
                                var customField = {
                                    customField: value.customField,
                                    id: value.id,
                                    delete: value.deleted,
                                    viewOrder:value.viewOrder,
                                    required: value.required,
                                    noEditable: value.noEditable,
                                    transactionType: value.transactionType,
                                    createdDate: value.createdDate,
                                    value: value.value,
                                    descriptionLanguage1: value.descriptionLanguage1,
                                    descriptionLanguage2: value.descriptionLanguage2,
                                    descriptionLanguage3: value.descriptionLanguage3,
                                    nameLanguage1: value.nameLanguage1,
        	                        nameLanguage2: value.nameLanguage2,
        	                        nameLanguage3: value.nameLanguage3,
			                        conditional: value.conditional,
			                        spel: value.spel,
			                        spelDossier: value.spelDossier,
			                        identifierField: value.identifierField,
			                        labelPropField:value.labelPropField,
			                        labelFieldsSeparator:value.labelFieldsSeparator,
			                        parentField:value.parentField,
			                        queryParams: value.queryParams,
			                        relatedCustomFields: value.relatedCustomFields,
			                        calculated:value.calculated,
			                        dynamicallyCalculated: value.dynamicallyCalculated,
			                        spelCalculated: value.spelCalculated,
			                        spelCalculatedDossier: value.spelCalculatedDossier,
			                        canPublish: value.canPublish,
			                        groups: value.groups
                                };
                                customField.requiredString = 'OPTIONAL';
                                if(value.required){
                                    customField.requiredString = 'REQUIRED';
                                }else if(value.noEditable) {
                                    customField.requiredString = 'NO_EDITABLE';
                                }
                                calculateRequiredStringGroups(customField.groups);
                                customFieldsType.push(customField);
                            });
							if(modal.data.subprocedures && modal.data.subprocedures.length > 0) {
								modal.subproceduresAux = angular.copy(modal.data.subprocedures);
								var subp = []
								angular.forEach(modal.data.subprocedures, function(value, key){
									if(value.subprocedure) {
										subp.push(value.subprocedure.id);
									}
								});
								modal.data.subprocedures = subp;
							}
							modal.customFields = customFieldsType;
		                    modal.transactionTypeAux = {language1:modal.data.language1, language2:modal.data.language2, language3:modal.data.language3,  finallyType:modal.data.finallyType, startType:modal.data.startType, countDays:modal.data.countDays, phases:modal.data.phases, days:modal.data.days, tramitationTypes: modal.data.tramitationTypes, transactionSubtype:modal.data.transactionSubtype, expireType:modal.data.expireType};
		                    modal.saveTransactionTypeEdit = $scope.saveTransactionTypeEdit;
		                    modal.cancelTransactioonTypeEdit = $scope.cancelTransactionTypeEdit;
                            modal.data.phases = $linq(modal.data.phases).select("x => x.tramitationPhase").toArray();
                            modal.data.tramitationTypes = $linq(modal.data.tramitationTypes).select("x => x.tramitationType").toArray();
		                    modal.tramitationPhases = AdminFactory.tramitationPhases;
                            modal.printPhases = function (model) {
                                var profs = '';
                                angular.forEach(model, function (val, key) {
                                    if(profs != '') {
                                        profs += ', ';
                                    }

                                    profs += val[Language.getActiveColumn()];
                                });

                                return profs;
                            }
                            modal.printTramitationTypes = function (model) {
                                var types = '';
                                angular.forEach(model, function (val, key) {
                                    if(types != '') {
                                    	types += ', ';
                                    }

                                    types += val[Language.getActiveColumn()];
                                });

                                return types;
                            }
		                    modal.tramitationTypes = GlobalDataFactory.dossierTramitationTypes;
		                    modal.canEdit = $scope.canEdit;
		                    modal.showTransactionSubtype = function(){
		                    	var subtypeSelected = modal.transactionTypeAux.transactionSubtype;
		                    	switch(subtypeSelected) {
		                    		case 0: return $filter('translate')('global.literals.standard');
		                    		case 1: return $filter('translate')('global.literals.proposalAgreement');
		                    		case 2: return $filter('translate')('global.literals.manageAgreement');
		                    		case 3: return $filter('translate')('global.literals.transferAgreement');
		                    		case 4: return $filter('translate')('global.literals.amendmentAgreement');
		                    		case 5: return $filter('translate')('global.literals.inspectionIntervention');
                    				case 6: return $filter('translate')('global.literals.inspectionDepartment');
                    				case 7: return $filter('translate')('global.literals.startInspectionFree');
                    				case 8: return $filter('translate')('global.literals.inspectionInterventionFree');
                    				case 9: return $filter('translate')('global.literals.inspectionDepartmentFree');
                    				case 10: return $filter('translate')('global.literals.manageAgreementFree');
                    				case 11: return $filter('translate')('global.literals.omission');
                    				case 12: return $filter('translate')('global.literals.reparation');
                    				case 13: return $filter('translate')('global.literals.omissionFree');
                    				case 14: return $filter('translate')('global.literals.reparationFree');
									case 15: return $filter('translate')('global.literals.manageAgreementOmission');
									case 16: return $filter('translate')('global.literals.discrepances');
                    				default: return 'empty';
		                    	}
		                    }
		                    modal.printActive = function (model) {
		                        if(model) {
		                            return $filter('translate')('global.commonAdmin.modal.transactionType.transactionTypeActive');
		                        } else {
		                            return $filter('translate')('global.commonAdmin.modal.transactionType.transactionTypeNoActive');
		                        }
		                    };
		                    modal.getSubprocedureValue = function(subprocedures){
		                        var prof = '';
		                        if(subprocedures){
		                            angular.forEach(subprocedures, function(value, key){
		                                var label = '';
		                                angular.forEach(modal.subproceduresList, function(value2, key2){
		                                    if(value && value.subprocedure && value.subprocedure.id == value2.id){
		                                    	label = value2[Language.getActiveColumn()];
		                                    } else if(value == value2.id) {
		                                        label = value2[Language.getActiveColumn()];
		                                    }
		                                });
		                                if(prof){
		                                    prof += ", "+label;
		                                }else{
		                                    prof += label;
		                                }
		                            })
		                        }
		                        return prof;
		                    };
		                    modal.showGIA = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.active_gia_diba && $rootScope.app.configuration.active_gia_diba.value === true)?true: false);
		                    modal.activitiesGia = angular.copy(GlobalDataFactory.activitiesGia);
		                    modal.activitiesGia.unshift({id:'', name:"global.tram.literals.gia.activities.nothing"});
		                    modal.getActivityGia = function(activity){
		                    	return $filter('translate')(((activity)? 'global.tram.literals.gia.activities.'+activity:'global.tram.literals.gia.activities.nothing')); 
		                    }
		                    AnnexaModalFactory.showModal('modalTransactionTypeEdit', modal);
		                });
			}).catch(function (error) {
	            console.log("ERROR");
	        });
        }

       $scope.openNewTransactionType = function () {
           var modal = angular.copy(CommonAdminModals.transactionType);
           modal.annexaFormly.model = {};
           modal.annexaFormly.model.modal_body = { column1: {active_1: true}, column2:{customFields:[]} };
           modal.annexaFormly.options = {};
           modal.annexaFormly.options.formState = { readOnly: false};
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[2].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[2].templateOptions.options = GlobalDataFactory.dossierTramitationTypes;
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[3].templateOptions.options = $linq(AdminFactory.tramitationPhases).toArray();
           AnnexaFormlyFactory.showModal("modalTransactionType", modal, $scope.saveTransactionType, false);
       }

        $scope.doSearchFilter=function(queued) {
            $scope.tableTransactionTypes.reloadInternalData(true);
        }
        
        $scope.importTransactionType = function () {  
        	AdminFactory.importDataAdmin($scope, "TRANSACTION_TYPE", $scope.tableTransactionTypes);
        }
        
        
        
        var listObject = function(objects, server, name){
            var objs = undefined;
            if(objects) {
                if(name) {
                    objs = [];
                    angular.forEach(objects, function (value, key) {
                        if (server) {
                            var obj = {};
                            obj[name] = {id: value};
                            objs.push(obj);
                        } else {
                            objs.push(value);
                        }

                    });
                }else{
                    objs = objects;
                }
            }
            return objs;
        }
        
    }]);
