/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('apiAdmin', {
            module:'ADMIN',
            moduleId:'admin',
            addUserPhoto: './api/users/addUserPhoto',
            getUsers: './api/users',
            getLanguages: './api/language',
            getSections: './api/sections',
            getSectionsByFilter: './api/sections/getSections',
            getProfiles: './api/profiles',
            getProfileById: './api/profiles/getProfileById',
            getProfileAdd: './api/profiles/insertOrUpdateProfile',
            getProfileDelete: './api/profiles/deleteProfile',
            getUserSections:'./api/userSections',
            getUserModules:'./api/usermodules',
            getUserProfiles:'./api/userProfiles',
            removeUserProfile: './api/userProfiles/removeUserProfile',
            getUserDefaultEntityProfiles:'./api/defaultEntityProfiles',
            getDefaultEntityProfilesByUserIdAndEntityType: './api/defaultEntityProfiles/getDefaultEntityProfilesByUserIdAndEntityType',
            insertOrUpdateDefaultEntityProfile: './api/defaultEntityProfiles/insertOrUpdateDefaultEntityProfile',
            removeDefaultEntityProfile: './api/defaultEntityProfiles/removeDefaultEntityProfile',
            getSectionTypes:'./api/sectionTypes',
            getCouncillorById: './api/councillors/getCouncillorById',
            deleteCouncilorById: './api/councillors/deleteCouncillor',
            getTemplates: './api/templates',
            getPresetSignCircuits: './api/presetsigncircuits',
            getPresetSignCircuitById:'./api/presetsigncircuits/getPresetSignCircuitById',
            insertOrUpdatePresetSignCircuit:'./api/presetsigncircuits/insertOrUpdatePresetSignCircuit',
            insertUnguidedProcedure: './api/procedures/insertUnguidedProcedure',
            updateUnguidedProcedure: './api/procedures/updateUnguidedProcedure',
            deleteProcedure: './api/procedures/deleteProcedure',
            getBaseTokenGroup: './api/tokengroups/getBaseTokenGroup',
            getTokensGroups: './api/tokengroups/loadGroupsByName',
            getTokens: './api/tokens/loadGroupsByName',
            statesUsers:[
                    {id:'',name:'global.literals.all'},
                    {id:1,name:'global.commonAdmin.filter.statuses.active'},
                    {id:2,name:'global.commonAdmin.filter.statuses.inactive'}
            ],
            typesProfiles:[
                    {id:'INICI',description:'global.commonAdmin.modal.user.INICI'},
                    {id:'CONSULTA',description:'global.commonAdmin.modal.user.CONSULTA'},
                    {id:'TRAMITADOR',description:'global.commonAdmin.modal.user.TRAMITADOR'}
            ],
            entityTypeProfiles:[
                {id:'REG', description:'global.commonAdmin.modal.defaultEntityProfile.REG'},
                {id:'DOC', description:'global.commonAdmin.modal.defaultEntityProfile.DOC'},
                {id:'EXP', description:'global.commonAdmin.modal.defaultEntityProfile.EXP'},
                {id:'SEC', description:'global.commonAdmin.modal.defaultEntityProfile.SEC'}
            ],
            expirationTypeList:[
                {id:'BUSINESS', description:'global.literals.BUSINESS'},
                {id:'NATURAL', description:'global.literals.NATURAL'},
                {id:'MONTHS', description:'global.literals.MONTHS'},
                {id:'YEARS', description:'global.literals.YEARS'}
            ],
            showSubjectList:[
                {id:'DISABLED', description:'global.literals.DISABLED'},
                {id:'REQUIRED', description:'global.literals.REQUIRED'},
                {id:'OPTIONAL', description:'global.literals.OPTIONAL'}
            ],
            showClassificationList:[
                {id:'DISABLED', description:'global.literals.DISABLED'},
                {id:'REQUIRED', description:'global.literals.REQUIRED'},
                {id:'OPTIONAL', description:'global.literals.OPTIONAL'}
            ],
            customFieldRequiredList:[
                {id:'OPTIONAL', description:'global.literals.OPTIONAL'},
                {id:'REQUIRED', description:'global.literals.REQUIRED'},
                {id:'NO_EDITABLE', description:'global.literals.NO_EDITABLE'},
                {id:'REQUIRED_TO_END', description:'global.literals.REQUIRED_TO_END'},
                {id:'HIDDEN_FIELD', description:'global.literals.HIDDEN_FIELD'}

            ],
            allowThirds:[
                {id:'NO', description:'global.literals.no'},
                {id:'YES', description:'global.literals.yes'}
            ],
            yesNo:[
                {id:'YES', description:'global.literals.yes'},
                {id:'NO', description:'global.literals.no'}
            ],
            yesNoBoolean:[
                {id:true, description:'global.literals.yes'},
                {id:false, description:'global.literals.no'}
            ],
            yesNoBooleanNull:[
            	{id:undefined, description:'global.literals.empty'},
                {id:true, description:'global.literals.yes'},
                {id:false, description:'global.literals.no'}
            ],
            transactionSubtypes:[
                {id:0, description:'global.literals.standard', order:1},
                {id:1, description:'global.literals.proposalAgreement', order:2},
                {id:2, description:'global.literals.manageAgreement', order:7},
                {id:3, description:'global.literals.transferAgreement', order:9},
                {id:4, description:'global.literals.amendmentAgreement', order:8},
                {id:5, description:'global.literals.inspectionIntervention', order:4},
                {id:6, description:'global.literals.inspectionDepartment', order:3},
                {id:7, description:'global.literals.startInspectionFree', order:10},
                {id:8, description:'global.literals.inspectionInterventionFree', order:12},
                {id:9, description:'global.literals.inspectionDepartmentFree', order:11},
                {id:10, description:'global.literals.manageAgreementFree', order:15},
                {id:11, description:'global.literals.omission', order:6},
                {id:12, description:'global.literals.reparation', order:5},
                {id:13, description:'global.literals.omissionFree', order:14},
                {id:14, description:'global.literals.reparationFree', order:13},
				{id:15, description:'global.literals.manageAgreementOmission', order:16},
				{id:16, description:'global.literals.discrepances', order:17}
            ],
            inspectionInterventionTypes:[
                {id:'AUDITING_FUNCTION', name:'global.sec.literals.AUDITING_FUNCTION'},
                {id:'PERMANENT_CONTROL', name:'global.sec.literals.PERMANENT_CONTROL'}
            ],
            proposalTypeSubtypes:[
                {id:'PROPOSAL', description:'global.sec.literals.proposal'},
                {id:'DECREE', description:'global.sec.literals.decree'}
            ],
            decreeSignProcessActionEnum:[
		        {id:'FIRST', description:'global.sec.literals.FIRST'},
		        {id:'LAST', description:'global.sec.literals.LAST'}
		    ],
		    decreeSignProcessTypeEnum:[
		        {id:'SIGN', description:'global.sec.literals.SIGN'},
		        {id:'VALIDATION', description:'global.sec.literals.VALIDATION'},
		        {id:'ANYONE', description:'global.sec.literals.ANYONE'}
            ],
            accessLevelNotificationList:[
                {id:null, description:''},
                {id:'HIGH', description:'global.literals.HIGH'},
                {id:'MEDIUM', description:'global.literals.MEDIUM'},
                {id:'LOW', description:'global.literals.LOW'}
            ],
            operationTypeList:[
                {id:'NONE', description:'global.literals.NONE'},
                {id:'REQUIRED', description:'global.literals.REQUIRED'},
                {id:'OPTIONAL', description:'global.literals.OPTIONAL'}
            ],
            diligenceStampCertificateType:[
                {id:'USER',description:'global.diligence_stamp_type.USER'},
                {id:'ORGAN',description:'global.diligence_stamp_type.ORGAN'}
            ],
            diligenceStampPages:[
                {id:'FIRST',description:'global.diligence_stamp_type.FIRST'},
                {id:'LAST',description:'global.diligence_stamp_type.LAST'},
                {id:'ALL',description:'global.diligence_stamp_type.ALL'}
            ],
            showAddressTypeList:[
                {id:'REQUIRED', description:'global.literals.REQUIRED'},
                {id:'OPTIONAL', description:'global.literals.OPTIONAL'},
                {id:'NOT_REQUIRED', description:'global.literals.DISABLED'}
            ],
            addressTypePermissionType:[
                {id:'CREATE', description:'global.literals.create'},
                {id:'EDIT', description:'global.literals.edit'}
            ],
            scopeApplication:[
                {id:'GLOBAL',description:'global.literals.global'},
                {id:'THIRD',description:'global.thirds.literals.third'}
            ],
            thirdRelationTypes:[
                {id:'ALL',description:'global.literals.all'},
                {id:'INTERESTED',description:'global.literals.interestedDoc'},
				{id:'REPRESENTED',description:'global.literals.representativeDoc'}
            ]
    })
    .constant('CommonAdminModals',{
        aspectNew: {
            title: 'global.literals.newAspect',
            size: '',
            annexaFormly:{
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'customFields',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.customFields',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'Language', 'AdminFactory', 'HelperService', function($scope, Language, AdminFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(AdminFactory.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }

                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        aspectEdit: {
            title: 'global.literals.editAspect',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateAspect(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="annexa-checkbox-list" label="global.literals.customFields" model-value="modal.customFields" model-property="customFields" save="modal.updateAspect(val, prop)" required="false" blur="ignore" label-col="modal.languageColumn" print-function="modal.printCustomFields"></annexa-editable-field>',
                '   </div>',
                '</div>',
            ]
        },
        roleNew: {
            title: 'global.commonAdmin.modal.role.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'active',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'actionPermissions',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.commonAdmin.modal.role.actionPermissions',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        roleEdit: {
            title: 'global.commonAdmin.modal.role.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRole(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.active\' | translate}}" model-value="modal.data.active" model-property="active" save="modal.updateRole(val, prop)" required="false" blur="ignore" print-function="modal.printActive"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="annexa-checkbox-list" label="global.commonAdmin.modal.role.actionPermissions" model-value="modal.actionPermissions" model-property="actionPermissions" save="modal.updateRole(val, prop)" required="false" blur="ignore" label-col="modal.languageColumn" print-function="modal.printActionPermissions"></annexa-editable-field>',
                '   </div>',
                '</div>',

            ]
        },
        roleInterestedNew: {
            title: 'global.commonAdmin.tabs.newRoleInterested',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'canUseRegisterEntry',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'canUseRegisterOutput',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'canUseDossier',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        roleInterestedEdit: {
            title: 'global.commonAdmin.tabs.editRoleInterested',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRole(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.roleInterested.canUseRegisterEntry\' | translate}}" model-value="modal.data.canUseRegisterEntry" model-property="canUseRegisterEntry" save="modal.updateRole(val, prop)" required="false" blur="ignore" print-function="modal.printCanUse"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.roleInterested.canUseRegisterOutput\' | translate}}" model-value="modal.data.canUseRegisterOutput" model-property="canUseRegisterOutput" save="modal.updateRole(val, prop)" required="false" blur="ignore" print-function="modal.printCanUse"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.roleInterested.canUseDossier\' | translate}}" model-value="modal.data.canUseDossier" model-property="canUseDossier" save="modal.updateRole(val, prop)" required="false" blur="ignore" print-function="modal.printCanUse"></annexa-editable-field>',
                '   </div>',
                '</div>',
            ]
        },
        profileNew: {
            title: 'global.commonAdmin.modal.profile.titleNew',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'acronym',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.acronym',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'profileCode',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
	                            key: 'userProfile',
	                            type: 'annexaComponent',
                                className: 'col-sm-12',
	                            templateOptions: {
	                                type: 'annexa-table-profile-users'
	                            },
	                            data: {
	                                roles: undefined,
	                                definition: {}
	                            }
                            }
                        ]
                    }
                ]
            },
        },
        profileEdit: {
            title: 'global.commonAdmin.modal.profile.titleEdit',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateProfile(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateProfile(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.profileCode" model-property="profileCode" save="modal.updateProfile(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="m-x-xs clearfix ">',
                '	<div class="col-lg-12">',
                '		<annexa-editable-table definition="modal.definitionUsers"></annexa-editable-table>',
                '	</div>',
                '</div>'
            ]
        },
        councillorNew: {
            title: 'global.commonAdmin.newCouncillor',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
							{
                        		key: 'row1',
                        		className: '',
                        		templateOptions: {},
                        		fieldGroup: [
		                            {
		                                key: 'type',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-6',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.type',
		                                    valueProp: 'id',
		                                    labelProp: 'language1',
		                                    placeholder: '',
		                                    options: [],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
									{
		                                key: 'language1',
		                                type: 'annexaInputLanguage',
		                                className: 'col-xs-6',
		                                validators:{
		                                    "required": {
		                                        "expression": function (viewValue, modelValue, scope) {
		                                            var valid = true;
		                                            if(scope.languages){
		                                                angular.forEach(scope.languages, function (value2, key2) {
		                                                    if(value2 && value2.column) {
		                                                        if (modelValue && !modelValue[value2.column]) {
		                                                            valid = false;
		                                                        }
		                                                    }
		                                                });
		                                            }
		                                            return valid;
		                                        }
		                                    }
		                                },
		                                watcher:{
		                                    type: '$watchCollection',
		                                    expression: 'model',
		                                    listener: function(field, _new) {
		                                        if(field.formControl){
		                                            field.formControl.$validate();
		                                        }
		                                    }
		                                },
		                                templateOptions: {
		                                    id:'language',
		                                    modelField:'',
		                                    type: 'text',
		                                    label: 'global.literals.name',
		                                    disposition:'horitzontal21',
		                                    required: true,
		                                    focus: true
		                                },
		                                controller: function ($scope, $rootScope) {
		                                    $scope.languages = $rootScope.app.languagedef;
		                                }
		                            }
							]
						},
						{
	                        key: 'row2',
	                        className: '',
	                        templateOptions: {},
	                        fieldGroup: [
	                            {
	                                key: 'councilMan',
	                                type: 'annexaLoadUserRow',
	                                className: 'col-xs-6',
	                                templateOptions: {
	                                    type: 'text',
	                                    label: 'global.literals.responsableCouncilMan',
	                                    required: true,
	                                    focus: false,
	                                    options: [],
	                                    loadFunction: function () {}
	                                },
	                                controller: function ($scope, $rootScope, RestService) {
	                                    $scope.options.templateOptions.loadFunction = function(value) {
	                                        if(!value && !value.val) {
	                                            return [];
	                                        }
	
	                                        if(value.val != '*' && value.val.length < 3) {
	                                            return [];
	                                        }
	
	                                        if(value.val == '*') {
	                                            value.val = '';
	                                        }
	
	                                        return RestService.loadData('common', 'User', value.val)
	                                            .then(function(data) {
	                                                var users = [];
	
	                                                if(data.data && data.data.content && data.data.content.length > 0) {
	                                                    _.forEach(JSOG.decode(data.data.content), function(val) {
	                                                        var name = val.name + ' ' + val.surename1;
	
	                                                        if(val.surename2) {
	                                                            name += ' ' + val.surename2;
	                                                        }
	
	                                                        users.push({ 'id':  val.id, 'user': val, 'value': name });
	                                                    });
	                                                }
	
	                                                return users;
	                                            }).catch(function() {
	                                                return [];
	                                        });
	
	                                    }
	                                },
	                                validators:{
	                                    "requiredCouncillor": {
	                                        "expression": function (viewValue, modelValue, scope) {
	                                            var valid = false;
	                                            if(modelValue && modelValue.id){
	                                                valid = true;
	                                            }
	                                            return valid;
	                                        }
	                                    }
	                                },
	                                watcher:{
	                                    type: '$watchCollection',
	                                    expression: 'model',
	                                    listener: function(field, _new) {
	                                        if(field.formControl){
	                                            field.formControl.$validate();
	                                        }
	                                    }
	                                },
	                                data: {
	                                    row: true,
	                                    colClass: ' col-xs-12',
	                                    labelClass: 'label-strong'
	                                }
	                            },
	                            {
	                                key: 'councilManPosition',
	                                type: 'annexaSelectRow',
	                                className: 'col-xs-6',
	                                templateOptions: {
	                                    optionsAttr: 'bs-options',
	                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                    label: 'global.literals.position',
	                                    valueProp: 'id',
	                                    labelProp: 'language1',
	                                    placeholder: '',
	                                    options: [],
	                                    required: true
	                                },
	                                data: {
	                                    row: true,
	                                    informed: true,
	                                    colClass: ' col-xs-12',
	                                    labelClass: 'label-strong'
	                                }
	                            }
							]
						},
						{
	                        key: 'row3',
	                        className: '',
	                        templateOptions: {},
	                        fieldGroup: [
	                            {
	                                key: 'parent',
	                                type: 'annexaSelectRow',
	                                className: 'col-xs-6',
	                                templateOptions: {
	                                    optionsAttr: 'bs-options',
	                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                    label: 'global.commonAdmin.modal.section.parent',
	                                    valueProp: 'id',
	                                    labelProp: 'language1',
	                                    placeholder: '',
	                                    options: [],
	                                },
	                                data: {
	                                    row: true,
	                                    informed: true,
	                                    colClass: ' col-xs-12',
	                                    labelClass: 'label-strong'
	                                }
	                            },
	                            {
	                                key: 'presetSignCircuit',
	                                type: 'annexaSelectRow',
	                                className: 'col-xs-6',
	                                data: {
	                                    informed: true,
	                                    row: true,
	                                    colClass: ' col-xs-12',
	                                    labelClass: 'label-strong',
	                                    clear: function ($event, model, key, $select) {
	                                        $event.stopPropagation();
	                                        model[key] = undefined;
	                                        if ($select) {
	                                            $select.selected = undefined;
	                                            $select.search = undefined;
	                                        }
	                                    }
	                                },
	                                templateOptions: {
	                                    optionsAttr: 'bs-options',
	                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
	                                    label: 'global.literals.presetSignCircuits',
	                                    valueProp: 'id',
	                                    labelProp: 'language1',
	                                    placeholder: '',
	                                    options: [],
	                                    required: true
	                                },
	                                validation: {
	                                    show: true
	                                },
	                                expressionProperties: {
	                                    'validation.show': 'formControl.$submitted'
	                                }
	                            }
							]
						},
						{
	                        key: 'proposalTypes',
	                        type: 'annexaComponent',
	                        className: 'col-xs-12',
	                        templateOptions: {
	                            type: 'annexa-councillor-proposal-type'
	                        },
							data: {
	                           	object: {},
								isNew:true,
								councillorProposalTypes:[]
	                        },
                            hideExpression: function ($viewValue, $modelValue, scope) {
                            	var $rootScope = angular.element(document.body).injector().get('$rootScope');
								if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active){
									return false;
                                }else{
	                                return true;
								}
                            }
	                    }]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        councillorEdit: {
            title: 'global.commonAdmin.editCouncillor',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.type" model-value="modal.data.type" model-property="type" save="modal.updateCouncillor(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.councillorTypes"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateCouncillor(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="loadUser" label="global.literals.responsableCouncilMan" model-value="modal.councilManAux" model-property="councilMan" save="modal.updateCouncillor(val, prop)" required="true" blur="ignore" search="modal.loadFunction(value)"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.position" model-value="modal.data.councilManPosition" model-property="councilManPosition" save="modal.updateCouncillor(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.positions"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.section.parent" model-value="modal.data.parent" model-property="parent" save="modal.updateCouncillor(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.councillors"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.presetSignCircuits" model-value="modal.data.presetSignCircuit" model-property="presetSignCircuit" save="modal.updateCouncillor(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.presetSignCircuits"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="number" label="global.literals.order" model-value="modal.data.orderView" model-property="orderView" save="modal.updateCouncillor(val, prop)" model-length="modal.maxOrderView" blur="ignore" required="true"></annexa-editable-field>',
                '   </div>',
				'   <div class="col-lg-12" ng-if="modal.councillorProposalTypeActive">',
                '       <annexa-councillor-proposal-type object="modal.data" is-new="false" councillor-proposal-types="modal.data.councillorProposalTypes"></annexa-councillor-proposal-type>',
                '   </div>',
                '</div>'
            ]
        },
        councillorProposalTypeNew: {
            title: 'global.sec.literals.proposalType',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'proposalType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.proposalType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.proposalTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                                key: 'councillorProposalTypeDelegationType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.commonAdmin.literals.councillorDelegationType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'AdminFactory', 'Language', function($scope, AdminFactory, Language) {
									$scope.to.labelProp = Language.getActiveColumn();
									$scope.options.templateOptions.options = AdminFactory.councillorProposalTypeDelegationType;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.proposalType){
	 									var GlobalDataFactory = angular.element(document.body).injector().get('GlobalDataFactory');
										var pt = $linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined, "x => x.id == "+scope.model.proposalType);
										if(pt && pt.proposalTypeSubtype === 'DECREE'){
	                                    	return false;
										}
                                    }
                                    return true;
                                }
                            },
                            {
                                key: 'presetSignCircuit',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.presetSignCircuits',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.presetSignCircuit;
                                }]
                            },
							{
		                        key: 'councillorProposalTypeDelegationDecrees',
		                        type: 'annexaComponent',
		                        className: 'col-xs-12',
		                        templateOptions: {
		                            type: 'annexa-councillor-proposal-type-decree'
		                        },
								data: {
									isNew:true,
									councillorProposalType:undefined,
									councillorProposalTypeDelegationDecrees: []
		                        },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.proposalType){
										var GlobalDataFactory = angular.element(document.body).injector().get('GlobalDataFactory');
										var pt = $linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined, "x => x.id == "+scope.model.proposalType);
										if(pt && pt.proposalTypeSubtype === 'DECREE'){
	                                    	return false;
										}
                                    }
                                    return true;
                                }
		                    },
							{
		                        key: 'councillorProposalTypeDelegationOrgans',
		                        type: 'annexaComponent',
		                        className: 'col-xs-12',
		                        templateOptions: {
		                            type: 'annexa-councillor-proposal-type-organ'
		                        },
								data: {
									isNew:true,
									councillorProposalType:undefined,
									councillorProposalTypeDelegationOrgans: []
		                        },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.proposalType){
										var GlobalDataFactory = angular.element(document.body).injector().get('GlobalDataFactory');
										var pt = $linq(GlobalDataFactory.proposalTypes).firstOrDefault(undefined, "x => x.id == "+scope.model.proposalType);
										if(pt && pt.proposalTypeSubtype !== 'DECREE'){
	                                    	return false;
										}
                                    }
                                    return true;
                                }
		                    }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
		councillorProposalTypesDecreeAdd: {
			title: 'global.commonAdmin.literals.delegationDecrees',
            size: 'modal-md',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'decreeNumber',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.commonAdmin.literals.decreeNumber',
                                    maxlength:1024
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'decreeDate',
                                type: 'annexaDatepicker',
                                className: '',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.commonAdmin.literals.decreeDate',
                                    required: false,
                                    validate: true,
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'decreeOfficialDiary',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.commonAdmin.literals.decreeOfficialDiary',
                                    maxlength:1024
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
						]
					}
				]
			}
		},
		councillorProposalTypesOrganAdd: {
			title: 'global.commonAdmin.literals.proposalTypeOrgan',
            size: 'modal-md',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
							{
                                key: 'organ',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.organ',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'AdminFactory', 'Language', 'RestService', '$rootScope', 'GlobalDataFactory', function($scope, AdminFactory, Language, RestService, $rootScope, GlobalDataFactory) {
                               		$scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.organs;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'delegated',
                                type: 'annexaCheckbox',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.commonAdmin.literals.delegated',
                                    validate: true,
                                    required: false
                                }
                            },
							{
                                key: 'delegationTypes',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.commonAdmin.literals.councillorDelegationType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'Language', 'AdminFactory', function($scope, Language, AdminFactory) {
                                   $scope.to.labelProp = Language.getActiveColumn();
									$scope.options.templateOptions.options = AdminFactory.councillorProposalTypeDelegationType;
                                }],
                            }
						]
					}
				]
			}
		},
		councillorTypeNew: {
            title: 'New element',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'finalType',
                                type: 'annexaCheckbox',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.literals.finalType',
                                    validate: true,
                                    required: false
                                }
                            },
                            {
                                key: 'contentLevel',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.level',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: 'LEVEL1', description: 'global.sec.literals.level1' }, { id: 'LEVEL2', description: 'global.sec.literals.level2' }, { id: 'LEVEL3', description: 'global.sec.literals.level3' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        councillorTypeEdit: {
            title: 'Edit element',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateCouncillorType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
				'		<annexa-editable-field type="checkbox" label="global.literals.finalType" model-value="modal.data.finalType" model-property="finalType" save="modal.updateCouncillorType(val, prop)" required="false" blur="ignore" print-function="modal.printFinalType"></annexa-editable-field>',
                '   </div>',
				'   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.sec.literals.level" model-value="modal.data.contentLevel" model-property="contentLevel" save="modal.updateCouncillorType(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn" select-options="modal.levels" print-function="modal.printContentLevel"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        positionEdit: {
            title: 'global.literals.editPosition',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updatePosition(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        positionNew: {
            title: 'global.literals.newPosition',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        editOrganMemberRol: {
            title: 'global.sec.literals.editOrganMemberRol',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateOrganMemberRol(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        newOrganMemberRol: {
            title: 'global.sec.literals.newOrganMemberRol',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: []
        },
        newFooterClaim: {
            title: 'global.sec.literals.newFooterClaim',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'file',
                                type: 'annexaFileUpload',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true,
                                    onSelected: function() {}
                                },
                                data: {
                                    row: true,
                                    colClass: 'col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            }
                        ]
                    }
                ]
            }
        },
        editFooterClaim: {
            title: 'global.sec.literals.edtFooterClaim',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateFooterClaim(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '      	<label class=" small m-b-0">',
                '          	<span class="ng-scope ng-binding" translate="global.literals.filename">File</span>',
                '      	</label>',
                '   </div>',
                '   <div class="col-xs-12">',
                '      	<p class="m-b-0 p-y-xs b-b m-r pull-left hellip calc-100-n-280"><span class="_600" title="{{modal.data.fileName}}">{{modal.data.fileName}}</span></p> ',
                '      	<button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '          	<span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '      	</button>',
                '      	<button type="button" ng-click="modal.editOnline(modal.data.fileId)" class="btn btn-sm grey inline">',
                '          	<span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '      	</button>',
                '       <button type="button" ng-click="modal.download(modal.data.fileId)" class="btn btn-sm grey inline">',
                '           <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '       </button>',
                '   </div>',
                '</div>'
            ]
        },
        diligenceTypeNew: {
            title: 'global.literals.newDiligenceType',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'canStartDossier',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.canStartDossier',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'canAppendDossier',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.canAppendDossier',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        diligenceTypeEdit: {
            title: 'global.literals.editDiligenceType',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateDiligenceType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.canStartDossier" model-value="modal.data.canStartDossier" model-property="canStartDossier" save="modal.updateDiligenceType(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.canAppendDossier" model-value="modal.data.canAppendDossier" model-property="canAppendDossier" save="modal.updateDiligenceType(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },

        registerChannelNew: {
            title: 'global.literals.newRegisterChannel',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'entryType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.entryType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: "INPUT", description: 'global.literals.INPUT' }, { id: "OUTPUT", description: 'global.literals.OUTPUT' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.codeType',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'active',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.active',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        registerChannelEdit: {
            title: 'global.literals.editRegisterChannel',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRegisterChannel(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.entryType" model-value="modal.data.entryType" model-property="entryType" save="modal.updateRegisterChannel(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn" select-options="modal.entryTypesOptions" print-function="modal.printEntryTypes"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.codeType" model-value="modal.data.code" model-property="code" save="modal.updateRegisterChannel(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.active" model-value="modal.data.active" model-property="active" save="modal.updateRegisterChannel(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        
        registerEntryDocumentType: {
            title: 'global.literals.regType',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'entryType', /* tipus de document */
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.regType',
                                    valueProp: 'id', /* ? entryType */
                                    labelProp: 'name', /* ? language 1 */
                                    placeholder: '',
                                    options: [
                                        { id: 'INPUT', name: 'global.literals.input' },
                                        { id: 'OUTPUT', name: 'global.literals.output' }

                                    ],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.codeType',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        RegisterEntryDocumentTypeEdit: {
            title: 'global.literals.regType',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRegisterEntryDocumentType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.regType" model-value="modal.data.entryType" model-property="entryType" save="modal.updateRegisterEntryDocumentType(val, prop)" required="true" blur="ignore" lang-col="modal.entryTypeName" select-options="modal.entryTypes" print-function="modal.printDocumentType"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.codeType" model-value="modal.data.code" model-property="code" save="modal.updateRegisterEntryDocumentType(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        notificationTypeEdit: {
            title: 'global.literals.editNotificationType',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateNotificationType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-8">',
                '       <annexa-editable-field type="select" label="global.literals.addressType" model-value="modal.data.addressType" model-property="addressType" save="modal.updateNotificationType(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.addressTypes" print-function="modal.printAddressType"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4 pl-0">',
                '       <annexa-editable-field type="select" label="global.literals.allowManualStatusChange" model-value="modal.data.allowManualStatusChange" model-property="allowManualStatusChange" save="modal.updateNotificationType(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.amscs" print-function="modal.printAmcs"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.notificationChannel" model-value="modal.data.registerEntryChannel" model-property="registerEntryChannel" save="modal.updateNotificationType(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.registerEntryChannels" print-function="modal.printRegisterEntryChannel"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.addressTypeAlert" model-value="modal.data.addressTypeAlert" model-property="addressTypeAlert" save="modal.updateNotificationType(val, prop)" required="false" blur="ignore" lang-col="modal.nameColumn" select-options="modal.addressTypes" print-function="modal.printAddressType"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="number" label="global.literals.limitSizeDocumentsNotifications" model-value="modal.data.limitSizeDocumentsNotifications" model-property="limitSizeDocumentsNotifications" save="modal.updateNotificationType(val, prop)" required="false" blur="ignore" model-length="100000000000000000"></annexa-editable-field>',
                '   </div>',                    
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="numberMin" label="global.literals.limitSizeExtractNotifications" model-value="modal.data.limitSizeExtractNotifications" model-property="limitSizeExtractNotifications" save="modal.updateNotificationType(val, prop)" required="false" blur="ignore" model-length="100000000000000000"></annexa-editable-field>',
                '   </div>',                                
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="number" label="global.literals.limitNumberDocumentsNotifications" model-value="modal.data.limitNumberDocumentsNotifications" model-property="limitNumberDocumentsNotifications" save="modal.updateNotificationType(val, prop)" required="false" blur="ignore" model-length="100000000000000000"></annexa-editable-field>',
                '   </div>', 
                '   <div class="col-lg-12">',                            
                '       <annexa-notification-statuses type="modal.data"></annexa-notification-statuses>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.identificationDestinataryType" model-value="modal.data.identificationDestinataryType" model-property="identificationDestinataryType" save="modal.updateNotificationType(val, prop)" required="false" blur="ignore" lang-col="modal.nameColumn" select-options="modal.identificationDestinataryTypes" print-function="modal.printIdentificationDestinataryType"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.identificationDestinataryPattern" model-value="modal.data.identificationDestinataryPattern" model-property="identificationDestinataryPattern" save="modal.updateNotificationType(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6 pl-0" ng-if="modal.data.addressType == \'POSTAL\'">',
                '       <annexa-editable-field type="select" label="global.literals.addRemittanceSicer" model-value="modal.data.addRemittanceSicer" model-property="addRemittanceSicer" save="modal.updateNotificationType(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.amscs" print-function="modal.printAmcs"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        organCollegiateEdit: {
            title: 'global.sec.literals.editCollegiateOrgan',
            size: 'modal-lg',
            data: undefined,
            content: [
            	'<div class=" nav-active-primary">',
                '   <uib-tabset>',
                '       <uib-tab class="nav-item" heading="{{\'global.sec.literals.generalData\' | translate}}" active="true">',
                '			<div class="row p-t-xs">',
                '   			<div class="col-xs-12 col-sm-6 col-lg-6">',
                '       			<annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateOrgan(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   			</div>',
                '   			<div class="col-xs-12 col-sm-6 col-lg-6">',
                '      				<annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-xs">',
                '   			<div class="col-lg-12">',
                '       			<annexa-editable-field type="select-tree" label="global.literals.classificationBox" model-value="modal.data.archiveClassification" model-property="archiveClassification" save="modal.updateOrgan(val, prop)" blur="ignore" lang-col="modal.languageColumn" select-options="modal.classifications"></annexa-editable-field>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-xs">',
                '   			<div class="col-xs-12 col-sm-6 col-lg-6">',
                '       			<annexa-editable-field type="select" label="global.sec.literals.meetingLocation" model-value="modal.data.meetingLocation" model-property="meetingLocation" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.meetingLocations"></annexa-editable-field>',
                '   			</div>',
                '   			<div class="col-xs-12 col-sm-6 col-lg-6">',
                '       			<annexa-editable-field type="select" label="global.sec.literals.resolutionType" model-value="modal.data.resolutionType" model-property="resolutionType" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.resolutionTypes" print-function="modal.printResolutionType"></annexa-editable-field>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-xs">',
                '   			<div class="col-xs-12 col-sm-6 col-lg-6">',
                '       			<annexa-editable-field type="text" label="global.sec.literals.organSessionPattern" model-value="modal.data.organSessionPattern" model-property="organSessionPattern" save="modal.updateOrgan(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   			</div>',
                '   			<div class="col-xs-12 col-sm-3 col-lg-3">',
                '       			<annexa-editable-field type="number" label="global.sec.literals.organSessionDigits" model-value="modal.data.organSessionDigits" model-property="organSessionDigits" save="modal.updateOrgan(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   			</div>',
                '   			<div class="col-xs-12 col-sm-3 col-lg-3">',
                '       			<annexa-editable-field type="select" label="global.sec.literals.canGlobalVote" model-value="modal.data.globalVote" model-property="globalVote" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-xs">',
                '   			<div class="col-xs-12 col-sm-3 col-lg-3">',
                '       			<annexa-editable-field type="select" label="global.sec.literals.canWeightedVote" model-value="modal.data.weightedVote" model-property="weightedVote" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   			</div>',
                '   			<div class="col-xs-12 col-sm-6 col-lg-6">',
                '       			<annexa-editable-field type="select" label="global.sec.literals.presidentVoteSeparately" model-value="modal.data.presidentVoteSeparately" model-property="presidentVoteSeparately" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-xs">',
                '   			<div class="col-lg-12 m-b-xs">',
                '		       		<annexa-editable-field type="select" label="{{\'global.sec.literals.everyoneCanViewSessions\' | translate}}" model-value="modal.data.everyoneCanViewSessions" model-property="everyoneCanViewSessions" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" lang-col="modal.onlyManagmentUsers" required="false" blur="ignore" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple ng-if="!modal.data.everyoneCanViewSessions" type="select-multiple" label="global.literals.profiles" model-value="modal.data.profilesAux" model-property="profiles" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.profiles"  print-function="modal.printProfiles"></annexa-select-multiple>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple ng-if="!modal.data.everyoneCanViewSessions" type="select-multiple" label="global.literals.user" model-value="modal.data.usersViewSessionsAux" model-property="usersViewSessions" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.usersValColumn" select-options="modal.usersViewSession" print-function="modal.printUsers"></annexa-select-multiple>',
                '   			</div>',
                '			</div>',
                '       </uib-tab>',
                '       <uib-tab class="nav-item" heading="{{\'global.sec.literals.members\' | translate}}">',
                '			<div class="row p-t-md">',
                '       		<annexa-organ-members members="modal.data.members" is-new="false" class="m-b-md clearfix" organ="modal.data"></annexa-organ-members>',
                '			</div>',
                '       </uib-tab>',
                '       <uib-tab class="nav-item" heading="{{\'global.sec.literals.agendaPointTypes\' | translate}}">',
                '			<div class="row p-t-md">',
                '       		<annexa-organ-agenda-point-types point-types="modal.data.agendaPointTypes" is-new="false" resolution-type="modal.data.resolutionType" default-points="modal.data.defaultPoints" organ-id="modal.data.id"></annexa-organ-agenda-point-types>',
                '			</div>',
                '       </uib-tab>',
                '       <uib-tab class="nav-item" heading="{{\'global.sec.literals.defaultPoints\' | translate}}">',
                '			<div class="row p-t-md">',
                '       		<annexa-organ-default-points default-points="modal.data.defaultPoints" point-types="modal.data.agendaPointTypes" is-new="false" organ="modal.data.id" weighted-vote="modal.data.weightedVote"></annexa-organ-default-points>',
                '			</div>',
                '       </uib-tab>',
                '       <uib-tab class="nav-item" heading="{{\'global.sec.literals.proposalPermissions\' | translate}}">',
                '			<div class="row" ng-show="!modal.data.showPropPermissionsTab">',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.viewProposalProfilesAux" model-property="viewProposalProfiles" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.profiles"  print-function="modal.printProfiles"></annexa-select-multiple>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.user" model-value="modal.data.viewProposalUsersAux" model-property="viewProposalUsers" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.usersValColumn" select-options="modal.usersView" print-function="modal.printUsers"></annexa-select-multiple>',
                '   			</div>',
                '			</div>',
                '			<div class="row" ng-show="modal.data.showPropPermissionsTab">',
                '   			<div class="col-lg-12 m-b-xs">',
                '		       		<annexa-editable-field type="select" label="{{\'global.sec.literals.everyoneCanView\' | translate}}" model-value="modal.data.everyoneCanView" model-property="everyoneCanView" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" lang-col="modal.onlyManagmentUsers" required="false" blur="ignore" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   			</div>',
                '			</div>',
                '			<div class="row" ng-show="modal.data.showPropPermissionsTab">',
                '   			<div class="col-lg-12 m-b-xs">',
                '      				<label class="label-strong text small">',
                '          				<span class="ng-scope ng-binding" translate="global.sec.literals.proposalPermissionsReview">File</span>',
                '      				</label>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.reviewProposalProfilesAux" model-property="reviewProposalProfiles" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.profiles"  print-function="modal.printProfiles"></annexa-select-multiple>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.user" model-value="modal.data.reviewProposalUsersAux" model-property="reviewProposalUsers" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.usersValColumn" select-options="modal.usersReview" print-function="modal.printUsers"></annexa-select-multiple>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-md" ng-show="modal.data.showPropPermissionsTab">',
                '   			<div class="col-lg-12 m-b-xs">',
                '      				<label class="label-strong text small">',
                '          				<span class="ng-scope ng-binding" translate="global.sec.literals.proposalPermissionsValidate">File</span>',
                '      				</label>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.validateProposalProfilesAux" model-property="validateProposalProfiles" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.profiles"  print-function="modal.printProfiles"></annexa-select-multiple>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.user" model-value="modal.data.validateProposalUsersAux" model-property="validateProposalUsers" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.usersValColumn" select-options="modal.usersValidate" print-function="modal.printUsers"></annexa-select-multiple>',
                '   			</div>',
                '			</div>',
                '			<div class="row p-t-md" ng-show="modal.data.showPropPermissionsTab">',
                '   			<div class="col-lg-12 m-b-xs">',
                '      				<label class="label-strong text small">',
                '          				<span class="ng-scope ng-binding" translate="global.sec.literals.proposalPermissionsAccept">File</span>',
                '      				</label>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.acceptProposalProfilesAux" model-property="acceptProposalProfiles" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.profiles"  print-function="modal.printProfiles"></annexa-select-multiple>',
                '   			</div>',
                '   			<div class="col-lg-12">',
                '       			<annexa-select-multiple type="select-multiple" label="global.literals.user" model-value="modal.data.acceptProposalUsersAux" model-property="acceptProposalUsers" save-modal="modal.updateOrgan" save="modal.updateOrgan(val, prop)" required="false" blur="ignore" lang-col="modal.usersValColumn" select-options="modal.usersAccept" print-function="modal.printUsers"></annexa-select-multiple>',
                '   			</div>',
                '			</div>',
                '       </uib-tab>'
            ],
            alerts: []
        },
        notificationTypeNew: {
            title: 'global.literals.newNotificationType',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                        ]
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'addressType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-8',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.addressType',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [
                                        { id: 'TELEMATIC_EMAIL', name: 'global.literals.TELEMATIC_EMAIL' },
                                        { id: 'POSTAL', name: 'global.literals.POSTAL' },
                                        { id: 'TELEMATIC_MOBILEPHONE', name: 'global.literals.TELEMATIC_MOBILEPHONE' },
                                        { id: 'POSTAL_CODE', name: 'global.literals.POSTAL_CODE' },
                                        { id: 'OTHER', name: 'global.literals.others' }
                                    ],
                                    required: true,
                                    onSelected: function($item) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    	$rootScope.$broadcast('selectAddressType', { item: $item.id });
                                    }
                                },
                                controller: ['$scope', '$filter', function ($scope, $filter) {
                                    _.forEach($scope.options.templateOptions.options, function(value, key) {
                                        $scope.options.templateOptions.options[key].name = $filter('translate')(value.name);
                                    })
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'allowManualStatusChange',
                                type: 'annexaTrueFalse',
                                className: 'col-sm-4',
                                templateOptions: {
                                    label: 'global.literals.allowManualStatusChange',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                        	{
                                key: 'registerEntryChannel',
                                type: 'annexaSelectRow',
                                className: 'col-sm-8',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.notificationChannel',
                                    valueProp: 'id',
                                    labelProp: '',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', '$filter', 'Language','GlobalDataFactory', function ($scope, $filter, Language, GlobalDataFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                	$scope.to.options = GlobalDataFactory.registerEntryChannelsOutput;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row4',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'addressTypeAlert',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.addressTypeAlert',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [
                                        { id: 'TELEMATIC_EMAIL', name: 'global.literals.TELEMATIC_EMAIL' },
                                        { id: 'POSTAL', name: 'global.literals.POSTAL' },
                                        { id: 'TELEMATIC_MOBILEPHONE', name: 'global.literals.TELEMATIC_MOBILEPHONE' },
                                        { id: 'POSTAL_CODE', name: 'global.literals.POSTAL_CODE' },
                                        { id: 'OTHER', name: 'global.literals.others' }
                                    ],
                                    required: false
                                },
                                controller: ['$scope', '$filter', function ($scope, $filter) {
                                    _.forEach($scope.options.templateOptions.options, function(value, key) {
                                        $scope.options.templateOptions.options[key].name = $filter('translate')(value.name);
                                    })
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row5',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                            	key: 'limitSizeDocumentsNotifications',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: false,
                                    type: 'number',
                                    label: 'global.literals.limitSizeDocumentsNotifications',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row6',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                            	key: 'limitSizeExtractNotifications',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: false,
                                    type: 'number',
                                    label: 'global.literals.limitSizeExtractNotifications',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row7',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'statuses',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-notification-statuses'
                                },
                                data: {
                                    type: undefined
                                }
                            }
                        ]
                    },
                    {
                        key: 'row8',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {

                            	key: 'limitNumberDocumentsNotifications',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: false,
                                    type: 'number',
                                    label: 'global.literals.limitNumberDocumentsNotifications',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row8',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'identificationDestinataryType',
                                type: 'annexaSelectRow',
                                className: 'col-lg-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.identificationDestinataryType',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [
                                    	{ id: 'START', name: 'global.enums.identificationDestinataryPatternType.START' },
                                        { id: 'NOT_START', name: 'global.enums.identificationDestinataryPatternType.NOT_START' },
                                        { id: 'FINISH', name: 'global.enums.identificationDestinataryPatternType.FINISH' },
                                        { id: 'NOT_FINISH', name: 'global.enums.identificationDestinataryPatternType.NOT_FINISH' },
                                        { id: 'CONTAINS', name: 'global.enums.identificationDestinataryPatternType.CONTAINS' },
                                        { id: 'NOT_CONTAINS', name: 'global.enums.identificationDestinataryPatternType.NOT_CONTAINS' }
                                    ],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                            	key: 'identificationDestinataryPattern',
                                type: 'annexaInputRow',
                                className: 'col-lg-6',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    placeholder: 'S, 10, EM',
                                    label: 'global.literals.identificationDestinataryPattern'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row9',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'addRemittanceSicer',
                                type: 'annexaTrueFalse',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.literals.addRemittanceSicer',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
                                	$rootScope.$on('selectAddressType', function(event, args) {
                                		if(args && args.item){
                                			$scope.model.addressType = args.item;
                                		}
                                    });
                                	if(!$scope.model.addressType) {
                                		$scope.model.addressType = 'NONE';
                                	}
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(!scope.model.addressType || scope.model.addressType == 'POSTAL') {
                                        return false;
                                    }else{
                                        return true;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        remittanceNew: {
            title: 'global.literals.newRemittanceType',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'idDocumentManagerNotificationsListDocument',
                                type: 'annexaFileUploadBase64',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.idDocumentManagerRemittanceNotificationsListDocument',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            },
                            {
                                key: 'idDocumentManagerNotificationsListElementDocument',
                                type: 'annexaFileUploadBase64',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.idDocumentManagerRemittanceNotificationsListElementDocument',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            },
                            {
                                key: 'idDocumentManagerWindowInfo',
                                type: 'annexaFileUploadBase64',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.idDocumentManagerRemittanceNotificationsWindowInfo',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        remittanceTypeEdit: {
            title: 'global.literals.editRemittanceType',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRemittanceType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateRemittanceType(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.idDocumentManagerRemittanceNotificationsListDocument">file</label></div>',
                '       <div class=" m-t-n-xs m-b-sm">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.nameNotificationsListDocument}}">{{modal.data.nameNotificationsListDocument}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal, modal.notificationsListDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManagerNotificationsListDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManagerNotificationsListDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.idDocumentManagerRemittanceNotificationsListElementDocument">file</label></div>',
                '       <div class=" m-t-n-xs m-b-sm">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.nameNotificationsListElementDocument}}">{{modal.data.nameNotificationsListElementDocument}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal, modal.notificationsListElementDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManagerNotificationsListElementDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManagerNotificationsListElementDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.idDocumentManagerRemittanceNotificationsWindowInfo">file</label></div>',
                '       <div class=" m-t-n-xs m-b-sm">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.nameWindowInfo}}">{{modal.data.nameWindowInfo}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal, modal.windowInfo)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManagerWindowInfo)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManagerWindowInfo)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        organCollegiateNew: {
            title: 'global.sec.literals.newCollegiateOrgan',
            size: 'modal-lg',
            tabs: [
                {
                    title: 'global.sec.literals.generalData',
                    active: true,
                    annexaFormly: {
                        fields: [
                        	{
	                            key: 'row_name_location',
	                            className: '',
	                            templateOptions: {},
	                            fieldGroup: [
	                                {
	                                    key: 'organType',
	                                    type: 'annexaHidden',
	                                    className: '',
	                                    templateOptions: {
	                                        type: 'hidden',
	                                        label: '',
	                                        required: false
	                                    },
	                                    defaultValue: 'COLLEGIATE'
	                                },
	                                {
	                                    key: 'language1',
	                                    type: 'annexaInputLanguage',
	                                    className: 'col-sm-6 col-xs-12',
	                                    validators: {
	                                        "required": {
	                                            "expression": function (viewValue, modelValue, scope) {
	                                                var valid = true;
	                                                var cont = 1;
	                                                var column = "language";
	                                                if (scope.languages) {
	                                                    angular.forEach(scope.languages, function (value2, key2) {
	                                                        if (modelValue && !modelValue[column + cont]) {
	                                                            valid = false;
	                                                        }
	                                                        cont++;
	                                                    });
	                                                }
	                                                return valid;
	                                            }
	                                        }
	                                    },
	                                    watcher: {
	                                        type: '$watchCollection',
	                                        expression: 'model',
	                                        listener: function (field, _new) {
	                                            if (field.formControl) {
	                                                field.formControl.$validate();
	                                            }
	                                        }
	                                    },
	                                    templateOptions: {
	                                        id: 'language',
	                                        modelField: '',
	                                        type: 'text',
	                                        label: 'global.literals.name',
	                                        disposition: 'horitzontal21',
	                                        required: true,
	                                        focus: true
	                                    },
	                                    controller: function ($scope, $rootScope) {
	                                        $scope.languages = $rootScope.app.languagedef;
	                                    }
	                                },
	                                {
	                                    key: 'acronym',
	                                    type: 'annexaInputRow',
	                                    className: 'col-sm-6 col-xs-12',
	                                    templateOptions: {
	                                        required: true,
	                                        type: 'text',
	                                        label: 'global.literals.acronym',
	                                        maxlength: 20
	                                    },
	                                    data: {
	                                        row: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                }
	                            ],
	                            wrapper: 'annexaRow'
	                        },
	                        {
	                            key: 'row_location',
	                            className: '',
	                            templateOptions: {},
	                            fieldGroup: [
	                            	{
                                        key: 'archiveClassification',
                                        type: 'annexaSelectTreeRow',
                                        className: 'col-sm-6 col-xs-12',
                                        templateOptions: {
                                            label: 'global.literals.classificationBox',
                                            options: [],
                                            required: false,
                                            focus: false
                                        },
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
	                                {
	                                    key: 'meetingLocation',
	                                    type: 'annexaSelectRow',
	                                    className: 'col-sm-6 col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.sec.literals.meetingLocation',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true
	                                    },
	                                    controller: ['$scope', 'GlobalDataFactory', 'Language', function ($scope, GlobalDataFactory, Language) {
	                                        $scope.options.templateOptions.labelProp = Language.getActiveColumn();
	                                        $scope.options.templateOptions.options = GlobalDataFactory.meetingLocations;
	                                    }],
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                },
	                                {
	                                    key: 'resolutionType',
	                                    type: 'annexaSelectRow',
	                                    className: 'col-sm-6 col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.sec.literals.resolutionType',
	                                        valueProp: 'id',
	                                        labelProp: 'name',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true,
	                                        onSelected: function($item) {
	                                            var $scope = angular.element('#modalNewcollegiateOrgan').scope();
	                                            $scope.modalObj.tabs[2].annexaFormly.fields[0].fieldGroup[0].data.resolutionType = $item.id;
	                                        }
	                                    },
	                                    controller: ['$scope', 'SecFactory', 'Language', function ($scope, SecFactory, Language) {
	                                        $scope.options.templateOptions.options = [
	                                            { id: 'OPINION', name: 'global.sec.literals.OPINION' },
	                                            { id: 'AGREEMENT', name: 'global.sec.literals.AGREEMENT' }
	                                        ];
	                                    }],
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                }
	                            ],
	                            wrapper: 'annexaRow'
	                        },
	                        {
	                        	key: 'row_session_pattern',
	                            className: '',
	                            templateOptions: {},
	                            fieldGroup: [
	                                {
	                                    key: 'organSessionPattern',
	                                    type: 'annexaInputRow',
	                                    className: 'col-sm-6 col-xs-12',
	                                    templateOptions: {
	                                        required: true,
	                                        type: 'text',
	                                        label: 'global.sec.literals.organSessionPattern',
	                                        maxlength: 50
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                },
	                                {
	                                    key: 'organSessionDigits',
	                                    type: 'annexaInputRow',
	                                    className: 'col-sm-3 col-xs-12',
	                                    templateOptions: {
	                                        required: true,
	                                        type: 'number',
	                                        label: 'global.sec.literals.organSessionDigits',
	                                        maxlength: 20
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                },
	                                {
	                                    key: 'globalVote',
	                                    type: 'annexaSelectRow',
	                                    className: 'col-sm-3 col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.sec.literals.canGlobalVote',
	                                        valueProp: 'id',
	                                        labelProp: 'name',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true
	                                    },
	                                    controller: ['$scope', '$filter', function($scope, $filter) {
	                                        $scope.to.options = [
	                                            { id: false, name: $filter('translate')('global.literals.no') },
	                                            { id: true, name: $filter('translate')('global.literals.yes') }
	                                        ]
	                                    }],
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                
	                                },
	                                {
	                                    key: 'weightedVote',
	                                    type: 'annexaSelectRow',
	                                    className: 'col-sm-3 col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.sec.literals.canWeightedVote',
	                                        valueProp: 'id',
	                                        labelProp: 'name',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true,
	                                        onSelected: function($item) {
	                                        	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                        	$rootScope.$broadcast('selectOrganWeightedVote', { item: $item });
	                                        }
	                                    },
	                                    controller: ['$scope', '$filter', function($scope, $filter) {
	                                        $scope.to.options = [
	                                            { id: false, name: $filter('translate')('global.literals.no') },
	                                            { id: true, name: $filter('translate')('global.literals.yes') }
	                                        ]
	                                    }],
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                
	                                },
	                                {
	                                    key: 'presidentVoteSeparately',
	                                    type: 'annexaSelectRow',
	                                    className: 'col-sm-6 col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.sec.literals.presidentVoteSeparately',
	                                        valueProp: 'id',
	                                        labelProp: 'name',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true
	                                    },
	                                    controller: ['$scope', '$filter', function($scope, $filter) {
	                                        $scope.to.options = [
	                                            { id: false, name: $filter('translate')('global.literals.no') },
	                                            { id: true, name: $filter('translate')('global.literals.yes') }
	                                        ]
	                                    }],
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                }
	                            ],
	                            wrapper: 'annexaRow'
	                        },
	                        {
	                        	key: 'row_session_permissions',
	                            className: '',
	                            templateOptions: {},
	                            fieldGroup: [
	                                {
	                                    key: 'everyoneCanViewSessions',
	                                    type: 'annexaSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.sec.literals.everyoneCanViewSessions',
	                                        valueProp: 'id',
	                                        labelProp: 'name',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true
	                                    },
	                                    controller: ['$scope', '$filter', function($scope, $filter) {
	                                        $scope.to.options = [
	                                            { id: false, name: $filter('translate')('global.literals.no') },
	                                            { id: true, name: $filter('translate')('global.literals.yes') }
	                                        ]
	                                        $scope.to.onChange = function() {
	                                    		if($scope && $scope.model && $scope.model.everyoneCanViewSessions) {
			                                    	$scope.model.usersViewSessions = [];
			                                    	$scope.model.profiles = [];
	                                    		}
	                                        }
	                                    }],
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: ' col-xs-12',
	                                        labelClass: 'label-strong'
	                                    }
	                                },
	                                {
	                                    key: 'profiles',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.profiles',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: true
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function($viewValue, $modelValue, scope) {
	                						if(scope && scope.model && scope.model.everyoneCanViewSessions){
	                							return true;
	                                        }else{
	                                            return false;
	                						}
	                                    },
	                                    controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
	                                        $scope.to.labelProp = Language.getActiveColumn();
	                                        $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
	                                        $scope.to.onChange = function() {
	                                    		var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                            $rootScope.$broadcast('selectedProfilesViewSessions', { });
	                                        }
	                                    }],
	                                },
	                                {
	                                    key: 'usersViewSessions',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.user',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function($viewValue, $modelValue, scope) {
	                						if(scope && scope.model && scope.model.everyoneCanViewSessions){
	                							return true;
	                                        }else{
	                                            return false;
	                						}
	                                    },
	                                    controller:['$scope', 'RestService', '$rootScope', function($scope, RestService, $rootScope) {
	                                    	$scope.to.labelProp = "value";
	                                    	$scope.$on('selectedProfilesViewSessions', function(event, args){
	                                            if($scope.model.profiles && $scope.model.profiles.length > 0){
	                                            	$scope.to.options = [];
	                                            	_.forEach($scope.to.users, function(usr) {
	                                            		var prof = $linq(usr.user.userProfiles).intersect($scope.model.profiles, function(x,y){
	    													if(y.profile && y.profile.id && y.profile.id == x){
	    														return true;
	    													}else{
	    														return false;
	    													}
	    												}).toArray();
	                                                	if(prof && prof.length > 0) {
	                                                		$scope.to.options.push(usr);
	                                                	}
	                                                });
	                                            	if($scope.model.usersViewSessions && $scope.model.usersViewSessions.length > 0 && $scope.to.options.length > 0) {
	                                            		var selUsr = [];
	                                            		_.forEach($scope.to.options, function(option) {
	                                            			if($linq($scope.model.usersViewSessions).contains(option.user.id)) {
	                                            				selUsr.push(option.user.id);
	                                            			}
	                                            		});
	                                            		$scope.model.usersViewSessions = selUsr;
	                                            	}
	                                            }else{
	                                            	$scope.model.usersViewSessions = [];
	                                            	$scope.to.options = [];
	                                            }
	                                        });
	                                    	RestService.findAll('User', 'adminCreateTransaction')
	    	                                    .then(function(data) {
	    	                                        var users = [];
	                                
	    	                                        if(data.data && data.data.length > 0) {
	    	                                            _.forEach(JSOG.decode(data.data), function(val) {
	    	                                            	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
	    	                                            });
	                                }
	    	
	    	                                        $scope.options.templateOptions.users = users;
	    	                                        $rootScope.$broadcast('selectedProfile', { });
	    	                                    });
	                                    }]
	                                }
	                            ],
	                            wrapper: 'annexaRow'
	                        }
                        ]
                    }
                },
                {
                    title: 'global.sec.literals.members',
                    active: false,
                    annexaFormly: {
                        fields: [
                        	{
                                key: 'row_members',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'members',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-organ-members'
                                        },
                                        data: {
                                            members: [],
                                            organ:undefined,
                                            isNew: true
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    }
                },
                {
                    title: 'global.sec.literals.agendaPointTypes',
                    active: false,
                    annexaFormly: {
                        fields: [
                        	{
                                key: 'row_agendaPointTypes',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'agendaPointTypes',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-organ-agenda-point-types'
                                        },
                                        data: {
                                            pointTypes: [],
                                            resolutionType: undefined,
                                            isNew:true
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    },
                    hideExpression: function(modal) {
                        if(modal && modal.tabs && modal.tabs.length > 2 && modal.tabs[0].annexaFormly && modal.tabs[0].annexaFormly.model && modal.tabs[0].annexaFormly && modal.tabs[0].annexaFormly.model.row_location && modal.tabs[0].annexaFormly.model.row_location.resolutionType) {
                            modal.tabs[2].annexaFormly.fields[0].fieldGroup[0].data.resolutionType = modal.tabs[0].annexaFormly.model.row_location.resolutionType;
                            return false;
                        }

                        return true;
                    }
                },
                {
                    title: 'global.sec.literals.proposalPermissions',
                    active: false,
                    annexaFormly: {
                        fields: [
                        	{
                                key: 'row_proposalPremissions',
                                className: 'row col-xs-12',
                                templateOptions: {},
                                fieldGroup: [
                                    {
	                                    key: 'profilesView',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.profiles',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return true;
	                                        }else{
	                                            return false;
	                						}
	                                    },
	                                    controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
	                                        $scope.to.labelProp = Language.getActiveColumn();
	                                        $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
	                                        $scope.to.onChange = function() {
	                                    		var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                            $rootScope.$broadcast('selectedProfileView', { });
	                                        }
	                                    }]
	                                },
	                                {
	                                    key: 'usersView',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.user',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return true;
	                                        }else{
	                                            return false;
	                						}
	                                    },
	                                    controller:['$scope', 'RestService', '$rootScope', function($scope, RestService, $rootScope) {
	                                    	$scope.to.labelProp = "value";
	                                    	$scope.$on('selectedProfileView', function(event, args){
	                                            if($scope.model.profilesView && $scope.model.profilesView.length > 0){
	                                            	$scope.to.options = [];
	                                            	_.forEach($scope.to.users, function(usr) {
	                                            		var prof = $linq(usr.user.userProfiles).intersect($scope.model.profilesView, function(x,y){
	    													if(y.profile && y.profile.id && y.profile.id == x){
	    														return true;
	    													}else{
	    														return false;
	    													}
	    												}).toArray();
	                                                	if(prof && prof.length > 0) {
	                                                		$scope.to.options.push(usr);
	                                                	}
	                                                });
	                                            	if($scope.model.usersView && $scope.model.usersView.length > 0 && $scope.to.options.length > 0) {
	                                            		var selUsr = [];
	                                            		_.forEach($scope.to.options, function(option) {
	                                            			if($linq($scope.model.usersView).contains(option.user.id)) {
	                                            				selUsr.push(option.user.id);
	                                            			}
	                                            		});
	                                            		$scope.model.usersView = selUsr;
	                                            	}
	                                            }else{
	                                            	$scope.model.usersView = [];
	                                            	$scope.to.options = [];
	                                            }
	                                        });
	                                    	RestService.findAll('User', 'adminCreateTransaction')
	    	                                    .then(function(data) {
	    	                                        var users = [];
	    	
	    	                                        if(data.data && data.data.length > 0) {
	    	                                            _.forEach(JSOG.decode(data.data), function(val) {
	    	                                            	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
	    	                                            });
	    	                                        }
	    	
	    	                                        $scope.options.templateOptions.users = users;
	    	                                        $rootScope.$broadcast('selectedProfile', { });
	    	                                    });
	                                    }]
	                                },
	                                {
		                                key: 'everyoneCanView',
		                                type: 'annexaRadioCheckboxRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    type: 'checkbox',
		                                    radioClass: 'radio-inline',
		                                    optionsAttr: 'bs-options',
		                                    required: false,
		                                    validate: true,
		                                    options:[],
		                                    ngOptions:'option[to.valueProp] as option in to.options',
		                                    valueProp: 'id',
		                                    labelProp: 'value',
		                                    label: ' '
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    clear: function($event,model,key, $select) {
		                                        $event.stopPropagation();
		                                        model[key] = undefined;
		                                        if($select) {
		                                            $select.selected = undefined;
		                                            $select.search = undefined;
		                                        }
		                                    }
		                                },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
		                                controller: function ($scope, $filter) {
		                                    $scope.to.options = [
		                                    	{ value: $filter('translate')('global.sec.literals.everyoneCanView'), id:1 }
		                                    ];
		                                }
		                            },
		                            {
        	                            key: 'reviewLabel',
        	                            type: 'annexaLabelRow',
        	                            className: 'col-xs-12',
        	                            templateOptions: {
        	                                label: 'global.sec.literals.proposalPermissionsReview',
        	                                value: ''
        	                            },
        	                            data: {
        	                                informed: true,
        	                                row: true,
        	                                colClass: 'col-xs-12',
        	                                labelClass: 'label-strong text',
        	                                breakLine: true
        	                            },
        	                            hideExpression: function(modal) {
        	                            	var $rootScope = angular.element(document.body).injector().get('$rootScope');
        	        						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
        	        							return false;
        	                                }else{
        	                                    return true;
        	        						}
        	                            }
        	                    	},
                                    {
	                                    key: 'profilesReview',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.profiles',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
	                                    controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
	                                        $scope.to.labelProp = Language.getActiveColumn();
	                                        $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
	                                        $scope.to.onChange = function() {
	                                    		var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                            $rootScope.$broadcast('selectedProfileReview', { });
	                                        }
	                                    }]
	                                },
	                                {
	                                    key: 'usersReview',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.user',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
	                                    controller:['$scope', 'RestService', '$rootScope', function($scope, RestService, $rootScope) {
	                                    	$scope.to.labelProp = "value";
	                                    	$scope.$on('selectedProfileReview', function(event, args){
	                                            if($scope.model.profilesReview && $scope.model.profilesReview.length > 0){
	                                            	$scope.to.options = [];
	                                            	_.forEach($scope.to.users, function(usr) {
	                                            		var prof = $linq(usr.user.userProfiles).intersect($scope.model.profilesReview, function(x,y){
	    													if(y.profile && y.profile.id && y.profile.id == x){
	    														return true;
	    													}else{
	    														return false;
	    													}
	    												}).toArray();
	                                                	if(prof && prof.length > 0) {
	                                                		$scope.to.options.push(usr);
	                                                	}
	                                                });
	                                            	if($scope.model.usersReview && $scope.model.usersReview.length > 0 && $scope.to.options.length > 0) {
	                                            		var selUsr = [];
	                                            		_.forEach($scope.to.options, function(option) {
	                                            			if($linq($scope.model.usersReview).contains(option.user.id)) {
	                                            				selUsr.push(option.user.id);
	                                            			}
	                                            		});
	                                            		$scope.model.usersReview = selUsr;
	                                            	}
	                                            }else{
	                                            	$scope.model.usersReview = [];
	                                            	$scope.to.options = [];
	                                            }
	                                        });
	                                    	RestService.findAll('User', 'adminCreateTransaction')
	    	                                    .then(function(data) {
	    	                                        var users = [];
	    	
	    	                                        if(data.data && data.data.length > 0) {
	    	                                            _.forEach(JSOG.decode(data.data), function(val) {
	    	                                            	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
	    	                                            });
	    	                                        }
	    	
	    	                                        $scope.options.templateOptions.users = users;
	    	                                        $rootScope.$broadcast('selectedProfile', { });
	    	                                    });
	                                    }]
	                                },
	                                {
        	                            key: 'validateLabel',
        	                            type: 'annexaLabelRow',
        	                            className: 'col-xs-12',
        	                            templateOptions: {
        	                                label: 'global.sec.literals.proposalPermissionsValidate',
        	                                value: ''
        	                            },
        	                            hideExpression: function(modal) {
        	                            	var $rootScope = angular.element(document.body).injector().get('$rootScope');
        	        						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
        	        							return false;
        	                                }else{
        	                                    return true;
        	        						}
        	                            },
        	                            data: {
        	                                informed: true,
        	                                row: true,
        	                                colClass: 'col-xs-12',
        	                                labelClass: 'label-strong text',
        	                                breakLine: true
        	                            }
        	                    	},
                                    {
	                                    key: 'profilesValidate',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.profiles',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
	                                    controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
	                                        $scope.to.labelProp = Language.getActiveColumn();
	                                        $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
	                                        $scope.to.onChange = function() {
	                                    		var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                            $rootScope.$broadcast('selectedProfileValidate', { });
	                                        }
	                                    }]
	                                },
	                                {
	                                    key: 'usersValidate',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.user',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
	                                    controller:['$scope', 'RestService', '$rootScope', function($scope, RestService, $rootScope) {
	                                    	$scope.to.labelProp = "value";
	                                    	$scope.$on('selectedProfileValidate', function(event, args){
	                                            if($scope.model.profilesValidate && $scope.model.profilesValidate.length > 0){
	                                            	$scope.to.options = [];
	                                            	_.forEach($scope.to.users, function(usr) {
	                                            		var prof = $linq(usr.user.userProfiles).intersect($scope.model.profilesValidate, function(x,y){
	    													if(y.profile && y.profile.id && y.profile.id == x){
	    														return true;
	    													}else{
	    														return false;
	    													}
	    												}).toArray();
	                                                	if(prof && prof.length > 0) {
	                                                		$scope.to.options.push(usr);
	                                                	}
	                                                });
	                                            	if($scope.model.usersValidate && $scope.model.usersValidate.length > 0 && $scope.to.options.length > 0) {
	                                            		var selUsr = [];
	                                            		_.forEach($scope.to.options, function(option) {
	                                            			if($linq($scope.model.usersValidate).contains(option.user.id)) {
	                                            				selUsr.push(option.user.id);
	                                            			}
	                                            		});
	                                            		$scope.model.usersValidate = selUsr;
	                                            	}
	                                            }else{
	                                            	$scope.model.usersValidate = [];
	                                            	$scope.to.options = [];
	                                            }
	                                        });
	                                    	RestService.findAll('User', 'adminCreateTransaction')
	    	                                    .then(function(data) {
	    	                                        var users = [];
	    	
	    	                                        if(data.data && data.data.length > 0) {
	    	                                            _.forEach(JSOG.decode(data.data), function(val) {
	    	                                            	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
	    	                                            });
	    	                                        }
	    	
	    	                                        $scope.options.templateOptions.users = users;
	    	                                        $rootScope.$broadcast('selectedProfile', { });
	    	                                    });
	                                    }]
	                                },
	                                {
        	                            key: 'acceptLabel',
        	                            type: 'annexaLabelRow',
        	                            className: 'col-xs-12',
        	                            templateOptions: {
        	                                label: 'global.sec.literals.proposalPermissionsAccept',
        	                                value: ''
        	                            },
        	                            data: {
        	                                informed: true,
        	                                row: true,
        	                                colClass: 'col-xs-12',
        	                                labelClass: 'label-strong text',
        	                                breakLine: true
        	                            },
        	                            hideExpression: function(modal) {
        	                            	var $rootScope = angular.element(document.body).injector().get('$rootScope');
        	        						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
        	        							return false;
        	                                }else{
        	                                    return true;
        	        						}
        	                            }
        	                    	},
                                    {
	                                    key: 'profilesAccept',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.profiles',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
	                                    controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
	                                        $scope.to.labelProp = Language.getActiveColumn();
	                                        $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
	                                        $scope.to.onChange = function() {
	                                    		var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                                            $rootScope.$broadcast('selectedProfileAccept', { });
	                                        }
	                                    }]
	                                },
	                                {
	                                    key: 'usersAccept',
	                                    type: 'annexaMultipleSelectRow',
	                                    className: 'col-xs-12',
	                                    templateOptions: {
	                                        optionsAttr: 'bs-options',
	                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                        label: 'global.literals.user',
	                                        valueProp: 'id',
	                                        labelProp: 'language1',
	                                        placeholder: '',
	                                        options: [],
	                                        required: false
	                                    },
	                                    data: {
	                                        row: true,
	                                        informed: true,
	                                        colClass: 'col-xs-12',
	                                        labelClass: 'label-strong'
	                                    },
	                                    hideExpression: function(modal) {
	                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
	                						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value){
	                							return false;
	                                        }else{
	                                            return true;
	                						}
	                                    },
	                                    controller:['$scope', 'RestService', '$rootScope', function($scope, RestService, $rootScope) {
	                                    	$scope.to.labelProp = "value";
	                                    	$scope.$on('selectedProfileAccept', function(event, args){
	                                            if($scope.model.profilesAccept && $scope.model.profilesAccept.length > 0){
	                                            	$scope.to.options = [];
	                                            	_.forEach($scope.to.users, function(usr) {
	                                            		var prof = $linq(usr.user.userProfiles).intersect($scope.model.profilesAccept, function(x,y){
	    													if(y.profile && y.profile.id && y.profile.id == x){
	    														return true;
	    													}else{
	    														return false;
	    													}
	    												}).toArray();
	                                                	if(prof && prof.length > 0) {
	                                                		$scope.to.options.push(usr);
	                                                	}
	                                                });
	                                            	if($scope.model.usersAccept && $scope.model.usersAccept.length > 0 && $scope.to.options.length > 0) {
	                                            		var selUsr = [];
	                                            		_.forEach($scope.to.options, function(option) {
	                                            			if($linq($scope.model.usersAccept).contains(option.user.id)) {
	                                            				selUsr.push(option.user.id);
	                                            			}
	                                            		});
	                                            		$scope.model.usersAccept = selUsr;
	                                            	}
	                                            }else{
	                                            	$scope.model.usersAccept = [];
	                                            	$scope.to.options = [];
	                                            }
	                                        });
	                                    	RestService.findAll('User', 'adminCreateTransaction')
	    	                                    .then(function(data) {
	    	                                        var users = [];
	    	
	    	                                        if(data.data && data.data.length > 0) {
	    	                                            _.forEach(JSOG.decode(data.data), function(val) {
	    	                                            	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
	    	                                            });
	    	                                        }
	    	
	    	                                        $scope.options.templateOptions.users = users;
	    	                                        $rootScope.$broadcast('selectedProfile', { });
	    	                                    });
	                                    }]
	                                }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    }
                }
            ],
            alerts: [],
            submitModal: function () {}
        },
        organMemberNew: {
            title: 'global.sec.literals.members',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'row_member',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'organMemberGlobal',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.member',
                                    valueProp: 'id',
                                    labelProp: 'completeName',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function($item) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    	$rootScope.$broadcast('selectOrganMemberGlobal', { item: $item });
                                    }
                                },
                                controller: ['$scope', 'AdminFactory', 'Language', 'RestService', '$rootScope', function($scope, AdminFactory, Language, RestService, $rootScope) {
                                    RestService.findAll('OrganMemberGlobal').then(function(data) {
                                    	var members = JSOG.decode(data.data);
                                    	var additional_filter = {};
                                        if($scope.to.added && $scope.to.added.length > 0){
                                        	$scope.to.options = $linq(members).where(function(x){
                                        		if(x.id){
	                                        		if(_.contains($scope.to.added, x.id)){
	                                        			if($scope.model && $scope.model.organMemberGlobal && $scope.model.organMemberGlobal.id == x.id){
	                                        				return true;
	                                        			}else{
	                                        				return false; 
	                                        			}
	                                        		}else{
	                                        			return true;
	                                        		}
                                        		}else{
                                        			return true;
                                        		}
                                        	}).toArray();
                                        }else{
                                        	$scope.to.options =members;
                                        }
                                        _.forEach($scope.to.options, function(option){
                                        	option.completeName = option.attendee.completeName;
                                        });
                                    }).catch(function (error) {
                                    	$scope.to.options = [];
                                    });
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'memberRol',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.rol',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'AdminFactory', 'Language', 'RestService', '$rootScope', 'GlobalDataFactory', function($scope, AdminFactory, Language, RestService, $rootScope, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                	var rols = angular.copy(GlobalDataFactory.organMemberRols);
                                	if($scope.to.existPresident && rols && rols.length > 0){
                                		rols = $linq(rols).where("x=>x.id !="+$rootScope.app.configuration.sec_organ_member_rols.president).toArray();
                                	}
                                	if($scope.to.existSecretary && rols && rols.length > 0){
                                		rols = $linq(rols).where("x=>x.id !="+$rootScope.app.configuration.sec_organ_member_rols.secretary).toArray();
                                	}
                                	$scope.to.options = new SortedArray(rols, Language.getActiveColumn()).sort();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'attendeeAddressConvene',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.secAddress',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', '$filter', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, $filter, Language, $rootScope, GlobalDataFactory) {
                                	var addAddresses = function(){
                                		$scope.to.options = [];
                                		if($scope.model.organMemberGlobal && $scope.model.organMemberGlobal.attendee && $scope.model.organMemberGlobal.attendee.addresses){
	                                		_.forEach($scope.model.organMemberGlobal.attendee.addresses, function(address){
	                                			var thirdAllAddress = new ThirdAddress($scope.model.organMemberGlobal.attendee, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
	                                    		address.name = thirdAllAddress.getAddressHtml(address, true, true);
	                                		});
	                                		$scope.to.options = $linq($scope.model.organMemberGlobal.attendee.addresses).where(function(x){
	                                			if(x && x.addressType){
	                                				if($linq(GlobalDataFactory.notificationTypes).firstOrDefault(undefined,"x => x.addressType == '" + x.addressType + "'")){
	                                					return true;
	                                				}
	                                			}
	                                			return false;
	                                		}).toArray();
	                                    }
                                	}
                                	addAddresses();
                                    $rootScope.$on('selectOrganMemberGlobal', function(event, args) {
                                    	$scope.model.attendeeAddressConvene = undefined;
                                        addAddresses();
                                    });
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(!scope.model.organMemberGlobal || !scope.model.organMemberGlobal.id) {
                                        return true;
                                    }else{
                                        return false;
                                    }
                                }
                            },
                            {
                                key: 'vote',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.vote',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [
                                        { id: false, name: $filter('translate')('global.literals.no') },
                                        { id: true, name: $filter('translate')('global.literals.yes') }
                                    ]
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'orderView',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.order',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'weightedVote',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.sec.literals.weightedVote',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }                         
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        organMemberGlobalNew: {
            title: 'global.sec.literals.members',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'row_member',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'attendee',
                                type: 'annexaLoadUserRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.attendee',
                                    required: true,
                                    focus: true,
                                    options: [],
                                    loadFunction: function () {},
                                    onSelect: function($item, $model, $label, $event) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    	$rootScope.$broadcast('selectAttendee', { item: $item });
                                    }
                                },
                                controller: function ($scope, $rootScope, RestService, CommonService, SecFactory) {
                                	$scope.options.templateOptions.oldAttendee = undefined;
                                	if($scope.model.attendee && $scope.model.attendee.id){
                                		$scope.options.templateOptions.oldAttendee = $scope.model.attendee.id
                                	}
                                	$scope.options.templateOptions.loadFunction = function(value) {
                                    	var valString = '';

                                        if(value.hasOwnProperty('val')) {
                                            valString = value.val;
                                        } else if(value.hasOwnProperty('value')) {
                                            valString = value.value;
                                        } else {
                                            valString = value;
                                        }

                                        if(!valString) {
                                            return [];
                                        }

                                        if(valString != '*' && valString.length < 3) {
                                            return [];
                                        }

                                        if(valString == '*') {
                                            valString = '';
                                        }

                                        var filter = { name: valString };
                                        var additional_filter = {};
                                        if(SecFactory.thirdsAdded && SecFactory.thirdsAdded.length > 0){
                                        	var added = angular.copy(SecFactory.thirdsAdded);
                                        	if($scope.options && $scope.options.templateOptions && $scope.options.templateOptions.oldAttendee && added && added.length > 0){
                                        		if($linq(added).count("x => x =="+$scope.options.templateOptions.oldAttendee) > 0){
                                        			added = $linq(added).where("x => x != "+$scope.options.templateOptions.oldAttendee).toArray();
                                        		}
                                        	}
                                        	additional_filter.added = added;
                                        }
                                        return CommonService.filterThirds(filter, additional_filter)
                                            .then(function(data) {
                                                var response = [];

                                                _.forEach(data, function(value) {
                                                    var name = '';

                                                    if(value.user) {
                                                        name += '<i class="fa fa-user"></i> '
                                                    }

                                                    if(value.third.corporateName) {
                                                        name += value.third.corporateName + ' (' + value.third.identificationDocument + ')';
                                                    } else {
                                                        name += value.third.name + ((value.third.surname1Particles)? ' ' +value.third.surname1Particles : '' ) + ' ' + value.third.surename1 + ((value.third.surname2Particles)? ' ' +value.third.surname2Particles : '' ) + ((value.third.surename2)? ' ' +value.third.surename2 : '' ) + ' (' + value.third.identificationDocument + ')'
                                                    }

                                                    response.push({ id: value.third.id, attendee: JSOG.encode(value.third), value: name, user: JSOG.encode(value.user)})
                                                });

                                                response = new SortedArray(response, 'value').sort();

                                                return response;
                                            });

                                    }
                                },
                                validators:{
                                    "requiredCouncillor": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = false;
                                            if(modelValue && modelValue.id){
                                                valid = true;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'memberPosition',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.position',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'SecFactory', 'Language', 'RestService', '$rootScope', function($scope, SecFactory, Language, RestService, $rootScope) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    var positions = SecFactory.positions;
                                    $scope.to.options = new SortedArray(positions, Language.getActiveColumn()).sort();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'politicalParty',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.politicalParty',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller: ['$scope', 'SecFactory', 'Language', function($scope, SecFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = new SortedArray(SecFactory.politicalParties, Language.getActiveColumn()).sort();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'govermmentTeam',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.govermmentTeam',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [
                                        { id: false, name: $filter('translate')('global.literals.no') },
                                        { id: true, name: $filter('translate')('global.literals.yes') }
                                    ]
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'identificationReference',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.sec.literals.identificationReference',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'organs',
                                className: 'col-xs-12',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-members-organ'
                                },
                                controller: ['$scope', '$filter', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, $filter, Language, $rootScope, GlobalDataFactory) {
                                    $rootScope.$on('selectAttendee', function(event, args) {
                                    	if(args && args.item && $scope.options.data.isNew) {
                                        	$scope.options.data.organMemberGlobal = {attendee: args.item};
                                    	}
                                    });
                                }],
                                data: {
                                    organs: [],
                                    isNew: false
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        memberOrgnaNew: {
            title: 'global.sec.literals.organs',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'row_organ',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'organ',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.organ',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'AdminFactory', 'Language', 'RestService', '$rootScope', 'GlobalDataFactory', function($scope, AdminFactory, Language, RestService, $rootScope, GlobalDataFactory) {
                                	if($scope.to.added && $scope.to.added.length > 0){
                                		$scope.to.options = $linq(GlobalDataFactory.organs).where(function(x){
                                    		if(x.id){
                                        		if(_.contains($scope.to.added, x.id)){
                                        			if($scope.model && $scope.model.organ && $scope.model.organ.id == x.id){
                                        				return true;
                                        			}else{
                                        				return false; 
                                        			}
                                        		}else{
                                        			return true;
                                        		}
                                    		}else{
                                    			return true;
                                    		}
                                    	}).toArray();
                                	} else {
                                		$scope.to.options = GlobalDataFactory.organs;
                                	}
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'memberRol',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.rol',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'AdminFactory', 'Language', 'RestService', '$rootScope', 'GlobalDataFactory', function($scope, AdminFactory, Language, RestService, $rootScope, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                	var rols = angular.copy(GlobalDataFactory.organMemberRols);
                                	if($scope.to.existPresident && rols && rols.length > 0){
                                		rols = $linq(rols).where("x=>x.id !="+$rootScope.app.configuration.sec_organ_member_rols.president).toArray();
                                	}
                                	if($scope.to.existSecretary && rols && rols.length > 0){
                                		rols = $linq(rols).where("x=>x.id !="+$rootScope.app.configuration.sec_organ_member_rols.secretary).toArray();
                                	}
                                	$scope.to.options = new SortedArray(rols, Language.getActiveColumn()).sort();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'attendeeAddressConvene',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.secAddress',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'vote',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.vote',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [
                                        { id: false, name: $filter('translate')('global.literals.no') },
                                        { id: true, name: $filter('translate')('global.literals.yes') }
                                    ]
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'orderView',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.order',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'weightedVote',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.sec.literals.weightedVote',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.organ && scope.model.organ.id){
                                		var GlobalDataFactory = angular.element(document.body).injector().get('GlobalDataFactory');
                                		var org = $linq(GlobalDataFactory.organs).firstOrDefault(undefined, "x => x.id == "+scope.model.organ.id);
                                		if(org && org.weightedVote && scope.model.vote){
                                			return false;
                                		}
                                	}
                                	return true;
                                }
                            }                         
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        organAgendaPointTypeNew: {
            title: 'global.sec.literals.agendaPointTypes',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'row_point',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            var cont = 1;
                                            var column = "language";
                                            if (scope.languages) {
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if (modelValue && !modelValue[column + cont]) {
                                                        valid = false;
                                                    }
                                                    cont++;
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'type',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'AdminFactory', 'Language', '$filter', function($scope, AdminFactory, Language, $filter) {
                                    if($scope.model.resolutionType == 'OPINION') {
                                    	$scope.to.options = [
                                    		{ id: 'OPINION', name: $filter('translate')('global.sec.literals.resolutionTypeOPINION') },
                                    		{ id: 'VOTE', name: $filter('translate')('global.sec.literals.resolutionTypeVOTE') },
                                            { id: 'NOVOTE', name: $filter('translate')('global.sec.literals.resolutionTypeNOVOTE') },
                                    		{ id: 'GROUP', name: $filter('translate')('global.sec.literals.resolutionTypeGROUP') }
                                        ];
                                    } else if ($scope.model.resolutionType == 'AGREEMENT') {
                                    	$scope.to.options = [
                                            { id: 'PROPOSAL', name: $filter('translate')('global.sec.literals.resolutionTypePROPOSAL') },
                                            { id: 'VOTE', name: $filter('translate')('global.sec.literals.resolutionTypeVOTE') },
                                            { id: 'NOVOTE', name: $filter('translate')('global.sec.literals.resolutionTypeNOVOTE') },
                                            { id: 'GROUP', name: $filter('translate')('global.sec.literals.resolutionTypeGROUP') }
                                        ];
                                    }else{
                                    	$scope.to.options = [];
                                    }
                                    
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'template',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.templateAgreementDocument',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'SecFactory', 'Language', '$filter', '$rootScope', 'GlobalDataFactory', function($scope, SecFactory, Language, $filter, $rootScope, GlobalDataFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                   	var documentType = $rootScope.app.configuration.sec_document_types['SPT'];
                                    var templates = $linq(GlobalDataFactory.secTemplates).where(function(x){
                                    	if(documentType && documentType.id && documentType.id.length > 0 && x.documentType && x.documentType.id){
                                    		if(_.contains(documentType.id, x.documentType.id+"") && 'SPT' == x.type){
                                    			return true;
                                    		}
                                    	}
                                    	return false;
                                    }).toArray();

                                    $scope.to.options = ((templates && templates.length > 0)?templates:[]);
                                    if($scope.to.options && $scope.to.options.length == 1){
                                		$scope.model.template = $scope.to.options[0];
                                	}
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(!scope.model.type || (scope.model.type && scope.model.type.id && scope.model.type.id == 'GROUP')) {
                                    	scope.model.template = undefined;
                                        return true;
                                    }else{
                                        return false;
                                    }
                                }
                            },
                            {
                                key: 'vote',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'radio',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: true,
                                    options:[],
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'value',
                                    label: 'global.sec.literals.isVoted'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [];
                                    $scope.to.options.push({id:true, value:$filter('translate')('global.literals.yes')});
                                    $scope.to.options.push({id:false, value:$filter('translate')('global.literals.no')});
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(!scope.model.type || (scope.model.type && scope.model.type.id && scope.model.type.id != 'OPINION' && scope.model.type.id != 'PROPOSAL')) {
                                    	scope.model.vote = undefined;
                                        return true;
                                    }else{
                                    	if(scope.model.type.id == 'PROPOSAL' && !scope.model.canUnsignProposals){ 
                                    		return true;
                                    	}else{
                                    		return false;
                                    	}
                                    }
                                }
                            }                 
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        sectionNew: {
            title: 'global.commonAdmin.modal.section.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'type',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                            },
                            {
                                key: 'councillor',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.councillor',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                            }
                        ]
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'responsible',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.responsible',
                                    valueProp: 'id',
                                    labelProp: 'completeName',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', function($scope) {
                                	$scope.to.ngOptions = 'option[to.valueProp] as option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                            },
                            {
                                key: 'parent',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.commonAdmin.modal.section.parent',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                defaultValue: undefined
                            }
                        ]
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row4',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'acronym',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.acronym',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    },
                    {
                        key: 'row5',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'presetSignCircuit',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.presetSignCircuits',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                }
                            }
                        ]
                    },
                    {
                        key: 'proposalTypes',
                        type: 'annexaComponent',
                        className: 'col-xs-12',
                        templateOptions: {
                            type: 'annexa-section-proposal-type'
                        },
						data: {
                           	object: {},
							isNew: true,
							sectionProposalTypes: []
                        },
                        hideExpression: function ($viewValue, $modelValue, scope) {
                        	var $rootScope = angular.element(document.body).injector().get('$rootScope');
							if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.section_proposal_type && $rootScope.app.configuration.section_proposal_type.active){
								return false;
                            }else{
                                return true;
							}
                        }
                    }
                ]
            },
            submitModal: function () {
            }
        },
        sectionEdit: {
            title: 'global.commonAdmin.literals.editSection',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.type" model-value="modal.data.type" model-property="type" save="modal.updateSection(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.sectionTypes"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.councillor" model-value="modal.data.councillor" model-property="councillor" save="modal.updateSection(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.councillors"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.responsible" model-value="modal.data.responsible" model-property="responsible" save="modal.updateSection(val, prop)" required="false" blur="ignore" lang-col="modal.responsibleColumn" select-options="modal.users"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.section.parent" model-value="modal.data.parent" model-property="parent" save="modal.updateSection(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.sections"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateSection(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateSection(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateSection(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',           
                '</div>',   
                '<div class="row p-t-xs">',
				'   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.presetSignCircuits" model-value="modal.data.presetSignCircuit" model-property="presetSignCircuit" save="modal.updateSection(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.presetSignCircuits"></annexa-editable-field>',
                '   </div>',        
                '</div>', 
                '<div class="row p-t-xs">',
				'   <div class="col-lg-12" ng-if="modal.sectionProposalTypeActive">',
                '       <annexa-section-proposal-type object="modal.data" is-new="false" section-proposal-types="modal.data.sectionProposalTypes"></annexa-section-proposal-type>',
                '   </div>',            
                '</div>'
            ]
        },
        sectionProposalTypeNew: {
            title: 'global.sec.literals.proposalType',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'proposalType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.proposalType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.proposalTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'presetSignCircuit',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.presetSignCircuits',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.presetSignCircuit;
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        templateTypeNew: {
            title: 'global.commonAdmin.literals.newTemplateType',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'parent',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                },
                                defaultValue: undefined
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            submitModal: function () {
            }
        },
        templateTypeEdit: {
            title: 'global.commonAdmin.literals.editTemplateType',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTemplateType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        familyProcedure: {
            title: 'global.commonAdmin.modal.familyProcedure.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'parent',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                },
                                defaultValue: undefined
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        familyProcedureEdit: {
            title: 'global.commonAdmin.modal.familyProcedure.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '  <div class="col-xs-4 col-md-3">',
                '      <label translate="global.literals.name">Name</label>',
                '  </div>',
                '  <div class="col-xs-8 col-md-9">',
                '      <a class="editable-click show" ng-show="!titleForm.$visible" editable-languageinputs="modal.data.language1" e-model-language="modal.data" e-model-field="" e-label="" onbeforesave="modal.saveFamilyProcedureEdit($data)" oncancel="modal.cancelFamilyProcedureEdit()" e-form="titleForm">{{ modal.data[modal.activeColumn] || \'empty\' }}</a>',
                '      <a ng-click="titleForm.$show()" href="" class="abs-r-1" role="button" aria-label="{{\'global.literals.edit\' | translate}}" ng-show="!titleForm.$visible" ><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i></a>',
                '  </div>',
                '</div>'
            ]
        },
        transactionType: {
            title: 'global.commonAdmin.modal.transactionType.titleNew',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'column1',
                                className: 'col-sm-6',
                                fieldGroup: [
									{
		                                key: 'orderView',
		                                type: 'annexaInputRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    type: 'number',
		                                    label: 'global.literals.order',
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'language1',
		                                type: 'annexaInputLanguage',
		                                className: 'col-xs-12',
		                                validators:{
		                                    "required": {
		                                        "expression": function (viewValue, modelValue, scope) {
		                                            var valid = true;
		                                            if(scope.languages){
		                                                angular.forEach(scope.languages, function (value2, key2) {
		                                                    if(value2 && value2.column) {
		                                                        if (modelValue && !modelValue[value2.column]) {
		                                                            valid = false;
		                                                        }
		                                                    }
		                                                });
		                                            }
		                                            return valid;
		                                        }
		                                    }
		                                },
		                                watcher:{
		                                    type: '$watchCollection',
		                                    expression: 'model',
		                                    listener: function(field, _new) {
		                                        if(field.formControl){
		                                            field.formControl.$validate();
		                                        }
		                                    }
		                                },
		                                templateOptions: {
		                                    id:'language',
		                                    modelField:'',
		                                    type: 'text',
		                                    label: 'global.literals.name',
		                                    disposition:'horitzontal21',
		                                    required: true,
		                                    focus: true
		                                },
		                                controller: function ($scope, $rootScope) {
		                                    $scope.languages = $rootScope.app.languagedef;
		                                }
		                            },
		                            {
		                                key: 'tramitationTypes',
		                                type: 'annexaMultipleSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.tramitationType',
		                                    valueProp: 'id',
		                                    labelProp: 'language1',
		                                    placeholder: '',
		                                    options: [],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'phases',
		                                type: 'annexaMultipleSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.phase',
		                                    valueProp: 'id',
		                                    labelProp: 'language1',
		                                    placeholder: '',
		                                    options: [],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'startType',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.startType',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'finallyType',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.finallyType',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'days',
		                                type: 'annexaInputRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    type: 'number',
		                                    label: 'global.literals.expire',
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                            	key: 'expireType',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.commonAdmin.modal.unguidedProcedure.expireType',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                },
		                                controller: function ($scope, $rootScope, apiAdmin) {
		                                    $scope.to.options = apiAdmin.expirationTypeList;
		                                }
		                            },
		                            {
		                                key: 'countDays',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.countDays',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'transactionSubtype',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.transactionSubtype',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                },
		                                controller: function ($scope, $rootScope, apiAdmin) {
		                                    $scope.to.options = new SortedArray(apiAdmin.transactionSubtypes, 'order').sort();
		                                }
		                            },
		                            {
		                                key: 'allowUserChangeMaxDays',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.allowUserChangeMaxDaysDT',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'allowUserChangeCountDays',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.literals.allowUserChangeCountDays',
		                                    valueProp: 'id',
		                                    labelProp: 'description',
		                                    placeholder: '',
		                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                                    required: true
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'subprocedures',
		                                type: 'annexaMultipleSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    focus: true,
		                                    label: 'global.literals.guidedSubprocedureCanStart',
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    valueProp: 'id',
		                                    labelProp: 'language1',
		                                    placeholder: '',
		                                    required: false,
		                                    options: []
		                                },
		                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
		                                    $scope.to.labelProp = Language.getActiveColumn();
		                                    $scope.to.options = $linq(GlobalDataFactory.procedures).where("x => x.procedureType == 'SUBPROCEDURE' || x.procedureType == 'PROCEDURE_SUBPROCEDURE'").orderBy("x => x." + Language.getActiveColumn()).toArray();
		                                }],
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'active',
		                                type: 'annexaRadioCheckboxRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    type: 'checkbox',
		                                    radioClass: 'radio-inline',
		                                    optionsAttr: 'bs-options',
		                                    required: false,
		                                    validate: true,
		                                    options:[],
		                                    ngOptions:'option[to.valueProp] as option in to.options',
		                                    valueProp: 'id',
		                                    labelProp: 'value',
		                                    label: ' '
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    clear: function($event,model,key, $select) {
		                                        $event.stopPropagation();
		                                        model[key] = undefined;
		                                        if($select) {
		                                            $select.selected = undefined;
		                                            $select.search = undefined;
		                                        }
		                                    }
		                                },
		                                controller: function ($scope, $filter) {
		                                    $scope.to.options = [
		                                    	{ value: $filter('translate')('global.literals.active'), id:1 }
		                                    ];
		                                }
		                            },
		                            {
		                                key: 'activityGia',
		                                type: 'annexaSelectRow',
		                                className: 'col-xs-12',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: 'global.tram.literals.gia.showGia',
		                                    valueProp: 'id',
		                                    labelProp: 'name',
		                                    placeholder: '',
		                                    options: [],
		                                    required: false
		                                },
		                                data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                },
		                                controller: function ($scope, $filter, GlobalDataFactory) {
		                                    $scope.to.options = angular.copy(GlobalDataFactory.activitiesGia);
		                                    $scope.to.options.unshift({id:'', name:"global.tram.literals.gia.activities.nothing"});
		                                },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                            if($rootScope.app.configuration.active_gia_diba.value === true) {
                                            	return false;
                                            }else{
                                            	return true;
                                            }
                                        }
		                            }
								]
                            },
                            {
                                key: 'column2',
                                className: 'col-sm-6',
                                fieldGroup: [
                                    {
                                        key: 'customFields',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-custom-field-definition'
                                        },
                                        controller: ['$scope', function($scope) {
                                            $scope.options.data.customFields = [];
                                        }],
                                        data: {
                                            customFields: [],
                                            type: 'TRANSACTION_TYPE',
											showType: false,
                                            updateOnChange: false
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        transactionTypeEdit: {
            title: 'global.commonAdmin.modal.transactionType.titleEdit',
            size: 'modal-lg',
            data: undefined,
            content: [
				'<div class="row p-t-xs">',
                '   <div class="col-sm-6 ">',
                '		<div class="row form-group ">',
                '   		<div class="col-xs-12">',
                '       		<annexa-editable-field type="number" label="global.literals.order" model-value="modal.data.orderView" model-property="orderView" save="modal.saveTransactionTypeEdit(val, prop)" blur="ignore" required="true"></annexa-editable-field>',
                '   		</div>',
                '		</div>',
                '		<div class="row form-group">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.name">Name</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-languageinputs="modal.data.language1" e-model-language="modal.data" e-model-field="" e-label="" onbeforesave="modal.saveTransactionTypeEdit($data,\'language1\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="titleForm" blur="ignore">{{ modal.data[modal.activeColumn] || \'empty\' }}</a>',
                '		       <a href="" ng-click="titleForm.$show()" class="abs-r-1" aria-label="{{\'global.literals.edit\' | translate}}" role="button" ng-show="!titleForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <annexa-editable-field type="select-multiple" label="global.literals.tramitationType" model-value="modal.data.tramitationTypes" model-property="tramitationTypes" save="modal.saveTransactionTypeEdit(val, prop)" required="true" blur="ignore" lang-col="modal.activeColumn" select-options="modal.tramitationTypes" print-function="modal.printTramitationTypes"></annexa-editable-field>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <annexa-editable-field type="select-multiple" label="global.literals.phase" model-value="modal.data.phases" model-property="phases" save="modal.saveTransactionTypeEdit(val, prop)" required="true" blur="ignore" lang-col="modal.activeColumn" select-options="modal.tramitationPhases" print-function="modal.printPhases"></annexa-editable-field>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.startType">Is start</span>',
                ' 		  </div>',
                ' 		  <div class="col-xs-12">',
                ' 		      <a class="editable-click label-strong inline wi-100" editable-select="modal.data.startType" e-ng-options="item.id as item.description | translate for item in modal.yesNoList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'startType\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="startTypeForm" blur="ignore">{{ (\'global.literals.\'+ (modal.data.startType == true ? \'yes\' : \'no\') | translate) || \'empty\' }}</a>',
                '		      <a href="" class="abs-r-1" ng-click="startTypeForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" ng-show="!startTypeForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.finallyType">Is finally</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.finallyType" e-ng-options="item.id as item.description | translate for item in modal.yesNoList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'finallyType\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="finallyTypeForm" blur="ignore">{{ (\'global.literals.\'+ (modal.data.finallyType == true ? \'yes\' : \'no\') | translate) || \'empty\' }}</a>',
                '		       <a ng-click="finallyTypeForm.$show()" href="" role="button" aria-label="{{\'global.literals.edit\' | translate}}" class="abs-r-1" ng-show="!finallyTypeForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group">',
                '		   <div class="col-xs-12">',
                '		       <annexa-editable-field type="number" label="global.literals.expire" model-value="modal.data.days" model-property="days" save="modal.saveTransactionTypeEdit(val, prop)" blur="ignore" required="true"></annexa-editable-field>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group">',
	            '		    <div class="col-xs-12"><span class=" small" translate="global.commonAdmin.modal.unguidedProcedure.expireType">Expire Type</span></div>',
	            '		    <div class="col-xs-12">',
	            '		        <a class="editable-click label-strong inline wi-100" editable-select="modal.data.expireType" e-ng-options="item.id as item.description | translate for item in modal.expirationTypeList"  onbeforesave="modal.saveTransactionTypeEdit($data,\'expireType\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="expireTypeForm" blur="ignore">{{ (\'global.literals.\'+modal.data.expireType | translate) || \'empty\' }}</a>',
	            '		        <a href="" role="button" aria-label="{{\'global.literals.edit\' | translate}}" class="abs-r-1"  ng-show="!expireTypeForm.$visible" ng-click="expireTypeForm.$show()"><i title="{{\'global.literals.edit\' | translate}}" class="fa fa-pencil-square grey-pencil" ></i></a>',
	            '		    </div>',
	            '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.countDays">Count days</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.countDays" e-ng-options="item.id as item.description | translate for item in modal.yesNoList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'countDays\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="countDaysForm" blur="ignore">{{ (\'global.literals.\'+ (modal.data.countDays == true ? \'yes\' : \'no\') | translate) || \'empty\' }}</a>',
                '		       <a ng-click="countDaysForm.$show()" href="" role="button" aria-label="{{\'global.literals.edit\' | translate}}" class="abs-r-1"  ng-show="!countDaysForm.$visible"><i title="{{\'global.literals.edit\' | translate}}" class="fa fa-pencil-square grey-pencil" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.transactionSubtype">Transaction subtype</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.transactionSubtype" e-ng-options="item.id as item.description | translate for item in modal.transactionSubtypesList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'transactionSubtype\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="transactionSubtypeForm" blur="ignore">{{ modal.showTransactionSubtype() }}</a>',
                '		       <a ng-click="transactionSubtypeForm.$show()" href="" role="button" aria-label="{{\'global.literals.edit\' | translate}}" class="abs-r-1"  ng-show="!transactionSubtypeForm.$visible"><i title="{{\'global.literals.edit\' | translate}}" class="fa fa-pencil-square grey-pencil" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.allowUserChangeMaxDaysDT">Allow user change max days</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.allowUserChangeMaxDays" e-ng-options="item.id as item.description | translate for item in modal.yesNoList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'allowUserChangeMaxDays\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="allowUserChangeMaxDaysForm" blur="ignore">{{ (\'global.literals.\'+ (modal.data.allowUserChangeMaxDays == true ? \'yes\' : \'no\') | translate) || \'empty\' }}</a>',
                '		       <a href="" class="abs-r-1" ng-click="allowUserChangeMaxDaysForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" ng-show="!allowUserChangeMaxDaysForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.allowUserChangeCountDays">Allow user change count days</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.allowUserChangeCountDays" e-ng-options="item.id as item.description | translate for item in modal.yesNoList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'allowUserChangeCountDays\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="allowUserChangeCountDaysForm" blur="ignore">{{ (\'global.literals.\'+ (modal.data.allowUserChangeCountDays == true ? \'yes\' : \'no\') | translate) || \'empty\' }}</a>',
                '		       <a href="" class="abs-r-1" ng-click="allowUserChangeCountDaysForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" ng-show="!allowUserChangeCountDaysForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group ">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.literals.guidedSubprocedureCanStart">Subprocedures</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.subprocedures" e-multiple e-ng-options="item.id as item[modal.activeColumn] for item in modal.subproceduresList"  onbeforesave="modal.saveTransactionTypeEdit($data, \'subprocedures\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="subproceduresForm" blur="ignore">{{ modal.getSubprocedureValue(modal.data.subprocedures) }}</a>',
                '		       <a href="" class="abs-r-1" ng-click="subproceduresForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" ng-show="!subproceduresForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '		<div class="row p-t-xs">',
                '		   <div class="col-lg-12">',
                '		       <annexa-editable-field type="checkbox" label="{{\'global.literals.active\' | translate}}" model-value="modal.data.active" model-property="active" save="modal.saveTransactionTypeEdit(val, prop)" required="false" blur="ignore" print-function="modal.printActive" can-edit="modal.canEdit" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '		   </div>',
                '		</div>',
                '		<div class="row form-group " ng-if="modal.showGIA">',
                '		   <div class="col-xs-12">',
                '		       <span class="small" translate="global.tram.literals.gia.showGia">Show GIA</span>',
                '		   </div>',
                '		   <div class="col-xs-12">',
                '		       <a class="editable-click label-strong inline wi-100" editable-select="modal.data.activityGia" e-ng-options="item.id as item.name | translate for item in modal.activitiesGia"  onbeforesave="modal.saveTransactionTypeEdit($data, \'activityGia\')" oncancel="modal.cancelTransactioonTypeEdit()" e-form="activityGiaForm" blur="ignore">{{ modal.getActivityGia(modal.data.activityGia)}}</a>',
                '		       <a href="" class="abs-r-1" ng-click="activityGiaForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" ng-show="!activityGiaForm.$visible"><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}" ></i></a>',
                '		   </div>',
                '		</div>',
                '   </div>',
                '   <div class="col-sm-6">',
                '       <annexa-custom-field-definition custom-fields="modal.customFields" type="TRANSACTION_TYPE" show-type="false" update-on-change="true" parent-property="transactionType" parent-id="modal.data.id"/>',
                '   </div>',
                '</div>'
            ]
        },
        userNew: {
            title: 'global.commonAdmin.newUser',
            size: 'modal-lg',
            icon: 'fa fa-user',
            tabs: [
                {
                    title: 'global.commonAdmin.modal.user.userData',
                    active: true,
                    annexaFormly: {
                        fields: [
                            {
                                key: 'row1',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'authenticationType',
                                        type: 'annexaSelectRow',
                                        className: 'col-sm-3',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.authenticationType',
                                            valueProp: 'id',
                                            labelProp: 'name',
                                            placeholder: '',
                                            options: [],
                                            required: true
                                        },
                                        controller: ['$scope', '$filter', '$rootScope', function($scope, $filter, $rootScope) {
                                        	var otherAuthType = undefined;
                                        	$scope.to.options = [
                                                { id: 'DATABASE', name: $filter('translate')('global.literals.DATABASE') }
                                            ];
                                        	if(_.contains(['LDAPANDDATABASE', 'DATABASEANDLDAP'], $rootScope.app.configuration.security_method.value)){
                                        		otherAuthType = { id: 'LDAP', name: $filter('translate')('global.literals.LDAP') };
                                        	}else if(_.contains(['OAUTHANDATABASE', 'OAUTH'], $rootScope.app.configuration.security_method.value)){
                                        		otherAuthType = { id: 'OAUTH', name: $filter('translate')('global.literals.OAUTH') };
                                        	}
                                        	if(otherAuthType){
                                        		$scope.to.options.push(otherAuthType);
                                        	}
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');

                                            if(!_.contains(['LDAPANDDATABASE', 'DATABASEANDLDAP', 'OAUTH', 'OAUTHANDATABASE'], $rootScope.app.configuration.security_method.value)) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    },
                                    {
                                        key: 'identifier',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-3',
                                        templateOptions: {
                                            required: true,
                                            type: 'text',
                                            label: 'global.literals.user',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: 'col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'password',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-3',
                                        templateOptions: {
                                            required: true,
                                            type: 'password',
                                            label: 'global.sigin.password',
                                            maxlength:20
                                        },
                                        data: {
                                            row: true,
                                            colClass: 'col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');

                                            if($rootScope.app.configuration.security_method.value != 'DATABASE') {
                                                if($rootScope.app.configuration.security_method.value == 'LDAP') {
                                                    return true;
                                                } else if(scope.model.authenticationType != 'DATABASE'){
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }
                                    },
                                    {
                                        key: 'repassword',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-3',
                                        templateOptions: {
                                            required: true,
                                            type: 'password',
                                            label: 'global.literals.repassword',
                                            maxlength:20
                                        },
                                        data: {
                                            row: true,
                                            colClass: 'col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            var $rootScope = angular.element(document.body).injector().get('$rootScope');

                                            if($rootScope.app.configuration.security_method.value != 'DATABASE') {
                                                if($rootScope.app.configuration.security_method.value == 'LDAP') {
                                                    return true;
                                                } else if(scope.model.authenticationType != 'DATABASE'){
                                                    return true
                                                }
                                            }
                                            return false;
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'row_photo',
                                className: '',
                                templateOptions: {},
                                fieldGroup:[
                                    {
                                        key: 'photo',
                                        type: 'annexaImageUpload',
                                        className: 'col-sm-6',
                                        templateOptions: {
                                            required: false,
                                            label: 'global.commonAdmin.modal.user.imageprofile'
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong',
                                            uploadImage: function(file, model, key) {
                                                var reader = new FileReader();
                                                reader.onload = function (e) {
                                                    model[key] = { file: e.target.result, fileName: file.name, fileType: file.type };
                                                }
                                                reader.readAsArrayBuffer(file);
                                            },
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'row2',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'name',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: true,
                                            type: 'text',
                                            label: 'global.literals.name',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    },
                                    {
                                        key: 'surename1',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: true,
                                            type: 'text',
                                            label: 'global.literals.surename1',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    },
                                    {
                                        key: 'surename2',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.surename2',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    }
                                ]
                            },
                            {
                                key: 'row3',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'phone',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.profile.phone',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    },
                                    {
                                        key: 'extension',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.extension',
                                            maxlength:10
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    },
                                    {
                                        key: 'cellPhone',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.cellPhone',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    }
                                ]
                            },
                            {
                                key: 'row4',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'email',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: true,
                                            type: 'email',
                                            label: 'global.profile.email',
                                            maxlength:50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    },
                                    {
                                        key: 'acronym',
                                        type: 'annexaInputRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            required: true,
                                            type: 'text',
                                            label: 'global.literals.acronym',
                                            maxlength:20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }

                                    },
                                    {
                                        key: 'language',
                                        type: 'annexaSelectRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.language',
                                            valueProp: 'acronym',
                                            labelProp: 'label',
                                            placeholder: '',
                                            options: [],
                                            required: true
                                        },
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'row5',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'positions',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-sm-4',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.positions',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                    	key: 'identificationNumber',
                                    	type: 'annexaInputRow',
                                    	className: 'col-sm-4',
                                    	templateOptions: {
                                    		required: false,
                                            type: 'text',
                                            label: 'global.thirds.literals.identificationdocument',
                                            maxlength:50
                                    	},
                                    	data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    title: 'global.commonAdmin.modal.user.rolesAndProfiles',
                    active: false,
                    annexaFormly: {
                        fields: [
                            {
                                key: 'row1',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'sections',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-xs-12',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.commonAdmin.tabs.sections',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', function($scope) {
                                    		$scope.to.ngOptions = 'option[to.valueProp] as option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                    	}],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    }                                ]
                            },
                            {
                                key: 'row2',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'roles',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-xs-12',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.rols',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', function($scope) {
                                    		$scope.to.ngOptions = 'option[to.valueProp] as option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                    	}],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    }                                ]
                            }
                        ]
                    },
                    requiredText: '',
                    duplicateEntityType: '',
                    userProfiles: [],
                    profiles: [],
                    roles: [],
                    languageColumn: 'language1',
                    checkRole: function($data, profile, object) {
                        profile.roles = [];

                        if($data) {
                            profile.roles = $data;
                        }
                    },
                    checkProfile: function($data, profile, object) {
                        if(!$data || $data == -1) {
                            return this.requiredText;
                        } else {
                            profile.profile.id = $data;

                            var selectedProfile = $linq(this.profiles).singleOrDefault({ language1: "NotTranslated", language2: "NotTranslated", language3: "NotTranslated"}, "x => x.id == " + $data);
                            profile.profile.language1 = selectedProfile.language1;
                            profile.profile.language2 = selectedProfile.language2;
                            profile.profile.language3 = selectedProfile.language3;
                        }
                    },
                    updateProfile: function(rowformprofile) {
                        var self = this;
                        angular.forEach(this.userProfiles, function (value, key) {
                            if(value.profile.id == -1 ) {
                                self.userProfiles.splice(key, 1);
                            }
                        });
                        rowformprofile.$cancel()
                    },
                    removeProfile: function ($index) {
                    	var self = this;
						var deleteProfile = function($index){	
							var profileId = self.userProfiles[$index].profile.id;							
							self.userProfiles.splice($index, 1); 
						   	if(self.userDefaultProfiles ){
	                       		var defProfs = angular.copy(self.userDefaultProfiles);
								self.userDefaultProfiles.length = 0;
								_.forEach(defProfs, function(defProf){
									if(defProf.profile && defProf.profile.id && defProf.profile.id !== profileId){
										self.userDefaultProfiles.push(defProf);
									}
								});
							}	
						}
						if(self.userDefaultProfiles && self.userProfiles && self.userProfiles.length > $index && self.userProfiles[$index].profile && self.userProfiles[$index].profile.id){
							var def = $linq(self.userDefaultProfiles).firstOrDefault(undefined,"x => x.profile && x.profile.id == "+self.userProfiles[$index].profile.id);
							if(def){
								var DialogsFactory = angular.element(document.body).injector().get('DialogsFactory');
								DialogsFactory.confirm('global.literals.deleteUserProfileMessage', 'global.literals.deleteUserProfileMessageConfirm').then(function (data) {
						            deleteProfile($index);
					           }).catch(function (data) {
						       });
							}else{
								deleteProfile($index);
							}
						}else{
							deleteProfile($index);
						}
                    },
                    addProfile: function() {
                        this.inserted = {
                            profile: { id: -1, language1: '', language2: '', language3: '' },
                            type: ''
                        };

                        this.userProfiles.push(this.inserted);
                    },
                    getProfiles: function() {
                        if(this.profiles && this.userProfiles && this.userProfiles.length > 0){
                            return $linq(this.profiles).except(this.userProfiles, function(y, x){
                                if(x.id && y.profile && y.profile.id){
                                    return (x.id == y.profile.id);
                                } else {
                                    return false;
                                }
                            }).where("x => x.expiryDate == null").toArray();
                        }
                        return $linq(this.profiles).where("x => x.expiryDate == null").toArray();
                    },
                    getOptionsProfiles: function() {
                    	var selectableOptions = [];
                    	var selectedProfiles = this.userProfiles;
                        _.forEach(this.profiles, function (value, key) {
                        	var haveProfile = undefined;
                        	if(selectedProfiles) {
                        		haveProfile = $linq(selectedProfiles).firstOrDefault(undefined, "x => x.profile && x.profile.id =="+value.id);
                        	}
                            if(haveProfile) {
                                selectableOptions.push(value);
                            }
                        });
                        return $linq(selectableOptions).orderBy("x => x." + this.languageColumn, linq.caseInsensitiveComparer).toArray();
                    },

                    getEntityTypes: function() {
                        if(this.entityTypes && this.userDefaultProfiles && this.userDefaultProfiles.length > 0){
                            return $linq(this.entityTypes).except(this.userDefaultProfiles, function(y, x){
                                if(x.id && y.entityType){
                                    return (x.id == y.entityType);
                                } else {
                                    return false;
                                }
                            }).toArray();
                        }
                        return this.entityTypes;
                    },
                    
                    inserted: {},
                    userDefaultProfiles: [],
                    entityTypes: [
                        {id:'REG', description:'global.commonAdmin.modal.defaultEntityProfile.REG'},
                        {id:'DOC', description:'global.commonAdmin.modal.defaultEntityProfile.DOC'},
                        {id:'EXP', description:'global.commonAdmin.modal.defaultEntityProfile.EXP'},
                        {id:'SEC', description:'global.commonAdmin.modal.defaultEntityProfile.SEC'}
                    ],
                    insertedDefault: {},
                    addDefaultProfile: function () {
                        this.insertedDefault = {
                            profile: { id: -1, language1: '', language2: '', language3: '' },
                            entityType: ''
                        };

                        this.userDefaultProfiles.push(this.insertedDefault);
                    },
                    checkDefaultProfile: function($data, defaultProfile, object) {
                        if($data == -1) {
                            return this.requiredText;
                        } else {
                            defaultProfile.profile.id = $data;

                            var selectedProfile = $linq(this.profiles).singleOrDefault({ language1: "NotTranslated", language2: "NotTranslated", language3: "NotTranslated"}, "x => x.id == " + $data);
                            defaultProfile.profile.language1 = selectedProfile.language1;
                            defaultProfile.profile.language2 = selectedProfile.language2;
                            defaultProfile.profile.language3 = selectedProfile.language3;
                        }
                    },
                    checkDefaultProfileType: function($data, defaultProfile, object) {
                        if($data == '') {
                            return this.requiredText;
                        } else {
                            var existEntityType = $linq(this.userDefaultProfiles).count("x => x.entityType =='" + $data + "'");
                            if(existEntityType == 0) {
                                defaultProfile.entityType = $data;
                            } else {
                                return this.duplicateEntityType;
                            }
                        }
                    },
                    updateDefaultProfile: function(rowformdefaultprofile) {
                        var self = this;
                        angular.forEach(this.userDefaultProfiles, function (value, key) {
                            if(value.profile.id == -1 || value.entityType == '') {
                                self.userDefaultProfiles.splice(key, 1);
                            }
                        });
                        rowformdefaultprofile.$cancel()
                    },
                    removeDefaultProfile: function ($index) {
                        this.userDefaultProfiles.splice($index, 1);
                    },
                    printRoles: function (roles) {
                        var self = this;
                        var result = '';

                        angular.forEach(roles, function (role) {
                            if(result != '') {
                                result += ', ';
                            }
                            result += role[self.languageColumn];
                        });

                        return result;
                    },
                    content: [
                        '<div class="m-x-xs clearfix ">',
                        '   <div class="col-xs-12">',
                        '       <div class=" container-fluid m-b-lg grey-100 p-y-sm b-all ">',
                        '           <h6 class="p-x-0 form-control-label"><span translate="global.literals.profiles">Profiles</span></h6>',
                        '           <table class="table table-hover m-a-0 ">',
                        '             <thead>',
                        '               <tr style="font-weight: bold">',
                        '                   <th style="width:30%"><span translate="global.literals.profile">Profile</span></th>',
                        '                   <th style="width:62%"><span translate="global.literals.rols">Roles</span></th>',
                        '                   <th style="width:8%"><span ng-show="rowformprofile.$visible"></span></th>',
                        '               </tr>',
                        '             </thead>',
                        '             <tbody>',
                        '               <tr ng-repeat="userProfile in objectScope.tabs[1].userProfiles">',
                        '                   <td class="wi-220">',
                        '                       <span class="" editable-select="userProfile.profile.id" e-form="rowformprofile" e-ng-options="profile.id as profile[objectScope.tabs[1].languageColumn] for profile in objectScope.tabs[1].getProfiles()" onbeforesave="objectScope.tabs[1].checkProfile($data, userProfile, this);">',
                        '                           {{userProfile.profile[objectScope.tabs[1].languageColumn]}}',
                        '                       </span>',
                        '                   </td>',
                        '                   <td class="text-whitespace-normal">',
                        '                       <span editable-ui-select="userProfile.roles" data-e-multiple data-e-form="rowformprofile" theme="bootstrap" data-e-ng-model="userProfile.roles" onbeforesave="objectScope.tabs[1].checkRole($data, userProfile, this);" data-e-style="min-width: 100%;">',
                        '                           {{ objectScope.tabs[1].printRoles(userProfile.roles) }}',
                        '                           <editable-ui-select-match placeholder="">',
                        '                               {{$item[objectScope.tabs[1].languageColumn]}}',
                        '                           </editable-ui-select-match>',
                        '                           <editable-ui-select-choices repeat="role as role in objectScope.tabs[1].roles | filter: $select.search track by $index">',
                        '                               {{role[objectScope.tabs[1].languageColumn]}}',
                        '                           </editable-ui-select-choices>',
                        '                       </span>',
                        '                   </td>',
                        '                   <td class="text-whitespace-nowrap">',
                        '                       <form editable-form="" name="rowformprofile" ng-show="rowformprofile.$visible" class="form-buttons form-inline" shown="objectScope.tabs[1].inserted == userProfile">',
                        '                           <button type="submit" ng-disabled="rowformprofile.$waiting" class="btn btn-sm primary"><span class="fa fa-check"></span></button>',
                        '                           <button type="button" ng-disabled="rowformprofile.$waiting" class="btn btn-sm btn-danger" ng-click="objectScope.tabs[1].updateProfile(rowformprofile)"><span class="fa fa-times"></span></button>',
                        '                       </form>',
                        '                       <div class="buttons" ng-show="!rowformprofile.$visible">',
                        '                           <button type="button" class="btn btn-sm primary" ng-click="rowformprofile.$show()"><span class="fa fa-pencil"></span></button>',
                        '                           <button type="button" class="btn btn-sm btn-danger" ng-click="objectScope.tabs[1].removeProfile($index)"><span class="fa fa-trash"></span></button>',
                        '                       </div>',
                        '                   </td>',
                        '               </tr>',
                        '             </tbody>',
                        '           </table>',
                        '           <div class="p-a dker b-t">',
                        '               <button type="button" class="btn btn-sm grey text-white" ng-click="objectScope.tabs[1].addProfile()">',
                        '                   <i class="fa fa-plus"></i> <span translate="global.commonAdmin.modal.user.addProfile">Add Profile</span>',
                        '               </button>',
                        '           </div>',
                        '       </div>',
                        '   </div>',
                        '',
                        '',
                        '',
                        '   <div class="col-xs-12">',
                        '       <div class=" container-fluid m-b-lg grey-100 p-y-sm b-all ">',
                        '           <h6 class="p-x-0 form-control-label"><span translate="global.profile.defaultEntityProfiles">Default Profiles</span></h6>',
                        '           <table class="table table-hover m-a-0 ">',
                        '             <thead>',
                        '               <tr style="font-weight: bold">',
                        '                   <th style="width:46%"><span translate="global.literals.profile">Profile</span></th>',
                        '                   <th style="width:46%"><span translate="global.profile.entityType">Type</span></th>',
                        '                   <th style="width:8%"><span ng-show="rowformdefaultprofile.$visible"></span></th>',
                        '               </tr>',
                        '             </thead>',
                        '             <tbody>',
                        '               <tr ng-repeat="userDefaultProfile in objectScope.tabs[1].userDefaultProfiles">',
                        '                   <td>',
                        '                       <span editable-select="userDefaultProfile.profile.id" e-form="rowformdefaultprofile" e-ng-options="profile.id as profile[objectScope.tabs[1].languageColumn] for profile in objectScope.tabs[1].getOptionsProfiles()" onbeforesave="objectScope.tabs[1].checkDefaultProfile($data, userDefaultProfile, this);">',
                        '                           {{userDefaultProfile.profile[objectScope.tabs[1].languageColumn]}}',
                        '                       </span>',
                        '                   </td>',
                        '                   <td>',
                        '                       <span editable-select="userDefaultProfile.entityType" e-form="rowformdefaultprofile" e-ng-options="entityType.id as entityType.description | translate for entityType in objectScope.tabs[1].getEntityTypes()"  onbeforesave="objectScope.tabs[1].checkDefaultProfileType($data, userDefaultProfile, this);">',
                        '                           {{ "global.commonAdmin.modal.defaultEntityProfile." + userDefaultProfile.entityType | translate}}',
                        '                       </span>',
                        '                   </td>',
                        '                   <td style="white-space: nowrap">',
                        '                       <form editable-form="" name="rowformdefaultprofile" ng-show="rowformdefaultprofile.$visible" class="form-buttons form-inline" shown="objectScope.tabs[1].insertedDefault == userDefaultProfile">',
                        '                           <button type="submit" ng-disabled="rowformdefaultprofile.$waiting" class="btn btn-sm primary"><span class="fa fa-check"></span></button>',
                        '                           <button type="button" ng-disabled="rowformdefaultprofile.$waiting" class="btn btn-sm btn-danger" ng-click="objectScope.tabs[1].updateDefaultProfile(rowformdefaultprofile)"><span class="fa fa-times"></span></button>',
                        '                       </form>',
                        '                       <div class="buttons" ng-show="!rowformdefaultprofile.$visible">',
                        '                           <button type="button" class="btn btn-sm primary" ng-click="rowformdefaultprofile.$show()"><span class="fa fa-pencil"></span></button>',
                        '                           <button type="button" class="btn btn-sm btn-danger" ng-click="objectScope.tabs[1].removeDefaultProfile($index)"><span class="fa fa-trash"></span></button>',
                        '                       </div>',
                        '                   </td>',
                        '               </tr>',
                        '             </tbody>',
                        '           </table>',
                        '           <div class="p-a dker b-t">',
                        '               <button type="button" class="btn btn-sm grey text-white" ng-click="objectScope.tabs[1].addDefaultProfile()">',
                        '                   <i class="fa fa-plus"></i> <span translate="global.commonAdmin.modal.user.addDefaultEntityProfile">Add Default Profile</span>',
                        '               </button>',
                        '           </div>',
                        '       </div>',
                        '   </div>',
                        '</div>',
                    ]
                },
                {
                    title: 'global.sec.literals.certificates',
                    active: false,
                    annexaFormly: {
                        fields: [
                            {
                                key: 'row1',
                                className: '',
                                templateOptions: {},
                                fieldGroup: [
                                ]
                            }
                        ]
                    },
                    certificates: [],
                    uploadCertificate: function(file, errFiles) {
                        var self = this;
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            self.certificates.push( { file: e.target.result, fileName: file.name.trim().replace(/[^A-Za-z0-9_.()-]/g,""), fileType: file.type });
                        }
                        reader.readAsArrayBuffer(file);
                    },
                    removeCertificate: function (index) {
                        this.certificates.splice(index, 1);
                    },
                    getDateNotAfterClass: function (dateNotAfter) {
                    	var textClass = "";	                	
	                	if (dateNotAfter) {
		                	var today = new Date();
		                	var difference = dateNotAfter - today.getTime();
		                	var days = Math.ceil(difference / (1000 * 3600 * 24));
		                	
		                	if (days <= 0) {
		                		textClass = "fa fa-warning text-danger"
		                	} else if (days > 0 && days <= 30) {
		                		textClass = "fa fa-warning text-warn"
		                	}
	                	}
	                	
	                    return textClass;
                    },
                    content: [
                        '<div class="grey-100 clearfix m-b-lg">',
                        '   <table class="table table-hover m-a-0">',
                        '       <tr style="font-weight: bold">',
                        '           <td style="width:65%"><span translate="global.sec.literals.certificate">Certificate</span></td>',
                        '           <td style="width:15%"><span translate="global.literals.validDateFrom">Valido desde</span></td>',
                        '           <td style="width:10%"><span translate="global.literals.dateTo">Valido hasta</span></td>',
                        '           <td style="width:10%"><span></span></td>',
                        '       </tr>',
                        '       <tr ng-repeat="certificate in objectScope.tabs[2].certificates">',
                        '           <td><span>{{certificate.fileName}}</span></td>',
                        '           <td><span>{{certificate.certificateInternalData.dateNotBefore | date:\'dd/MM/yyyy\'}}</span></td>',
                        '           <td ng-class="objectScope.tabs[2].getDateNotAfterClass({{certificate.certificateInternalData.dateNotAfter}})"><span>{{certificate.certificateInternalData.dateNotAfter | date:\'dd/MM/yyyy\'}}</span></td>',                
                        '           <td><button type="button" ng-click="objectScope.tabs[2].removeCertificate($index)" class="btn btn-sm btn-danger pull-right"><span class="fa fa-trash"></span></button>',
                        '       </tr>',
                        '   </table>',
                        '   <div class="p-a dker b-t">',
                        '       <button type="file" class="btn btn-sm grey text-white" ngf-select="objectScope.tabs[2].uploadCertificate($file, $invalidFile)" accept="application/x-pkcs12">',
                        '           <i class="fa fa-plus"></i> <span translate="global.commonAdmin.literals.newCertificate">Add certificate</span>',
                        '       </button>',
                        '   </div>',
                        '</div>'
                    ]
                }
            ],
            submitModal: function () {
            },
            alerts: []
        },
        userEdit: {
            title: 'global.commonAdmin.modal.user.titleEdit',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class=" nav-active-primary">',
                '   <uib-tabset>',
                '       <uib-tab class="nav-item" heading="{{\'global.commonAdmin.modal.user.userData\' | translate}}" active="true">',
                '           <div class="row p-t-xs">',
                '               <div class="col-lg-4">',
				'					<annexa-editable-field type="text" label="global.literals.user" model-value="modal.data.identifier" model-property="identifier" edit-function="modal.openModalUpdateIdentifier" required="true" blur="ignore" model-length="20" is-read-only="{{((modal.data.havePassword)?true:false)}}"></annexa-editable-field> ',
                '               </div>',
                '               <div class="col-lg-4 ">',
                '                   <label class="small m-b-0 " translate="global.commonAdmin.modal.user.imageprofile">Photo</label>',
                '                   <div class="m-t-n-xs">',
                '                       <p class="p-y-xs b-b pull-left m-r m-b-0 hellip calc-100-n-105 min-h-21p"><span class="_600" title="{{modal.data.photo}}">{{modal.data.photo}} </span></p>',
                '                       <button type="button" ng-click="modal.replacePhotoModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '                           <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '                       </button>',
                '                   </div>',
                '               </div>',
                '               <div class="col-lg-4">',
                '               </div>',
                '				<div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.thirds.literals.identificationdocument" model-value="modal.data.identificationNumber" model-property="identificationNumber" save="modal.updateUser(val, prop)" required="false" blur="ignore" model-length="255"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '           <div class="row p-t-xs">',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.literals.name" model-value="modal.data.name" model-property="name" save="modal.updateUser(val, prop)" required="true" blur="ignore" model-length="50"></annexa-editable-field>',
                '               </div>',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.literals.surename1" model-value="modal.data.surename1" model-property="surename1" save="modal.updateUser(val, prop)" required="true" blur="ignore"  model-length="50"></annexa-editable-field>',
                '               </div>',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.literals.surename2" model-value="modal.data.surename2" model-property="surename2" save="modal.updateUser(val, prop)" blur="ignore"  model-length="50"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '           <div class="row p-t-xs">',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.profile.phone" model-value="modal.data.phone" model-property="phone" save="modal.updateUser(val, prop)" required="false" blur="ignore" model-length="50"></annexa-editable-field>',
                '               </div>',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.literals.extension" model-value="modal.data.extension" model-property="extension" save="modal.updateUser(val, prop)" required="false" blur="ignore" model-length="10"></annexa-editable-field>',
                '               </div>',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.literals.cellPhone" model-value="modal.data.cellPhone" model-property="cellPhone" save="modal.updateUser(val, prop)" required="false" blur="ignore" model-length="50"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '           <div class="row p-t-xs">',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.profile.email" model-value="modal.data.email" model-property="email" save="modal.updateUser(val, prop)" required="false" blur="ignore" model-length="50"></annexa-editable-field>',
                '               </div>',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateUser(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '               </div>',
                '               <div class="col-lg-4">',
                '                   <annexa-editable-field type="select" label="global.literals.language" model-value="modal.data.language" model-property="language" save="modal.updateUser(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.languages"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '			<div class="row p-t-xs">',
                '               <div class="col-lg-12">',
                '                   <annexa-editable-field type="select-multiple" label="global.literals.positions" model-value="modal.data.positions" model-property="userPositions" save="modal.updateUser(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.positions" print-function="modal.printPositions"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '			<div class="row p-t-xs">',
                '               <div class="col-lg-12">',
                '                   <div class="checkbox checkbox-inline col-lg-12">',
            	'						<label class="ui-check">',
        		'							<input type="checkbox" name="userMailAlertDocumentsToSignOrValidate" ng-value="userMailAlert" ng-model="modal.data.documentsToSignOrValidate" ng-change="modal.changeUserMailAlert(\'DOCUMENT_SIGNATURE\',{{!modal.data.documentsToSignOrValidate}});">',
        		'							<i class="dark-white"></i>',
        		'						</label>',
        		'						<span translate="global.profile.alertByMailDocumentsToSignOrValidate" class="small">Documents To Sign Or Validate</span>',
        		'					</div>',
                '                   <div class="checkbox checkbox-inline col-lg-12">',
            	'						<label class="ui-check">',
        		'							<input type="checkbox" name="userMailAlertByMailDiligence" ng-value="userMailAlert" ng-model="modal.data.diligenceChanges" ng-change="modal.changeUserMailAlert(\'DILIGENCE\',{{!modal.data.diligenceChanges}});">',
        		'							<i class="dark-white"></i>',
        		'						</label>',
        		'						<span translate="global.profile.alertByMailDiligence" class="small">Diligence changes</span>',
        		'					</div>',
                '                   <div class="checkbox checkbox-inline col-lg-12">',
            	'						<label class="ui-check">',
        		'							<input type="checkbox" name="userMailAlertByMailDossier" ng-value="userMailAlert" ng-model="modal.data.dossierChanges" ng-change="modal.changeUserMailAlert(\'DOSSIER\',{{!modal.data.dossierChanges}});">',
        		'							<i class="dark-white"></i>',
        		'						</label>',
        		'						<span translate="global.profile.alertByMailDossier" class="small">Dossier changes</span>',
        		'					</div>',
                '                   <div class="checkbox checkbox-inline col-lg-12">',
            	'						<label class="ui-check">',
        		'							<input type="checkbox" name="userMailAlertByMailProposal" ng-value="userMailAlert" ng-model="modal.data.proposalChanges" ng-change="modal.changeUserMailAlert(\'PROPOSAL\',{{!modal.data.proposalChanges}});">',
        		'							<i class="dark-white"></i>',
        		'						</label>',
        		'						<span translate="global.profile.alertByMailProposal" class="small">Proposal changes</span>',
        		'					</div>',
                '                   <div class="checkbox checkbox-inline col-lg-12">',
            	'						<label class="ui-check">',
        		'							<input type="checkbox" name="userMailAlertByMailTask" ng-value="userMailAlert" ng-model="modal.data.taskChanges" ng-change="modal.changeUserMailAlert(\'TASK\',{{!modal.data.taskChanges}});">',
        		'							<i class="dark-white"></i>',
        		'						</label>',
        		'						<span translate="global.profile.alertByMailTask" class="small">Task changes</span>',
        		'					</div>',
                '                   <div class="checkbox checkbox-inline col-lg-12">',
            	'						<label class="ui-check">',
        		'							<input type="checkbox" name="userMailAlertByMailNotification" ng-value="userMailAlert" ng-model="modal.data.notificationChanges" ng-change="modal.changeUserMailAlert(\'NOTIFICATION\',{{!modal.data.notificationChanges}});">',
        		'							<i class="dark-white"></i>',
        		'						</label>',
        		'						<span translate="global.profile.alertByMailNotification" class="small">Notification changes</span>',
        		'					</div>',
        		'               </div>',
                '           </div>',
                '       </uib-tab>',
                '       <uib-tab class="nav-item" heading="{{\'global.commonAdmin.modal.user.rolesAndProfiles\' | translate}}">',
                '           <div class="row p-t-xs">',
                '               <div class="col-lg-12">',
                '                   <annexa-editable-field type="select-multiple" label="global.commonAdmin.tabs.sections" model-value="modal.data.sections" model-property="userSections" save="modal.updateUser(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.sections" print-function="modal.printSections"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '           <div class="row p-t-xs">',
                '               <div class="col-lg-12">',
                '                   <annexa-editable-field type="select-multiple" label="global.literals.rols" model-value="modal.data.roles" model-property="userRoles" save="modal.updateUser(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.roles" print-function="modal.printRoles"></annexa-editable-field>',
                '               </div>',
                '           </div>',
                '           <div class="m-x-xs clearfix ">',
                '               <div class="col-lg-12">',
                '                   <annexa-editable-table definition="modal.definitionProfiles"></annexa-editable-table>',
                '               </div>',
                '               <div class="col-lg-12">',
                '                   <annexa-editable-table definition="modal.definitionDefaultProfiles"></annexa-editable-table>',
                '               </div>',
                '           </div>',
                '       </uib-tab>',
                '       <uib-tab class="nav-item" heading="{{\'global.sec.literals.certificates\' | translate}}">',
                '           <div class="grey-100 clearfix m-b-lg">',
                '               <table class="table table-hover m-a-0">',
                '                   <tr style="font-weight: bold">',
                '                       <td style="width:65%"><span translate="global.sec.literals.certificate">Certificate</span></td>',
                '                       <td style="width:15%"><span translate="global.literals.validDateFrom">Valido desde</span></td>',
                '                       <td style="width:10%"><span translate="global.literals.dateTo">Valido hasta</span></td>',                
                '                      <td style="width:10%"><span></span></td>',
                '                  </tr>',
                '                  <tr ng-repeat="certificate in modal.data.userCertificates">',
                '                       <td><span>{{certificate.filename}}</span></td>',
                '                       <td><span>{{certificate.certificateInternalData.dateNotBefore | date:\'dd/MM/yyyy\'}}</span></td>',
                '                       <td ng-class="modal.getDateNotAfterClass({{certificate.certificateInternalData.dateNotAfter}})"><span>{{certificate.certificateInternalData.dateNotAfter | date:\'dd/MM/yyyy\'}}</span></td>',                                
                '                      <td><button type="button" ng-click="modal.removeCertificate($index)" class="btn btn-sm btn-danger pull-right"><span class="fa fa-trash"></span></button>',
                '                  </tr>',
                '               </table>',
                '               <div class="p-a dker b-t">',
                '                   <button type="file" class="btn btn-sm grey text-white" ngf-select="modal.uploadCertificate($file, $invalidFile)" accept="application/x-pkcs12">',
                '                       <i class="fa fa-plus"></i> <span translate="global.commonAdmin.literals.newCertificate">Add certificate</span>',
                '                   </button>',
                '               </div>',
                '           </div>',
                '       </uib-tab>',
                '   </uib-tabset>',
                '</div>'
            ]
        },
        docTemplateEdit: {
            title: 'global.commonAdmin.modal.tramTemplate.titleEdit',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select-tree" label="global.literals.type" model-value="modal.data.templateType" model-property="templateType" save="modal.updateDocTemplate(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.templateTypes"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.docType" model-value="modal.data.documentType" model-property="documentType" save="modal.updateDocTemplate(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.documentTypes"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.name" model-value="modal.data.name" model-property="name" save="modal.updateDocTemplate(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6 ">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.filename">file</label></div>',
                '       <div class=" m-t-n-xs">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.fileName}}">{{modal.data.fileName}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManager)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManager)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.description" model-value="modal.data.description" model-property="description" save="modal.updateDocTemplate(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select-tree" label="global.literals.classificationBox" model-value="modal.data.archiveClassification" model-property="archiveClassification" save="modal.updateDocTemplate(val, prop)" blur="ignore" lang-col="modal.languageColumn" select-options="modal.archiveClassifications"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="form-group">',
                '	<annexa-add-conditioned-sign-circuit-template data="modal.data"></annexa-add-conditioned-sign-circuit-template>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profilesCreateTemplate" model-value="modal.data.profiles" model-property="profiles" save-modal="modal.updateDocTemplate" save="modal.updateDocTemplate(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField\' | translate}}" model-value="modal.data.notHaveDossierCustomField" model-property="notHaveDossierCustomField" save="modal.updateDocTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDossierCustomField"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField\' | translate}}" model-value="modal.data.notHaveDossierTransactionCustomField" model-property="notHaveDossierTransactionCustomField" save="modal.updateDocTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDossierTransactionCustomField"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField\' | translate}}" model-value="modal.data.notHaveDocumentCustomField" model-property="notHaveDocumentCustomField" save="modal.updateDocTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDocumentCustomField"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        docTemplateNew: {
            title: 'global.commonAdmin.newTemplate',
            size: 'modal-lg',
            icon: 'fa fa-file-powerpoint-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'templateType',
                                type: 'annexaSelectTreeRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.literals.type',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'documentType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.docType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: 'model.isPublic_true'
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'name',
                                type: 'annexaInputRow',
                                className: 'col-xs-12 col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.name'
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'file',
                                type: 'annexaFileUpload',
                                className: 'col-xs-12 col-sm-6',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'',
                                    setFile: function() { }
                                }
                            }
                            /*
							 * { key: 'file', type: 'annexaFileUpload',
							 * className: 'col-sm-6', templateOptions: { label:
							 * 'global.literals.filename', required: true },
							 * data: { row: true, colClass: ' col-xs-12',
							 * labelClass: 'label-strong', accept:
							 * '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'',
							 * setFile: function() { //TODO: Mirar si fem el
							 * setFile per ha evitar l'error continuo } } }
							 */
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'description',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.description',
                                    maxlength: 245
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'archiveClassification',
                                type: 'annexaSelectTreeRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.literals.classificationBox',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row4',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                            	key: 'conditionedSignCircuitList',
                                type: 'annexaComponent',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'annexa-add-conditioned-sign-circuit-template'
                                },
                                data: {}
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row5',
                        className: 'col-xs-12',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    focus: true,
                                    label: 'global.literals.profilesCreateTemplate',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row6',
                        className: 'col-xs-12',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'notHaveDossierCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'notHaveDossierTransactionCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'notHaveDocumentCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        }, addConditionedSignCircuit: {
            title: 'global.literals.addAction',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'circuitOrder',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.order',
                                    minNumber: 1
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                 key: 'presetSignCircuit',
                                 type: 'annexaSelectRow',
                                 className: 'col-sm-12',
                                 templateOptions: {
                                     optionsAttr: 'bs-options',
                                     ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                     label: 'global.commonAdmin.modal.template.presetSignCircuit',
                                     valueProp: 'id',
                                     labelProp: 'language1',
                                     placeholder: '',
                                     options: [],
                                     required: true
                                 },
                                 data: {
                                     row: true,
                                     informed: true,
                                     colClass: ' col-xs-12',
                                     labelClass: 'label-strong'
                                 },
                                 hideExpression: 'model.isPublic_true'
                             },
                             {
                                 key: 'editPresetSignCircuit',
                                 type: 'annexaRadioCheckboxRow',
                                 className: 'col-sm-12',
                                 templateOptions: {
                                     type: 'checkbox',
                                     optionsAttr: 'bs-options',
                                     ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                     label: '',
                                     valueProp: 'value',
                                     labelProp: 'label',
                                     placeholder: '',
                                     options: [],
                                     required: false
                                 },
                                 data: {
                                     row: true,
                                     informed: true,
                                     colClass: ' col-xs-12',
                                     labelClass: 'label-strong'
                                 }
                             },
                             {
                                key: 'spelCondition',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                validation: {
                                    show: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.condition',
                                    rows: 5,
                                    required: false
                                }
                            },
                            {
                                key: 'addPreExpression',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.expressionEvaluator',
                                	executeFunction: function() {}
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        tramTemplateNew: {
            title: 'global.commonAdmin.newTemplate',
            size: 'modal-lg',
            icon: 'fa fa-file-powerpoint-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'documentType',
                                type: 'annexaSelectRow',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.docType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'file',
                                type: 'annexaFileUpload',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            },
                            {
                                key: 'isPublic',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'procedures',
                                type: 'annexaMultipleSelectRow',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.procedures',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: 'model.isPublic_true'
                            },
                            {
                            	key: 'conditionedSignCircuitList',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-add-conditioned-sign-circuit-template'
                                },
                                data: {}
                            },
                            {
                                key: 'allowThirds',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                templateOptions: {
                                    focus: true,
                                    label: 'global.literals.profilesCreateTemplate',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'transactions',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-tram-template-transactions'
                                },
                                data: {
                                	transactions:[]
                                }
                            },
                            {
                                key: 'notHaveDossierCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'notHaveDossierTransactionCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'notHaveDocumentCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        tramTemplateEdit: {
            title: 'global.commonAdmin.modal.tramTemplate.titleEdit',
            size: 'modal-lg',
            icon: 'fa fa-file-powerpoint-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTramTemplate(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.docType" model-value="modal.data.documentType" model-property="documentType" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.documentTypes"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm">',
                '   <div class="col-xs-12">       ',
                '       <label class=" small m-b-0">',
                '           <span class="ng-scope ng-binding" translate="global.literals.filename">File</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row  m-b-sm">',
                '   <div class="col-xs-12">',
                '       <p class="m-b-0 p-y-xs b-b m-r pull-left hellip calc-100-n-280"><span class="_600" title="{{modal.data.fileName}}">{{modal.data.fileName}}</span></p> ',
                '       <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '           <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '       </button>',
                '       <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManager)" class="btn btn-sm grey inline">',
                '           <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '       </button>',
                '       <button type="button" ng-click="modal.download(modal.data.idDocumentManager)" class="btn btn-sm grey inline">',
                '           <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '       </button>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.isPublic\' | translate}}" model-value="modal.data.public" model-property="public" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printIsPublic"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.allowThirds\' | translate}}" model-value="modal.data.allowThirds" model-property="allowThirds" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printAllowThirds"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select-multiple" label="global.literals.procedures" model-value="modal.data.procedures" model-property="procedures" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.procedures" print-function="modal.printPresetSignCircuits"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="form-group">',
                '	<annexa-add-conditioned-sign-circuit-template data="modal.data"></annexa-add-conditioned-sign-circuit-template>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '		<annexa-select-multiple type="select-multiple" label="global.literals.profilesCreateTemplate" model-value="modal.data.profiles" model-property="profiles" save-modal="modal.updateTramTemplate" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '		<annexa-tram-template-transactions data="modal.data"></annexa-tram-template-transactions>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField\' | translate}}" model-value="modal.data.notHaveDossierCustomField" model-property="notHaveDossierCustomField" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDossierCustomField"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField\' | translate}}" model-value="modal.data.notHaveDossierTransactionCustomField" model-property="notHaveDossierTransactionCustomField" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDossierTransactionCustomField"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField\' | translate}}" model-value="modal.data.notHaveDocumentCustomField" model-property="notHaveDocumentCustomField" save="modal.updateTramTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDocumentCustomField"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },

        /** ***************************************************************** */
        /** ***************************************************************** */
        /** ***************************************************************** */
        customFields: {
            title: 'global.literals.customFields',
            size: 'modal-lg',
            icon: 'fa fa-tasks',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'p-x-xs col-xs-10',
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'active',
                                type: 'annexaSelectRow',
                                className: 'col-xs-2',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.active',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'descriptionLanguage1',
                                type: 'annexaTextareaLanguageNoMultilanguage',
                                className: 'p-x-xs',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    id:'descriptionLanguage',
                                    modelField:'descriptionLanguage',
                                    type: 'text',
                                    label: 'global.literals.descriptionCustomField',
                                    disposition:'horitzontal',
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'backendType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-6 daughter-label-strong',
                                data:{
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.dataType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: []
                                },
                                validation: {
                                    show: true
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue) {
                                            if(modelValue){
                                                return true;
                                            }else{
                                                return false;
                                            }
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                controller: function ($scope, $filter, GlobalDataFactory) {
                                    $scope.to.options = angular.copy(GlobalDataFactory.backendOptions);
                                    if($scope.$root.$state.current.url != "/documentTypes") {
                                    	$scope.to.options = $linq($scope.to.options).where("x => x.id != 'ADDRESS'").toArray();
                                    }
                                }
                            },
                            {
                                key: 'frontendType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-6 daughter-label-strong',
                                data:{
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                validation: {
                                    show: true
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue) {
                                            if(modelValue){
                                                return true;
                                            }else{
                                                return false;
                                            }
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.fieldType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: []
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted',
                                    'templateOptions.options': function($viewValue, $modelValue, scope, $filter){
                                        var opts = [];
                                        if(scope.model.backendType){
                                            angular.forEach(scope.fields, function(value, key){
                                                if(value.key == 'listValues'){
                                                    opts = [];
                                                    opts.push({id:'INPUT', description: 'global.customFields.INPUT'});
                                                    if(scope.model.backendType == 'STRING'){
                                                        opts.push({id:'TEXTAREA', description: 'global.customFields.TEXTAREA'});
                                                        opts.push({id:'JSON', description: 'global.customFields.JSON'});
                                                        opts.push({id:'CF_GROUP', description: 'global.customFields.CF_GROUP'});
                                                    }
                                                    if(scope.model.backendType == 'INTEGER'){
                                                        opts.push({id:'SELECT', description: 'global.customFields.SELECT'});
                                                        opts.push({id:'MULTIPLESELECT', description: 'global.customFields.MULTIPLESELECT'});
                                                        opts.push({id:'CHECKBOX', description: 'global.customFields.CHECKBOX'});
                                                        opts.push({id:'RADIO', description: 'global.customFields.RADIO'});
                                                        opts.push({id:'SELECT_LINKED', description: 'global.customFields.SELECT_LINKED'});
                                                        opts.push({id:'STRUCTURAL_SELECT', description: 'global.customFields.STRUCTURAL_SELECT'});
                                                    }
                                                }
                                            });
                                        }
                                        return opts;
                                    }
                                }
                            },
                            {
                                key: 'fromQuery',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 daughter-label-strong',
                                data:{
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.fromQuery',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [{ id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var showList = true;
                                    if(scope.model.frontendType == 'SELECT' || scope.model.frontendType == 'MULTIPLESELECT' || scope.model.frontendType == 'CHECKBOX' || scope.model.frontendType == 'RADIO' || scope.model.frontendType == 'SELECT_LINKED' || scope.model.frontendType == 'STRUCTURAL_SELECT'){
                                        showList = false;
                                    }else{
                                        if(scope.model.listValues){
                                            scope.model.listValues.length = 0;
                                        }
                                    }
                                    return showList;
                                }
                            },
                            {
                                key: 'listValues',
                                type: 'annexaLanguageFieldSet',
                                className: 'col-xs-12',
                                data:{
                                    row: true,
                                    colClass: 'col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    label: 'global.literals.optionsList',
                                    modelField:'',
                                    addFunction: 'to.addFunction',
                                    removeFunction: 'to.removeFunction',
                                    placeholder: '',
                                    optionAdd:'',
                                    showAdd:true,
                                    required:false
                                },
                                validation: {
                                    show: true
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            var column = "language";
                                            if(scope.model.pressButton){
                                                if(scope.model.listValues && scope.model.listValues.length > 0){
                                                    if(scope.languages){
                                                        angular.forEach(scope.model.listValues, function (value, key) {
                                                        	angular.forEach(scope.languages, function (value2, key2) {
                                                                if(value2 && value2.column) {
                                                                    if (modelValue && !modelValue[value2.column]) {
                                                                        valid = false;
                                                                    }
                                                                }
                                                            });
                                                        });
                                                    }
                                                }else if(scope.model.frontendType == 'SELECT' || scope.model.frontendType == 'MULTIPLESELECT' || scope.model.frontendType == 'CHECKBOX' || scope.model.frontendType == 'RADIO') {
                                                    valid = false;
                                                }
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            if(Array.isArray(field.formControl)){
                                                angular.forEach(field.formControl, function(value, key){
                                                    value.$validate();
                                                })
                                            }else {
                                                field.formControl.$validate();
                                            }
                                        }
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var showList = true;
                                    if(!scope.model.fromQuery && (scope.model.frontendType == 'SELECT' || scope.model.frontendType == 'MULTIPLESELECT' || scope.model.frontendType == 'CHECKBOX' || scope.model.frontendType == 'RADIO')){
                                        showList = false;
                                    }
                                    return showList;
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                controller: function ($scope, $rootScope, Language) {
                                    $scope.languages = $rootScope.app.languagedef;
                                    $scope.to.addFunction = function(){
                                        var id = 0;
                                        if(this.optionAdd){
                                            var value = {language1:'', language2:'', language3:'', deleted:false, removedDate:null};
                                            value['language1'] = this.optionAdd;
                                            value['language2'] = this.optionAdd;
                                            value['language3'] = this.optionAdd;
                                            var lastValue = 0;
                                            if($scope.model.listValues){
                                                if($scope.model.listValues.length > 0){
                                                    lastValue = $linq($scope.model.listValues).max("x => x.value")
                                                }
                                                value.value = lastValue+1;
                                                $scope.model.listValues.push(value);
                                            }else{
                                                $scope.model.listValues = [];
                                                value.value = lastValue+1;
                                                $scope.model.listValues.push(value);
                                            }
                                            this.optionAdd = '';
                                        }
                                    };
                                    $scope.to.removeFunction = function(index){
                                        if($scope.model.listValues && $scope.model.listValues.length > index){
                                            if($scope.model.listValues[index] && $scope.model.listValues[index].id){
                                                if(!$scope.model.listValues[index].language1){
                                                    $scope.model.listValues[index].language1 = 'No Translated';
                                                }
                                                if(!$scope.model.listValues[index].language2){
                                                    $scope.model.listValues[index].language2 = 'No Translated';
                                                }
                                                if(!$scope.model.listValues[index].language3){
                                                    $scope.model.listValues[index].language3 = 'No Translated';
                                                }
                                                $scope.model.listValues[index].deleted = true;
                                                $scope.model.listValues[index].removedDate = new Date();
                                            }else {
                                                $scope.model.listValues.splice(index, 1);
                                            }
                                        }
                                    };
                                }
                            },
                            {
                                key: 'linkedCustomField',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 daughter-label-strong',
                                data:{
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.linkedCustomField',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyLinkedCustomFieldFromCF', { item: $item });
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var showList = true;
                                    if(scope.model.frontendType == 'SELECT_LINKED'){
                                        showList = false;
                                    }
                                    return showList;
                                },
                                controller: function ($scope, $rootScope, Language, RestService) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                }
                            },
                            {
                                key: 'listValuesLinked',
                                type: 'annexaComponent',
                                className: 'col-sm-12 b-t p-t-sm',
                                templateOptions: {
                                	type: 'annexa-language-field-set-linked-c-f'
                                },
                                data: {
                                	label: 'global.literals.optionsList',
                                    placeholder: '',
                                    showAdd:true,
                                    listValues: [],
                                    options:[],
                                    isDisabled: false
                                },
	                            hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(!scope.model.fromQuery && (scope.model.frontendType == 'SELECT_LINKED')){
	                                  showList = false;
	                              }
	                              return showList;
	                            }
                            },
                            {
                                key: 'listValuesTree',
                                type: 'annexaComponent',
                                className: 'col-sm-12 b-t p-t-sm',
                                templateOptions: {
                                	type: 'annexa-list_values_tree-c-f'
                                },
                                data: {
                                	label: 'global.literals.optionsList',
                                    placeholder: '',
                                    showAdd:true,
                                    listValues: [],
                                    isDisabled: false
                                },
	                            hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(!scope.model.fromQuery && (scope.model.frontendType == 'STRUCTURAL_SELECT')){
	                                  showList = false;
	                              }
	                              return showList;
	                            }
                            },
                            {
                                key: 'query',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 daughter-label-strong',
                                data:{
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.query',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var showList = true;
                                    if(scope.model.fromQuery && (scope.model.frontendType == 'SELECT' || scope.model.frontendType == 'MULTIPLESELECT' || scope.model.frontendType == 'CHECKBOX' || scope.model.frontendType == 'RADIO' || scope.model.frontendType == 'SELECT_LINKED' || scope.model.frontendType == 'STRUCTURAL_SELECT')){
                                        showList = false;
                                    }
                                    return showList;
                                },
                                controller: function ($scope, $rootScope, Language, RestService) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                }
                            },
                            {
                                key: 'customFieldsGroup',
                                type: 'annexaComponent',
                                className: 'col-sm-12 b-t p-t-sm',
                                templateOptions: {
                                	type: 'annexa-custom-fields-group'
                                },
                                data: {
                                	label: 'global.literals.customFieldsGroup',
                                    placeholder: '',
                                    showAdd:true,
                                    customFieldList: [],
                                    isDisabled: false
                                },
	                            hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(!scope.model.fromQuery && (scope.model.frontendType == 'CF_GROUP')){
	                                  showList = false;
	                              }
	                              return showList;
	                            }
                            },
                            {
                                key: 'templateTag',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                data:{
                                    row: true,
                                    colClass: '',
                                    labelClass: 'label-strong'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.commonAdmin.modal.tramTemplate.templateTag',
                                    required: true,
                                    focus: false
                                },
                                validators:{
                                    "spaceError": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = false;
                                            if(viewValue){
                                                valid = viewValue.indexOf(' ') == -1;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl && field.formControl.$validate){
                                            field.formControl.$validate();
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        documentTypesNew: {
            title: 'global.commonAdmin.modal.documentTypes.titleNew',
            size: 'modal-lg',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'column1',
                                className: 'col-sm-5',
                                fieldGroup: [
                                    {
                                        key: 'language1',
                                        type: 'annexaInputLanguage',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        validators:{
                                            "required": {
                                                "expression": function (viewValue, modelValue, scope) {
                                                    var valid = true;
                                                    if(scope.languages){
                                                        angular.forEach(scope.languages, function (value2, key2) {
                                                            if(value2 && value2.column) {
                                                                if (modelValue && !modelValue[value2.column]) {
                                                                    valid = false;
                                                                }
                                                            }
                                                        });
                                                    }
                                                    return valid;
                                                }
                                            }
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            id:'language',
                                            modelField:'',
                                            type: 'text',
                                            label: 'global.literals.name',
                                            disposition:'horitzontal21',
                                            required: true,
                                            focus: true
                                        },
                                        controller: function ($scope, $rootScope) {
                                            $scope.languages = $rootScope.app.languagedef;
                                        }
                                    },
                                    {
                                        key: 'acronym',
                                        type: 'annexaInputRow',
                                        className: 'col-xs-12',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        data:{
                                            row: true,
                                            colClass: '',
                                            labelClass: 'label-strong'
                                        },
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.acronym',
                                            required: true,
                                            focus: false,
                                            maxlength:20
                                        }
                                    },
                                    {
                                        key: 'archiveClassification',
                                        type: 'annexaSelectTreeRow',
                                        templateOptions: {
                                            label: 'global.literals.classificationBox',
                                            options: [],
                                            required: false,
                                            focus: false
                                        },
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'documentFileAttachment',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12 daughter-label-strong',
                                        data: {
                                            informed: true,
                                            row: true,
                                            clear: function ($event, model, key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if ($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'radio',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: true,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            label: 'global.literals.fileAttachment'
                                        },
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [
                                                {id:'NOT_REQUIRED', description: $filter("translate")('global.literals.NOT_REQUIRED')},
                                                {id:'REQUIRED', description: $filter("translate")('global.literals.REQUIRED')},
                                                {id:'OPTIONAL', description: $filter("translate")('global.literals.OPTIONAL')},
                                                {id:'DOCUMENT_OR_TEMPLATE', description: $filter("translate")('global.literals.DOCUMENT_OR_TEMPLATE')}
                                            ];
                                        }
                                    },
                                    {
                                        key: 'documentTemplateSelection',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12 daughter-label-strong',
                                        data: {
                                            informed: true,
                                            row: true,
                                            clear: function ($event, model, key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if ($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'radio',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: true,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            label: 'global.literals.templateSelection'
                                        },
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [
                                                {id:'NOT_REQUIRED', description: $filter("translate")('global.literals.NOT_REQUIRED')},
                                                {id:'REQUIRED', description: $filter("translate")('global.literals.REQUIRED')},
                                                {id:'OPTIONAL', description: $filter("translate")('global.literals.OPTIONAL')},
                                                {id:'DOCUMENT_OR_TEMPLATE', description: $filter("translate")('global.literals.DOCUMENT_OR_TEMPLATE')}
                                            ];
                                        }
                                    },
                                    {
                                        key: 'documentPhysicalSelection',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12 daughter-label-strong',
                                        data: {
                                            informed: true,
                                            row: true,
                                            clear: function ($event, model, key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if ($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'radio',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: true,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            label: 'global.literals.physicalSelection'
                                        },
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [
                                                {id:'NOT_REQUIRED', description: $filter("translate")('global.literals.NOT_REQUIRED')},
                                                {id:'REQUIRED', description: $filter("translate")('global.literals.REQUIRED')},
                                                {id:'OPTIONAL', description: $filter("translate")('global.literals.OPTIONAL')},
                                                {id:'DOCUMENT_OR_TEMPLATE', description: $filter("translate")('global.literals.DOCUMENT_OR_TEMPLATE')}
                                            ];
                                        }
                                    },
                                    {
                                        key: 'documentEniTipoDocumental',
                                        type: 'annexaSelectRow',
                                        className: 'col-xs-12 daughter-label-strong',
                                        data: {
                                            informed: true,
                                            row: true,
                                            clear: function ($event, model, key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if ($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.tipoDocumental',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            placeholder: '',
                                            options: [],
                                            required: true
                                        },
                                        controller: ['$scope', '$rootScope', 'RestService', 'Language', '$filter', function($scope, $rootScope, RestService, Language, $filter) {
                                            $scope.to.options = [
                                                {id:'TD01', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD01')},
                                                {id:'TD02', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD02')},
                                                {id:'TD03', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD03')},
                                                {id:'TD04', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD04')},
                                                {id:'TD05', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD05')},
                                                {id:'TD06', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD06')},
                                                {id:'TD07', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD07')},
                                                {id:'TD08', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD08')},
                                                {id:'TD09', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD09')},
                                                {id:'TD10', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD10')},
                                                {id:'TD11', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD11')},
                                                {id:'TD12', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD12')},
                                                {id:'TD13', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD13')},
                                                {id:'TD14', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD14')},
                                                {id:'TD15', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD15')},
                                                {id:'TD16', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD16')},
                                                {id:'TD17', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD17')},
                                                {id:'TD18', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD18')},
                                                {id:'TD19', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD19')},
                                                {id:'TD20', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD20')},
                                                {id:'TD51', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD51')},
                                                {id:'TD52', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD52')},
                                                {id:'TD53', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD53')},
                                				{id:'TD54', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD54')},
                                                {id:'TD55', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD55')},
                                                {id:'TD56', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD56')},
                                                {id:'TD57', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD57')},
                                                {id:'TD58', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD58')},
                                				{id:'TD59', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD59')},
                                                {id:'TD60', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD60')},
                                                {id:'TD61', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD61')},
                                                {id:'TD62', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD62')},
                                                {id:'TD63', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD63')},
                                				{id:'TD64', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD64')},
                                                {id:'TD65', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD65')},
                                                {id:'TD66', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD66')},
                                                {id:'TD67', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD67')},
                                                {id:'TD68', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD68')},
                                				{id:'TD69', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD69')},
                                                {id:'TD99', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD99')}

                                            ];

                                        }]
                                    },
                                    {
                                        key: 'style',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12 daughter-label-strong',
                                        data: {
                                            informed: true,
                                            row: true,
                                            clear: function ($event, model, key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if ($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'radioHTML',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: true,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            label: 'global.literals.style'
                                        },
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        controller: function ($scope) {
                                            $scope.to.options = [
                                                {id:'blue-A100', description: 'blue-A100'},
                                                {id:'brown-200', description: 'brown-200'},
                                                {id:'blue-grey-200', description:'blue-grey-200'},
                                                {id:'green-A200', description: 'green-A200'}
                                            ];
                                        }
                                    },
                                    {
                                        key: 'presetSignCircuits',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-xs-12 daughter-label-strong',
                                        data: {
                                            informed: true,
                                            row: true,
                                            clear: function ($event, model, key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if ($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                            label: 'global.literals.presetSignCircuits',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        }
                                    },
                                    {
                                        key: 'editPresetSignCircuit',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12',
                                        data: {
                                            row: true,
                                            informed: true,
                                            clear: function($event,model,key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'checkbox',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: false,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'value'
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [];
                                            $scope.to.options.push({id:1, value:$filter('translate')('global.literals.editCircuit')});
                                        }
                                    },
                                    {
                                        key: 'profiles',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-xs-12',
                                        templateOptions: {
                                            focus: true,
                                            label: 'global.literals.profilesCreate',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true
                                        }
                                    },
                                    {
                                        key: 'profilesTotalAcces',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-xs-12',
                                        templateOptions: {
                                            focus: true,
                                            label: 'global.literals.profilesTotalAccess',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true
                                        }
                                    },
                                    {
                                        key: 'documentTypesRelated',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-xs-12',
                                        templateOptions: {
                                            focus: true,
                                            label: 'global.literals.allowGenerateFromContentDocumentTypes',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = GlobalDataFactory.documentTypes;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true
                                        }
                                    },
                                    {
                                        key: 'includeRegisterEntry',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12',
                                        data: {
                                            row: true,
                                            informed: true,
                                            clear: function($event,model,key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'checkbox',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: false,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'value',
                                            label: ' '
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [];
                                            $scope.to.options.push({id:1, value:$filter('translate')('global.literals.includeRegisterEntry')});
                                        }
                                    },
                                    {
                                        key: 'includeNotification',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12',
                                        data: {
                                            row: true,
                                            informed: true,
                                            clear: function($event,model,key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'checkbox',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: false,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'value',
                                            label: ' '
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [];
                                            $scope.to.options.push({id:1, value:$filter('translate')('global.literals.includeNotification')});
                                        }
                                    },
                                    {
                                        key: 'canChangeType',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12',
                                        data: {
                                            row: true,
                                            informed: true,
                                            clear: function($event,model,key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'checkbox',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: false,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'value',
                                            label: ' '
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [];
                                            $scope.to.options.push({id:1, value:$filter('translate')('global.literals.canChangeType')});
                                        }
                                    },
                                    {
                                        key: 'canChangeToThisType',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12',
                                        data: {
                                            row: true,
                                            informed: true,
                                            clear: function($event,model,key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'checkbox',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: false,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'value',
                                            label: ' '
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [];
                                            $scope.to.options.push({id:1, value:$filter('translate')('global.literals.canChangeToThisType')});
                                        }
                                    },
                                    {
                                        key: 'validateDocumentContent',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-xs-12',
                                        data: {
                                            row: true,
                                            informed: true,
                                            clear: function($event,model,key, $select) {
                                                $event.stopPropagation();
                                                model[key] = undefined;
                                                if($select) {
                                                    $select.selected = undefined;
                                                    $select.search = undefined;
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            type: 'checkbox',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: false,
                                            validate: true,
                                            options:[],
                                            ngOptions:'option[to.valueProp] as option in to.options',
                                            valueProp: 'id',
                                            labelProp: 'value',
                                            label: ' '
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.options = [];
                                            $scope.to.options.push({id:1, value:$filter('translate')('global.literals.validateDocumentContent')});
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'column2',
                                className: 'col-sm-7',
                                fieldGroup: [
                                    {
                                        key: 'customFields',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-custom-field-definition'
                                        },
                                        controller: ['$scope', function($scope) {
                                            $scope.options.data.customFields = [];
                                        }],
                                        data: {
                                            customFields: [],
                                            type: 'DOCUMENT_TYPE',
											showType: false,
                                            updateOnChange: false
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        documentTypesEdit: {
            title: 'global.commonAdmin.modal.documentTypes.titleEdit',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-5 ">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="select-tree" label="global.literals.classificationBox" model-value="modal.data.archiveClassification" model-property="archiveClassification" save="modal.updateDocumentType(val, prop)" blur="ignore" lang-col="modal.languageColumn" select-options="modal.classifications"></annexa-editable-field>',
                '       <annexa-editable-field type="radio" label="global.literals.fileAttachment" model-value="modal.data.documentFileAttachmentId" model-property="documentFileAttachmentId" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore" print-function="modal.printRadio" select-options="modal.attachForms"></annexa-editable-field>',
                '       <annexa-editable-field type="radio" label="global.literals.templateSelection" model-value="modal.data.documentTemplateSelectionId" model-property="documentTemplateSelectionId" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore" print-function="modal.printRadio" select-options="modal.attachForms"></annexa-editable-field>',
                '       <annexa-editable-field type="radio" label="global.literals.physicalSelection" model-value="modal.data.documentPhysicalSelectionId" model-property="documentPhysicalSelectionId" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore" print-function="modal.printRadio" select-options="modal.attachForms"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.literals.tipoDocumental" model-value="modal.data.documentEniTipoDocumental" model-property="documentEniTipoDocumental" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore" lang-col="modal.tipoDocumentValue" select-options="modal.documentEniTipoDocumental" print-function="modal.printTipoDocumental"></annexa-editable-field>',
                '       <annexa-editable-field type="radio-color" label="global.literals.style" model-value="modal.data.styleId" model-property="styleId" save="modal.updateDocumentType(val, prop)" required="true" blur="ignore" print-function="modal.printColorRadio" select-options="modal.colorForms"></annexa-editable-field>',
                '       <annexa-editable-field type="select-multiple" label="global.literals.presetSignCircuits" model-value="modal.data.presetSignCircuits" model-property="presetSignCircuits" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.presetSignCircuits" print-function="modal.printPresetSignCircuits"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.editCircuit\' | translate}}" model-value="modal.data.editPresetSignCircuit" model-property="editPresetSignCircuit" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" print-function="modal.printEditPresetSignCircuit"></annexa-editable-field>',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profilesCreate" model-value="modal.data.profiles" model-property="profiles" save-modal="modal.updateDocumentType" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profilesTotalAccess" model-value="modal.data.profilesTotalAcces" model-property="profilesTotalAcces" save-modal="modal.updateDocumentType" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" select-options="modal.profilesTotalAcces" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '       <annexa-editable-field type="select-multiple" label="global.literals.allowGenerateFromContentDocumentTypes" model-value="modal.data.documentTypesRelated" model-property="allowGenerateFromContentDocumentTypes" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" select-options="modal.documentTypes" lang-col="modal.languageColumn" print-function="modal.printDocumentTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.includeRegisterEntry\' | translate}}" model-value="modal.data.includeRegisterEntry" model-property="includeRegisterEntry" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" print-function="modal.printIncludeRegisterEntry"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.includeNotification\' | translate}}" model-value="modal.data.includeNotification" model-property="includeNotification" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" print-function="modal.printIncludeNotification"></annexa-editable-field>',
				'       <annexa-editable-field type="checkbox" label="{{\'global.literals.canChangeType\' | translate}}" model-value="modal.data.canChangeType" model-property="canChangeType" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" print-function="modal.printCanChangeType"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.canChangeToThisType\' | translate}}" model-value="modal.data.canChangeToThisType" model-property="canChangeToThisType" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" print-function="modal.printCanChangeToThisType"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.validateDocumentContent\' | translate}}" model-value="modal.data.validateContent" model-property="validateContent" save="modal.updateDocumentType(val, prop)" required="false" blur="ignore" print-function="modal.printValidateContent"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-sm-7">',
                '       <annexa-custom-field-definition custom-fields="modal.customFieldsType" type="DOCUMENT_TYPE" show-type="false" update-on-change="true" parent-property="documentType" parent-id="modal.data.id"/>',
                '   </div>',
                '</div>'
            ]
        },
        presetSignCircuitNew: {
            title: 'global.commonAdmin.modal.presetSign.titleNew',
            size: 'modal-lg',
            data: undefined,
            content: [
                '       <form id="{{modal.id}} " name="{{modal.id}}" ng-submit="modal.form.submit()">',
                '           <formly-form model="modal.form.model" fields="modal.form.fields" options="modal.form.options" form="modal.form.form">',
                '               <button type="submit" class="btn btn-primary submit-button" id="{{\'smPresetForm\' + modal.id}}" style="display: none;">Submit</button>',
                '           </formly-form>',
                '       </form>',
                '       <div class="p-x-md">',
                '           <annexa-preset-sign-actions preset-sign-action-definition="modal.presetSignActionDefinition"/>',
                '       </div>',
                '   </div>',
                '   <div class=modal-footer>',
                '       <button type="button" class="btn btn-sm primary" ng-click="modal.savePreset()"><i class="fa fa-floppy-o"></i> <span translate="global.literals.save">Save</span></button>',
                '       <button type="button" class="btn btn-sm grey-500  text-white" data-dismiss="modal" ng-click="modal.close();"><span translate="global.literals.cancel">Cancel</span></button>',
            ]
        },
        presetSignCircuitEdit: {
            title: 'global.commonAdmin.modal.presetSign.titleEdit',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updatePresetSignCircuit(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="{{\'global.documents.modal.sendToSign.alertProfilesendCircuit\' | translate}}" model-value="modal.data.sendAlertProfile" model-property="sendAlertProfile" save="modal.updatePresetSignCircuit(val, prop)" required="true" blur="ignore" lang-col="modal.sendAlertProfileColumn" select-options="modal.yesNoBoolean"  print-function="modal.printYesNo"></annexa-editable-field>',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.profilesAux" model-property="profiles" save-modal="modal.updatePresetSignCircuit" save="modal.updatePresetSignCircuit(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.profiles"  print-function="modal.printProfiles" ng-if="modal.data.sendAlertProfile"></annexa-select-multiple>',
                '       <div class="col-xs-12">',
                '           <annexa-preset-sign-actions preset-sign-action-definition="modal.presetSignActionDefinition"/>',
                '       </div>',
                '   </div>',
                '</div>'
            ]
        },
        classificationNew: {
            title: 'global.commonAdmin.newClassification',
            size: 'modal-xxl',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: 'col-xs-6',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'alerts',
                                type: 'annexaErrorsSubmit',
                                className: 'col-xs-12',
                                templateOptions: {
                                    alerts: [],
                                    closeAlert:function(index){
                                        this.alerts.splice(index,1);
                                    },
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'referenceCode',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12 col-md-6',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.referenceCode',
                                    required: false,
                                    focus: false,
                                    maxlength:20
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12 col-md-6',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.herarchyCode',
                                    required: true,
                                    focus: false, 
                                    maxlength:20
                                }
                            },
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.description',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'parent',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                },
                                defaultValue: undefined
                            },
                            {
                                key: 'inspectionIntervention',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.inspectionIntervention',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'BooksFactory', 'apiAdmin', function($scope, BooksFactory, apiAdmin) {
                                    $scope.options.templateOptions.options = apiAdmin.yesNoBooleanNull;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'inspectionInterventionType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.inspectionInterventionType',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                },
                                controller:['$scope', 'BooksFactory', 'apiAdmin', function($scope, BooksFactory, apiAdmin) {
                                    $scope.options.templateOptions.options = apiAdmin.inspectionInterventionTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    if(scope.model.internalControl && scope.model.inspectionIntervention) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {
                            	key: 'closeExpireType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.closeExpireType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.expirationTypeList;
                                }
                            },
                            {
                                key: 'closeExpireQuantity',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'number',
                                    label: 'global.sec.literals.closeExpireQuantity',
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'automaticClose',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.archive.automaticClose',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'BooksFactory', 'apiAdmin', function($scope, BooksFactory, apiAdmin) {
                                    $scope.options.templateOptions.options = apiAdmin.yesNoBooleanNull;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'transferExpireType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.transferExpireType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.expirationTypeList;
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {
                                key: 'transferExpireQuantity',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'number',
                                    label: 'global.sec.literals.transferExpireQuantity',
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {
                                key: 'automaticTransfer',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.archive.automaticTransfer',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'BooksFactory', 'apiAdmin', function($scope, BooksFactory, apiAdmin) {
                                    $scope.options.templateOptions.options = apiAdmin.yesNoBooleanNull;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {
                            	key: 'archiveExpireType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.archiveExpireType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.expirationTypeList;
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {
                                key: 'archiveExpireQuantity',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'number',
                                    label: 'global.sec.literals.archiveExpireQuantity',
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {
                                key: 'automaticArchive',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.archive.automaticArchive',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'BooksFactory', 'apiAdmin', function($scope, BooksFactory, apiAdmin) {
                                    $scope.options.templateOptions.options = apiAdmin.yesNoBooleanNull;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            { 
                                key: 'secNotification',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.sec.literals.configureSecNotificacions',
                                	executeFunction: function() {}
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_notification_configuration && $rootScope.app.configuration.sec_notification_configuration.type == 'PARTIAL'){
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        ],
                        wrapper: ''
                    },
					{
                        key: 'row2',
                        className: 'col-xs-6',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'emgdeAC',
                                type: 'annexaComponent',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'annexa-document-emgde-config'
                                },
								data: {
                                   object: {}
                                }
                            },
							{
                                key: 'docToProvide',
                                type: 'annexaComponent',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'annexa-box-admin-documentation-to-provide'
                                },
								data: {
                                   	object: {},
									isNew:true,
									type:"QDC",
									documentationToProvide:[]
                                }
                            }
 			            ],
                        wrapper: ''
                    }
          
                ]
            },
            submitModal: function () {
            }
        },
        classificationEdit: {
            title: 'global.commonAdmin.literals.editClassification',
            size: 'modal-xxl',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.referenceCode" model-value="modal.data.referenceCode" model-property="referenceCode" save="modal.updateClassification(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.herarchyCode" model-value="modal.data.code" model-property="code" save="modal.updateClassification(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="language" label="global.literals.description" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateClassification(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="{{\'global.sec.literals.inspectionIntervention\' | translate}}" model-value="modal.data.inspectionIntervention" model-property="inspectionIntervention" save="modal.updateClassification(val, prop)" required="false" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.inspectionInterventions"  print-function="modal.printInspectionIntervention"></annexa-editable-field>',
                '		<annexa-editable-field ng-if="modal.internalControl && modal.data.inspectionIntervention" type="select" label="global.sec.literals.inspectionInterventionType" model-value="modal.data.inspectionInterventionType" model-property="inspectionInterventionType" save="modal.updateClassification(val, prop)" required="true" blur="ignore" lang-col="modal.inspectionInterventionTypeColumn" select-options="modal.inspectionInterventionTypes" print-function="modal.printInspectionInterventionType"></annexa-editable-field>',
                '		<annexa-editable-field type="select" label="global.sec.literals.closeExpireType" model-value="modal.data.closeExpireType" model-property="closeExpireType" save="modal.updateClassification(val, prop)" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.expireTypes" print-function="modal.printExpireType"></annexa-editable-field>',
                '       <annexa-editable-field type="number" label="global.sec.literals.closeExpireQuantity" model-value="modal.data.closeExpireQuantity" model-property="closeExpireQuantity" save="modal.updateClassification(val, prop)" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.archive.automaticClose" model-value="modal.data.automaticClose" model-property="automaticClose" save="modal.updateClassification(val, prop)" required="false" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.inspectionInterventions"  print-function="modal.printInspectionIntervention"></annexa-editable-field>',
                '		<annexa-editable-field ng-if="modal.archiveGee" type="select" label="global.sec.literals.transferExpireType" model-value="modal.data.transferExpireType" model-property="transferExpireType" save="modal.updateClassification(val, prop)" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.expireTypes" print-function="modal.printExpireType"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.archiveGee" type="number" label="global.sec.literals.transferExpireQuantity" model-value="modal.data.transferExpireQuantity" model-property="transferExpireQuantity" save="modal.updateClassification(val, prop)" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.archiveGee" type="select" label="{{\'global.archive.automaticTransfer\' | translate}}" model-value="modal.data.automaticTransfer" model-property="automaticTransfer" save="modal.updateClassification(val, prop)" required="false" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.inspectionInterventions"  print-function="modal.printInspectionIntervention"></annexa-editable-field>',
                '		<annexa-editable-field ng-if="modal.archiveGee || modal.archiveTool" type="select" label="global.sec.literals.archiveExpireType" model-value="modal.data.archiveExpireType" model-property="archiveExpireType" save="modal.updateClassification(val, prop)" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.expireTypes" print-function="modal.printExpireType"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.archiveGee || modal.archiveTool" type="number" label="global.sec.literals.archiveExpireQuantity" model-value="modal.data.archiveExpireQuantity" model-property="archiveExpireQuantity" save="modal.updateClassification(val, prop)" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.archiveGee || modal.archiveTool" type="select" label="global.archive.automaticArchive" model-value="modal.data.automaticArchive" model-property="automaticArchive" save="modal.updateClassification(val, prop)" required="false" blur="ignore" lang-col="modal.inspectionInterventionColumn" select-options="modal.inspectionInterventions"  print-function="modal.printInspectionIntervention"></annexa-editable-field>',
				'		<div class="row  m-b-sm" ng-if="modal.showSecNotification">',
                '   		<div class="col-xs-12">',
	            ' 		      	<button type="button" class="btn primary text-white" ng-click="modal.configureSecNotificacions()">',
                '       		    <span translate="global.sec.literals.configureSecNotificacions" class="ng-scope">Configure secreatry notificacions</span>',
                '       		</button>',
                '   		</div>',
                '		</div>',
				'   </div>',
                '   <div class="col-lg-6">',
                '		<annexa-document-emgde-config object="modal.dataAux" update="modal.updateEMGDEClassification" update-without-dialog="true"></annexa-document-emgde-config>',
				'		<annexa-box-admin-documentation-to-provide object="modal.dataAux" is-new="modal.dtp.isNew" type="QDC" documentation-to-provide="modal.dtp.documentationToProvide"></annexa-box-admin-documentation-to-provide>',
                '   </div>',
                '</div>'
            ]
        },
        registerClassificationNew: {
            title: 'global.literals.registerclassificationnew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'entryType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: true
                                },
                                controller:['$scope', '$filter', 'Language', function($scope, $filter, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = [
                                        { id:'INPUT', language1: $filter('translate')('global.literals.input'), language2: $filter('translate')('global.literals.input'), language3: $filter('translate')('global.literals.input') },
                                        { id:'OUTPUT', language1: $filter('translate')('global.literals.output'), language2: $filter('translate')('global.literals.output'), language3: $filter('translate')('global.literals.output') }
                                    ];
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'classificationCode',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    informed: true
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.code',
                                    required: false,
                                    focus: false, 
                                    maxlength:20
                                }
                            },
                            {
                                key: 'procedures',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.procedures',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'Language', 'AdminFactory', function($scope, Language, AdminFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = AdminFactory.procedures;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts:[],
            submitModal: function () {
            }
        },
        registerClassificacionEdit: {
            title: 'global.literals.registerclassificationedit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.type" model-value="modal.data.entryType" model-property="entryType" save="modal.updateClassification(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.entryTypes"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateClassification(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.classificationCode" model-property="classificationCode" save="modal.updateClassification(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select-multiple" label="global.literals.procedures" model-value="modal.data.procedures" model-property="procedures" save="modal.updateClassification(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.procedures" print-function="modal.printProcedures"></annexa-editable-field>',
                '   </div>',
                '</div>',
            ]
        },
        taskTemplateNew: {
            title: 'global.commonAdmin.newTemplate',
                size: '',
                icon: 'fa fa-check-square-o',
                annexaFormly: {
            },
            submitModal: function () {
            }
        },
        taskTemplateEdit: {
            title: 'global.commonAdmin.modal.tramTemplate.titleEdit',
                size: '',
                icon: 'fa fa-check-square-o',
                data: undefined,
                content: [
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12"><span class=" small" translate="global.literals.description">Description</span> *</div>',
                '   <div class="col-xs-12">',
                '       <a class="editable-click label-strong inline wi-100" editable-languageinputs="modal.data.descriptionLanguage1" e-model-language="modal.data" e-model-field="descriptionLanguage"  blur="ignore" e-label="" onbeforesave="modal.updateTaskTemplate($data, \'descriptionLanguage\')" e-form="descriptionForm">{{ modal.printTaskTemplate() }}</a>',
                '       <a ng-click="descriptionForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" href="" class="abs-r-1" ng-show="!descriptionForm.$visible" ><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i></a>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="number" label="global.literals.days" model-value="modal.data.days" model-property="days" save="modal.updateTaskTemplate(val, prop)" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" is-read-only="false" label="global.literals.taskType" model-value="modal.data.type" model-property="type" save="modal.updateTaskStatuses(val)" blur="ignore" print-function="modal.printTaskType" lang-col="modal.languageColumn" select-options="modal.taskTypes"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.literals.taskStatus" model-value="modal.data.status" model-property="status" save="modal.updateTaskTemplate(val, prop)" blur="ignore" print-function="modal.printTaskStatus" lang-col="modal.languageColumn" select-options="modal.taskStatuses"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-edit-custom-field custom-fields="modal.data.customFields" language-column="modal.languageColumn" is-update="true" update-function="modal.updateCustomField" profile="modal.data.type.profiles"></annexa-edit-custom-field>',
                '   </div>',
                '</div>'
            ]
        },
        newSecTemplate: {
            title: 'global.commonAdmin.newTemplate',
            size: '',
            icon: 'fa fa-file-powerpoint-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'documentType',
                                type: 'annexaSelectRow',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.docType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller:['$scope', 'Language', 'SecFactory', '$filter', function($scope, Language, SecFactory, $filter) {
									$scope.to.options = angular.copy(SecFactory.secDocumentTypes);
									$scope.to.options.push({id:undefined, language1: $filter('translate')('global.literals.others'), language2:$filter('translate')('global.literals.others'), language3:$filter('translate')('global.literals.others')});
                                    $scope.to.labelProp = Language.getActiveColumn();
                                }],
                                data: {
                                    row: true, 
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'file',
                                type: 'annexaFileUpload',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true,
                                    onSelected: function() {}
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            },
                            {
                                key: 'councillorSign',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'conditionedSignCircuitList',
                                type: 'annexaComponent',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'annexa-add-conditioned-sign-circuit-template'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var hide = false;
                                	if (scope.model.councillorSign || scope.model.councillorSign_true) {
                                		hide = true;
                                	}
                                	return hide;
                                },
                                data: {}
                            },
                            {
                                key: 'type',
                                type: 'annexaSelectRow',
                                templateOptions:  {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    label: 'global.literals.type',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'SecFactory', function($scope, SecFactory) {
                                    $scope.to.options = SecFactory.secTemplateTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'organs',
                                type: 'annexaMultipleSelectRow',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.organs',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, GlobalDataFactory) {
                                    $scope.to.options = $linq(GlobalDataFactory.organs).where("x => x.active").toArray();
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    focus: true,
                                    label: 'global.literals.profilesCreateTemplate',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true
                                }
                            },
                            {
                                key: 'notHaveDossierCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'notHaveDossierTransactionCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'notHaveDocumentCustomField',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        editSecTemplate: {
            title: 'global.commonAdmin.modal.tramTemplate.titleEdit',
            size: '',
            icon: 'fa fa-file-powerpoint-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.literals.docType" model-value="modal.data.documentType" model-property="documentType" save="modal.updateSecTemplate(val, prop)" blur="ignore" lang-col="modal.languageColumn" select-options="modal.secDocumentTypes" print-function="modal.printSecDocumentType"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateSecTemplate(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm">',
                '   <div class="col-xs-12">       ',
                '       <label class=" small m-b-0">',
                '           <span class="ng-scope ng-binding" translate="global.literals.filename">File</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row  m-b-sm">',
                '   <div class="col-xs-12">',
                '       <p class="m-b-0 p-y-xs b-b m-r pull-left hellip calc-100-n-280"><span class="_600" title="{{modal.data.fileName}}">{{modal.data.fileName}}</span></p> ',
                '       <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '           <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '       </button>',
                '       <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManager)" class="btn btn-sm grey inline">',
                '           <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '       </button>',
                '       <button type="button" ng-click="modal.download(modal.data.idDocumentManager)" class="btn btn-sm grey inline">',
                '           <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '       </button>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.showCouncillor">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.councillorSign\' | translate}}" model-value="modal.data.councillorSign" model-property="councillorSign" save="modal.updateSecTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printCouncillorSign"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="form-group" ng-if="!modal.data.councillorSign || !modal.showCouncillor">',
                '	<annexa-add-conditioned-sign-circuit-template data="modal.data"></annexa-add-conditioned-sign-circuit-template>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.literals.type" model-value="modal.data.type" model-property="type" save="modal.updateSecTemplate(val, prop)" required="true" blur="ignore" lang-col="modal.secTemplateTypeName" select-options="modal.secTemplateTypes" print-function="modal.printSecTemplateType"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select-multiple" label="global.sec.literals.organs" model-value="modal.data.organs" model-property="organs" save="modal.updateSecTemplate(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.organs" print-function="modal.printOrgan"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profilesCreateTemplate" model-value="modal.data.profiles" model-property="profiles" save-modal="modal.updateSecTemplate" save="modal.updateSecTemplate(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField\' | translate}}" model-value="modal.data.notHaveDossierCustomField" model-property="notHaveDossierCustomField" save="modal.updateSecTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDossierCustomField"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField\' | translate}}" model-value="modal.data.notHaveDossierTransactionCustomField" model-property="notHaveDossierTransactionCustomField" save="modal.updateSecTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDossierTransactionCustomField"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-4">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField\' | translate}}" model-value="modal.data.notHaveDocumentCustomField" model-property="notHaveDocumentCustomField" save="modal.updateSecTemplate(val, prop)" required="false" blur="ignore" print-function="modal.printNotHaveDocumentCustomField"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        newRegisterOffice: {
            title: 'global.literals.newRegisterOffice',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'acronym',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.acronym',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    required: false,
                                    focus: true,
                                    label: 'global.literals.profiles',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryInputPattern',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.registerEntryInputPattern',
                                    maxlength: 50
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryOutputPattern',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.registerEntryOutputPattern',
                                    maxlength: 50
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryInputDigits',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.registerEntryInputDigits',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryOutputDigits',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.registerEntryOutputDigits',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'idDocumentManagerRegisterEntrySupportingDocument',
                                type: 'annexaFileUploadBase64',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.idDocumentManagerRegisterEntrySupportingDocument',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            },
                            {
                                key: 'idDocumentManagerRegisterEntryBook',
                                type: 'annexaFileUploadBase64',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.idDocumentManagerRegisterEntryBook',
                                    required: false
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	return true;
                                }
                            },
                            {
                                key: 'idDocumentManagerRegisterEntryEvidence',
                                type: 'annexaFileUploadBase64',
                                className: 'col-xs-12',
                                templateOptions: {
                                    label: 'global.literals.idDocumentManagerRegisterEntryEvidence',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                }
                            },
                            {
                                key: 'registerEntryEditDays',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.registerEntryEditDays',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryInputChannel',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    focus: true,
                                    optionsAttr: 'bs-options',
                                    label: 'global.literals.registerEntryInputChannel',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = GlobalDataFactory.registerEntryChannelsInput;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryInputInitDossierDiligenceTypeMultiple',
                                type: 'annexaTrueFalse',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.literals.registerEntryInputInitDossierDiligenceTypeMultiple',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'registerEntryPending',
                                type: 'annexaTrueFalse',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.literals.registerEntryPending',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }                           

                        ]
                    }
                ]
            },
            alerts: []
        },
        editRegisterEntryOffice: {
            title: 'global.literals.editRegisterOffice',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.profiles" model-property="profiles" save-modal="modal.updateRegisterOffice" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.registerEntryInputPattern" model-value="modal.data.registerEntryInputPattern" model-property="registerEntryInputPattern" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="text" label="global.literals.registerEntryOutputPattern" model-value="modal.data.registerEntryOutputPattern" model-property="registerEntryOutputPattern" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="number" label="global.literals.registerEntryInputDigits" model-value="modal.data.registerEntryInputDigits" model-property="registerEntryInputDigits" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="number" label="global.literals.registerEntryOutputDigits" model-value="modal.data.registerEntryOutputDigits" model-property="registerEntryOutputDigits" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.idDocumentManagerRegisterEntrySupportingDocument">file</label></div>',
                '       <div class=" m-t-n-xs m-b-sm">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.nameRegisterEntrySupportingDocument}}">{{modal.data.nameRegisterEntrySupportingDocument}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal, modal.supportingDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManagerRegisterEntrySupportingDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManagerRegisterEntrySupportingDocument)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',
                '   <div class="col-lg-12" ng-show="false">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.idDocumentManagerRegisterEntryBook">file</label></div>',
                '       <div class=" m-t-n-xs m-b-sm">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.nameRegisterEntryBook}}">{{modal.data.nameRegisterEntryBook}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal, modal.entryBook)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManagerRegisterEntryBook)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManagerRegisterEntryBook)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class=""><label class="small m-b-0 " translate="global.literals.idDocumentManagerRegisterEntryEvidence">file</label></div>',
                '       <div class=" m-t-n-xs m-b-sm">',
                '           <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-280 b-b"><span class="_600" title="{{modal.data.nameRegisterEntryEvidence}}">{{modal.data.nameRegisterEntryEvidence}} </span></p>',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal, modal.entryEvidence)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.idDocumentManagerRegisterEntryEvidence)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.idDocumentManagerRegisterEntryEvidence)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '   </div>',                     
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="numberMin" label="global.literals.registerEntryEditDays" model-value="modal.data.registerEntryEditDays" model-property="registerEntryEditDays" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore" min-length="0"></annexa-editable-field>',
                '   </div>',      
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="select" label="global.literals.registerEntryInputChannel" model-value="modal.data.registerEntryInputChannel" model-property="registerEntryInputChannel" save="modal.updateRegisterOffice(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.registerEntryInputChannel"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.registerEntryInputInitDossierDiligenceTypeMultiple\' | translate}}" model-value="modal.data.registerEntryInputInitDossierDiligenceTypeMultiple" model-property="registerEntryInputInitDossierDiligenceTypeMultiple" save="modal.updateRegisterOffice(val, prop)" required="false" blur="ignore" print-function="modal.registerEntryInputInitDossierDiligenceTypeMultiple"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-6">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.registerEntryPending\' | translate}}" model-value="modal.data.registerEntryPending" model-property="registerEntryPending" save="modal.updateRegisterOffice(val, prop)" required="false" blur="ignore" print-function="modal.registerEntryPending"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        organPointNew: {
            title: 'global.sec.literals.newPoint',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'type',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.pointType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'Language', 'SecFactory', function ($scope, Language, SecFactory) {
                                    $scope.options.templateOptions.labelProp = Language.getActiveColumn();
                                    if($scope.options.templateOptions.isGroup){
                                    	$scope.options.templateOptions.options = $linq($scope.to.agendaPointTypes).where("x => (x.type && !x.type.id && x.type == 'GROUP') || (x.type && x.type.id && x.type.id == 'GROUP')").toArray();
                                    	$scope.options.templateOptions.label = 'global.sec.literals.groupType';
                                    }else if($scope.options.templateOptions.isGroup == false){
                                    	$scope.options.templateOptions.options = $linq($scope.to.agendaPointTypes).where("x => (x.type && !x.type.id && x.type != 'PROPOSAL' && x.type != 'OPINION' && x.type != 'GROUP') || (x.type && x.type.id && x.type.id != 'PROPOSAL' && x.type.id != 'OPINION' && x.type.id != 'GROUP')").toArray();
                                    }else{
                                    	$scope.options.templateOptions.options = $linq($scope.to.agendaPointTypes).where("x => (x.type && !x.type.id && x.type != 'PROPOSAL' && x.type != 'OPINION') || (x.type && x.type.id && x.type.id != 'PROPOSAL' && x.type.id != 'OPINION')").toArray();
                                    }
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'proposals',
                                type: 'annexaComponent',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'annexa-session-points'
                                },
                                data: {
                                    type: undefined,
                                    proposals: [],
                                    pointData: "",
                                    pointObservations: "",
                                    documents: [],
                                    canEdit:true,
                                    votationType: "",
                                	requiredVoteState:"",
									fromAdmin: true,
									proposalsContainer:false,
									containerOrder:"",
									delegationType: undefined,
									weightedVote:false
                                }
                            }
                        ],
                        hideExpression: function($viewValue, $modelValue, scope) {
                            if(!scope.model.row1.hasOwnProperty('type')) {
                                return true;
                            } else if(!scope.model.row1.type) {
                                return true;
                            } else {
                                scope.fields[1].fieldGroup[0].data.type = $linq(scope.fields[0].fieldGroup[0].templateOptions.agendaPointTypes).singleOrDefault(undefined, "x => x.id == " + scope.model.row1.type);
                                return false;
                            }
                        },
                        wrapper: 'annexaRow'
                    },
                ]
            }
        },
        organPointEdit: {
            title: 'global.sec.literals.editPoint',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs p-b-sm">',
                '   <div class="col-lg-2">',
                '       <label ng-if="modal.data.type.type != \'GROUP\'" class="small m-b-xs" translate="global.sec.literals.pointType"></label>',
                '       <label ng-if="modal.data.type.type == \'GROUP\'" class="small m-b-xs" translate="global.sec.literals.groupType"></label>',
                '   </div>',
                '   <div class="col-lg-10 label-strong">',
                '		{{modal.data.type[modal.languageColumn]}}',
                '   </div>',
                '</div>',
                '<div class="row">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field ng-if="modal.data.type.type != \'GROUP\'" type="textarea" label="global.sec.literals.pointTitle" model-value="modal.data.title" model-property="title" save="modal.updatePoint(val, prop)" required="true" blur="igonre" rows="3" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.data.type.type == \'GROUP\'" type="textarea" label="global.sec.literals.groupTitle" model-value="modal.data.title" model-property="title" save="modal.updatePoint(val, prop)" required="true" blur="igonre" rows="3" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row" ng-if="modal.showVoteType">',
                '   <div class="col-lg-12">',
		        '		<annexa-editable-field type="select" label="global.sec.literals.votationType" model-value="modal.votationType" model-property="votationType" save="modal.updatePoint(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumnEnum" select-options="modal.votationTypes" print-function="modal.printEnum"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
		        '   </div>',
		        '</div>',
                '<div class="row" ng-if="modal.showVoteType">',
                '   <div class="col-lg-12">',
		        '		<annexa-editable-field type="select" label="global.sec.literals.resultType" model-value="modal.requiredVoteState" model-property="requiredVoteState" save="modal.updatePoint(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumnEnum" select-options="modal.requiredVoteStates" print-function="modal.printEnum"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
		        '   </div>',
		        '</div>',
                '<div class="row">',
                '   <div class="col-lg-12" >',
                '       <annexa-editable-field type="textarea" label="global.literals.observations" model-value="modal.data.observations" model-property="observations" save="modal.updatePoint(val, prop)" required="false" blur="igonre" rows="5" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row" ng-if="modal.data.type.type != \'GROUP\'">',
                '    <div class="col-sm-12">',
		        '        <label class="small m-b-xs _700"><span translate="global.literals.documents">Documents</span></label>',
		        '    </div>',
		        '</div>',
                '<div class="row" ng-if="modal.data.type.type != \'GROUP\'">',
                '    <div class="col-sm-12">',
		        '        <annexa-box-object-session-orders-documents documents="modal.data.documents" module="SEC" save-documents="modal.updatePoint" model="modal.data" can-edit="modal.canEdit"></annexa-box-object-session-orders-documents>',
		        '    </div>',
                '</div>',
                '<div class="row" ng-if="modal.data.type.type == \'GROUP\' && modal.isActiveCouncillor">',
                '	<div class="col-sm-4">',
				'		<annexa-editable-field type="checkbox" label="{{\'global.sec.literals.isProposalContainer\' | translate}}" model-value="modal.data.proposalsContainer" model-property="proposalsContainer" save="modal.updatePoint(val, prop)" required="false" blur="ignore" print-function="modal.printProposalsContainer"></annexa-editable-field>',
                '	</div>',
                '	<div class="col-sm-4" ng-if="modal.data.proposalsContainer">',
				'		<annexa-editable-field type="select" label="global.sec.literals.containerOrder" model-value="modal.data.containerOrder" model-property="containerOrder" save="modal.updatePoint(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumnOrder" select-options="modal.containerOrders" print-function="modal.printOrder"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
            	'	</div>',
            	'	<div class="col-sm-4" ng-if="modal.data.proposalsContainer">',
				'		<annexa-editable-field type="select" label="global.commonAdmin.literals.councillorDelegationType" model-value="modal.data.delegationType" model-property="delegationType" save="modal.updatePoint(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.delegationTypes" print-function="modal.printDelegationTypes"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
            	'	</div>',
            	'</div>'
            ]
        },
        queryParameters: {
            title: 'global.literals.queryParameters',
            size: '',
            icon: 'fa fa-database',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'p-x-xs',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'queryParameterType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data:{
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.dataType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: []
                                },
                                validation: {
                                    show: true
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue) {
                                            if(modelValue){
                                                return true;
                                            }else{
                                                return false;
                                            }
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [
                                        {id:'STRING', description: $filter("translate")('global.queryParameters.STRING')},
                                        {id:'INTEGER', description: $filter("translate")('global.queryParameters.INTEGER')},
                                        {id:'DOUBLE', description: $filter("translate")('global.queryParameters.DOUBLE')},
                                        {id:'DATE', description: $filter("translate")('global.queryParameters.DATE')},
                                        {id:'BOOLEAN', description: $filter("translate")('global.queryParameters.BOOLEAN')},
                                        {id:'BOOLEAN_NULL', description: $filter("translate")('global.queryParameters.BOOLEAN_NULL')},
                                        {id:'CURRENT_USER', description: $filter("translate")('global.queryParameters.CURRENT_USER')}
                                    ];
                                }
                            },
                            {
                                key: 'alias',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data:{
                                    row: true,
                                    colClass: '',
                                    labelClass: 'label-strong'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.commonAdmin.modal.query.alias',
                                    required: true,
                                    focus: false
                                },
                                validators:{
                                    "spaceError": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = false;
                                            if(viewValue){
                                                valid = viewValue.indexOf(' ') == -1;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl && field.formControl.$validate){
                                            field.formControl.$validate();
                                        }
                                    }
                                }
                            },
                            {
                                key: 'otherQueries',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-query-accordion'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        queryNew: {
            title: 'global.commonAdmin.modal.query.titleNew',
            size: 'modal-xxl',
            icon: 'fa fa-database',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'column1',
                                className: 'col-sm-6',
                                fieldGroup: [
                                    {
                                        key: 'language1',
                                        type: 'annexaInputLanguage',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        validators:{
                                            "required": {
                                                "expression": function (viewValue, modelValue, scope) {
                                                    var valid = true;
                                                    if(scope.languages){
                                                        angular.forEach(scope.languages, function (value2, key2) {
                                                            if(value2 && value2.column) {
                                                                if (modelValue && !modelValue[value2.column]) {
                                                                    valid = false;
                                                                }
                                                            }
                                                        });
                                                    }
                                                    return valid;
                                                }
                                            }
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            id:'language',
                                            modelField:'',
                                            type: 'text',
                                            label: 'global.literals.name',
                                            disposition:'horitzontal21',
                                            required: true,
                                            focus: true
                                        },
                                        controller: function ($scope, $rootScope) {
                                            $scope.languages = $rootScope.app.languagedef;
                                        }
                                    },
                                    {
                                        key: 'description',
                                        type: 'annexaTextAreaRow',
                                        className: 'col-sm-12',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.description',
                                            rows: 5,
                                            required: true,
                                            focus: true,
                                            maxLength: 3000
                                        }
                                    },
                                    {
                                        key: 'query',
                                        type: 'annexaTextAreaRow',
                                        className: 'col-sm-12',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.query',
                                            rows: 10,
                                            required: true,
                                            focus: true
                                        }
                                    },
                                    {
                                        key: 'connection',
                                        type: 'annexaRadioCheckboxRow',
                                        className: 'col-sm-12',
                                        templateOptions: {
                                            type: 'radio',
                                            radioClass: 'radio-inline',
                                            optionsAttr: 'bs-options',
                                            required: true,
                                            options:[],
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'value',
                                            label: 'global.querys.connectors.connection'
                                        },
                                        data: {
                                            row: true,
                                            informed: true,
                                            labelClass: 'label-strong'
                                        },
                                        controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                        	$scope.to.options = [];
                                            $scope.to.options.push({id:'local', value:$filter('translate')('global.querys.connectors.local')});
                                            $scope.to.options.push({id:'remote', value:$filter('translate')('global.querys.connectors.remote')});
                                        	if (!$scope.model.connection) {
                                        		$scope.model.connection = 'local';
                                        	}
                                        }]
                                    },
                                    {
                                        key: 'connector',
                                        type: 'annexaSelectRow',
                                        className: 'col-sm-12',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.querys.connectors.connector',
                                            valueProp: 'id',
                                            labelProp: 'name',
                                            placeholder: '',
                                            options: [],
                                            required: true,
                                            onSelected: function($item) {
                                                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                                $rootScope.$broadcast('modifyConnectorAdmin', { item: $item });
                                            }
                                        },
                                        controller:['$scope', 'AdminFactory', function($scope, AdminFactory) {
                                        	$scope.options.templateOptions.options = new SortedArray(AdminFactory.queryConnectors, 'name').sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if (scope.model.connection == 'local') {
                                            	scope.fields[4].templateOptions.disabled = true;
                                            	scope.fields[4].templateOptions.required = false;
                                            	scope.model.connector = undefined;
                                            	
                                            	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                                $rootScope.$broadcast('modifyConnectorAdmin', {});
                                                
                                            	return true;
                                            } else {
                                            	scope.fields[4].templateOptions.disabled = false;
                                            	scope.fields[4].templateOptions.required = true;
                                            }

                                            return false;
                                        }
                                    },
                                    {
                                        key: 'linkTables',
                                        type: 'annexaComponent',
                                        className: 'col-sm-12',
                                        templateOptions: {
                                            type: 'link',
                                            link: 'annexa.queryTables',
                                            label:'global.querys.tables.showTables'
                                        },
                                        controller:['$scope', '$state', function($scope, $state) {
                                        	$scope.$on('modifyConnectorAdmin', function(event, args){ 
                                        		if(args && args.item){
                                        			$scope.to.param = args.item.id;
                                        		} else {
                                        			$scope.to.param = '';
                                        		}
                                        	});
                                        }]
                                    },
                                    {
                                        key: 'profiles',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-sm-12',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.literals.profiles',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = GlobalDataFactory.allProfiles;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'column2',
                                className: 'col-sm-6',
                                fieldGroup: [
                                    {
                                        key: 'queryParameters',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-query-parameter-definition'
                                        },
                                        controller: ['$scope', function($scope) {
                                            $scope.options.data.queryParameters = [];
                                        }],
                                        data: {
                                            queryParameters: [],
                                            updateOnChange: false
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        queryEdit: {
            title: 'global.commonAdmin.modal.query.titleEdit',
            size: 'modal-xxl',
            icon: 'fa fa-database',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '	<div class="col-sm-12">',
                '		<annexa-alerts alerts="modal.cronAlerts"></annexa-alerts>',
                '	</div>',
                '   <div class="col-sm-6 ">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateQuery(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="textarea" rows="5" label="global.literals.description" model-value="modal.data.description" model-property="description" save="modal.updateQuery(val, prop)" required="true" blur="ignore" rows="5"></annexa-editable-field>',
                '       <annexa-editable-field type="textarea" rows="10" label="global.literals.query" model-value="modal.data.query" model-property="query" save="modal.updateQuery(val, prop)" required="true" blur="ignore" rows="5"></annexa-editable-field>',
                '       <div class="clearfix m-b-sm ng-scope">',
                '           <span class="small m-b-xs" translate="global.querys.connectors.connection">Connection</span>',
                '           <div>',
 				'               <div class="radio p-l inline-formly" ng-repeat="(key, option) in modal.connectionOptions">',
 				'                   <label class="ng-class: {\'check-selected\' : connection}">',
 				'                       <input type="radio" name="connection" id="{{option.id + \'_\' + $index}}" ng-value="option.id" ng-required="true" ng-model="modal.connection" ng-click="modal.updateQuery(option.id, \'connection\')">',
 				'                       {{option.value}}',
				'                   </label>',
				'               </div>',
				'           </div>',
				'       </div>',
                '       <annexa-editable-field ng-if="modal.connection == \'remote\'" type="select" label="global.querys.connectors.connector" model-value="modal.data.connector" model-property="connector" save="modal.updateQuery(val, prop)" required="true" blur="ignore" print-function="printConnector" lang-col="modal.connectorName" select-options="modal.queryConnectors"></annexa-editable-field>',
                '       <div class="clearfix m-b-sm ng-scope"><span class="block small m-b-0"></span><div class="pos-relative"> <p class="_600 m-b-xs p-r-md"><a href="" class="text-primary" ui-sref="annexa.queryTables({objectId:modal.data.connector.id})" target="_blank" translate="global.querys.tables.showTables"></a></p></div></div>',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.profiles.profile" model-property="profiles" save-modal="modal.updateQuery" save="modal.updateQuery(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '   </div>',
                '   <div class="col-sm-6">',
                '       <annexa-query-parameter-definition query-parameters="modal.queryParameters" update-on-change="true" parent-property="query" parent-id="modal.data.id" reload-function="modal.data.reloadFunction"/>',
                '   </div>',
                '</div>'
            ]
        },
        queryConnectorNew: {
            title: 'global.commonAdmin.modal.queryConnector.titleNew',
            size: 'modal-lg',
            icon: 'fa fa-database',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'name',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.name'
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'driver',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.commonAdmin.modal.queryConnector.driver'
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'connectionUrl',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.commonAdmin.modal.queryConnector.connectionUrl'
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        queryConnectorEdit: {
            title: 'global.commonAdmin.modal.queryConnector.titleEdit',
            size: 'modal-lg',
            icon: 'fa fa-database',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12 ">',
                '		<annexa-editable-field type="text" label="global.literals.name" model-value="modal.data.name" model-property="name" save="modal.updateQueryConnector(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="text" label="global.commonAdmin.modal.queryConnector.driver" model-value="modal.data.driver" model-property="driver" save="modal.updateQueryConnector(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="text" label="global.commonAdmin.modal.queryConnector.connectionUrl" model-value="modal.data.connectionUrl" model-property="connectionUrl" save="modal.updateQueryConnector(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        reportNew: {
            title: 'global.commonAdmin.modal.report.titleNew',
            size: 'modal-lg',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'column1',
                                className: 'col-sm-6',
                                fieldGroup: [
                                    {
                                        key: 'language1',
                                        type: 'annexaInputLanguage',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        validators:{
                                            "required": {
                                                "expression": function (viewValue, modelValue, scope) {
                                                    var valid = true;
                                                    if(scope.languages){
                                                        angular.forEach(scope.languages, function (value2, key2) {
                                                            if(value2 && value2.column) {
                                                                if (modelValue && !modelValue[value2.column]) {
                                                                    valid = false;
                                                                }
                                                            }
                                                        });
                                                    }
                                                    return valid;
                                                }
                                            }
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            id:'language',
                                            modelField:'',
                                            type: 'text',
                                            label: 'global.literals.name',
                                            disposition:'horitzontal21',
                                            required: true,
                                            focus: true
                                        },
                                        controller: function ($scope, $rootScope) {
                                            $scope.languages = $rootScope.app.languagedef;
                                        }
                                    },
                                    {
                                        key: 'description',
                                        type: 'annexaTextAreaRow',
                                        className: 'col-sm-12',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.description',
                                            rows: 5,
                                            required: true,
                                            focus: true,
                                            maxLength: 3000
                                        }
                                    },
                                    {
                                        key: 'file',
                                        type: 'annexaFileUploadBase64',
                                        className: 'col-sm-12',
                                        templateOptions: {
                                            label: 'global.literals.filename',
                                            required: true
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong',
                                            accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                        }
                                    },
                                    {
                                        key: 'profiles',
                                        type: 'annexaMultipleSelectRow',
                                        className: 'col-sm-12',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.literals.profiles',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = GlobalDataFactory.allProfiles;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'joinCommonParams',
                                        type: 'annexaCheckbox',
                                        className: 'col-sm-12',
                                        templateOptions: {
                                            type: 'checkbox',
                                            label: 'global.commonAdmin.modal.report.joinCommonParams',
                                            validate: true,
                                            required: false
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'column2',
                                className: 'col-sm-6',
                                fieldGroup: [
                                    {
                                        key: 'queries',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-report-query-definition'
                                        },
                                        controller: ['$scope', function($scope) {
                                            $scope.options.data.reportQueries = [];
                                        }],
                                        data: {
                                            reportQueries: [],
                                            updateOnChange: false
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        reportEdit: {
            title: 'global.commonAdmin.modal.report.titleEdit',
            size: 'modal-lg',
            icon: 'fa fa-database',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '	<div class="col-sm-12">',
                '		<annexa-alerts alerts="modal.cronAlerts"></annexa-alerts>',
                '	</div>',
                '   <div class="col-sm-6 ">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateReport(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="textarea" rows="5" label="global.literals.description" model-value="modal.data.description" model-property="description" save="modal.updateReport(val, prop)" required="true" blur="ignore" rows="5"></annexa-editable-field>',
                '       <div class="clearfix m-b-sm">',
                '           <div class=""><label class="small m-b-0 " translate="global.literals.filename">file</label></div>',
                '           <div class=" m-t-n-xs">',
                '               <p class="p-y-xs pull-left m-r m-b-0 hellip calc-100-n-160 b-b"><span class="_600" title="{{modal.data.fileName}}">{{modal.data.fileName}} </span></p>',
                '               <div class="pull-right">',
                '                   <button type="button" ng-click="modal.replaceFileReportModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '                       <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '                   </button>',
                '		            <button type="button" ng-click="modal.editOnline(modal.data)" class="btn btn-sm grey inline">',
                '           		    <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                ' 			        </button>',
                '                   <button type="button" ng-click="modal.downloadFileReport(modal.data)" class="btn btn-sm grey inline">',
                '                       <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '                   </button>',
                '               </div>',
                '           </div>',
                '       </div>',
                '       <annexa-select-multiple type="select-multiple" label="global.literals.profiles" model-value="modal.data.profiles.profile" model-property="profiles" save-modal="modal.updateReport" save="modal.updateReport(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-select-multiple>',
                '       <annexa-editable-field type="checkbox" label="global.commonAdmin.modal.report.joinCommonParams" model-value="modal.data.joinCommonParams" model-property="joinCommonParams" save="modal.updateReport(val, prop)" required="false" blur="ignore" print-function="modal.printJoinCommonParams"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-sm-6">',
                '       <annexa-report-query-definition report-queries="modal.reportQueries" update-on-change="true" parent-property="report" parent-id="modal.data.id" parent-alerts="modal.alerts"/>',
                '   </div>',
                '</div>'
            ]
        },
        bookTypeNew: {
            title: 'global.books.admin.booktype',
            size: 'modal-lg',
            icon: 'fa fa-database',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'column1',
                                className: 'col-sm-6',
                                fieldGroup: [
                                    {
                                        key: 'language1',
                                        type: 'annexaInputLanguage',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        validators:{
                                            "required": {
                                                "expression": function (viewValue, modelValue, scope) {
                                                    var valid = true;
                                                    if(scope.languages){
                                                        angular.forEach(scope.languages, function (value2, key2) {
                                                            if(value2 && value2.column) {
                                                                if (modelValue && !modelValue[value2.column]) {
                                                                    valid = false;
                                                                }
                                                            }
                                                        });
                                                    }
                                                    return valid;
                                                }
                                            }
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            id:'language',
                                            modelField:'',
                                            type: 'text',
                                            label: 'global.literals.name',
                                            disposition:'horitzontal21',
                                            required: true,
                                            focus: true
                                        },
                                        controller: function ($scope, $rootScope) {
                                            $scope.languages = $rootScope.app.languagedef;
                                        }
                                    },
                                    {
                                        key: 'bookElement',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.books.admin.bookElement',
                                            valueProp: 'id',
                                            labelProp: 'name',
                                            placeholder: '',
                                            options: [],
                                            required: true,
                                            onSelected: function($item) {
                                                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                                $rootScope.$broadcast('modifyBookElementAdmin', { item: $item });
                                            }
                                        },
                                        controller:['$scope', 'BooksFactory', function($scope, BooksFactory) {
                                            $scope.options.templateOptions.options = new SortedArray(BooksFactory.bookElements, 'name').sort();
                                            $scope.$on('modifyBookElementAdmin', function(event, args){ 
                                        		if(args && args.item){
                                        			$scope.$parent.$parent.$parent.$parent.model.column2.appAux = args.item.id;
                                        		}
                                        	});
                                            
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'attachDocumentTypes',
                                        type: 'annexaMultipleSelectRow',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: true,
                                            label: 'global.books.admin.attachDocumentType',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = new SortedArray(GlobalDataFactory.allDocumentTypes, Language.getActiveColumn()).sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.bookElement && _.contains(['SESSION','DOSSIER_DOCUMENT'], scope.model.bookElement)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'indexTemplate',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.books.admin.indexTemplate',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            placeholder: '',
                                            options: [],
                                            required: true
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                        	$scope.options.templateOptions.options = new SortedArray(GlobalDataFactory.templates, 'description').sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'file',
                                        type: 'annexaFileUploadBase64',
                                        className: '',
                                        templateOptions: {
                                            label: 'global.books.admin.indexElementTemplate',
                                            required: true
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong',
                                            accept: '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\''
                                        }
                                    },
                                    {
                                        key: 'procedure',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.books.admin.procedure',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: true
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	$scope.options.templateOptions.options = new SortedArray(GlobalDataFactory.procedures, Language.getActiveColumn()).sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'bookTypeprocessProfiles',
                                        type: 'annexaMultipleSelectRow',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.books.admin.bookTypeprocessProfiles',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = new SortedArray($linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray(), Language.getActiveColumn()).sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    },
                                    {
                                        key: 'bookTypeViewProfiles',
                                        type: 'annexaMultipleSelectRow',
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.books.admin.bookTypeViewProfiles',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = new SortedArray($linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray(), Language.getActiveColumn()).sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'column2',
                                className: 'col-sm-6',
                                fieldGroup: [
                                	{
                                        key: 'elementLabel',
                                        type: 'annexaLabelRow',
                                        className: '',
                                        templateOptions: {
                                            label: '',
                                            value: ''
                                        },
                                        data: {
                                            informed: true,
                                            row: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong text',
                                            breakLine: true
                                        },
                                        controller: function ($scope, $filter) {
                                            $scope.to.label = $filter("translate")("global.books.admin.filterElements");
                                        }
                                	}
                                	,{
                                        key: 'registerEntryOffice',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.register_office',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.registerEntryOffices);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                        	if(scope.model.appAux && scope.model.multipleOffice && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'entryType',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.registerType',
                                            valueProp: 'id',
                                            labelProp: 'name',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	var options = angular.copy(GlobalDataFactory.entryTypes);
                                        	var index = $linq(options).indexOf("x => x.name == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1, name: $filter('translate')('global.literals.empty')}; 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index].name = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'entryNumber',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.number',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'extract',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.extract',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'procedureName',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.procedure',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'dataRegFrom',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-l-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.regFrom',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'dataRegTo',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-r-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.dateTo',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'dataPresFrom',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-l-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.presFrom',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'dataPresTo',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-r-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.dateTo',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'entryDocumentType',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.regType',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.registerEntryDocumentTypesInput);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'entryClassification',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.classification',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.registerEntryClassificationsInput);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'representant',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.representant',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'code',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.code',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'name',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.title',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'template',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.template',
                                            valueProp: 'id',
                                            labelProp: 'description',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	var options = angular.copy($linq(GlobalDataFactory.templates).orderBy("x => x." + $scope.to.labelProp).toArray());
                                        	var index = $linq(options).indexOf("x => x.description == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1, description: $filter('translate')('global.literals.empty')}; 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index].description = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'section',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.section',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.sections);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER','DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'type',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.type',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy($linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray());
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'origin',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.module',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.docModules);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'createUser',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.user',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'meta',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.meta',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'modifyDdateFrom',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-l-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.modified',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'modifyDdateTo',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-r-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.dateTo',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'dossierNumber',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.numExp',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER','DOCUMENT','DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'diligenceProfile',
                                        type: 'annexaMultipleSelectRow', 
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.literals.profile',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                            $scope.to.labelProp = Language.getActiveColumn();
                                            $scope.to.options = new SortedArray(GlobalDataFactory.allProfiles,$scope.to.labelProp).sort();
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['REGISTER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'docStatus',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.status',
                                            valueProp: 'id',
                                            labelProp: 'nameLiteral',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	var states = angular.copy(GlobalDataFactory.documentStatuses);
                                        	states = $linq(states).orderBy("x => x.orderStatus").toArray();
                                        	var trobat = false;
                                        	_.forEach(states, function(state){
                                        		if(state.name == 'global.literals.all'){
                                        			state.id = -1;
                                        			state.nameLiteral = $filter('translate')('global.literals.empty');
                                        			trobat = true;
                                        		}else{
                                        			state.nameLiteral = $filter('translate')(state.nameLiteral);
                                        		}
                                        	});
                                        	if(!trobat){
                                        		states.unshift({id:-1, nameLiteral: $filter('translate')('global.literals.empty')});
                                        	}
                                        	$scope.options.templateOptions.options = states;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOCUMENT'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'state',
                                        type: 'annexaMultipleSelectRow', 
                                        className: '',
                                        validation: {
                                            show: true
                                        },
                                        expressionProperties: {
                                            'validation.show': 'formControl.$submitted'
                                        },
                                        watcher:{
                                            type: '$watchCollection',
                                            expression: 'model',
                                            listener: function(field, _new) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        },
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.literals.state',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'name',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'SecFactory', '$filter', function($scope, Language, SecFactory, $filter) {
                                        	var states = angular.copy(SecFactory.sessionStates);
                                        	_.forEach(states, function(state){
                                        		state.name = $filter('translate')(state.name);
                                        	});
                                            $scope.to.options = states;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['SESSION'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'organ',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.sec.literals.organ',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'SecFactory', 'Language', '$filter', 'GlobalDataFactory', function($scope, SecFactory, Language, $filter, GlobalDataFactory) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.organs);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['SESSION'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'sessionDateFirstConveneFrom',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-l-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.sec.literals.sessionDate',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['SESSION'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'sessionDateFirstConveneTo',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-r-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.dateTo',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['SESSION'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'sessionType',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.type',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'SecFactory', 'Language', '$filter', 'GlobalDataFactory', function($scope, SecFactory, Language, $filter, GlobalDataFactory) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.sessionTypes);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['SESSION'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'sessionNumber',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.sec.literals.sessionNumber',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['SESSION'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'family',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.literals.family',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var options = angular.copy(GlobalDataFactory.familyProcedures);
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'procedure',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.procedure',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'subject',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.subject',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'thirds',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.thirdsInt',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'thirds_identification',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.thirds_identification',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'language',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.transaction',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'custom_fields_search',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.customFields',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'datesFrom',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-l-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.delegate.startDate',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'datesTo',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-r-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.dateTo',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'creator',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.creator',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'register_entry',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.register_entry_INI',
                                            maxlength: 20
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DOSSIER_DOCUMENT','DOSSIER'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'state',
                                        type: 'annexaSelectRow', 
                                        className: '',
                                        templateOptions: {
                                            focus: true,
                                            required: false,
                                            label: 'global.literals.state',
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            valueProp: 'id',
                                            labelProp: 'name',
                                            placeholder: '',
                                            options: []
                                        },
                                        controller:['$scope', 'Language', 'SecFactory', '$filter', function($scope, Language, SecFactory, $filter) {
                                        	var states = angular.copy(SecFactory.decreeStates);
                                        	var index = $linq(states).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		states.unshift(option);
                                        	}else{
                                        		states[index].id = -1;
                                        		states[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.to.options = states;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'decreeNumber',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.sec.literals.decreeNumberAbrv',
                                            maxlength: 50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'proposalType',
                                        type: 'annexaSelectRow',
                                        className: '',
                                        templateOptions: {
                                            optionsAttr: 'bs-options',
                                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                            label: 'global.sec.literals.proposalType',
                                            valueProp: 'id',
                                            labelProp: 'language1',
                                            placeholder: '',
                                            options: [],
                                            required: false
                                        },
                                        controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                        	$scope.to.labelProp = Language.getActiveColumn();
                                        	var propTypes = angular.copy(GlobalDataFactory.proposalTypes);
                                        	propTypes = $linq(propTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
                                        	var options = propTypes;
                                        	
                                        	var index = $linq(options).indexOf("x => x."+$scope.to.labelProp+" == 'global.literals.all'");
                                        	if(index < 0){
                                        		var option = {id: -1};
                                        		option[$scope.to.labelProp] = $filter('translate')('global.literals.empty'); 
                                        		options.unshift(option);
                                        	}else{
                                        		options[index].id = -1;
                                        		options[index][$scope.to.labelProp] = $filter('translate')('global.literals.empty');
                                        	}
                                        	$scope.options.templateOptions.options = options;
                                        }],
                                        data: {
                                            row: true,
                                            informed: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'proposalNumber',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.sec.literals.proposalNumber',
                                            maxlength: 50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'decreeDateFrom',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-l-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.sec.literals.decreeDate',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'decreeDateTo',
                                        type: 'annexaDatepicker',
                                        className: 'col-xs-6 p-r-0',
                                        templateOptions: {
                                            type: 'text',
                                            label: 'global.literals.dateTo',
                                            required: false,
                                            validate: true,
                                            changeFunction: 'to.changeFunction',
                                            datepickerOptions: {
                                                format: 'dd/MM/yyyy',
                                                initDate: new Date(),
                                                showWeeks: false,
                                                startingDay: 1
                                            }
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        key: 'extract',
                                        type: 'annexaInputRow',
                                        className: '',
                                        templateOptions: {
                                            required: false,
                                            type: 'text',
                                            label: 'global.literals.extract',
                                            maxlength: 50
                                        },
                                        data: {
                                            row: true,
                                            colClass: ' col-xs-12',
                                            labelClass: 'label-strong'
                                        },
                                        hideExpression: function ($viewValue, $modelValue, scope) {
                                            if(scope.model.appAux && _.contains(['DECREE'], scope.model.appAux)) {
                                                return false;
                                            }else{
                                                return true;
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        bookTypeEdit: {
            title: 'global.books.admin.booktype',
            size: 'modal-xxl',
            icon: 'fa fa-database',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-6 ">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateBookType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.books.admin.bookElement" model-value="modal.dataAux.bookElementAux" model-property="bookElement" save="modal.updateBookType(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.bookElements"></annexa-editable-field>',
                '       <annexa-editable-field type="select-multiple" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'SESSION\'" label="global.books.admin.attachDocumentType" model-value="modal.dataAux.attachDocumentTypesAux" model-property="attachDocumentTypes" save="modal.updateBookType(val, prop)" required="true" blur="ignore" select-options="modal.attachDocumentTypes" lang-col="modal.languageColumn" print-function="modal.printLangugaes"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.books.admin.indexTemplate" model-value="modal.data.indexTemplate" model-property="indexTemplate" save="modal.updateBookType(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.indexTemplates"></annexa-editable-field>',
                '       <div class=""><label class="small m-b-0 " translate="global.books.admin.indexElementTemplate">indexElementTemplate</label></div>',
                '       <div class=" m-t-xs m-b-xs">',
                '           <button type="button" ng-click="modal.replaceDocumentModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.replace" class="ng-scope">Replace</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.editOnline(modal.data.attachTemplate)" class="btn btn-sm grey inline">',
                '               <span translate="global.literals.edit" class="ng-scope">Edit</span>',
                '           </button>',
                '           <button type="button" ng-click="modal.download(modal.data.attachTemplate)" class="btn btn-sm grey inline">',
                '               <span translate="global.documents.datatable.descargar" class="ng-scope">Download</span>',
                '           </button>',
                '       </div>',
                '       <annexa-editable-field type="select" label="global.books.admin.procedure" model-value="modal.data.procedure" model-property="procedure" save="modal.updateBookType(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.procedures"></annexa-editable-field>',
                '       <annexa-select-multiple type="select-multiple" label="global.books.admin.bookTypeprocessProfiles" model-value="modal.dataAux.bookTypeprocessProfilesAux" model-property="bookTypeprocessProfiles" save-modal="modal.updateBookType" save="modal.updateBookType(val, prop)" required="false" blur="ignore" select-options="modal.bookTypeprocessProfiles" lang-col="modal.languageColumn" print-function="modal.printLangugaes"></annexa-select-multiple>',
                '       <annexa-select-multiple type="select-multiple" label="global.books.admin.bookTypeViewProfiles" model-value="modal.dataAux.bookTypeViewProfilesAux" model-property="bookTypeViewProfiles" save-modal="modal.updateBookType" save="modal.updateBookType(val, prop)" required="false" blur="ignore" select-options="modal.bookTypeViewProfiles" lang-col="modal.languageColumn" print-function="modal.printLangugaes"></annexa-select-multiple>',
                '   </div>',
                '   <div class="col-sm-6">',
                '		<div class=" m-t-xs m-b-xs">',
                '			<span class="label-strong text small m-b-0 block" translate="global.books.admin.filterElements">Filtres elements</span>',
                '       </div>',
                '       <annexa-editable-field type="select" ng-if="modal.multipleOffice && modal.data.bookElement == \'REGISTER\'" label="global.literals.register_office" model-value="modal.dataAux.params.registerEntryOffice" model-property="registerEntryOffice" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.registerEntryOffices"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.registerType" model-value="modal.dataAux.params.entryType" model-property="entryType" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.nameColumn" select-options="modal.entryTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.number" model-value="modal.dataAux.params.entryNumber" model-property="entryNumber" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.extract" model-value="modal.dataAux.params.extract" model-property="extract" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.procedure" model-value="modal.dataAux.params.procedureName" model-property="procedureName" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'REGISTER\'" child-class="col-xs-6 p-l-0" label="global.literals.regFrom" model-value="modal.dataAux.params.dataRegFrom" model-property="dataRegFrom" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'REGISTER\'" child-class="col-xs-6" label="global.literals.dateTo" model-value="modal.dataAux.params.dataRegTo" model-property="dataRegTo" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'REGISTER\'" child-class="col-xs-6 p-l-0" label="global.literals.presFrom" model-value="modal.dataAux.params.dataPresFrom" model-property="dataPresFrom" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'REGISTER\'" child-class="col-xs-6" label="global.literals.dateTo" model-value="modal.dataAux.params.dataPresTo" model-property="dataPresTo" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.regType" model-value="modal.dataAux.params.entryDocumentType" model-property="entryDocumentType" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.registerEntryDocumentTypesInput"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.classification" model-value="modal.dataAux.params.entryClassification" model-property="entryClassification" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.registerEntryClassificationsInput"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.representant" model-value="modal.dataAux.params.representant" model-property="representant" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.code" model-value="modal.dataAux.params.code" model-property="code" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.title" model-value="modal.dataAux.params.name" model-property="name" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.template" model-value="modal.dataAux.params.template" model-property="template" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.templates"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'REGISTER\' || modal.data.bookElement == \'DOCUMENT\'" label="global.literals.section" model-value="modal.dataAux.params.section" model-property="section" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.sections"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.type" model-value="modal.dataAux.params.type" model-property="type" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.documentTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.module" model-value="modal.dataAux.params.origin" model-property="origin" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.docModules"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.user" model-value="modal.dataAux.params.createUser" model-property="createUser" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.meta" model-value="modal.dataAux.params.meta" model-property="meta" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'DOCUMENT\'" child-class="col-xs-6 p-l-0" label="global.literals.modified" model-value="modal.dataAux.params.modifyDdateFrom" model-property="modifyDdateFrom" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'DOCUMENT\'" child-class="col-xs-6" label="global.literals.dateTo" model-value="modal.dataAux.params.modifyDdateTo" model-property="modifyDdateTo" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'REGISTER\' || modal.data.bookElement == \'DOCUMENT\' || modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.numExp" model-value="modal.dataAux.params.dossierNumber" model-property="dossierNumber" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-select-multiple type="select-multiple" ng-if="modal.data.bookElement == \'REGISTER\'" label="global.literals.profile" model-value="modal.dataAux.params.diligenceProfile" model-property="diligenceProfile" save-modal="modal.updateBookTypeParam" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" select-options="modal.bookTypeProfiles" lang-col="modal.languageColumn" print-function="modal.printLangugaes"></annexa-select-multiple>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'DOCUMENT\'" label="global.literals.status" model-value="modal.dataAux.params.docStatus" model-property="docStatus" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.nameColumn" select-options="modal.documentStatuses"></annexa-editable-field>',
                '       <annexa-editable-field type="select-multiple" ng-if="modal.data.bookElement == \'SESSION\'" label="global.literals.state" model-value="modal.dataAux.params.state" model-property="state" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" select-options="modal.sessionStates" lang-col="modal.nameColumn" print-function="modal.printStates"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'SESSION\'" label="global.sec.literals.organ" model-value="modal.dataAux.params.organ" model-property="organ" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.organs"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'SESSION\'" child-class="col-xs-6 p-l-0" label="global.sec.literals.sessionDate" model-value="modal.dataAux.params.sessionDateFirstConveneFrom" model-property="sessionDateFirstConveneFrom" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'SESSION\'" child-class="col-xs-6" label="global.literals.dateTo" model-value="modal.dataAux.params.sessionDateFirstConveneTo" model-property="sessionDateFirstConveneTo" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'SESSION\'" label="global.literals.type" model-value="modal.dataAux.params.sessionType" model-property="sessionType" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.sessionTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'SESSION\'" label="global.sec.literals.sessionNumber" model-value="modal.dataAux.params.sessionNumber" model-property="sessionNumber" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.family" model-value="modal.dataAux.params.family" model-property="family" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.families"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.procedure" model-value="modal.dataAux.params.procedure" model-property="procedure" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.subject" model-value="modal.dataAux.params.subject" model-property="subject" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.thirdsInt" model-value="modal.dataAux.params.thirds" model-property="thirds" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.thirds_identification" model-value="modal.dataAux.params.thirds_identification" model-property="thirds_identification" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.transaction" model-value="modal.dataAux.params.language" model-property="language" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.customFields" model-value="modal.dataAux.params.custom_fields_search" model-property="custom_fields_search" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" child-class="col-xs-6 p-l-0" label="global.delegate.startDate" model-value="modal.dataAux.params.datesFrom" model-property="datesFrom" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" child-class="col-xs-6" label="global.literals.dateTo" model-value="modal.dataAux.params.datesTo" model-property="datesTo" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.creator" model-value="modal.dataAux.params.creator" model-property="creator" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement == \'DOSSIER_DOCUMENT\' || modal.data.bookElement == \'DOSSIER\'" label="global.literals.register_entry_INI" model-value="modal.dataAux.params.register_entry" model-property="register_entry" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement == \'DECREE\'" label="global.literals.state" model-value="modal.dataAux.params.state" model-property="state" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.nameColumn" select-options="modal.decreeStates"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement ==  \'DECREE\'" label="global.sec.literals.decreeNumberAbrv" model-value="modal.dataAux.params.decreeNumber" model-property="decreeNumber" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="50"></annexa-editable-field>',
                '       <annexa-editable-field type="select" ng-if="modal.data.bookElement ==  \'DECREE\'" label="global.sec.literals.proposalType" model-value="modal.dataAux.params.proposalType" model-property="proposalType" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.proposalTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement ==  \'DECREE\'" label="global.sec.literals.proposalNumber" model-value="modal.dataAux.params.proposalNumber" model-property="proposalNumber" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="50"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement ==  \'DECREE\'" child-class="col-xs-6 p-l-0" label="global.sec.literals.decreeDate" model-value="modal.dataAux.params.decreeDateFrom" model-property="decreeDateFrom" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" ng-if="modal.data.bookElement ==  \'DECREE\'" child-class="col-xs-6" label="global.literals.dateTo" model-value="modal.dataAux.params.decreeDateTo" model-property="decreeDateTo" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" ng-if="modal.data.bookElement ==  \'DECREE\'" label="global.literals.extract" model-value="modal.dataAux.params.extract" model-property="extract" save="modal.updateBookTypeParam(val, prop)" required="false" blur="ignore" model-length="50"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        bookTypeEditDocType: { 
            title: 'global.books.admin.booktype',
            size: '',
            icon: 'fa fa-database',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                          {
                                key: 'attachDocumentTypes',
                                type: 'annexaMultipleSelectRow',
                                className: '',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    focus: true,
                                    required: true,
                                    label: 'global.books.admin.attachDocumentType',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = GlobalDataFactory.allDocumentTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            }
        },
        delegationNew:{
        	title: 'global.delegate.titleNew',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                	{
                        key: 'delegatorUser',
                        type: 'annexaLoadUserRow',
                        className: '',
                        templateOptions: {
                            type: 'text',
                            label: 'global.delegate.delegatorUser',
                            required: true,
                            focus: false,
                            options: [],
                            loadFunction: function () {},
                            onSelect: function($item, $model, $label, $event) {
                            	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                            	$rootScope.$broadcast('selectDelegatorUser', { item: $item });
                            }
                        },
                        controller: function ($scope, $rootScope, RestService) {
                            $scope.options.templateOptions.loadFunction = function(value) {
                                if(!value && !value.val) {
                                    return [];
                                }

                                if(value.val != '*' && value.val.length < 3) {
                                    return [];
                                }

                                if(value.val == '*') {
                                    value.val = '';
                                }

                                return RestService.loadData('common', 'User', value.val)
                                    .then(function(data) {
                                        var users = [];

                                        if(data.data && data.data.content && data.data.content.length > 0) {
                                            _.forEach(JSOG.decode(data.data.content), function(val) {
                                                var name = val.name + ' ' + val.surename1;

                                                if(val.surename2) {
                                                    name += ' ' + val.surename2;
                                                }

                                                users.push({ 'id':  val.id, 'user': val, 'value': name });
                                            });
                                        }

                                        return users;
                                    }).catch(function() {
                                        return [];
                                    });

                            }
                        },
                        validators:{
                            "requiredCouncillor": {
                                "expression": function (viewValue, modelValue, scope) {
                                    var valid = false;
                                    if(modelValue && modelValue.id){
                                        valid = true;
                                    }
                                    return valid;
                                }
                            }
                        },
                        watcher:{
                            type: '$watchCollection',
                            expression: 'model',
                            listener: function(field, _new) {
                                if(field.formControl){
                                    field.formControl.$validate();
                                }
                            }
                        },
                        data: {
                            row: true,
                            colClass: ' col-sm-12',
                            labelClass: 'label-strong'
                        }
                    },
                    {
                        key: 'delegatedUser',
                        type: 'annexaLoadUserRow',
                        className: '',
                        templateOptions: {
                            type: 'text',
                            label: 'global.delegate.delegatedUser',
                            required: true,
                            focus: false,
                            options: [],
                            loadFunction: function () {}
                        },
                        controller: function ($scope, $rootScope, RestService) {
                            $scope.options.templateOptions.loadFunction = function(value) {
                                if(!value && !value.val) {
                                    return [];
                                }

                                if(value.val != '*' && value.val.length < 3) {
                                    return [];
                                }

                                if(value.val == '*') {
                                    value.val = '';
                                }

                                return RestService.loadData('common', 'User', value.val)
                                    .then(function(data) {
                                        var users = [];

                                        if(data.data && data.data.content && data.data.content.length > 0) {
                                            _.forEach(JSOG.decode(data.data.content), function(val) {
                                                var name = val.name + ' ' + val.surename1;

                                                if(val.surename2) {
                                                    name += ' ' + val.surename2;
                                                }

                                                users.push({ 'id':  val.id, 'user': val, 'value': name });
                                            });
                                        }

                                        return users;
                                    }).catch(function() {
                                        return [];
                                    });

                            }
                        },
                        validators:{
                            "requiredCouncillor": {
                                "expression": function (viewValue, modelValue, scope) {
                                    var valid = false;
                                    if(modelValue && modelValue.id){
                                        valid = true;
                                    }
                                    return valid;
                                }
                            }
                        },
                        watcher:{
                            type: '$watchCollection',
                            expression: 'model',
                            listener: function(field, _new) {
                                if(field.formControl){
                                    field.formControl.$validate();
                                }
                            }
                        },
                        data: {
                            row: true,
                            colClass: ' col-sm-12',
                            labelClass: 'label-strong'
                        }
                    },
                    {
                        key: 'positions',
                        type: 'annexaMultipleSelectRow',
                        className: '',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                            label: 'global.delegate.userPositions',
                            valueProp: 'id',
                            labelProp: 'language1',
                            placeholder: '',
                            options: [],
                            required: false
                        },
                        data: {
                            row: true,
                            informed: true,
                            colClass: ' col-sm-12',
                            labelClass: 'label-strong'
                        },
                        controller:['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                            $scope.to.labelProp = Language.getActiveColumn();
                            $rootScope.$on('selectDelegatorUser', function(event, args) {
                            	if(args && args.item && args.item.user && args.item.user.userPositions) {
                            		$scope.to.options = $linq(args.item.user.userPositions).select("x => x.position").toArray();
                            		var positionIds = $linq($scope.to.options).select("x => x.id").toArray();
                                	var result = $linq($scope.model.positions).intersect(positionIds, "(x,y) => x == y").toArray();
	                                if (result.length > 0) {
	                                	$scope.model.positions = result;
	                                } else {
	                                	$scope.model.positions = [];
	                                }
                            	}
                            });
                        }]
                    },
                    {
                        key: 'startDelegationDate',
                        type: 'annexaDatepicker',
                        className: 'col-xs-6 p-l-0',
                        templateOptions: {
                            type: 'text',
                            label: 'global.delegate.startDate',
                            required: true,
                            validate: true,
                            changeFunction: 'to.changeFunction',
                            datepickerOptions: {
                                format: 'dd/MM/yyyy',
                                initDate: new Date(),
                                showWeeks: false,
                                startingDay: 1
                            }
                        }
                    },
                    {
                        key: 'endDelegationDate',
                        type: 'annexaDatepicker',
                        className: 'col-xs-6 p-r-0',
                        templateOptions: {
                            type: 'text',
                            label: 'global.delegate.endDate',
                            required: true,
                            validate: true,
                            changeFunction: 'to.changeFunction',
                            datepickerOptions: {
                                format: 'dd/MM/yyyy',
                                initDate: new Date(),
                                showWeeks: false,
                                startingDay: 1
                            }
                        }
                    }
                ]
            },
            alerts: []
        },
        delegationEdit: {
            title: 'global.delegate.activated_delegations',
            size: 'modal-lg',
            icon: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12 ">',
                '       <annexa-editable-field type="text" label="global.delegate.delegatorUser" model-value="modal.data.delegatorUser.completeName" is-read-only="false"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.delegate.delegatedUser" model-value="modal.data.delegatedUser.completeName" is-read-only="false"></annexa-editable-field>',
                '		<annexa-editable-field type="select-multiple" label="global.delegate.userPositions" model-value="modal.userPositions" model-property="positions" save="modal.updateDelegation(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.positions" print-function="modal.printPositions"></annexa-editable-field>',
                '		<annexa-editable-field type="date" label="global.delegate.startDate" model-value="modal.data.startDelegationDate" model-property="startDelegationDate" save="modal.updateDelegation(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '		<annexa-editable-field type="date" label="global.delegate.endDate" model-value="modal.data.endDelegationDate" model-property="endDelegationDate" save="modal.updateDelegation(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        manageCertificates: {
            title: 'global.literals.manageCertificates',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class=" nav-active-primary">',
                '    <div class="grey-100 clearfix m-b-lg">',
                '        <table class="table table-hover m-a-0">',
                '            <tr style="font-weight: bold">',
                '                <td style="width:65%"><span translate="global.sec.literals.certificate">Certificate</span></td>',
                '                <td style="width:15%"><span translate="global.literals.validDateFrom">Valido desde</span></td>',
                '                <td style="width:10%"><span translate="global.literals.dateTo">Valido hasta</span></td>',
                '                <td style="width:10%"><span></span></td>',
                '            </tr>',
                '            <tr ng-repeat="certificate in modal.data.userCertificates">',
                '                <td><span>{{certificate.filename}}</span></td>',
                '                <td><span>{{certificate.certificateInternalData.dateNotBefore | date:\'dd/MM/yyyy\'}}</span></td>',
                '                <td ng-class="modal.getDateNotAfterClass({{certificate.certificateInternalData.dateNotAfter}})"><span>{{certificate.certificateInternalData.dateNotAfter | date:\'dd/MM/yyyy\'}}</span></td>',                
                '                <td><button type="button" ng-click="modal.removeCertificate(certificate.id)" class="btn btn-sm btn-danger pull-right"><span class="fa fa-trash"></span></button>',
                '            </tr>',
                '        </table>',
                '        <div class="p-a dker b-t">',
                '            <button type="file" class="btn btn-sm grey text-white" ngf-select="modal.uploadCertificate($file, $invalidFile)" accept="application/x-pkcs12">',
                '                <i class="fa fa-plus"></i> <span translate="global.commonAdmin.literals.newCertificate">Add certificate</span>',
                '            </button>',
                '        </div>',
                '    </div>',
                '</div>'
            ]
        },
        proposalTypeNew: {
            title: 'global.sec.literals.newProposalType',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12 col-xs-12',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            var cont = 1;
                                            var column = "language";
                                            if (scope.languages) {
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if (modelValue && !modelValue[column + cont]) {
                                                        valid = false;
                                                    }
                                                    cont++;
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'proposalTypeSubtype',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.proposalSubType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.proposalTypeSubtypes;
                                }
                            },
                            {
                                key: 'validateCircuit',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.validationCircuitActions',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                	if($rootScope && $rootScope.app && $rootScope.app.configuration && 
                                			$rootScope.app.configuration.validation_circuit_secretary && $rootScope.app.configuration.validation_circuit_secretary.value) {
                                    	return false;
                                	}
                                	return true;
                                },
                                controller: function ($scope, $filter) {
                                	$scope.options.templateOptions.options = [
                                		{ label: $filter('translate')('global.literals.review'), value: 'REVIEW' },
                                		{ label: $filter('translate')('global.literals.validate'), value: 'VALIDATE' },
                                		{ label: $filter('translate')('global.literals.accept'), value: 'ACCEPT' }
                                	];
                                }
                            },
                            {
                                key: 'decreePattern',
                                type: 'annexaInputRow',
                                className: 'col-sm-12 col-xs-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.sec.literals.organSessionPattern',
                                    maxlength: 50
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.decreePattern = undefined;
                                    	return true;
                                    }
                                }
                            },
                            {
                                key: 'decreeDigits',
                                type: 'annexaInputRow',
                                className: 'col-sm-12 col-xs-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.sec.literals.organSessionDigits',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.decreeDigits = undefined;
                                    	return true;
                                    }
                                }
                            },
                            {
                                key: 'secTemplate',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.templateAgreementDocument',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'SecFactory', 'Language', '$filter', '$rootScope', 'GlobalDataFactory', function($scope, SecFactory, Language, $filter, $rootScope, GlobalDataFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                	var documentType = $rootScope.app.configuration.sec_document_types['SDR'];
                                    var templates = $linq(GlobalDataFactory.secTemplates).where(function(x){
                                    	if(documentType && documentType.id && documentType.id.length > 0 && x.documentType && x.documentType.id){
                                    		if(_.contains(documentType.id, x.documentType.id+"") && 'SDR' == x.type){
                                    			return true;
                                    		}
                                    	}
                                    	return false;
                                    }).toArray();
                                    $scope.to.options = ((templates && templates.length > 0)?templates:[]);
                                    if($scope.to.options && $scope.to.options.length == 1){
                                		$scope.model.secTemplate = $scope.to.options[0];
                                	}
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.secTemplate = undefined;
                                    	return true;
                                    }
                                }
                            },
                            {
                                key: 'actionNumber',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.actionNumber',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.decreeSignProcessActionEnum;
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.actionNumber = undefined;
                                    	return true;
                                    }
                                }
                            },
                            {
                                key: 'typeNumber',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.typeNumber',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.decreeSignProcessTypeEnum;
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.typeNumber = undefined;
                                    	return true;
                                    }
                                }
                            },
                            {
                                key: 'actionDate',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.actionDate',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.decreeSignProcessActionEnum;
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.actionDate = undefined;
                                    	return true;
                                    }
                                }
                            },
                            {
                                key: 'typeDate',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.typeDate',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, apiAdmin) {
                                    $scope.to.options = apiAdmin.decreeSignProcessTypeEnum;
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.proposalTypeSubtype && scope.model.proposalTypeSubtype == 'DECREE') {
                                        return false;
                                    }else{
                                    	scope.model.typeDate = undefined;
                                    	return true;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        proposalTypeEdit: {
            title: 'global.sec.literals.editProposalType',
            size: '',
            data: undefined,
            content: [
            	'<div class="row p-t-xs">',
                '	<div class="col-xs-12">',
                '   	<annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateProposalType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',   		
                '<div class="row p-t-xs">',
                '	<div class="col-xs-12">',
                '   	<annexa-editable-field type="select" label="global.sec.literals.proposalSubType" model-value="modal.data.proposalTypeSubtype" model-property="proposalTypeSubtype" save="modal.updateProposalType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.proposalTypeSubtypes" print-function="modal.printProposalSubtype"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.showValidateCircuit">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="annexa-checkbox-list" label="global.sec.literals.validationCircuitActions" model-value="modal.validateCircuits" model-property="validateCircuit" save="modal.updateProposalType(val, prop)" required="false" blur="ignore" label-col="modal.labelCol" print-function="modal.printValidateCircuit"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '   	<annexa-editable-field type="text" label="global.sec.literals.organSessionPattern" model-value="modal.data.decreePattern" model-property="decreePattern" save="modal.updateProposalType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '   	<annexa-editable-field type="number" label="global.sec.literals.organSessionDigits" model-value="modal.data.decreeDigits" model-property="decreeDigits" save="modal.updateProposalType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.sec.literals.templateAgreementDocument" model-value="modal.data.secTemplate" model-property="secTemplate" save="modal.updateProposalType(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.secTemplates"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.sec.literals.actionNumber" model-value="modal.data.actionNumber" model-property="actionNumber" save="modal.updateProposalType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.decreeSignProcessActionEnum" print-function="modal.printDecreeSignProcessActionEnum"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.sec.literals.typeNumber" model-value="modal.data.typeNumber" model-property="typeNumber" save="modal.updateProposalType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.decreeSignProcessTypeEnum" print-function="modal.printDecreeSignProcessTypeEnum"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.sec.literals.actionDate" model-value="modal.data.actionDate" model-property="actionDate" save="modal.updateProposalType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.decreeSignProcessActionEnum" print-function="modal.printDecreeSignProcessActionEnum"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs" ng-if="modal.data.proposalTypeSubtype && (modal.data.proposalTypeSubtype == \'DECREE\' || modal.data.proposalTypeSubtype.id == \'DECREE\')">',
                '   <div class="col-xs-12">',
                '       <annexa-editable-field type="select" label="global.sec.literals.typeDate" model-value="modal.data.typeDate" model-property="typeDate" save="modal.updateProposalType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.decreeSignProcessTypeEnum" print-function="modal.printDecreeSignProcessTypeEnum"></annexa-editable-field>',
                '   </div>',
                '</div>',
            ]
        },
        signDefaultConfigUser: {
            title: 'global.commonAdmin.modal.sign.editDefaultSignUser',
            size: 'modal-lg',
            data: undefined,
            content: [
				'<fieldset class="row b-a-0 p-a-0" >',
	            '  <div class="col-sm-8">',
	            '    <legend><strong translate="global.sign.configModal.firstPage">Signatura només visible a la primera pàgina?</strong></legend>',
	            '  </div>',
	            '  <div class="col-sm-4">',
	            '    <div class="radio-inline">',
	            '      <label >',
	            '        <input type="radio" id="firstpage" name="firstpage" data-ng-value="true" ng-model="modal.confValue.firstPage">  <label for="firstpage" translate="global.literals.yes">Si</label>',
	            '      </label>',
	            '    </div>',
	            '    &nbsp;',
	            '    <div class="radio-inline">',
	            '      <label >',
	            '        <input type="radio" id="nofirstpage" name="firstpage" data-ng-value="false" ng-model="modal.confValue.firstPage"> <label for="nofirstpage" translate="global.literals.no">No</label>',
	            '      </label>',
	            '    </div>',
	            '  </div>',
	            '</fieldset>',
	            '<hr/>',
            	'<div class="row">',
	            '    <div class="col-sm-12">',
	            '      <fieldset id="doc_views_selection" class="text-center">',
	            '            <div class="col-xs-2 col-xs-offset-5">',
	            '                <div class="radio " ng-class="{\'primary\':modal.confValue.position==1,\'grey-200\':modal.confValue.position!=1}">',
	            '                  <label class="ui-check">',
	            '                    <input type="radio" name="position" value="1" ng-model="modal.confValue.position">',
	            '                    <i class="dark-white" aria-hidden="true" title="{{ \'global.sign.queued.position.1\' | translate }}"></i><span class="sr-only">{{ \'global.sign.queued.position.1\' | translate }}</span>',
	            '                  </label>',
	            '                </div>',
	            '             </div>',
	            '             <div class="col-xs-2">',
	            '                <div class="radio " ng-class="{\'primary\':modal.confValue.position==2,\'grey-200\':modal.confValue.position!=2}">',
	            '                  <label class="ui-check">',
	            '                    <input type="radio" name="position" value="2"  ng-model="modal.confValue.position">',
	            '                    <i class="dark-white" aria-hidden="true" title="{{ \'global.sign.queued.position.2\' | translate }}"></i><span class="sr-only">{{ \'global.sign.queued.position.2\' | translate }}</span>',
	            '                  </label>',
	            '                </div>',
	            '             </div>',
	            '             <div class="col-xs-2">',
	            '                <div class="radio " ng-class="{\'primary\':modal.confValue.position==3,\'grey-200\':modal.confValue.position!=3}">',
	            '                  <label class="ui-check">',
	            '                    <input type="radio" name="position" value="3" ng-model="modal.confValue.position">',
	            '                    <i class="dark-white" aria-hidden="true" title="{{ \'global.sign.queued.position.3\' | translate }}"></i><span class="sr-only">{{ \'global.sign.queued.position.3\' | translate }}</span>',
	            '                  </label>',
	            '                </div>',
	            '            </div>',
	            '            <div class="col-xs-2 col-xs-offset-5">',
	            '                <div class="radio " ng-class="{\'primary\':modal.confValue.position==4,\'grey-200\':modal.confValue.position!=4}">',
	            '                  <label class="ui-check">',
	            '                    <input type="radio" name="position" value="4"  ng-model="modal.confValue.position">',
	            '                    <i class="dark-white" aria-hidden="true" title="{{ \'global.sign.queued.position.4\' | translate }}"></i><span class="sr-only">{{ \'global.sign.queued.position.4\' | translate }}</span>',
	            '                  </label>',
	            '                </div>',
	            '             </div>',
	            '             <div class="col-xs-2">',
	            '                <div class="radio " ng-class="{\'primary\':modal.confValue.position==5,\'grey-200\':modal.confValue.position!=5}">',
	            '                  <label class="ui-check">',
	            '                    <input type="radio" name="position" value="5"  ng-model="modal.confValue.position">',
	            '                    <i class="dark-white" aria-hidden="true" title="{{ \'global.sign.queued.position.5\' | translate }}"></i><span class="sr-only">{{ \'global.sign.queued.position.5\' | translate }}</span>',
	            '                  </label>',
	            '                </div>',
	            '             </div>',
	            '             <div class="col-xs-2">',
	            '                <div class="radio " ng-class="{\'primary\':modal.confValue.position==6,\'grey-200\':modal.confValue.position!=6}">',
	            '                  <label class="ui-check">',
	            '                    <input type="radio" name="position" value="6"  ng-model="modal.confValue.position">',
	            '                    <i class="dark-white" aria-hidden="true" title="{{ \'global.sign.queued.position.6\' | translate }}"></i><span class="sr-only">{{ \'global.sign.queued.position.6\' | translate }}</span>',
	            '                  </label>',
	            '               </div>',
	            '            </div>',
	            '          </fieldset>',
	            '          <p><strong><span translate="global.sign.configModal.position">Posici� de la signatura</span></strong></p>',
	            '          <img src="./extResources/{{modal.tenant}}/images/sign/docpreview.png" alt="..." class="vistadoc"/>',
	            '      </div>',
                '</div>',
				'<hr/>',
	            '<fieldset class="p-a-0 b-a-0" >',
	            '    <legend class="_700 m-b-sm" translate="global.sign.configModal.conflict">En cas de conflicte quina estratègia vol utilitzar?</legend>',
	            '    <div class="radio ">',
	            '        <label class="m-l m-b-xs" >',
	            '            <input type="radio" id="confConflict" name="confConflict" value="SEGUENT_HORARIA" ng-model="modal.confValue.typeConflict">',
	            '            <span for="confConflict" translate="global.sign.configModal.conflict1">Següent en sentit de les agulles del rellotge.</span>',
	            '        </label>',
	            '    </div>',
	            '    <div class="radio ">',
	            '        <label class="m-l m-b-xs">',
	            '            <input type="radio" name="confConflict" id="anticlockwise" value="SEGUENT_NO_HORARIA" ng-model="modal.confValue.typeConflict">',
	            '            <span for="anticlockwise" translate="global.sign.configModal.conflict2">Següent en sentit contrari a les agulles del rellotge.</span>',
	            '        </label>',
	            '    </div>',
	            '    <div class="radio">',
	            '        <label class="m-l m-b-xs">',
	            '            <input type="radio" id="askme" name="confConflict" value="PREGUNTAR" ng-model="modal.confValue.typeConflict">',
	            '            <span for="askme"  translate="global.sign.configModal.conflict3">Preguntar.</span>',
	            '        </label>',
	            '    </div>',
	            '</fieldset>'
            ],
            alerts: []
        },
        operationTypeNew: {
            title: 'global.operationType.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength: 1024,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'type',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.type',
                                    maxlength: 1024,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                        		key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'relatedThirds',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                	$scope.options.templateOptions.options = [ { label: $filter('translate')('global.operationType.relatedThirdsCheck'), value: false }];
                                }
                            },
                            {
                                key: 'parentOperation',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.operationType.parentOperation',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'apiAdmin', function($scope, apiAdmin) {
                                    $scope.options.templateOptions.options = apiAdmin.operationTypeList;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'previousOperationTypes',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.operationType.previousOperationTypes',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.parentOperation && scope.model.parentOperation != 'NONE'){
                                    	return false;
                                    }
                                    return true;
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function ($scope, GlobalDataFactory, Language) {
                                	 $scope.to.labelProp = Language.getActiveColumn();
                                     $scope.to.options = angular.copy(GlobalDataFactory.operationTypes);
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        operationTypeEdit: {
            title: 'global.commonAdmin.modal.transactionType.titleEditOperation',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateOperationType(val, prop)" required="true" blur="ignore" model-length="1024"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.type" model-value="modal.data.type" model-property="type" save="modal.updateOperationType(val, prop)" required="true" blur="ignore" model-length="1024"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateOperationType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.operationType.relatedThirdsCheck" model-value="modal.data.relatedThirds" model-property="relatedThirds" save="modal.updateOperationType(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn" select-options="modal.yesno" print-function="modal.printYesNo"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.operationType.parentOperation" model-value="modal.data.parentOperation" model-property="parentOperation" save="modal.updateOperationType(val, prop)" required="true" blur="ignore" lang-col="modal.otherLangColumn2" select-options="modal.operationTypeList" print-function="modal.printOperationTypeList"></annexa-editable-field>',
                '   </div>',
                '  	<div class="col-lg-12">',
                '	   <annexa-editable-field type="select-multiple" label="global.operationType.previousOperationTypes" model-value="modal.data.previousOperationTypesAux" model-property="previousOperationTypes" save="modal.updateOperationType(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.previousOperationTypes" print-function="modal.printPreviousOperationTypes"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        userAccountingInstanceNew: {
            title: 'global.userAccountingInstace.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'code',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.code',
                                    valueProp: 'code',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'AdminFactory', function($scope, AdminFactory) {
                                    $scope.options.templateOptions.options = AdminFactory.accountInstances;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'user',
                                type: 'annexaLoadUserRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.user',
                                    required: true,
                                    focus: false,
                                    options: [],
                                    loadFunction: function () {}
                                },
                                controller: function ($scope, $rootScope, RestService) {
                                    $scope.options.templateOptions.loadFunction = function(value) {
                                        if(!value && !value.val) {
                                            return [];
                                        }

                                        if(value.val != '*' && value.val.length < 3) {
                                            return [];
                                        }

                                        if(value.val == '*') {
                                            value.val = '';
                                        }

                                        return RestService.loadData('common', 'User', value.val)
                                            .then(function(data) {
                                                var users = [];

                                                if(data.data && data.data.content && data.data.content.length > 0) {
                                                    _.forEach(JSOG.decode(data.data.content), function(val) {
                                                        var name = val.name + ' ' + val.surename1;

                                                        if(val.surename2) {
                                                            name += ' ' + val.surename2;
                                                        }

                                                        users.push({ 'id':  val.id, 'user': val, 'value': name });
                                                    });
                                                }

                                                return users;
                                            }).catch(function() {
                                                return [];
                                        });

                                    }
                                },
                                validators:{
                                    "requiredCouncillor": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = false;
                                            if(modelValue && modelValue.id){
                                                valid = true;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'userName',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.userAccountingInstace.userName',
                                    maxlength: 1024,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'managementCenter',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.userAccountingInstace.managementCenter',
                                    maxlength: 1024,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'operationTypesDefinitive',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.userAccountingInstace.operationTypesDefinitive',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = GlobalDataFactory.operationTypes;
                                }]
                            },
                            {
                                key: 'operationTypesPrevious',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.userAccountingInstace.operationTypesPrevious',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = GlobalDataFactory.operationTypes;
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        userAccountingInstanceEdit: {
            title: 'global.commonAdmin.modal.transactionType.titleEditAccountancy', 
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateUserAccountingInstance(val, prop)" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.accountInstances"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="loadUser" label="global.literals.user" model-value="modal.userAux" model-property="user" save="modal.updateUserAccountingInstance(val, prop)" required="true" blur="ignore" search="modal.loadFunction(value)"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.userAccountingInstace.userName" model-value="modal.data.userName" model-property="userName" save="modal.updateUserAccountingInstance(val, prop)" required="true" blur="ignore" model-length="1024"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.userAccountingInstace.managementCenter" model-value="modal.data.managementCenter" model-property="managementCenter" save="modal.updateUserAccountingInstance(val, prop)" blur="ignore" model-length="1024"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select-multiple" label="global.userAccountingInstace.operationTypesDefinitive" model-value="modal.data.opTypesDefinitive" model-property="operationTypesDefinitive" save="modal.updateUserAccountingInstance(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.operationTypes" print-function="modal.printOperationTypes"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="select-multiple" label="global.userAccountingInstace.operationTypesPrevious" model-value="modal.data.opTypesPrevious" model-property="operationTypesPrevious" save="modal.updateUserAccountingInstance(val, prop)" required="false" blur="ignore" lang-col="modal.languageColumn" select-options="modal.operationTypes" print-function="modal.printOperationTypes"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        diligenceStampTypeNew: {
            title: 'global.diligence_stamp_type.title',
            size: '',
            icon: 'fa fa-database',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'diligenceTextLanguage1',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.description',
                                    rows: 5,
                                    required: true,
                                    focus: true
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	var hide = true;
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                	if($rootScope && $rootScope.app && $rootScope.app.languagedef){
                                        angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                                             if(value2 && value2.column && value2.column == 'language1') {
                                                hide = false;
                                            }
                                        });
                                    }
                                	return hide;
                                },
                                controller: function ($scope, $rootScope, $filter) {
                                	var labelLang = "";
                                	if($rootScope && $rootScope.app && $rootScope.app.languagedef){
                                        angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                                             if(value2 && value2.column && value2.column == 'language1') {
                                            	 labelLang = value2.label;
                                            }
                                        });
                                    }
                                    $scope.to.label = $filter('translate')('global.literals.description')+" "+labelLang;
                                }
                            },
                            {
                                key: 'diligenceTextLanguage2',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.description',
                                    rows: 5,
                                    required: true,
                                    focus: true
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	var hide = true;
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                	if($rootScope && $rootScope.app && $rootScope.app.languagedef){
                                        angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                                            if(value2 && value2.column && value2.column == 'language2') {
                                                hide = false;
                                            }
                                        });
                                    }
                                	return hide;
                                },
                                controller: function ($scope, $rootScope, $filter) {
                                	var labelLang = "";
                                	if($rootScope && $rootScope.app && $rootScope.app.languagedef){
                                        angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                                             if(value2 && value2.column && value2.column == 'language2') {
                                            	 labelLang = value2.label;
                                            }
                                        });
                                    }
                                    $scope.to.label = $filter('translate')('global.literals.description')+" "+labelLang;
                                }
                            },
                            {
                                key: 'diligenceTextLanguage3',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.description',
                                    rows: 5,
                                    required: true,
                                    focus: true
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	var hide = true;
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                	if($rootScope && $rootScope.app && $rootScope.app.languagedef){
                                        angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                                            if(value2 && value2.column && value2.column == 'language3') {
                                                hide = false;
                                            }
                                        });
                                    }
                                	return hide;
                                },
                                controller: function ($scope, $rootScope, $filter) {
                                	var labelLang = "";
                                	if($rootScope && $rootScope.app && $rootScope.app.languagedef){
                                        angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                                             if(value2 && value2.column && value2.column == 'language3') {
                                            	 labelLang = value2.label;
                                            }
                                        });
                                    }
                                    $scope.to.label = $filter('translate')('global.literals.description')+" "+labelLang;
                                }
                            },
                            {
                                key: 'canChange',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-xs-12',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.diligence_stamp_type.canChange')});
                                }
                            },
                            {
                                key: 'pages',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.diligence_stamp_type.pages',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: 'col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'apiAdmin', function($scope, apiAdmin) {
                                    $scope.to.options = angular.copy(apiAdmin.diligenceStampPages);
                                }]
                            },
                            {
                                key: 'certificateType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.diligence_stamp_type.certificateType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'apiAdmin', function($scope, apiAdmin) {
                                    $scope.to.options = angular.copy(apiAdmin.diligenceStampCertificateType);
                                }]
                            },
                            {
                                key: 'certificate',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.diligence_stamp_type.certificate',
                                    valueProp: 'alias',
                                    labelProp: 'file',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
									if(scope.model.certificateType && scope.model.certificateType == 'ORGAN'){
										return false;
									}else{
										return true;
									}
                                },
                                controller:['$scope', '$rootScope', function($scope, $rootScope) {
                                    $scope.to.options = angular.copy($rootScope.app.configuration.organ_certificates.certificates);
                                }]
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.profiles',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                }]
                            }
                        ]
                    }
                ]
            }
        },
        diligenceStampTypeEdit: {
            title: 'global.diligence_stamp_type.title',
            size: '',
            icon: 'fa fa-database',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12 ">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.isLanguage(\'language1\')" type="textarea" label="{{modal.langText1}}" model-value="modal.data.diligenceTextLanguage1" model-property="diligenceTextLanguage1" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="igonre" rows="5"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.isLanguage(\'language2\')" type="textarea" label="{{modal.langText2}}" model-value="modal.data.diligenceTextLanguage2" model-property="diligenceTextLanguage2" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="igonre" rows="5"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.isLanguage(\'language3\')" type="textarea" label="{{modal.langText3}}" model-value="modal.data.diligenceTextLanguage3" model-property="diligenceTextLanguage3" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="igonre" rows="5"></annexa-editable-field>',
                '		<annexa-editable-field type="checkbox" label="{{\'global.diligence_stamp_type.canChange\' | translate}}" model-value="modal.data.canChange" model-property="canChange" save="modal.updateDiligenceStampType(val, prop)" required="false" blur="ignore" print-function="modal.printCanChange"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.diligence_stamp_type.pages" model-value="modal.data.pages" model-property="pages" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.pages"  print-function="modal.printDescription"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.diligence_stamp_type.certificateType" model-value="modal.data.certificateType" model-property="certificateType" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.certificateTypes"  print-function="modal.printDescription"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.data.certificateType == \'ORGAN\'" type="select" label="global.diligence_stamp_type.certificate" model-value="modal.data.certificate" model-property="certificate" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="ignore" lang-col="modal.fileColumn" select-options="modal.certificates" print-function="modal.printCertificate"></annexa-editable-field>',
                '       <annexa-editable-field type="select-multiple" label="global.literals.profiles" model-value="modal.dataAux.profilesAux" model-property="profiles" save="modal.updateDiligenceStampType(val, prop)" required="true" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printLangugaes"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        taskTypeNew: {
            title: 'global.commonAdmin.modal.taskType.titleNew',
            size: 'modal-xxl',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'column1',
                                className: 'col-sm-7',
                                fieldGroup: [
                                    {
		                                key: 'language1',
		                                type: 'annexaInputLanguage',
		                                className: 'col-sm-12',
		                                validators: {
		                                    "required": {
		                                        "expression": function (viewValue, modelValue, scope) {
		                                            var valid = true;
		                                            if(scope.languages){
		                                                angular.forEach(scope.languages, function (value2, key2) {
		                                                    if(value2 && value2.column) {
		                                                        if (modelValue && !modelValue[value2.column]) {
		                                                            valid = false;
		                                                        }
		                                                    }
		                                                });
		                                            }
		                                            return valid;
		                                        }
		                                    }
		                                },
		                                watcher: {
		                                    type: '$watchCollection',
		                                    expression: 'model',
		                                    listener: function (field, _new) {
		                                        if (field.formControl) {
		                                            field.formControl.$validate();
		                                        }
		                                    }
		                                },
		                                templateOptions: {
		                                    id: 'language',
		                                    modelField: '',
		                                    type: 'text',
		                                    label: 'global.literals.name',
		                                    disposition: 'horitzontal21',
		                                    required: true,
		                                    focus: true
		                                },
		                                controller: function ($scope, $rootScope) {
		                                    $scope.languages = $rootScope.app.languagedef;
		                                }
		                            },
		                            {
		                                key: 'acronym',
		                                type: 'annexaInputRow',
		                                className: 'col-sm-12',
		                                templateOptions: {
		                                    required: true,
		                                    type: 'text',
		                                    label: 'global.literals.acronym',
		                                    maxlength:20
		                                },
		                                data: {
		                                    row: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong'
		                                }
		                            },
		                            {
		                                key: 'abstractTaskType',
		                                type: 'annexaSelectAllRow',
		                                className: 'col-sm-12',
		                                templateOptions: {
		                                    type: 'checkbox',
		                                    label: 'global.commonAdmin.modal.taskType.abstract',
		                                    validate: false,
		                                    required: false
		                                }
		                            },
				                    {
				                        key: 'parent',
				                        type: 'annexaSelectRow',
				                        className: 'col-sm-12',
				                        validation: {
				                            show: true
				                        },
				                        expressionProperties: {
				                            'validation.show': 'formControl.$submitted'
				                        },
				                        watcher:{
				                            type: '$watchCollection',
				                            expression: 'model',
				                            listener: function(field, _new) {
				                                if(field.formControl){
				                                    field.formControl.$validate();
				                                }
				                            }
				                        },
				                        templateOptions: {
				                            focus: false,
				                            required: false,
				                            label: 'global.commonAdmin.modal.taskType.parent',
				                            optionsAttr: 'bs-options',
				                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
				                            valueProp: 'id',
				                            labelProp: 'language1',
				                            placeholder: '',
				                            options: []
				                        },
				                        data: {
				                            row: true,
				                            informed: true,
				                            colClass: ' col-xs-12',
				                            labelClass: 'label-strong'
				                        },
		                                defaultValue: undefined
				                    },
		                            {
		                                key: 'inheritProfiles',
		                                type: 'annexaSelectAllRow',
		                                className: 'col-sm-12',
		                                templateOptions: {
		                                    type: 'checkbox',
		                                    label: 'global.commonAdmin.modal.taskType.inheritParentTaskTypeProfiles',
		                                    validate: false,
		                                    required: false
		                                }
		                            },
		                            {
		                                key: 'parentTaskProfiles',
		                                type: 'annexaComponent',
		                                templateOptions: {
		                                    type: 'annexa-label-value'
		                                },
		                                data: {
		                                    title: '',
		                                    value: '',
		                                    type: 'text',
		                                    labelStrong: true
		                                }
		                            },
				                    {
				                        key: 'profiles',
				                        type: 'annexaMultipleSelectRow',
				                        className: 'col-sm-12',
				                        validation: {
				                            show: true
				                        },
				                        expressionProperties: {
				                            'validation.show': 'formControl.$submitted'
				                        },
				                        watcher:{
				                            type: '$watchCollection',
				                            expression: 'model',
				                            listener: function(field, _new) {
				                                if(field.formControl){
				                                    field.formControl.$validate();
				                                }
				                            }
				                        },
				                        templateOptions: {
				                            focus: true,
				                            required: false,
				                            label: 'global.commonAdmin.modal.taskType.profiles',
				                            optionsAttr: 'bs-options',
				                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
				                            valueProp: 'id',
				                            labelProp: 'language1',
				                            placeholder: '',
				                            options: []
				                        },
				                        data: {
				                            row: true,
				                            informed: true,
				                            colClass: ' col-xs-12',
				                            labelClass: 'label-strong'
		                                }
				                    },
				                    {
				                        key: 'origin',
				                        type: 'annexaSelectRow',
				                        className: 'col-sm-12',
				                        validation: {
				                            show: true
				                        },
				                        expressionProperties: {
				                            'validation.show': 'formControl.$submitted'
				                        },
				                        watcher:{
				                            type: '$watchCollection',
				                            expression: 'model',
				                            listener: function(field, _new) {
				                                if(field.formControl){
				                                    field.formControl.$validate();
				                                }
				                            }
				                        },
				                        templateOptions: {
				                            focus: false,
				                            required: false,
				                            label: 'global.commonAdmin.modal.taskType.origin',
				                            optionsAttr: 'bs-options',
				                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
				                            valueProp: 'id',
				                            labelProp: 'language1',
				                            placeholder: '',
				                            options: []
				                        },
				                        data: {
				                            row: true,
				                            informed: true,
				                            colClass: ' col-xs-12',
				                            labelClass: 'label-strong'
				                        },
		                                defaultValue: undefined
				                    },
				                    {
		                                key: 'allowedCreateExternally',
		                                type: 'annexaSelectAllRow',
		                                className: 'col-sm-12',
		                                templateOptions: {
		                                    type: 'checkbox',
		                                    label: 'global.commonAdmin.modal.taskType.allowedCreateExternally',
		                                    validate: false,
		                                    required: false
		                                }
		                            },
		                            {
		                                key: 'statuses',
		                                type: 'annexaComponent',
		                                templateOptions: {
		                                    type: 'annexa-task-type-statuses'
		                                },
		                                data: {
		                                    type: { statuses: [] },
		                                    isNew: true
		                                }
		                            }
		                        ]
		                    },
                            {
                                key: 'column2',
                                className: 'col-sm-5',
                                fieldGroup: [
                                    {
                                        key: 'customFields',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-custom-field-definition'
                                        },
                                        data: {
                                            customFields: [],
                                            type: 'TASK_TYPE',
											showType: false,
                                            updateOnChange: false
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        taskTypeEdit: {
            title: 'global.commonAdmin.modal.taskType.titleEdit',
            size: 'modal-xxl',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-7">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTaskType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateTaskType(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.taskType.abstractEdit\' | translate}}" model-value="modal.data.abstractTaskType" model-property="abstractTaskType" save="modal.updateTaskType(val, prop)" required="false" blur="ignore" print-function="modal.printAbstractTaskType"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.taskType.parent" is-read-only="{{modal.canEditParentTaskType}}" model-value="modal.data.parent" model-property="parent" save="modal.updateTaskType(val, prop)" required="false" blur="ignore" select-options="modal.taskTypes" lang-col="modal.languageColumn""></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.taskType.inheritParentTaskTypeProfiles\' | translate}}" ng-if="modal.data.parent && modal.data.parent.id != -1 && modal.data.parent.profiles.length > 0" model-value="modal.data.inheritProfiles" model-property="inheritProfiles" save="modal.updateTaskType(val, prop)" required="false" blur="ignore" print-function="modal.printInheritParentTaskTypeProfiles"></annexa-editable-field>',
                '       <annexa-label-value label="" type="text" ng-if="modal.parentProfiles" value="modal.parentProfiles"></annexa-label-value>',
                '       <annexa-editable-field type="select-multiple" label="global.commonAdmin.modal.taskType.profiles" model-value="modal.data.profiles.profile" model-property="profiles" save="modal.updateTaskType(val, prop)" required="false" blur="ignore" select-options="modal.profiles" lang-col="modal.languageColumn" print-function="modal.printProfiles"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.taskType.origin" model-value="modal.data.origin" model-property="origin" save="modal.updateTaskType(val, prop)" required="false" blur="ignore" select-options="modal.taskOrigins" lang-col="modal.languageColumn""></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.taskType.allowedCreateExternally\' | translate}}" model-value="modal.data.allowedCreateExternally" model-property="allowedCreateExternally" save="modal.updateTaskType(val, prop)" required="false" blur="ignore" print-function="modal.printAllowedCreateExternally"></annexa-editable-field>',
                '       <annexa-task-type-statuses type="modal.data" is-new="false"></annexa-task-type-statuses>',
                '   </div>',
                '   <div class="col-sm-5">',
                '       <annexa-custom-field-definition custom-fields="modal.customFieldsType" type="TASK_TYPE" show-type="false" update-on-change="true" parent-property="taskType" parent-id="modal.data.id"/>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        taskOriginNew: {
            title: 'global.commonAdmin.modal.taskOrigin.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'acronym',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.acronym',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        taskOriginEdit: {
            title: 'global.commonAdmin.modal.taskOrigin.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTaskOrigin(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateTaskOrigin(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },        
        taskTypeStatusAssignTo: {
            title: 'global.literals.assign_to',
            size: 'modal-md',
            data: undefined,
            content: [
	            '<div class="row formly-row">',
		        '	<div class="col-xs-6">',
	            '		<span class="small m-b-xs" translate="global.literals.assign_to">Assign to</span>',
	            '   </div>',
	            '   <br>',
	            '   <div class="radio p-l inline-formly" ng-repeat="(key, option) in modal.assignToOptions">',
	            '   	<label class="ng-class: {\'check-selected\' : modal.assignTo}">',
	            '       	<input type="radio" name="{{option.id + \'_\' + $index}}" id="{{option.id + \'_\' + $index}}" ng-value="option.id" ng-required="true" ng-model="modal.assignTo" ng-click="modal.selectAssignTo(option.id)">',
	            '           	{{option.description}}',
	            '       </label>',
	            '	</div>',
	            '</div>',
	            '<br>',
	            '<div class="row formly-row">',
	            '	<div class="col-xs-12 ">',
	            '		<annexa-editable-field ng-if="modal.assignTo == \'user\'" type="loadUser" label="global.literals.assigned_to" model-value="modal.userAux" model-property="user" save="modal.updateAssignTo(val, prop)" blur="ignore" search="modal.searchAssignToUser(value)"></annexa-editable-field>',
	    		'		<annexa-editable-field ng-if="modal.assignTo == \'profile\'" type="select" label="global.literals.assigned_to" model-value="modal.data.profile" model-property="profile" save="modal.updateAssignTo(val, prop)" required="true" update-on-blur="true" blur="ignore" print-function="modal.printProfile" lang-col="modal.languageColumn" select-options="modal.allProfiles"></annexa-editable-field>',	            
				'	</div>',
				'</div>',
            ],
            alerts: []
        },        
        addressTypeNew: {
            title: 'global.commonAdmin.modal.addressType.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'postalGroup',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.commonAdmin.modal.addressType.postalGroup',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'apiAdmin', function($scope, apiAdmin) {
                                	$scope.options.templateOptions.options = angular.copy(apiAdmin.showAddressTypeList);
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'cadastralReferenceGroup',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.commonAdmin.modal.addressType.cadastralReferenceGroup',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'apiAdmin', function($scope, apiAdmin) {
                                	$scope.options.templateOptions.options = angular.copy(apiAdmin.showAddressTypeList);
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'coordinatesGroup',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.commonAdmin.modal.addressType.coordinatesGroup',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'apiAdmin', function($scope, apiAdmin) {
                                	$scope.options.templateOptions.options = angular.copy(apiAdmin.showAddressTypeList);
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'validateNumbers',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'id',
                                    labelProp: 'value',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:true, value:$filter('translate')('global.commonAdmin.modal.addressType.validateNumbers')});
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'permissions',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'permissionsAux',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-address-type-permission'
                                        },
                                        data: {
                                            permissions: [],
                                            isNew: true
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        addressTypeEdit: {
            title: 'global.commonAdmin.modal.addressType.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateAddressType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateAddressType(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.addressType.postalGroup" model-value="modal.data.postalGroup" model-property="postalGroup" save="modal.updateAddressType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.showAddressTypeList" print-function="modal.printGroups"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.addressType.cadastralReferenceGroup" model-value="modal.data.cadastralReferenceGroup" model-property="cadastralReferenceGroup" save="modal.updateAddressType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.showAddressTypeList" print-function="modal.printGroups"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.commonAdmin.modal.addressType.coordinatesGroup" model-value="modal.data.coordinatesGroup" model-property="coordinatesGroup" save="modal.updateAddressType(val, prop)" required="true" blur="ignore" lang-col="modal.descriptionColumn" select-options="modal.showAddressTypeList" print-function="modal.printGroups"></annexa-editable-field>',
                '       <annexa-editable-field type="checkbox" label="{{\'global.commonAdmin.modal.addressType.validateNumbers\' | translate}}" model-value="modal.data.validateNumbers" model-property="validateNumbers" save="modal.updateAddressType(val, prop)" required="false" blur="ignore" print-function="modal.printValidateNumbers"></annexa-editable-field>',
                '		<annexa-address-type-permission is-new="false" permissions="modal.data.permissions" class="m-b-md clearfix" address-type-id="modal.data.id"></annexa-address-type-permission>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        countryNew: {
            title: 'global.commonAdmin.modal.country.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'codeISO2',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.codeISO2',
                                    maxlength:2
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'codeISO3',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.codeISO3',
                                    maxlength:3
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'dir3',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.dir3',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        countryEdit: {
            title: 'global.commonAdmin.modal.country.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateCountry(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.codeISO2" model-value="modal.data.codeISO2" model-property="codeISO2" save="modal.updateCountry(val, prop)" required="true" blur="ignore" model-length="2"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.codeISO3" model-value="modal.data.codeISO3" model-property="codeISO3" save="modal.updateCountry(val, prop)" required="false" blur="ignore" model-length="3"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.dir3" model-value="modal.data.dir3" model-property="dir3" save="modal.updateCountry(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        streetTypeNew: {
            title: 'global.commonAdmin.modal.streetType.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        streetTypeEdit: {
            title: 'global.commonAdmin.modal.streetType.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateStreetType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateStreetType(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        pseudoViaNew: {
            title: 'global.commonAdmin.modal.pseudoVia.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        pseudoViaEdit: {
            title: 'global.commonAdmin.modal.pseudoVia.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updatePseudoVia(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        territorialGroupTypeNew: {
            title: 'global.commonAdmin.modal.territorialGroupType.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'applicableTo',
                                type: 'annexaSelectRow',
                                className: '',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.applicableTo',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', '$rootScope', function($scope, Language, GlobalDataFactory, DccumentsFactory, $rootScope) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                	$scope.to.options = GlobalDataFactory.applicableTo;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        territorialGroupTypeEdit: {
            title: 'global.commonAdmin.modal.territorialGroupType.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTerritorialGroupType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.literals.applicableTo" model-value="modal.data.applicableTo" model-property="applicableTo" save="modal.updateTerritorialGroupType(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.applicableTo" print-function="modal.printApplicableTo"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        territorialGroupNew: {
            title: 'global.commonAdmin.modal.territorialGroup.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
	                            key: 'language1',
	                            type: 'annexaInputLanguage',
	                            className: '',
	                            validators: {
	                                "required": {
	                                    "expression": function (viewValue, modelValue, scope) {
	                                        var valid = true;
	                                        if(scope.languages){
	                                            angular.forEach(scope.languages, function (value2, key2) {
	                                                if(value2 && value2.column) {
	                                                    if (modelValue && !modelValue[value2.column]) {
	                                                        valid = false;
	                                                    }
	                                                }
	                                            });
	                                        }
	                                        return valid;
	                                    }
	                                }
	                            },
	                            watcher: {
	                                type: '$watchCollection',
	                                expression: 'model',
	                                listener: function (field, _new) {
	                                    if (field.formControl) {
	                                        field.formControl.$validate();
	                                    }
	                                }
	                            },
	                            templateOptions: {
	                                id: 'language',
	                                modelField: '',
	                                type: 'text',
	                                label: 'global.literals.name',
	                                disposition: 'horitzontal21',
	                                required: true,
	                                focus: true
	                            },
	                            controller: function ($scope, $rootScope) {
	                                $scope.languages = $rootScope.app.languagedef;
	                            }
	                        },
							{
		                        key: 'territorialGroupType',
		                        type: 'annexaSelectRow',
		                        className: 'col-sm-12 daughter-label-strong',
		                        data: {
		                            informed:true,
		                            row:true,
		                            clear: function($event,model,key, $select) {
		                                model[key] = undefined;
		                                if($select) {
		                                    $select.selected = undefined;
		                                    $select.search = undefined;
		                                }
		                            }
		                        },
		                        templateOptions: {
		                            optionsAttr: 'bs-options',
		                            ngOptions: 'option in to.options | filter: $select.search',
		                            label: 'global.thirds.literals.territorialGroupType',
		                            valueProp: 'id',
		                            labelProp: 'language1',
		                            placeholder: '',
		                            options: [],
		                            required: false,
		                            focus: false
		                        },
		                        controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
		                            $scope.to.labelProp = Language.getActiveColumn();
		                            $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.territorialGroupTypes);
		                        }
		                    }
	                    ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        territorialGroupEdit: {
            title: 'global.commonAdmin.modal.territorialGroup.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="select" label="global.thirds.literals.territorialGroupType" model-value="modal.data.territorialGroupType" model-property="territorialGroupType" save="modal.updateTerritorialGroup(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.territorialGroupTypes" print-function="modal.printTerritorialGroupType"></annexa-editable-field>',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTerritorialGroup(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        stateNew: {
            title: 'global.commonAdmin.modal.state.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.countries);
                                    if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                        var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                        if (countries) {
                                            $scope.model.country = countries;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        stateEdit: {
            title: 'global.commonAdmin.modal.state.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateState(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.literals.country" model-value="modal.data.country" model-property="country" save="modal.updateState(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.countries" print-function="modal.printcountries"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateState(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        cityNew: {
            title: 'global.commonAdmin.modal.city.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('adminModifyCountryEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.countries);
                                    if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                        var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                        if (countries) {
                                            $scope.model.country = countries;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'state',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.state',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    if($scope.model.country && $scope.model.country.id){
                                    	var states = angular.copy(TerritoryFactory.states);
                                    	var options = $linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray();
                                    	$scope.to.options = HelperService.sortArrayByLanguage(options);
                                    }else{
                                    	$scope.to.options = [];
                                    }
                                    $rootScope.$on('adminModifyCountryEvent', function(event, args) {
                                        $scope.model.state = undefined;
                                        var states = angular.copy(TerritoryFactory.states);
                                        var options = $linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray();
                                    	$scope.to.options = HelperService.sortArrayByLanguage(options);
                                    });
                                }
                            },
                            {
                                key: 'name',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.name',
                                    maxlength:255
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'dir3',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.dir3',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'pseudoVies',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'pseudoViesAux',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-address-pseudo-vies'
                                        },
                                        data: {
                                            pseudoVies: [],
                                            isNew: true
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            },
                            {
                                key: 'territorialGroups',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'territorialGroupsAux',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-address-territorial-groups'
                                        },
                                        data: {
                                            pseudoVies: [],
                                            isNew: true,
                                            applicableTo: 'TOWNS'
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        cityEdit: {
            title: 'global.commonAdmin.modal.city.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="select" label="global.literals.country" model-value="modal.data.country" model-property="country" edit-function="modal.openModalUpdateParents" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.countries" print-function="modal.printcountriesAndStates"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.literals.state" model-value="modal.data.state" model-property="state" edit-function="modal.openModalUpdateParents" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.states" print-function="modal.printcountriesAndStates"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.name" model-value="modal.data.name" model-property="name" save="modal.updateCity(val, prop)" required="true" blur="ignore" model-length="255"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateCity(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.dir3" model-value="modal.data.dir3" model-property="dir3" save="modal.updateCity(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '		<annexa-address-pseudo-vies is-new="false" pseudo-vies="modal.data.pseudoVies" class="m-b-md clearfix" city-id="modal.data.id"></annexa-address-pseudo-vies>',
				'		<annexa-address-territorial-groups is-new="false" territorial-groups="modal.data.territorialGroups" class="m-b-md clearfix" city-id="modal.data.id" applicable-to="modal.applicableTo"></annexa-address-territorial-groups>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        updateCityState:{
            title: 'global.commonAdmin.modal.city.titleEdit',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                        	{
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('adminModifyCountryEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.countries);
                                    if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                        var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                        if (countries) {
                                            $scope.model.country = countries;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'state',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.state',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    if($scope.model.country && $scope.model.country.id){
                                    	var states = angular.copy(TerritoryFactory.states)
                                    	$scope.to.options = HelperService.sortArrayByLanguage($linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray());
                                    }else{
                                    	$scope.to.options = [];
                                    }
                                    if($scope.to.options && $scope.model.state && $scope.model.state.id) {
                                        var state = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.state.id);
                                        if (state) {
                                            $scope.model.state = state;
                                        }
                                    }
                                    $rootScope.$on('adminModifyCountryEvent', function(event, args) {
                                        $scope.model.state = undefined;
                                        var states = angular.copy(TerritoryFactory.states)
                                    	$scope.to.options = HelperService.sortArrayByLanguage($linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray());
                                    });
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        cityPseudoViesNew: {
            title: 'global.thirds.literals.pseudoVias',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                	{
                        key: 'pseudoVia',
                        type: 'annexaSelectRow',
                        className: 'col-sm-12 daughter-label-strong',
                        data: {
                            informed:true,
                            row:true,
                            clear: function($event,model,key, $select) {
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        },
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option in to.options | filter: $select.search',
                            label: 'global.thirds.literals.pseudoVia',
                            valueProp: 'id',
                            labelProp: 'language1',
                            placeholder: '',
                            options: [],
                            required: true,
                            focus: false
                        },
                        controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                            $scope.to.labelProp = Language.getActiveColumn();
                            var pseudoVies = angular.copy(TerritoryFactory.pseudoVies);
                            if(pseudoVies && $scope.to.actualPseudoVies && $scope.to.actualPseudoVies.length > 0){
                            	$scope.to.options = $linq(pseudoVies).where(
                            		function(x){
                            			if($scope.to.actualPseudoVies){
			                           		var pseudo = $linq($scope.to.actualPseudoVies).firstOrDefault(undefined, "x => x == "+x.id);
			                           		if(pseudo){
			                           			return false;
			                           		}else{
			                           			return true;
			                           		}
			                           	}else{
			                           		return true;
			                           	}
                            		}
                            	).toArray();
                                $scope.to.options = HelperService.sortArrayByLanguage($scope.to.options);
                            }else{
                            	$scope.to.options = ((pseudoVies)?pseudoVies:[]);
                            	$scope.to.options = HelperService.sortArrayByLanguage($scope.to.options);
                            }
                        }
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        streetNew: {
            title: 'global.commonAdmin.modal.street.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                        	{
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('adminModifyCountryEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.countries);
                                    if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                        var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                        if (countries) {
                                            $scope.model.country = countries;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'state',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.state',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('adminModifyStateEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    if($scope.model.country && $scope.model.country.id){
                                    	var states = angular.copy(TerritoryFactory.states)
                                    	$scope.to.options = HelperService.sortArrayByLanguage($linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray());
                                    }else{
                                    	$scope.to.options = [];
                                    }
                                    $rootScope.$on('adminModifyCountryEvent', function(event, args) {
                                        $scope.model.state = undefined;
                                        var states = angular.copy(TerritoryFactory.states)
                                    	$scope.to.options = HelperService.sortArrayByLanguage($linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray());
                                    });
                                }
                            },
                            {
                                key: 'city',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.city',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory) {
                                	var calculateCities = function(){
                                		TerritoryFactory.getCitiesByState($scope.model.state.id).then(function (data) {
                                            var citiesAux = JSOG.decode(data);
                                            citiesAux = citiesAux.sort(function (a, b) {
                                                return a.name.localeCompare(b.name);
                                            });
                                            if (citiesAux.length > 0) {
                                                $scope.to.options = citiesAux;
                                            } else {
                                                $scope.to.options = [];
                                            }
                                        }).catch(function (error) {
                                            $scope.to.options = [];
                                        });
                                    }
                                    if($scope.model && $scope.model.state && $scope.model.state.id){
                                        calculateCities();
                                    }
                                    $rootScope.$on('adminModifyStateEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                        calculateCities();
                                    });

                                    $rootScope.$on('adminModifyCountryEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                        $scope.to.options = [];
                                    });
                                }
                            },
                            {
                                key: 'streetType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.streetType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                	 $scope.to.labelProp = Language.getActiveColumn();
                                     $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.streetTypes);
                                }
                            },
                            {
                                key: 'name',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.name',
                                    maxlength:255
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'particles',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.particles',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'numbers',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'numbersAux',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-address-street-numbers'
                                        },
                                        data: {
                                            numbers: [],
                                            isNew: true
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        streetEdit: {
            title: 'global.commonAdmin.modal.street.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="select" label="global.literals.country" model-value="modal.data.country" model-property="country" edit-function="modal.openModalUpdateParents" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.countries" print-function="modal.printcountriesAndStatesAndStreetTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.literals.state" model-value="modal.data.state" model-property="state" edit-function="modal.openModalUpdateParents" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.states" print-function="modal.printcountriesAndStatesAndStreetTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.literals.city" model-value="modal.data.city" model-property="city" edit-function="modal.openModalUpdateParents" required="true" blur="ignore" lang-col="modal.nameColumn" select-options="modal.cities" print-function="modal.printCities"></annexa-editable-field>',
                '       <annexa-editable-field type="select" label="global.thirds.literals.streetType" model-value="modal.data.streetType" model-property="streetType" save="modal.updateStreet(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.streetTypes" print-function="modal.printcountriesAndStatesAndStreetTypes"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.name" model-value="modal.data.name" model-property="name" save="modal.updateStreet(val, prop)" required="true" blur="ignore" model-length="255"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.particles" model-value="modal.data.particles" model-property="particles" save="modal.updateStreet(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateStreet(val, prop)" required="false" blur="ignore" model-length="20"></annexa-editable-field>',
                '		<annexa-address-street-numbers is-new="false" numbers="modal.data.numbers" class="m-b-md clearfix" street-id="modal.data.id"></annexa-address-street-numbers>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        updateStreetCity:{
            title: 'global.commonAdmin.modal.street.titleEdit',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                        	{
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('adminModifyCountryEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = HelperService.sortArrayByLanguage(TerritoryFactory.countries);
                                    if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                        var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                        if (countries) {
                                            $scope.model.country = countries;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'state',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.state',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('adminModifyStateEvent', { item: $item });
                                    }
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory, HelperService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    if($scope.model.country && $scope.model.country.id){
                                    	var states = angular.copy(TerritoryFactory.states)
                                    	$scope.to.options = HelperService.sortArrayByLanguage($linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray());
                                    }else{
                                    	$scope.to.options = [];
                                    }
                                    if($scope.to.options && $scope.model.state && $scope.model.state.id) {
                                        var state = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.state.id);
                                        if (state) {
                                            $scope.model.state = state;
                                        }
                                    }
                                    $rootScope.$on('adminModifyCountryEvent', function(event, args) {
                                        $scope.model.state = undefined;
                                        var states = angular.copy(TerritoryFactory.states)
                                    	$scope.to.options = HelperService.sortArrayByLanguage($linq(states).where("x => x.country &&  x.country.id == "+$scope.model.country.id).toArray());
                                    });
                                }
                            },
                            {
                                key: 'city',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.city',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller: function ($scope, Language, RestService, $rootScope, TerritoryFactory) {
                                	var calculateCities = function(addDefault){
                                		TerritoryFactory.getCitiesByState($scope.model.state.id).then(function (data) {
                                            var citiesAux = JSOG.decode(data);
                                            citiesAux = citiesAux.sort(function (a, b) {
                                                return a.name.localeCompare(b.name);
                                            });
                                            if (citiesAux.length > 0) {
                                                $scope.to.options = citiesAux;
                                                if(addDefault){
                                                	if($scope.to.options && $scope.model.city && $scope.model.city.id) {
                                                        var city = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.city.id);
                                                        if (city) {
                                                            $scope.model.city = city;
                                                        }
                                                    }
                                                }
                                            } else {
                                                $scope.to.options = [];
                                            }
                                        }).catch(function (error) {
                                            $scope.to.options = [];
                                        });
                                    }
                                    if($scope.model && $scope.model.state && $scope.model.state.id){
                                        calculateCities(true);
                                    }
                                    $rootScope.$on('adminModifyStateEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                        calculateCities(false);
                                    });

                                    $rootScope.$on('adminModifyCountryEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                        $scope.to.options = [];
                                    });
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        streetNumberNew: {
            title: 'global.thirds.literals.numbers',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                	{
                        key: 'type',
                        type: 'annexaSelectRow',
                        className: 'col-sm-12 daughter-label-strong',
                        data: {
                            informed:true,
                            row:true,
                            clear: function($event,model,key, $select) {
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        },
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option in to.options | filter: $select.search',
                            label: 'global.literals.type',
                            valueProp: 'id',
                            labelProp: 'name',
                            placeholder: '',
                            options: [],
                            required: true,
                            focus: false
                        },
                        controller: function ($scope, $filter, $rootScope, TerritoryFactory) {
                        	$scope.to.options = [
                            	{id:"ALL", name:$filter('translate')('global.literals.all')},
                            	{id:"EVEN", name:$filter('translate')('global.territory.list.even')},
                            	{id:"ODD", name:$filter('translate')('global.territory.list.odd')}
                            ]
                        	if($scope.to.options && $scope.model.type && $scope.model.type.id) {
                                var types = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == '" + $scope.model.type.id+"'");
                                if (types) {
                                    $scope.model.type = types;
                                }
                            }
                        }
                    },
                    {
                    	key: 'initialNumber',
                        type: 'annexaInputRow',
                        className: 'col-lg-12',
                        templateOptions: {
                            required: true,
                            type: 'number',
                            label: 'global.territory.list.initialNumber',
                            maxlength: 20
                        },
                        data: {
                            row: true,
                            informed: true,
                            colClass: ' col-lg-12',
                            labelClass: 'label-strong'
                        }
                    },
                    {
                    	key: 'finalNumber',
                        type: 'annexaInputRow',
                        className: 'col-lg-12',
                        templateOptions: {
                            required: true,
                            type: 'number',
                            label: 'global.territory.list.finalNumber',
                            maxlength: 20
                        },
                        data: {
                            row: true,
                            informed: true,
                            colClass: ' col-lg-12',
                            labelClass: 'label-strong'
                        }
                    },
                    {
                        key: 'territorialGroups',
                        className: 'row',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'territorialGroupsAux',
                                className: 'col-xs-12',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-address-territorial-groups'
                                },
                                data: {
                                    pseudoVies: [],
                                    isNew: true,
                                    applicableTo: 'STREET_SECTIONS'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        adddressTypePermissionNew: {
            title: 'global.literals.permissions',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                     key: 'permissionType',
                        type: 'annexaSelectRow',
                        className: 'col-sm-12',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option in to.options | filter: $select.search',
                            label: 'global.literals.type',
                            valueProp: 'id',
                            labelProp: 'description',
                            placeholder: '',
                            options: [],
                            required: true
                        },
                        controller: ['$scope', 'apiAdmin', function($scope, apiAdmin) {
                        	if(apiAdmin.addressTypePermissionType){
                        		$scope.to.options = angular.copy(apiAdmin.addressTypePermissionType);
                        	}else{
                        		$scope.to.options = [];
                        	}
                        }],
                        data: {
                            row: true,
                            informed: true,
                            colClass: ' col-xs-12',
                            labelClass: 'label-strong',
                            clear: function($event,model,key, $select) {
                                $event.stopPropagation();
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        }
                    },
                    {
                        key: 'city',
                        type: 'annexaLoadUserRow',
                        className: 'col-xs-12',
                        templateOptions: {
                            type: 'text',
                            label: 'global.literals.city',
                            required: false,
                            focus: false,
                            options: [],
                            loadFunction: function () {}
                        },
                        controller: function ($scope, $rootScope, RestService) {
                            $scope.options.templateOptions.loadFunction = function(value) {
                                if(!value && !value.val) {
                                    return [];
                                }

                                if(value.val != '*' && value.val.length < 3) {
                                    return [];
                                }

                                if(value.val == '*') {
                                    value.val = '';
                                }

                                return RestService.loadData('territory', 'StreetCity', value.val)
                                    .then(function(data) {
                                        var cities = [];

                                        if(data.data && data.data.content && data.data.content.length > 0) {
                                            _.forEach(JSOG.decode(data.data.content), function(val) {
                                            	cities.push({ 'id':  val.id, 'city': val, 'value': val.name });
                                            });
                                        }

                                        return cities;
                                    }).catch(function() {
                                        return [];
                                });

                            }
                        },
                        data: {
                            row: true,
                            colClass: ' col-xs-12',
                            labelClass: 'label-strong'
                        }
                    },
                    {
                        key: 'profiles',
                        type: 'annexaMultipleSelectRow',
                        className: 'col-sm-12',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option in to.options | filter: $select.search',
                            label: 'global.literals.profiles',
                            valueProp: 'id',
                            labelProp: 'name',
                            placeholder: '',
                            options: [],
                            required: true
                        },
                        controller: ['$scope', '$filter', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, $filter, Language, $rootScope, GlobalDataFactory) {
                        	$scope.to.labelProp = Language.getActiveColumn();
                        	if(GlobalDataFactory.allProfiles){
                        		$scope.to.options = angular.copy(GlobalDataFactory.allProfiles);
                        	}else{
                        		$scope.to.options = [];
                        	}
                        }],
                        data: {
                            row: true,
                            informed: true,
                            colClass: ' col-sm-12',
                            labelClass: 'label-strong'
                        }
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        importDataGeneral: {
        	 title: 'global.literals.import',
             size: 'modal-lg',
             icon: 'fa fa-download',
             data: undefined,
             content: [
                 '<form id="{{modal.id}} " name="{{modal.id}}" ng-submit="modal.form.submit()">',
                 '   <formly-form model="modal.form.model" fields="modal.form.fields" options="modal.form.options" form="modal.form.form">',
                 '      <div>',
                 '         <span translate="global.literals.imports.importDescription">Import description</span>',
                 '         <br>',
                 '      </div>',                 
                 '      <br>',
                 '      <div class=modal-footer>',
                 '         <button type="button" class="btn btn-sm primary" ng-click="modal.getImportTemplate()"><i class="fa fa-download"></i> <span translate="global.literals.imports.downloadTemplate">Template</span></button>',
                 '		   <button type="file" class="btn btn-sm primary" ngf-select="modal.validateData($file)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">',
                 '            <i class="fa fa-check"></i> <span translate="global.literals.imports.validate">Validate</span>',
                 '         </button>',
                 '		   <button type="file" class="btn btn-sm primary" ngf-select="modal.importData($file)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">',
                 '            <i class="fa fa-check"></i><span translate="global.literals.import">Import</span>',
                 '         </button>',
                 '         <button type="button" class="btn btn-sm grey-500  text-white" data-dismiss="modal" ng-click="modal.close();"><span translate="global.literals.cancel">Cancel</span></button>',                 
                 '      </div>',
                 '   </formly-form>',
                 '</form>',                 
             ]        	
        
        },
        relatedDossierRelationNew: {
            title: 'global.userAccountingInstace.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'inverseLanguage1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'inverseLanguage',
                                    modelField:'inverseLanguage',
                                    type: 'text',
                                    label: 'global.relatedDossierRelation.inverseLanguage',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'parent',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, $filter) {
                                   $scope.to.options = [{ label: $filter('translate')('global.literals.relatedDossierRelationParent'), value: true }];
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        relatedDossierRelationEdit: {
            title: 'global.commonAdmin.modal.transactionType.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRelatedDossierRelationNew(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
               	'   <div class="col-lg-12"><span class=" small" translate="global.relatedDossierRelation.inverseLanguage">inverseLanguage</span></div>',
                '   <div class="col-lg-12">',
                '       <a class="editable-click label-strong inline wi-100" editable-languageinputs="modal.data.inverseLanguage1" e-model-language="modal.data" e-model-field="inverseLanguage"  blur="ignore" e-label="" onbeforesave="modal.updateRelatedDossierRelationNew($data, \'inverseLanguage\')" e-form="descriptionForm">{{ modal.printInverse() }}</a>',
                '       <a ng-click="descriptionForm.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" href="" class="abs-r-1" ng-show="!descriptionForm.$visible" ><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i></a>',
                '   </div>',
                '   <div class="col-lg-12 m-t">',
                '       <annexa-editable-field type="checkbox" label="{{\'global.literals.relatedDossierRelationParent\' | translate}}" model-value="modal.data.parent" model-property="parent" save="modal.updateRelatedDossierRelationNew(val, prop)" required="false" blur="ignore" print-function="modal.printParent"></annexa-editable-field>',
                '   </div>',
               	'</div>'
            ]
        },
        delegationTypeNew: {
            title: 'global.commonAdmin.modal.councillor.titleNewDelegationType',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'decreeMessageLanguage1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'decreeMessageLanguage',
                                    modelField:'decreeMessageLanguage',
                                    type: 'text',
                                    label: 'global.commonAdmin.literals.decreeMessageLanguage',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'decreesMessageLanguage1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'decreesMessageLanguage',
                                    modelField:'decreesMessageLanguage',
                                    type: 'text',
                                    label: 'global.commonAdmin.literals.decreesMessageLanguage',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'label',
                                type: 'annexaLabelRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: { 
                                    informed: true,
                                    row: true
                                },
                                controller: ['$scope', '$filter', function($scope, $filter) {
	                      	       	$scope.to.value = $filter('translate')('global.commonAdmin.literals.delegationTypeMessage');
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        delegationTypeEdit: {
            title: 'global.commonAdmin.modal.councillor.titleEditDelegationType',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateDelegationType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
               	'   <div class="col-lg-12"><span class=" small" translate="global.commonAdmin.literals.decreeMessageLanguage">decreeMessageLanguage</span></div>',
                '   <div class="col-lg-12">',
                '       <a class="editable-click label-strong inline wi-100" editable-languageinputs="modal.data.decreeMessageLanguage1" e-model-language="modal.data" e-model-field="decreeMessageLanguage"  blur="ignore" e-label="" onbeforesave="modal.updateDelegationType($data, \'decreeMessageLanguage\')" e-form="description1Form">{{ modal.printDecree() }}</a>',
                '       <a ng-click="description1Form.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" href="" class="abs-r-1" ng-show="!description1Form.$visible" ><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i></a>',
                '   </div>',
                '   <div class="col-lg-12"><span class=" small" translate="global.commonAdmin.literals.decreesMessageLanguage">decreesMessagesLanguage</span></div>',
                '   <div class="col-lg-12">',
                '       <a class="editable-click label-strong inline wi-100" editable-languageinputs="modal.data.decreesMessageLanguage1" e-model-language="modal.data" e-model-field="decreesMessageLanguage"  blur="ignore" e-label="" onbeforesave="modal.updateDelegationType($data, \'decreesMessageLanguage\')" e-form="description2Form">{{ modal.printDecrees() }}</a>',
                '       <a ng-click="description2Form.$show()" role="button" aria-label="{{\'global.literals.edit\' | translate}}" href="" class="abs-r-1" ng-show="!description2Form.$visible" ><i class="fa fa-pencil-square grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i></a>',
                '   </div>',
                '   <div class="col-lg-12 m-t">',
				'   	<span class="label-strong" translate="global.commonAdmin.literals.delegationTypeMessage">delegationTypeMessage</span>',
                '   </div>',
                '</div>'
            ]
        },
        jsonViewer: {
            size: 'modal-lg',
            icon: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12 ">',
                '		<view-file data="modal.data" content-type="\'application/json\'" allow-modal="false" show-code="true" class="docViewer docViewerAllBrowsers"></view-file>',
                '   </div>',
                '</div>'
            ]
        },
		changeIdentifierUser: {
            title: 'global.literals.changeIdentifierUser',
            size: '',
            icon: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'identifier',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.user',
                                    required: true,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'newPassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.newPassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'rePassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.repassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'labelPassword',
                                type: 'annexaLabel',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope','GlobalDataFactory', function($scope, GlobalDataFactory) {
                                	$scope.to.label = GlobalDataFactory.passwordAlertMessage;
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
		updateEMGDETransfer: {
            title: 'global.documents.EMGDE.infoEMGDE',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '		<annexa-document-emgde-config object="modal.data" open-content="true"></annexa-document-emgde-config>',
                '   </div>',
                '</div>'
            ]
        },
        updatePredefinedFilterModal: {
            title: 'global.predefinedFields.modify',
            size: 'modal-lg',
            icon: 'fa fa-pencil',
            annexaFormly: {
            	fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
							{
								key: 'filtersRow',
                        		className: 'col-sm-12',
                        		templateOptions: {},
								fieldGroup: [],
								wrapper: 'annexaRow'
							}
						]
					}
		       ]
            },
            submitModal: function () {
            }
        },
        tramClassificationNew: {
            title: 'global.literals.newTramClassification',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.codeType',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        tramClassificationEdit: {
            title: 'global.literals.editTramClassification',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTramClassification(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateTramClassification(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
            ],
            alerts: []
        },
        spelFunctionNew: {
            title: 'global.literals.spelFunctionNew',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
          					{
                                key: 'name',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.name',
                                    maxlength:1024,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'functionName',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.functionName',
                                    maxlength:1024,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    labelClass: 'label-strong'
                                }
                            },
							{
                                key: 'spel',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.expression',
                                    required: true,
									rows: 10
                                }
                            },
          					{
                                key: 'returns',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.returns',
                                    maxlength:1024
                                },
                                data: {
                                    row: true,
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'params',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'paramsAux',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-spel-function-params'
                                        },
                                        data: {
                                            params: [],
                                            isNew: true
                                        }
                                    }
                                ],
                                wrapper: 'annexaRow'
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        spelFunctionEdit: {
            title: 'global.literals.spelFunctionEdit',
            size: 'modal-lg',
            data: undefined,
			content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="text" label="global.literals.name" model-value="modal.data.name" model-property="name" save="modal.updateSpelFunction(val, prop)" required="true" blur="ignore" model-length="1024" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.functionName" model-value="modal.data.functionName" model-property="functionName" save="modal.updateSpelFunction(val, prop)" required="true" blur="ignore" model-length="1024" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '       <annexa-editable-field type="textarea" label="global.literals.expression" model-value="modal.data.spel" model-property="spel" save="modal.updateSpelFunction(val, prop)" required="true" blur="igonre" rows="10" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.returns" model-value="modal.data.returns" model-property="returns" save="modal.updateSpelFunction(val, prop)" required="true" blur="ignore" model-length="1024" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '		<annexa-spel-function-params is-new="false" can-edit="modal.canEdit" params="modal.data.params" class="m-b-md clearfix" spel-function-id="modal.data.id"></annexa-spel-function-params>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        spelFunctionParamNew: {
            title: 'global.literals.spelFunctionParamNew',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
          					{
                                key: 'name',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.name',
                                    maxlength:1024,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    labelClass: 'label-strong'
                                }
                            },
							{
                                key: 'type',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                }
                            },
							{
                                key: 'typeList',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
									if(scope.model.type &&  scope.model.type.id){
										if(_.contains(['List'], scope.model.type.id)){
											return false;
										}else{
											return true;
										}
									}else{
										return true;
									}
                                }
                            }
					   ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        documentationToProvideAdd: {
            title: 'global.tram.literals.documentationToProvide',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'documentType',
                                type: 'annexaSelectRow',
                                className: '',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.docType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.documentTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
          					{
                                key: 'description',
                                type: 'annexaInputRow',
                                className: '',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.description',
                                    maxlength: 1024
                                },
                                data: {
                                    row: true,
									colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                                key: 'scopeApplication',
                                type: 'annexaSelectRow',
                                className: '',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.scopeApplication',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'RestService', 'apiAdmin', function($scope, RestService, apiAdmin) {
									$scope.options.templateOptions.options = apiAdmin.scopeApplication;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'required',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.mandatory',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [ { label: $filter('translate')('global.literals.required'), value: true }];
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.scopeApplication == 'GLOBAL'){
                                    	return false;
                                    }
                                    return true;
                                }
                            },
                            {
                                key: 'docThirds',
                                className: 'row',
                                templateOptions: {},
                                fieldGroup: [
                                    {
                                        key: 'paramsAux',
                                        className: 'col-xs-12',
                                        type: 'annexaComponent',
                                        templateOptions: {
                                            type: 'annexa-box-admin-documentation-to-provide-thirds'
                                        },
                                        data: {
                                            docThirds: [],
                                            isNew: true,
											roles: [],
											type: undefined,
											documentationProvide:undefined
                                        }
                                    }
                                ],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.scopeApplication == 'THIRD'){
                                    	return false;
                                    }
                                    return true;
                                },
                                wrapper: 'annexaRow'
                            },
                            {
                                key: 'interoperability',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.interoperability',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [ { label: $filter('translate')('global.literals.interoperabilityOk'), value: true }];
                                }]
                            },
                            {
                                key: 'service',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        model.voGenericData = undefined;
                                        model.voModalityDataList = undefined;
                                        model.serviceLabel = undefined;
                                        model.idService = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    label: 'global.literals.servicesWizard',
                                    valueProp: 'codeService',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false,
                                    onSelected: function($item) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyServiceProvidedEvent', {item: $item});
                                    }
                                },
                                controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', 'DialogsFactory', 'ErrorFactory', '$filter', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory, DialogsFactory, ErrorFactory, $filter) {
									DccumentsFactory.getServicesVO().then(function (data) {
										$scope.to.options = data;
										if($scope.model && $scope.model.idService){
											var serv = $linq($scope.to.options).firstOrDefault({name: undefined, voModalityList:[]}, "x => x.id == "+$scope.model.idService);
											if(serv){
												$scope.model.serviceLabel = serv.name;
												$scope.model.service = serv.codeService;
												$scope.fields[7].templateOptions.options = serv.voModalityList;
												if(serv.voModalityList && serv.voModalityList.length > 0 && $scope.model && $scope.model.modality){
													var modality = $linq(serv.voModalityList).firstOrDefault({name: undefined, finalitats:[]}, "x => x.codeModality == '"+$scope.model.modality+"'");
													if(modality){
														$scope.model.modalityLabel = modality.name;
														$scope.fields[8].templateOptions.options = modality.finalitats;
														if(modality.finalitats && modality.finalitats.length > 0 && $scope.model && $scope.model.purpose){
															var purpose = $linq(modality.finalitats).firstOrDefault({name: undefined}, "x => x.code == '"+$scope.model.purpose+"'");
															if(purpose){
																$scope.model.purposeLabel = purpose.name;
															}
														}
													} 
												}
											}
										}
									}).catch(function (error) {
							            	DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'voDocument', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
							        });
                                    $scope.$on('modifyServiceProvidedEvent', function(event, args) {
                                        if(args && args.item && args.item.voModalityList) {
                                        	$scope.model.modality = undefined;
                                        	$scope.model.purpose = undefined;
                                        	$scope.fields[7].templateOptions.options = args.item.voModalityList;
                                        	$scope.model.serviceLabel = args.item.name;
                                        	$scope.model.idService = args.item.id;
                                        }
                                    });
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.interoperability_true === true){
                                    	return false;
                                    }
                                    return true;
                                }
                            },
                            {
                                key: 'modality',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        model.modalityLabel = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    label: 'global.literals.modalitiesWizard',
                                    valueProp: 'codeModality',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false,
                                    onSelected: function($item) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyModalityProvidedEvent', {item: $item});
                                    }
                                },
                                controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {
                                    $scope.$on('modifyModalityProvidedEvent', function(event, args) {
                                        if(args && args.item) {
                                        	$scope.model.purpose = undefined;
                                        	$scope.model.voGenericData = args.item.voGenericData;
                                        	$scope.model.voModalityDataList = args.item.voModalityDataList;
                                        	$scope.model.modalityLabel = args.item.name;
                                        	$scope.fields[8].templateOptions.options = args.item.finalitats;
                                        }
                                    });
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.interoperability_true === true){
                                    	return false;
                                    }
                                    return true;
                                }
                            },
                            {
                                key: 'purpose',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    label: 'global.literals.finalitatsWizard',
                                    valueProp: 'code',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.interoperability_true === true){
                                    	return false;
                                    }
                                    return true;
                                }
                            },
                            {
                                key: 'original',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.originalDocument',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [ { label: $filter('translate')('global.literals.originalDocumentOk'), value: true }];
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        documentationToProvideThirdAdd: {
            title: 'global.literals.applicability',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'thirdType',
                                type: 'annexaSelectRow',
                                className: '',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.tram.literals.thirdType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', '$filter', function($scope, GlobalDataFactory, Language, $filter) {
                                    $scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = angular.copy(GlobalDataFactory.thirdTypes);
									$scope.to.options.unshift({id:"ALL", language1:$filter('translate')('global.literals.all'), language2:$filter('translate')('global.literals.all'), language3:$filter('translate')('global.literals.all')});
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                                key: 'relationType',
                                type: 'annexaSelectRow',
                                className: '',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.relatedDossierRelation',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'RestService', 'apiAdmin', function($scope, RestService, apiAdmin) {
									$scope.options.templateOptions.options = apiAdmin.thirdRelationTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                                key: 'roleInterested',
                                type: 'annexaSelectRow',
                                className: '',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.roleInterested',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'RestService', 'apiAdmin', 'GlobalDataFactory', 'Language', function($scope, RestService, apiAdmin, GlobalDataFactory, Language) {
									 $scope.to.labelProp = Language.getActiveColumn();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'required',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.mandatory',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [ { label: $filter('translate')('global.literals.required'), value: true }];
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
		documentationToProvideBox: {
            title: 'global.tram.literals.documentationToProvide',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
				'		<annexa-box-admin-documentation-to-provide object="modal.data" is-new="true" type="PROCEDURE" documentation-to-provide="modal.documentationToProvide" ></annexa-box-admin-documentation-to-provide>',
                '   </div>',
                '</div>'
            ]
        },
        identificationReferenceTypeNew: {
            title: 'global.commonAdmin.modal.identificationReferenceType.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.literals.codeType',
                                    maxlength:20
                                },
                                data: {
                                    row: true,
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        identificationReferenceTypeEdit: {
            title: 'global.commonAdmin.modal.identificationReferenceType.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateIdentificationReferenceType(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '       <annexa-editable-field type="text" label="global.literals.codeType" model-value="modal.data.code" model-property="code" save="modal.updateIdentificationReferenceType(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        genderNew: {
            title: 'global.commonAdmin.modal.gender.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        genderEdit: {
            title: 'global.commonAdmin.modal.gender.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateGender(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        treatmentNew: {
            title: 'global.commonAdmin.modal.treatment.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        treatmentEdit: {
            title: 'global.commonAdmin.modal.treatment.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateTreatment(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        relationshipLanguageNew: {
            title: 'global.commonAdmin.modal.relationshipLanguage.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        relationshipLanguageEdit: {
            title: 'global.commonAdmin.modal.relationshipLanguage.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateRelationshipLanguage(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        createTransactionAdd: {
            title: 'global.literals.createTransactionType',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'transactionType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.createTransactionType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'assignationTypeTram',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.assignationType',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
									$scope.to.options = [{ id: 'GUIDED', description: 'global.literals.typeTramGUIDED' }, 
										{ id: 'INHERITED', description: 'global.literals.typeTramINHERITED' }, 
										{ id: 'NONGUIDED', description: 'global.literals.typeTramNONGUIDED' }];
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-sm-12 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.profiles',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'GUIDED'){
                                    	return false;
                                    }
                                    return true;
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
                                    $scope.to.onChange = function() {
                                		var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('selectedProfile', { });
                                    }
                                }]
                            },
                            {
                                key: 'users',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-sm-12 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.user',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'GUIDED'){
                                    	return false;
                                    }
                                    return true;
                                },
                                controller:['$scope', 'RestService', '$rootScope', function($scope, RestService, $rootScope) {
                                	$scope.to.labelProp = "value";
                                	$scope.$on('selectedProfile', function(event, args){
                                        if($scope.model.profiles && $scope.model.profiles.length > 0){
                                        	$scope.to.options = [];
                                        	_.forEach($scope.to.users, function(usr) {
                                        		var prof = $linq(usr.user.userProfiles).intersect($scope.model.profiles, function(x,y){
													if(y.profile && y.profile.id && y.profile.id == x){
														return true;
													}else{
														return false;
													}
												}).toArray();
                                            	if(prof && prof.length > 0) {
                                            		$scope.to.options.push(usr);
                                            	}
                                            });
                                        	if($scope.model.users && $scope.model.users.length > 0 && $scope.to.options.length > 0) {
                                        		var selUsr = [];
                                        		_.forEach($scope.to.options, function(option) {
                                        			if($linq($scope.model.users).contains(option.user.id)) {
                                        				selUsr.push(option.user.id);
                                        			}
                                        		});
                                        		$scope.model.users = selUsr;
                                        	}
                                        }else{
                                        	$scope.model.users = [];
                                        	$scope.to.options = [];
                                        }
                                    });
                                	RestService.findAll('User', 'adminCreateTransaction')
	                                    .then(function(data) {
	                                        var users = [];
	
	                                        if(data.data && data.data.length > 0) {
	                                            _.forEach(JSOG.decode(data.data), function(val) {
	                                            	users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
	                                            });
	                                        }
	
	                                        $scope.options.templateOptions.users = users;
	                                        $rootScope.$broadcast('selectedProfile', { });
	                                    });
                                }]
                            },
                            {
                                key: 'useCustomFieldProfile',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'GUIDED'){
                                    	return false;
                                    }
                                    return true;
                                },
                                controller:['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [ { label: $filter('translate')('global.literals.useCustomFieldProfile'), value: true }];
                                }]
                            },
                            {
                                key: 'useCustomFieldUser',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'GUIDED'){
                                    	return false;
                                    }
                                    return true;
                                },
                                controller:['$scope', '$filter', function($scope, $filter) {
                                    $scope.to.options = [ { label: $filter('translate')('global.literals.useCustomFieldUser'), value: true }];
                                }]
                            },
                            {
                                key: 'customFieldProfile',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.customFieldProfile',
                                    valueProp: 'customFieldId',
                                    labelProp: 'literal',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'GUIDED'){
                                    	var customFieldProfileField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == 'customFieldProfile'");
            				            if(customFieldProfileField){
            				            	if(!scope.model.useCustomFieldProfile_true) {
            				            		customFieldProfileField.templateOptions.disabled = true;
            				            		scope.model.customFieldProfile = undefined;
	                                		} else {
	                                			customFieldProfileField.templateOptions.disabled = false;
	                                		}
            				            }
                                    	return false;
                                    }
                                    return true;
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                }
                            },
                            {
                                key: 'customFieldUser',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.customFieldUser',
                                    valueProp: 'customFieldId',
                                    labelProp: 'literal',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'GUIDED'){
                                		var customFieldUserField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == 'customFieldUser'");
            				            if(customFieldUserField){
            				            	if(!scope.model.useCustomFieldUser_true) {
                                				customFieldUserField.templateOptions.disabled = true;
            				            		scope.model.customFieldUser = undefined;
	                                		} else {
                                				customFieldUserField.templateOptions.disabled = false;
	                                		}
            				            }
                                    	return false;
                                    }
                                    return true;
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                }
                            },
                            {
                                key: 'inheritedAssignationTypeTram',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.inheritedFrom',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller:['$scope', 'GlobalDataFactory', 'Language', function($scope, GlobalDataFactory, Language) {
									$scope.to.options = [{ id: 'STARTTRAM', description: 'global.literals.inheritedTypeSTARTTRAM' }, 
										{ id: 'PREVIOUSTRAM', description: 'global.literals.inheritedTypePREVIOUSTRAM' }, 
										{ id: 'DOSSIERRESP', description: 'global.literals.inheritedTypeDOSSIERRESP' }];
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.assignationTypeTram === 'INHERITED'){
                                    	return false;
                                    }
                                    return true;
                                }
                            },
                            {
                                key: 'subprocedures',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    focus: true,
                                    label: 'global.literals.guidedSubprocedureCanStart',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    required: false,
                                    options: []
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $linq(GlobalDataFactory.procedures).where("x => x.procedureType == 'SUBPROCEDURE' || x.procedureType == 'PROCEDURE_SUBPROCEDURE'").orderBy("x => x." + Language.getActiveColumn()).toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'preSpel',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.preCondition',
                                    rows: 5,
									required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            { 
                                key: 'addPreExpression',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.expressionEvaluator',
                                	executeFunction: function() {}
                                }
                            },
                            {
                                key: 'postSpel',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.postCondition',
                                    rows: 5,
									required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            { 
                                key: 'addPostExpression',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.expressionEvaluator',
                                	executeFunction: function() {}
                                }
                            },
                            {
                                key: 'descriptionNoPreSpel',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.tramPreSpelDesciptError',
                                    rows: 5,
									required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'descriptionNoPostSpel',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.tramPostSpelDesciptError',
                                    rows: 5,
									required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
	                            key: 'tramTemplate',
	                            type: 'annexaComponent',
                                className: 'col-sm-12',
	                            templateOptions: {
	                                type: 'annexa-table-tram-templates'
	                            },
	                            data: {
                                    templates: [],
                                    isNew: true,
                                    templatesOptions: [],
                                    procedureId: undefined
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        modalAddCreateTransactionTemplate: {
            title: 'global.commonAdmin.literals.addTemplate',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                        	{
                                key: 'typeSelect',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    onSelected: function($item) {
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('newDocumentTypeSelectedTramTemplate', { item: $item });
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select, to) {
                                    	$event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                        if(to.onSelected) {
                                            to.onSelected(undefined, undefined);
                                        }
                                    }
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                	$scope.to.options = GlobalDataFactory.documentTypes;
                                }]
                        	},
                        	{
                                key: 'tramTemplate',
                                type: 'annexaSelectGroupRow',
                                className: 'col-sm-12 col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.template',
                                    valueProp: 'id',
                                    labelProp: 'literal',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    tramTemplates: []
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
					                    $event.stopPropagation();
					                    model[key] = undefined;
					                    if($select) {
					                        $select.selected = undefined;
					                        $select.search = undefined;
					                    }
					                }
                                },
                                controller:['$scope', 'Language', '$filter', function($scope, Language, $filter) {
                                    var setOptions = function(documentType){
                                        if(documentType && $scope.to.tramTemplates){
                                            $scope.to.options = $linq($scope.form && $scope.to.tramTemplates).where("x => !x.documentType || !x.documentType.id || x.documentType.id == "+documentType).toArray();
                                            if($scope.model.tramTemplate && !$linq($scope.to.options).contains($scope.model.tramTemplate, "(x, y) => x.id == y")){
                                            	$scope.model.tramTemplate =	undefined;
                                            }
                                        }else{
                                        	$scope.to.options = $scope.to.tramTemplates;
                                        }
                                    }
                                    $scope.$on('newDocumentTypeSelectedTramTemplate', function(event, args){ 
                                            if(args && args.item && args.item.id){
                                                setOptions(args.item.id);
                                            }else{
                                            	$scope.to.options = $scope.to.tramTemplates;
                                            }
                                        }
                                    )
                                    setOptions($scope.to.typeSelect);
                                	$scope.to.groupFunction = function(item){
                                		if(item.documentType && item.documentType.id){
                                			return $filter('translate')("global.tram.literals.templatesOf")+" "+item.documentType[Language.getActiveColumn()];
                                		}else{
                                			return $filter('translate')("global.tram.literals.templateGeneric");
                                		}
                                	}
                                	$scope.to.orderByFunction = function(item){
                                		if(item.documentType){
                                			return "[ !"+item.documentType+", -"+item.documentType+" ]";
                                		}else{
                                			return null;
                                		}
                                	}
                                	$scope.$on('showSpecificTemplates', function(event, args){ 
	                                        if(args && args.onlySpecificTemplate) {
	                                        	var newOptions = [];
	                                        	var exist = false;
	                                        	_.forEach(tram_templates, function (value, key) {
	                                                if(transaction && transaction.dossier && transaction.dossier.procedure && value.procedures 
	                                                		&& $linq(value.procedures).contains(transaction.dossier.procedure, "(x, y) => x.id == y.id")) {
	                                                	if($scope.model.tramTemplate == value.id) {
	                                                		exist = true;
	                                                	}
	                                                	newOptions.push(value);
	                                                }
	                                            });
	                                        	if(!exist) {
	                                        		$scope.model.tramTemplate =	undefined;
	                                        	}
	                                        	$scope.to.options = newOptions;
	                                        } else {
	                                        	$scope.to.options = form.tramTemplates;
	                                        }
	                                    }
	                                )
                                }]
                        	},
                            {
                                key: 'spel',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12 col-xs-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.condition',
                                    rows: 5,
                                    required: false,
                                    focus: false
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                }
                            },
                            { 
                                key: 'executeSpel',
                                type: 'annexaLabelButton',
                                className: 'col-sm-4',
                                templateOptions: {
                                	buttonLabel: 'global.literals.expressionEvaluator',
                                	executeFunction: function() {}
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
		modalAddCreateTransactionTemplateBox: {
            title: 'global.tram.literals.transactionTemplates',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
	                            key: 'tramTemplate',
	                            type: 'annexaComponent',
                                className: 'col-sm-12',
	                            templateOptions: {
	                                type: 'annexa-table-tram-templates'
	                            },
	                            data: {
                                    templates: [],
                                    isNew: true,
                                    templatesOptions: [],
                                    procedureId: undefined
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        addConfigurationTypeSequence: {
            title: 'global.configuration.numbersConfiguration.addObject',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'objectId',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.configuration.numbersConfiguration.objectId',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'seqObject',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.seqObject',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        addConfigurationYearSequence: {
            title: 'global.configuration.numbersConfiguration.addYear',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                            	key: 'year',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.year',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'seqYear',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.seqYear',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
		addConfigurationDocumentsCreate: {
		    title: 'global.configuration.create_document_from_template_properties.add',
		    size: '',
		    annexaFormly: {
		        fields: [
		            {
		                key: 'modal_body',
		                className: 'modal-body p-lg',
		                fieldGroup: [
		                	{
		                    	key: 'reportCode',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'text',
		                            label: 'global.configuration.create_document_from_template_properties.reportCode'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'template',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'number',
		                            label: 'global.configuration.create_document_from_template_properties.template',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'name',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'text',
		                            label: 'global.configuration.create_document_from_template_properties.name'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
							{
		                    	key: 'sectionId',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'number',
		                            label: 'global.configuration.create_document_from_template_properties.sectionId',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'profileIds',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'text',
		                            label: 'global.configuration.create_document_from_template_properties.profileIds'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                        key: 'saveData',
		                        type: 'annexaSelectRow',
		                        className: 'col-xs-12',
		                        templateOptions: {
		                            optionsAttr: 'bs-options',
		                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                            label: 'global.configuration.create_document_from_template_properties.saveData',
		                            valueProp: 'id',
		                            labelProp: 'description',
		                            placeholder: '',
		                            options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                            required: true
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-xs-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'nameAttachment',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.create_document_from_template_properties.nameAttachment'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    }
		                ]
		            }
		        ]
		    },
		    submitModal: function () {
		    }
		},
		
		addRegisterEntryCreateDiligence: {
		    title: 'global.configuration.create_document_from_template_properties.add',
		    size: '',
		    annexaFormly: {
		        fields: [
		            {
		                key: 'modal_body',
		                className: 'modal-body p-lg',
		                fieldGroup: [
		                    {
		                        key: 'registerEntryChannelCode',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.register_entry_create_diligence.registerEntryChannelCode'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                        key: 'registerEntryOriginRegisterOrgan',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.register_entry_create_diligence.registerEntryOriginRegisterOrgan',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                        key: 'definitionDiligence.typeCode',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.register_entry_create_diligence.typeCode'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                        key: 'definitionDiligence.profileAcronym',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.register_entry_create_diligence.profileAcronym',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                        key: 'definitionDiligence.responsibleUser',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.register_entry_create_diligence.responsibleUser'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                        key: 'definitionDiligence.state',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'text',
		                            label: 'global.configuration.register_entry_create_diligence.state'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    }
		                ]
		            }
		        ]
		    },
		    submitModal: function () {
		    }
		},
		
		addConfigurationDocumentsSendToSign: {
		    title: 'global.configuration.send_to_sign_documents_properties.add',
		    size: '',
		    annexaFormly: {
		        fields: [
		            {
		                key: 'modal_body',
		                className: 'modal-body p-lg',
		                fieldGroup: [
		                	{
		                    	key: 'documentTypeCode',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'text',
		                            label: 'global.configuration.send_to_sign_documents_properties.documentTypeCode'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'presetSignCircuit',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: false,
		                            type: 'number',
		                            label: 'global.configuration.send_to_sign_documents_properties.presetSignCircuit',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'userIds',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'text',
		                            label: 'global.configuration.send_to_sign_documents_properties.userIds'
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
							{
		                    	key: 'signActionType',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'number',
		                            label: 'global.configuration.send_to_sign_documents_properties.signActionType',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'signatoryType',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'number',
		                            label: 'global.configuration.send_to_sign_documents_properties.signatoryType',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    },
		                    {
		                    	key: 'maxDays',
		                        type: 'annexaInputRow',
		                        className: 'col-lg-12',
		                        templateOptions: {
		                            required: true,
		                            type: 'number',
		                            label: 'global.configuration.send_to_sign_documents_properties.maxDays',
		                            maxlength: 20
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-lg-12',
		                            labelClass: 'label-strong'
		                        }
		                    }
		                ]
		            }
		        ]
		    },
		    submitModal: function () {
		    }
		},
        addConfigurationDecreeDocument: {
            title: 'global.configuration.decree_document_type_custom_field.addData',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                            	key: 'id',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.decree_document_type_custom_field.id',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'extract',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.configuration.decree_document_type_custom_field.extract'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'number',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.configuration.decree_document_type_custom_field.number'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'date',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.configuration.decree_document_type_custom_field.date'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'tipusDelegat',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.configuration.decree_document_type_custom_field.tipusDelegat'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'motiuAnulacio',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.configuration.decree_document_type_custom_field.motiuAnulacio'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                            	key: 'dataAnulacio',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: false,
                                    type: 'text',
                                    label: 'global.configuration.decree_document_type_custom_field.dataAnulacio'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        addConfigurationAllSequence: {
            title: 'global.configuration.numbersConfiguration.addAll',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                            	key: 'all',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.seqAll',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        addLdapConnections: {
        	title: 'global.configuration.ldap_connections.addSearch',
        	size: '',
        	annexaFormly: {
        		fields: [
        			{
        				key: 'modal_body',
        				className: 'modal-body p-lg',
        				fieldGroup: [
        					{
        						key: 'userSearchBase',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.ldap_connections.userSearchBase',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'userSearchFilter',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.ldap_connections.userSearchFilter',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'url',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.ldap_connections.url',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'port',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'number',
        							label: 'global.configuration.ldap_connections.port',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'managerDn',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.ldap_connections.managerDn',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'managerPassword',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.ldap_connections.managerPassword',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        				]
        			}
        		]
        	},
        	submitModal: function () {
        	}
        },
        
        //----//
        addCreateNotificationStates: {
        	title: 'global.configuration.create_notifications_states.states',
        	size: '',
        	annexaFormly: {
        		fields: [
        			{
        				key: 'modal_body',
        				className: 'modal-body p-lg',
        				fieldGroup: [
        					{
        						key: 'stateId',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.create_notifications_states.stateId',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'stateIdName',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.create_notifications_states.stateIdName',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'stateDescription',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.create_notifications_states.stateDescription',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					}
        				]
        			}
        		]
        	},
        	submitModal: function () {
        	}
        },
        addCreateNotificationObservationStates: {
        	title: 'global.configuration.create_notifications_states.observationStates',
        	size: '',
        	annexaFormly: {
        		fields: [
        			{
        				key: 'modal_body',
        				className: 'modal-body p-lg',
        				fieldGroup: [
        					{
        						key: 'state',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.create_notifications_states.state',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
        						key: 'literal',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'text',
        							label: 'global.configuration.create_notifications_states.literal',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
        					},
        					{
		                        key: 'showServiceId',
		                        type: 'annexaSelectRow',
		                        className: 'col-xs-12',
		                        templateOptions: {
		                            optionsAttr: 'bs-options',
		                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                            label: 'global.configuration.create_notifications_states.showServiceId',
		                            valueProp: 'id',
		                            labelProp: 'description',
		                            placeholder: '',
		                            options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
		                            required: true
		                        },
		                        data: {
		                            row: true,
		                            informed: true,
		                            colClass: ' col-xs-12',
		                            labelClass: 'label-strong'
		                        }
		                    }
        				]
        			}
        		]
        	},
        	submitModal: function () {
        	}
        },
        editCreateNotificationOtherStates: {
        	title: 'global.configuration.create_notifications_states.otherStates',
        	size: '',
        	annexaFormly: {
        		fields: [
        			{
        				key: 'modal_body',
        				className: 'modal-body p-lg',
        				fieldGroup: [
        					 {
 		                    	key: 'errorStates',
 		                        type: 'annexaInputRow',
 		                        className: 'col-lg-12',
 		                        templateOptions: {
 		                            required: true,
 		                            type: 'text',
 		                            label: 'global.configuration.create_notifications_states.errorStatesExplanation'
 		                        },
 		                        data: {
 		                            row: true,
 		                            informed: true,
 		                            colClass: ' col-lg-12',
 		                            labelClass: 'label-strong'
 		                        }
 		                    },
        					{
        						key: 'maxErrorsCall',
        						type: 'annexaInputRow',
        						className: 'col-lg-12',
        						templateOptions: {
        							required: true,
        							type: 'number',
        							label: 'global.configuration.create_notifications_states.maxErrorsCall',
        							maxlength: 20
        						},
        						data: {
        							row: true,
        							informed: true,
        							colClass: 'col-lg-12',
        							labelClass: 'label-strong',
        						}
		                    }
        				]
        			}
        		]
        	},
        	submitModal: function () {
        	}
        },
        //----//
        addConfigurationTypeSequenceDecreePlugin: {
            title: 'global.configuration.numbersConfiguration.addObject',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'idDocumentType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.configuration.numbersConfiguration.objectId',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'year',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.configuration.numbersConfiguration.year'
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'seq',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.seqObject',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        addConfigurationTypeSequenceRegisterEntry: {
            title: 'global.configuration.numbersConfiguration.addObject',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'id',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.configuration.numbersConfiguration.objectId',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'yearIn',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.yearIn',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'seqIn',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.seqIn',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'yearOut',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.yearOut',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            },
							{
                            	key: 'seqOut',
                                type: 'annexaInputRow',
                                className: 'col-lg-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.configuration.numbersConfiguration.seqOut',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-lg-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        automatismNew:{
        	title: 'global.querys.automatisms.titleEdit',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-lg-4',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'description',
                                type: 'annexaInputRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.description'
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'active',
                                type: 'annexaSelectRow',
                                className: 'col-sm-2',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.active',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'query',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 b-t p-t-sm',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.query',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyQueryFromAutomatism', { item: $item });
                                    }
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter, AdminFactory) {
                                	$scope.$on('modifyQueryFromAutomatism', function(event, args) {
                                    	if(args && args.item && args.item.id !== $scope.model.queryOld){
                                    		$scope.model.queryParametersShow = false;
                                    		$scope.model.queryColumnsTypesShow = false;
                                    		$scope.model.queryOld = args.item.id;
                                    		var queryParametersField = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'queryParameters'");
                                    		var columnsTypesField = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'columnsTypes'");
                                    		if(queryParametersField && queryParametersField.fieldGroup){
                                    			queryParametersField.fieldGroup.length = 0;
                                    			$scope.model.queryParameters = {};
                                    		}
                                    		if(columnsTypesField && columnsTypesField.fieldGroup){
                                    			columnsTypesField.fieldGroup.length = 0;
                                    			$scope.model.columnsTypes = {};
                                    		}
                                    		AdminFactory.updateQueryParamsFields(queryParametersField, $scope.model).then(function(data) {
                                    			$scope.model.queryParametersShow = true;
                                    		}).catch(function() {
                                        		queryParametersField.fieldGroup.length = 0;
                                        		queryParametersField.fieldGroup.push({
                                                    key: 'elementLabel',
                                                    type: 'annexaLabelRow',
                                                    className: '',
                                                    templateOptions: {
                                                        label: '',
                                                        value: ''
                                                    },
                                                    data: {
                                                        informed: true,
                                                        row: true,
                                                        colClass: ' col-sm-12',
                                                        labelClass: 'label-strong text',
                                                        breakLine: true
                                                    },
                                                    controller: function ($scope, $filter) {
                                                        $scope.to.label = $filter("translate")("global.querys.automatisms.downloadQueryParamsError");
                                                    }
                                            	});
                                    			$scope.model.queryParametersShow = true;
	                                        });
                                    		AdminFactory.updateQueryColumnsFields(columnsTypesField, $scope.model).then(function(data) {
                                        		$scope.model.queryColumnsTypesShow = true;
                                    		}).catch(function() {
                                        		columnsTypesField.fieldGroup.length = 0;
                                        		columnsTypesField.fieldGroup.push({
                                                    key: 'elementLabel',
                                                    type: 'annexaLabelRow',
                                                    className: '',
                                                    templateOptions: {
                                                        label: '',
                                                        value: ''
                                                    },
                                                    data: {
                                                        informed: true,
                                                        row: true,
                                                        colClass: ' col-sm-12',
                                                        labelClass: 'label-strong text',
                                                        breakLine: true
                                                    },
                                                    controller: function ($scope, $filter) {
                                                        $scope.to.label = $filter("translate")("global.querys.automatisms.downloadQueryColumnsError");
                                                    }
                                            	});
                                        		$scope.model.queryColumnsTypesShow = true;
	                                        });
                                    	}
                                    });
                                }
                            },
                            {
                                key: 'queryParametersLabel',
                                type: 'annexaLabelRow',
                                className: '',
                                templateOptions: {
                                    label: '',
                                    value: ''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong text',
                                    breakLine: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.querys.automatisms.downloadQueryParams");
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
    								if(scope.model && scope.model.query && !scope.model.queryParametersShow){
    									return false;
                                    }else{
    	                                return true;
    								}
                                }
                        	},
    						{
    	                        key: 'queryParameters',
    	                        className: '',
    	                        templateOptions: {},
    	                        fieldGroup: [
    							],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.query && scope.model.queryParametersShow){
    									return false;
                                    }else{
    	                                return true;
    								}
                                }
    						},
                            {
                                key: 'columnsTypesLabel',
                                type: 'annexaLabelRow',
                                className: '',
                                templateOptions: {
                                    label: '',
                                    value: ''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong text',
                                    breakLine: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.querys.automatisms.downloadQueryColumns");
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
    								if(scope.model && scope.model.query && !scope.model.queryColumnsTypesShow){
    									return false;
                                    }else{
    	                                return true;
    								}
                                }
                        	},
    						{
    	                        key: 'columnsTypes',
    	                        className: '',
    	                        templateOptions: {},
    	                        fieldGroup: [
    							],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.query && scope.model.queryColumnsTypesShow){
    									return false;
                                    }else{
    	                                return true;
    								}
                                }
    						},
                            {
                                key: 'actionCatalog',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 b-t p-t-sm',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.querys.automatisms.actionCatalog',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyActionCatalogFromAutomatism', { item: $item });
                                    }
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter, AdminFactory) {
                                	$scope.$on('modifyActionCatalogFromAutomatism', function(event, args) {
                                    	if(args && args.item && args.item.id !== $scope.model.actionCatalogOld){
                                    		$scope.model.actionCatalogParametersShow = false;
                                    		$scope.model.actionCatalogOld = args.item.id;
                                    		$scope.model.dossierCustomFieldsOrig = [];
                                    		$scope.model.dossierCustomFields = {};
                                    		$scope.model.dossierTransactionCustomFieldsOrig = [];
                                    		$scope.model.dossierTransactionCustomFields = {};
                                    		$scope.model.documentCustomFieldsOrig = [];
                                    		$scope.model.documentCustomFields = {};
                                    		var automatismActionparametersField = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'automatismActionparameters'");
                                    		if(automatismActionparametersField && automatismActionparametersField.fieldGroup){
                                    			automatismActionparametersField.fieldGroup.length = 0;
                                    			$scope.model.automatismActionparametersField = {};
                                    		}
                                    		AdminFactory.updateAutomatismActionFields(automatismActionparametersField, $scope.model).then(function(data) {
                                    			$scope.model.actionCatalogParametersShow = true;
                                    		}).catch(function() {
                                    			automatismActionparametersField.fieldGroup.length = 0;
                                    			automatismActionparametersField.fieldGroup.push({
                                                    key: 'elementLabel',
                                                    type: 'annexaLabelRow',
                                                    className: '',
                                                    templateOptions: {
                                                        label: '',
                                                        value: ''
                                                    },
                                                    data: {
                                                        informed: true,
                                                        row: true,
                                                        colClass: ' col-sm-12',
                                                        labelClass: 'label-strong text',
                                                        breakLine: true
                                                    },
                                                    controller: function ($scope, $filter) {
                                                        $scope.to.label = $filter("translate")("global.querys.automatisms.downloadActionCatalogParamsError");
                                                    }
                                            	});
                                    			$scope.model.actionCatalogParametersShow = true;
	                                        });
                                    	}
                                    });
                                }
                            },
                            {
                                key: 'objectIdProc',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 b-t p-t-sm',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.procedure',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyActionCatalogObjectIdProd', { item: $item });
                                    }
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter, Language, GlobalDataFactory, AdminFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = GlobalDataFactory.procedures;
									$scope.$on('modifyActionCatalogObjectIdProd', function(event, args) {
                                    	if(args && args.item && args.item.id !== $scope.model.objectIdProcOld){
                                    		$scope.model.dossierCustomFieldsShow = false;
                                    		$scope.model.dossierTransactionCustomFieldsShow = false;
                                    		$scope.model.dossierCustomFieldsOrig = [];
                                    		$scope.model.dossierCustomFields = {};
                                    		$scope.model.dossierTransactionCustomFieldsOrig = [];
                                    		$scope.model.dossierTransactionCustomFields = {};
                                    		$scope.model.documentCustomFieldsOrig = [];
                                    		$scope.model.documentCustomFields = {};
                	    					$scope.model.objectIdProcOld = args.item.id;
                                    		var dossierCustomFieldsField = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'dossierCustomFields'");
                                    		if(dossierCustomFieldsField && dossierCustomFieldsField.fieldGroup){
                                    			dossierCustomFieldsField.fieldGroup.length = 0;
                                    		}
                                    		var dossierTransactionCustomFieldsField = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'dossierTransactionCustomFields'");
                                    		if(dossierTransactionCustomFieldsField && dossierTransactionCustomFieldsField.fieldGroup){
                                    			dossierTransactionCustomFieldsField.fieldGroup.length = 0;
                                    		}
                                    		AdminFactory.updateAutomatismCustomFields(dossierCustomFieldsField, dossierTransactionCustomFieldsField, $scope.model).then(function(data) {
                                    			if(data.haveDossierCustomFields){
                                    				$scope.model.dossierCustomFieldsShow = true;
                                    			}
                                    			if(data.haveDossierTransactionCustomFields){
                                    				$scope.model.dossierTransactionCustomFieldsShow = true;
                                    			}
                                    		}).catch(function() {
                                    			dossierCustomFieldsField.fieldGroup.length = 0;
                                    			dossierTransactionCustomFieldsField.fieldGroup.length = 0;
	                                        });
                                    	}
                                    });
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.actionCatalog){
                                		var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.model.actionCatalog);
                                			if(sd && (sd.acronym === 'startDossier' || sd.acronym === 'updateDossier')){
                                				return false;
                                			}else{
                                				scope.model.objectProcId = undefined;
                                    			return true;
                                			}
                                		}else{
                                			scope.model.objectProcId = undefined;
                                			return true;
                                		}
                                    }else{
                                    	scope.model.objectProcId = undefined;
    	                                return true;
    								}
                                }
                            },
                            {
                                key: 'origType',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 b-t p-t-sm',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.origin',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyActionCatalogOrigType', { item: $item });
                                    }
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter, Language, GlobalDataFactory) {
									$scope.to.options = GlobalDataFactory.automatismInitDossierTypes;
									$scope.$on('modifyActionCatalogOrigType', function(event, args) {
										if($scope.model && $scope.model.automatismActionparameters && args && args.item){
											$scope.model.automatismActionparameters.auxParamChangeEventAux = ((args.item.id)?args.item.id:args.item);
										}
									});
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.actionCatalog){
                                		var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.model.actionCatalog);
                                			if(sd && sd.acronym === 'startDossier'){
                                				return false;
                                			}else{
                                				scope.model.origType = undefined;
                                    			return true;
                                			}
                                		}else{
                                			scope.model.origType = undefined;
                                			return true;
                                		}
                                    }else{
                                    	scope.model.origType = undefined;
    	                                return true;
    								}
                                }
                            },
                            {
                                key: 'objectIdTramTemplate',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12 b-t p-t-sm',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.template',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyActionCatalogObjectIdTramTemplate', { item: $item });
                                    }
                                },
								data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter, Language, GlobalDataFactory, AdminFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
									$scope.to.options = AdminFactory.tramTemplates;
									$scope.$on('modifyActionCatalogObjectIdTramTemplate', function(event, args) {
                                    	if(args && args.item && args.item.id !== $scope.model.objectIdTramTemplateOld){
                                    		$scope.model.documentCustomFieldsShow = false;
                                    		$scope.model.objectIdTramTemplateOld = args.item.id;
                                    		$scope.model.dossierCustomFieldsOrig = [];
                                    		$scope.model.dossierCustomFields = {};
                                    		$scope.model.dossierTransactionCustomFieldsOrig = [];
                                    		$scope.model.dossierTransactionCustomFields = {};
                                    		$scope.model.documentCustomFieldsOrig = [];
                                    		$scope.model.documentCustomFields = {};
                	    					var documentCustomFieldsField = $linq($scope.fields).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
                                    		if(documentCustomFieldsField && documentCustomFieldsField.fieldGroup){
                                    			documentCustomFieldsField.fieldGroup.length = 0;
                                    		}
                                    		AdminFactory.updateAutomatismDocumentCustomFields(documentCustomFieldsField, $scope.model).then(function(data) {
                                    			if(data.haveDocumentCustomFields){
                                    				$scope.model.documentCustomFieldsShow = true;
                                    			}
                                    		}).catch(function() {
                                    			documentCustomFieldsField.fieldGroup.length = 0;
	                                        });
                                    	}
                                    });
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.actionCatalog){
                                		var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.model.actionCatalog);
                                			if(sd && sd.acronym === 'generateDocumentFromTramTemplate'){
                                				return false;
                                			}else{
                                				scope.model.objectTramTemplateId = undefined;
                                    			return true;
                                			}
                                		}else{
                                			scope.model.objectTramTemplateId = undefined;
                                			return true;
                                		}
                                    }else{
                                    	scope.model.objectTramTemplateId = undefined;
    	                                return true;
    								}
                                }
                            },
                            {
                                key: 'automatismActionparametersLabel',
                                type: 'annexaLabelRow',
                                className: '',
                                templateOptions: {
                                    label: '',
                                    value: ''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong text',
                                    breakLine: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.querys.automatisms.downloadActionCatalogParams");
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
    								if(scope.model && scope.model.actionCatalog && !scope.model.actionCatalogParametersShow){
    									return false;
                                    }else{
    	                                return true;
    								}
                                }
                        	},
    						{
    	                        key: 'automatismActionparameters',
    	                        className: '',
    	                        templateOptions: {},
    	                        fieldGroup: [
    							],
                                hideExpression: function ($viewValue, $modelValue, scope) {
    								if(scope.model && scope.model.actionCatalog && scope.model.actionCatalogParametersShow){
    									return false;
                                    }else{
    	                                return true;
    								}
                                }
    						},
    						{
    	                        key: 'dossierCustomFields',
    	                        className: '',
    	                        templateOptions: {},
    	                        fieldGroup: [
    							],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.actionCatalog){
                                		var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.model.actionCatalog);
                                			if(sd && (sd.acronym === 'startDossier' || sd.acronym === 'updateDossier') && scope.model.objectIdProc && scope.model.dossierCustomFieldsShow){
                                				return false;
                                			}else{
                                				scope.model.origType = undefined;
                                    			return true;
                                			}
                                		}else{
                                			scope.model.origType = undefined;
                                			return true;
                                		}
                                    }else{
                                    	scope.model.origType = undefined;
    	                                return true;
    								}
                                }
    						},
    						{
    	                        key: 'dossierTransactionCustomFields',
    	                        className: '',
    	                        templateOptions: {},
    	                        fieldGroup: [
    							],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.actionCatalog){
                                		var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.model.actionCatalog);
                                			if(sd && sd.acronym === 'startDossier' && scope.model.objectIdProc && scope.model.dossierTransactionCustomFieldsShow){
                                				return false;
                                			}else{
                                				scope.model.origType = undefined;
                                    			return true;
                                			}
                                		}else{
                                			scope.model.origType = undefined;
                                			return true;
                                		}
                                    }else{
                                    	scope.model.origType = undefined;
    	                                return true;
    								}
                                }
    						},
    						{
    	                        key: 'documentCustomFields',
    	                        className: '',
    	                        templateOptions: {},
    	                        fieldGroup: [
    							],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model && scope.model.actionCatalog){
                                		var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.model.actionCatalog);
                                			if(sd && sd.acronym === 'generateDocumentFromTramTemplate' && scope.model.objectIdTramTemplate && scope.model.documentCustomFieldsShow){
                                				return false;
                                			}else{
                                				if(!sd || sd.acronym !== 'startDossier'){
                                					scope.model.origType = undefined;
                                				}
                                    			return true;
                                			}
                                		}else{
                                			scope.model.origType = undefined;
                                			return true;
                                		}
                                    }else{
                                    	scope.model.origType = undefined;
    	                                return true;
    								}
                                }
    						},
                            {
                            	key: 'relateds',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-automatism-related'
                                },
                                data: {
                                	isNew: true,
                                	relateds:[]
                                }
                            },
                            {
                                key: 'cronExpression',
                                type: 'annexaComponent',
                                className: 'col-sm-12 b-t p-t-sm',
                                templateOptions: {
                                	type: 'cron-gen',
                                	cronOptions: {},
                                	isCronDisabled: false
                                },
                                data: {
                                	cronExpression: ''
                                },
                                controller: ['$scope', 'Language', function($scope, Language) {
                                	$scope.to.cronOptions = {
                                    	hideMinutesTab: false,
                                    	hideAdvancedTab: false,
                                    	use24HourTime: true,
                                    	language: Language.getActiveLang()	
                                	};
                                	if ($scope.model.cronExpression) {
                                		$scope.options.data.cronExpression = $scope.model.cronExpression;
                                	} else {
                                		$scope.options.data.cronExpression = '0 0 8 ? * MON *';
                                	}
                                }]
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        publishSiteNew: {
            title: 'global.publishSite.literals.newPublishSite',
            size: 'modal-md',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        editPublishSite: {
            title: 'global.publishSite.literals.editPublishSite',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updatePublishSite(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        addLinkedValueCustomField:{
        	title: 'global.literals.addLinkedValue',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-lg-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'parentValue',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.linkedCustomFieldValue',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope,Language) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        addTreeValueCustomField:{
        	title: 'global.literals.addTreeValue',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-lg-12',
                                validation: {
                                    show: true
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        customFieldsParent: {
            title: 'global.literals.customFields',
            size: 'modal-lg',
            icon: 'fa fa-tasks',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg', 
                        fieldGroup: [
                        	{
                                key: 'customFieldLabel',
                                type: 'annexaLabelRowEdit',
                                className: 'col-xs-9',
                                templateOptions: {
                                    label: '',
                                    value: '',
                                    canEdit: true,
                                    editObject: function(){}
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong text',
                                    breakLine: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.literals.customFieldName");
                                }
                        	},
  	                      	{
                                key: 'nameLanguage1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-9',
                                templateOptions: {
                                    id:'nameLanguage',
                                    modelField:'nameLanguage',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
  	                      	{
  	                          key: 'canPublish',
  	                          type: 'annexaSelectRow',
  	                          className: 'col-xs-3 daughter-label-strong',
  	                          data:{
  	                        	  row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
  	                                  $event.stopPropagation();
  	                                  model[key] = undefined;
  	                                  if($select) {
  	                                      $select.selected = undefined;
  	                                      $select.search = undefined;
  	                                  }
  	                              }
  	                          },
  	                          templateOptions: {
  	                        	  optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.canPublish',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
  	                          }
  	                      	},
                        	{
	                          key: 'requiredString',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-9 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                              optionsAttr: 'bs-options',
	                              ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                              label: 'global.literals.requiredCustomField',
	                              valueProp: 'id',
	                              labelProp: 'description',
	                              placeholder: '',
	                              options: [],
	                              required:true
	                          }
	                      },
	                      {
	                          key: 'conditional',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-3 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                        	  optionsAttr: 'bs-options',
                                  ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                  label: 'global.literals.conditional',
                                  valueProp: 'id',
                                  labelProp: 'description',
                                  placeholder: '',
                                  options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                  required: true
	                          }
	                      },
                          {
                              key: 'spel',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.condition',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.conditional && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
        	                          showList = false;
    	                          }else if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;

                              }
                          },
                          { 
                              key: 'executeSpel',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                                buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
                            	  if(scope.model.conditional && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
        	                          showList = false;
    	                          }else if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          {
                              key: 'spelDossier',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.conditionDossier',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
    	                              showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          { 
                              key: 'executeSpelDossier',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                              	buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
	                                  showList = false;
	                              }
    	                          return showList;
                              }
                          },
                          {
	                          key: 'customFieldType',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-12 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                              optionsAttr: 'bs-options',
	                              ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                              label: 'global.literals.type',
	                              valueProp: 'id',
	                              labelProp: 'description',
	                              placeholder: '',
	                              options: [],
	                              required: true
	                          },
	                          hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue){
	                                  showList = false;
	                              }
	                              return showList;
	                          }
	                      },
                          {
                              key: 'transactionTypesAux',
                              type: 'annexaMultipleSelectRow',
                              className: 'col-xs-12',
                              templateOptions: {
                                  optionsAttr: 'bs-options',
                                  ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                  label: 'global.literals.transactionType',
                                  valueProp: 'id',
                                  labelProp: 'language1',
                                  placeholder: '',
                                  options: [],
                                  required: true
                              },
                              controller:['$scope', 'Language', 'AdminFactory', 'GlobalDataFactory', function($scope, Language, AdminFactory, GlobalDataFactory) {
                                  $scope.to.labelProp = Language.getActiveColumn();
                                  $scope.to.options = GlobalDataFactory.transactiontypes;
                              }],
                              data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong'
                              },
	                          hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && (scope.model.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE' || scope.model.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' || scope.model.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' || scope.model.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY')){
	                                  showList = false;
	                              }
	                              return showList;
	                          }
	                      },
	                      {
	                    	  key: 'descriptionLanguage1',
	                    	  type: 'annexaTextAreaLanguageCustomFields',
	                    	  className: 'px-lg-nt',
	                    	  validation: {
	                    		  show: true
	                    	  },
	                    	  templateOptions: {
	                    		  id:'descriptionLanguage',
	                    		  modelField:'descriptionLanguage',
	                    		  type: 'text',
	                    		  label: 'global.literals.descriptionCustomField',
	                    		  disposition:'horitzontal',
	                    		  required: false,
	                    		  focus: false
	                    	  },
	                    	  controller: function ($scope, $rootScope) {
	                    		  $scope.languages = $rootScope.app.languagedef;
	                    	  }
	                      },
  						  {
	                    	  key: 'queryParams',
	                    	  className: '',
	                    	  templateOptions: {},
	                    	  fieldGroup: [
                    		  ],
	                    	  hideExpression: function ($viewValue, $modelValue, scope) {
                              	if(scope.model && scope.model.customField && scope.model.customField.fromQuery && scope.model.customField.query && scope.model.customField.query.id && scope.model.customField.query.parameters && scope.model.customField.query.parameters.length > 0){
  									return false;
                                  }else{
  	                                return true;
  								}
	                    	  }
  						  },
  						  {
	                    	  key: 'columnsQuery',
	                    	  className: '',
	                    	  templateOptions: {},
	                    	  fieldGroup: [
	                    		  {
	    	                          key: 'identifier',
	    	                          type: 'annexaSelectRow',
	    	                          className: 'col-xs-3 daughter-label-strong',
	    	                          data:{
	    	                        	  row: true,
	                                      informed: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong',
	                                      clear: function($event,model,key, $select) {
	    	                                  $event.stopPropagation();
	    	                                  model[key] = undefined;
	    	                                  if($select) {
	    	                                      $select.selected = undefined;
	    	                                      $select.search = undefined;
	    	                                  }
	    	                              }
	    	                          },
	    	                          templateOptions: {
	    	                        	  optionsAttr: 'bs-options',
	                                      ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                      label: 'global.literals.identifier',
	                                      valueProp: 'id',
	                                      labelProp: 'description',
	                                      placeholder: '',
	                                      options: [],
	                                      required: true
	    	                          }
	    	                      },
	                              {
	                                  key: 'labelProp',
	                                  type: 'annexaMultipleSelectRow',
	                                  className: 'col-xs-6',
	                                  templateOptions: {
	                                      optionsAttr: 'bs-options',
	                                      ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                      label: 'global.literals.labelFields',
	                                      valueProp: 'id',
	                                      labelProp: 'description',
	                                      placeholder: '',
	                                      options: [],
	                                      required: true
	                                  },
	                                  data: {
	                                      row: true,
	                                      informed: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong'
	                                  }
	    	                      },
	    	                      {
	                                  key: 'labelFieldsSeparator',
	                                  type: 'annexaInputRow',
	                                  className: 'col-sm-3',
	                                  templateOptions: {
	                                      required: false,
	                                      type: 'text',
	                                      label: 'global.literals.labelFieldsSeparator',
	                                      maxlength: 20
	                                  },
	                                  data: {
	                                      row: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong'
	                                  }
	                              },
	                              {
	    	                          key: 'parent',
	    	                          type: 'annexaSelectRow',
	    	                          className: 'col-xs-12 daughter-label-strong',
	    	                          data:{
	    	                        	  row: true,
	                                      informed: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong',
	                                      clear: function($event,model,key, $select) {
	    	                                  $event.stopPropagation();
	    	                                  model[key] = undefined;
	    	                                  if($select) {
	    	                                      $select.selected = undefined;
	    	                                      $select.search = undefined;
	    	                                  }
	    	                              }
	    	                          },
	    	                          templateOptions: {
	    	                        	  optionsAttr: 'bs-options',
	                                      ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                      label: 'global.literals.father',
	                                      valueProp: 'id',
	                                      labelProp: 'description',
	                                      placeholder: '',
	                                      options: [],
	                                      required: true
	    	                          },
	                                  hideExpression:function($viewValue, $modelValue, scope){
	                                	  var showList = true;
	                                	  if(!scope.modifyCFFromModal && scope.$parent.model.customField && scope.$parent.model.customField.frontendType === 'STRUCTURAL_SELECT'){
    	    	                              showList = false;
	                                	  }
	        	                          return showList;
	                                  }
	    	                      }
	                    	  ],
	                    	  hideExpression: function ($viewValue, $modelValue, scope) {
                              	if(scope.model && scope.model.customField && scope.model.customField.fromQuery && scope.model.customField.query && scope.model.customField.query.id){
  									return false;
                                  }else{
  	                                return true;
  								}
	                    	  }
  						  },
  						  {
	                    	  key: 'relatedCustomFields',
	                    	  className: '',
	                    	  templateOptions: {},
	                    	  fieldGroup: [
                    		  ],
	                    	  hideExpression: function ($viewValue, $modelValue, scope) {
                              	if(scope.model && scope.model.customField && scope.model.customField.fromQuery && scope.model.customField.query && scope.model.customField.query.id){
  									return false;
                                  }else{
  	                                return true;
  								}
	                    	  }
  						  },
                          { 
                              key: 'addRelatedCustomField',
                              type: 'annexaLabelButton',
                              className: 'col-sm-12',
                              templateOptions: {
                              	buttonLabel: 'global.literals.addRelatedCustomFields',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  if(scope.model && scope.model.customField && scope.model.customField.fromQuery && scope.model.customField.query && scope.model.customField.query.id){
    									return false;
                                    }else{
    	                                return true;
    								}
                              }
                          },
  						  {
  	                        key: 'configGroup',
  	                        type: 'annexaComponent',
  	                        className: 'col-xs-12',
  	                        templateOptions: {
  	                            type: 'annexa-config-group-custom-field'
  	                        },
  							data: {
  								groups:[],
  								objectGroups:[],
  								type:[],
  								objectParent:undefined,
  								parent:undefined,
  								showCustomFieldType:false,
  								procedureCustomFields:[]
  	                        },
	                        hideExpression: function ($viewValue, $modelValue, scope) {
	                        	if(scope.model.customField && scope.model.customField.frontendType === 'CF_GROUP'){
									return false;
	                              }else{
	                                return true;
								}
	                        }
  	                      },
  	                      {
	                          key: 'calculated',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-6 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                        	  optionsAttr: 'bs-options',
                                  ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                  label: 'global.literals.calculated',
                                  valueProp: 'id',
                                  labelProp: 'description',
                                  placeholder: '',
                                  options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                  required: true
	                          },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT'))){
        	                          showList = false;
    	                          }
    	                          return showList;
                              }
    	                  },
  	                      {
  	                          key: 'dynamicallyCalculated',
  	                          type: 'annexaSelectRow',
  	                          className: 'col-xs-6 daughter-label-strong',
  	                          data:{
  	                        	  row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
  	                                  $event.stopPropagation();
  	                                  model[key] = undefined;
  	                                  if($select) {
  	                                      $select.selected = undefined;
  	                                      $select.search = undefined;
  	                                  }
  	                              }
  	                          },
  	                          templateOptions: {
  	                        	  optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.dynamicallyCalculated',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.dynamicallyCalculatedDynamically' }, { id: false, description: 'global.literals.dynamicallyCalculatedOnCreate' }],
                                    required: true
  	                          },
                                hideExpression:function($viewValue, $modelValue, scope){
      	                          var showList = true;
      	                          if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT'))){
          	                          showList = false;
      	                          }
      	                          return showList;
                                }
      	                  },
                          {
	                    	  key: 'defaultValue',
	                    	  type: 'annexaComponent',
                              className: 'col-sm-10',
                              templateOptions: {
                            	  type: 'annexa-custom-field-component'
                              },
                              data: {
                            	  key:undefined,
                            	  frontendType:undefined,
                            	  backendType:undefined,
                            	  model:undefined,
                            	  minLength:undefined,
                            	  maxLength:undefined,
                            	  decimalPositions:undefined,
                            	  cfDisabled:undefined,
                            	  optionLabelProp:undefined,
                            	  options:undefined,
                            	  optionOnSelect:undefined,
                            	  label:"",
                            	  required:false,
                            	  optionValueProp:'id',
                            	  optionsInline:false,
                            	  isConfiguration:true,
                            	  extra:undefined
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
                            	  if(scope.model.customField && scope.model.customField.frontendType !== 'SELECT_LINKED' && scope.model.customField && scope.model.customField.frontendType !== 'CF_GROUP' && !scope.model.calculated){
	    	                          if(scope.model.customField && !scope.model.customField.fromQuery){
	    	                              showList = false;
	    	                          }else if(!scope.model.reload && scope.model.customField && scope.model.customField.fromQuery && scope.model.customField.query && scope.model.customField.query.id){
	    	                        	  if(scope.model.columnsQuery && scope.model.columnsQuery.identifier && scope.model.columnsQuery.labelProp && scope.model.columnsQuery.labelProp.length > 0){
	    	                        		  showList = false;
	    	                        	  }
	    	                          }
                            	  }
    	                          return showList;
                              },
                              controller: function ($scope, $rootScope) {
                            	  $rootScope.$on('modifyIdentifierForListCF', function(event, args) {
                            		if($scope.model.customField && $scope.model.customField.listValues && $scope.model.customField.originalListValues){
                            			$scope.model.customField.listValues.length = 0;
                            			if($scope.model.columnsQuery.identifier && $scope.model.columnsQuery.labelProp && $scope.model.columnsQuery.labelProp.length > 0){
                            				_.forEach($scope.model.customField.originalListValues, function(lv){
                            					if(lv[$scope.model.columnsQuery.identifier]){
                            						var label = '';
                            						_.forEach($scope.model.columnsQuery.labelProp, function(lp){
                            							label = label + ((label)?(($scope.model.columnsQuery.labelFieldsSeparator)?$scope.model.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
                            						});
                            						$scope.model.customField.listValues.push({id: lv[$scope.model.columnsQuery.identifier], description:label, value:lv[$scope.model.columnsQuery.identifier] });
                            					}
                            				});
                            			}
      								}
                                  });
                              }
                          },
                          {
	                    	  key: 'defaultValueReloadButton',
                              type: 'annexaLabelButton',
                              className: 'col-sm-2 m-t',
                              templateOptions: {
                              	buttonLabel: 'global.literals.reload',
                              	executeFunction: function() {
                              		var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    $rootScope.$broadcast('modifyIdentifierForListCFCall', { });
                                }
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
                            	  if(scope.model.customField && scope.model.customField.frontendType !== 'SELECT_LINKED' && !scope.model.calculated){
	    	                          if(!scope.model.reload && scope.model.customField && scope.model.customField.fromQuery && scope.model.customField.query && scope.model.customField.query.id){
	    	                        	  if(scope.model.columnsQuery && scope.model.columnsQuery.identifier && scope.model.columnsQuery.labelProp && scope.model.columnsQuery.labelProp.length > 0){
	    	                        		  showList = false;
	    	                        	  }
	    	                          }
                            	  }
    	                          return showList;
                              },
                              controller: function ($scope, $rootScope, $http) {
                            	  $scope.$on('modifyIdentifierForListCFCall', function(event, args) {
                            		  $scope.model.reload = true;
                            		  $http({method: 'PUT',url: './api/custom_fields/listValues', data: {
	                  	            	customFieldId: $scope.model.customField.id,
	                  	            	parentValueId: -1,
	                  	            	additionalFilter: JSON.stringify($scope.model.queryParams),
	                  	            	objectParentId:$scope.to.objectParentId,
	                	            	objectParentType:$scope.to.objectParentType,
	                	            	inConfig:$scope.to.inConfig
	                            	  }}).then(function(data){
	                            		  if(data){
	                            			  $scope.model.customField.originalListValues = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
	                            			  if($scope.model.customField.listValues){
	                            				  $scope.model.customField.listValues.length = 0;
	                            			  }else{
	                            				  $scope.model.customField.listValues = [];
	                            			  }
	                            			  _.forEach($scope.model.customField.originalListValues, function(lv){
	                            					if(lv[$scope.model.columnsQuery.identifier]){
	                            						var label = '';
	                            						_.forEach($scope.model.columnsQuery.labelProp, function(lp){
	                            							label = label + ((label)?(($scope.model.columnsQuery.labelFieldsSeparator)?$scope.model.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	                            						});
	                            						if($scope.model.customField.frontendType === 'STRUCTURAL_SELECT' && $scope.model.columnsQuery.parent && lv[$scope.model.columnsQuery.parent]){
	        												$scope.model.customField.listValues.push({id: lv[$scope.model.columnsQuery.identifier], description:label, value:lv[$scope.model.columnsQuery.identifier], parentValue: {id:lv[$scope.model.columnsQuery.parent]}});
	        											}else{
	        												$scope.model.customField.listValues.push({id: lv[$scope.model.columnsQuery.identifier], description:label, value:lv[$scope.model.columnsQuery.identifier] });
	        											}
	                            					}
	                            				});
	                            		  }
	                            		  $scope.model.reload = false;
	                            	  }).catch(function(error){
	                            		  $scope.model.reload = false;
	                            	  });
                                  });
                              }
                          },
                          {
                              key: 'spelCalculated',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.conditionCalculated',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
    	                        	  showList = false;
    	                          }else if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          { 
                              key: 'executeSpelCalculated',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                                buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
    	                        	  showList = false;
    	                          }else if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          {
                              key: 'spelCalculatedDossier',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.conditionCalculatedDossier',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
    	                              showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          { 
                              key: 'executeSpelCalculatedDossier',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                              	buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.calculated && scope.model.customField && (scope.model.customField.backendType === 'DATETIME' || scope.model.customField.backendType === 'DECIMAL' || (scope.model.customField.backendType === 'INTEGER' && scope.model.customField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
	                                  showList = false;
	                              }
    	                          return showList;
                              }
                          }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        customFieldsRelated:{
        	title: 'global.literals.addRelatedCustomFields',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
  	                          key: 'relatedCustomField',
  	                          type: 'annexaSelectRow',
  	                          className: 'col-xs-12 daughter-label-strong',
  	                          data:{
  	                        	  row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
  	                                  $event.stopPropagation();
  	                                  model[key] = undefined;
  	                                  if($select) {
  	                                      $select.selected = undefined;
  	                                      $select.search = undefined;
  	                                  }
  	                              }
  	                          },
  	                          templateOptions: {
  	                        	  optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.relatedCustomFields',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
  	                          },
	                    	  controller: function ($scope, $rootScope, Language) {
	                    		  $scope.to.labelProp = Language.getActiveColumn();
	                    	  }
  	                      },
  	                      {
  	                          key: 'relatedQueryColumn',
  	                          type: 'annexaSelectRow',
  	                          className: 'col-xs-12 daughter-label-strong',
  	                          data:{
  	                        	  row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
  	                                  $event.stopPropagation();
  	                                  model[key] = undefined;
  	                                  if($select) {
  	                                      $select.selected = undefined;
  	                                      $select.search = undefined;
  	                                  }
  	                              }
  	                          },
  	                          templateOptions: {
  	                        	  optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.relatedQueryColumn',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [],
                                    required: true
	                    	  }
  	                      }
                        	
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        customFieldsGroupConfig: {
            title: 'global.literals.customFields',
            size: 'modal-lg',
            icon: 'fa fa-tasks',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'customFieldLabel',
                                type: 'annexaLabelRowEdit',
                                className: 'col-xs-9',
                                templateOptions: {
                                    label: '',
                                    value: '',
                                    canEdit: true,
                                    editObject: function(){}
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong text',
                                    breakLine: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.literals.customFieldName");
                                }
                        	},
  	                      	{
  	                          key: 'canRepeat',
  	                          type: 'annexaSelectRow',
  	                          className: 'col-xs-3 daughter-label-strong',
  	                          data:{
  	                        	  row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
  	                                  $event.stopPropagation();
  	                                  model[key] = undefined;
  	                                  if($select) {
  	                                      $select.selected = undefined;
  	                                      $select.search = undefined;
  	                                  }
  	                              }
  	                          },
  	                          templateOptions: {
  	                        	  optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.canRepeat',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                    required: true
  	                          }
  	                      	},
                        	{
	                          key: 'requiredString',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-9 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                              optionsAttr: 'bs-options',
	                              ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                              label: 'global.literals.requiredCustomField',
	                              valueProp: 'id',
	                              labelProp: 'description',
	                              placeholder: '',
	                              options: [],
	                              required:true
	                          }
	                      },
	                      {
	                          key: 'conditional',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-3 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                        	  optionsAttr: 'bs-options',
                                  ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                  label: 'global.literals.conditional',
                                  valueProp: 'id',
                                  labelProp: 'description',
                                  placeholder: '',
                                  options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                  required: true
	                          }
	                      },
                          {
                              key: 'spel',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.condition',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.conditional && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
        	                          showList = false;
    	                          }else if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE'  && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;

                              }
                          },
                          { 
                              key: 'executeSpel',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                                buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
                            	  if(scope.model.conditional && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
        	                          showList = false;
    	                          }else if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE'  && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          {
                              key: 'spelDossier',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.conditionDossier',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
    	                              showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          { 
                              key: 'executeSpelDossier',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                              	buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.conditional && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
	                                  showList = false;
	                              }
    	                          return showList;
                              }
                          },
                          {
	                          key: 'customFieldType',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-12 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                              optionsAttr: 'bs-options',
	                              ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                              label: 'global.literals.type',
	                              valueProp: 'id',
	                              labelProp: 'description',
	                              placeholder: '',
	                              options: [],
	                              required: true
	                          },
	                          hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue){
	                                  showList = false;
	                              }
	                              return showList;
	                          }
	                      },
                          {
                              key: 'transactionTypesAux',
                              type: 'annexaMultipleSelectRow',
                              className: 'col-xs-12',
                              templateOptions: {
                                  optionsAttr: 'bs-options',
                                  ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                  label: 'global.literals.transactionType',
                                  valueProp: 'id',
                                  labelProp: 'language1',
                                  placeholder: '',
                                  options: [],
                                  required: true
                              },
                              controller:['$scope', 'Language', 'AdminFactory', 'GlobalDataFactory', function($scope, Language, AdminFactory, GlobalDataFactory) {
                                  $scope.to.labelProp = Language.getActiveColumn();
                                  $scope.to.options = GlobalDataFactory.transactiontypes;
                              }],
                              data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong'
                              },
	                          hideExpression:function($viewValue, $modelValue, scope){
	                              var showList = true;
	                              if(scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && (scope.model.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE' || scope.model.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' || scope.model.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' || scope.model.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY')){
	                                  showList = false;
	                              }
	                              return showList;
	                          }
	                      },
	                      {
	                    	  key: 'descriptionLanguage1',
	                    	  type: 'annexaTextAreaLanguageCustomFields',
	                    	  className: 'px-lg-nt',
	                    	  validation: {
	                    		  show: true
	                    	  },
	                    	  templateOptions: {
	                    		  id:'descriptionLanguage',
	                    		  modelField:'descriptionLanguage',
	                    		  type: 'text',
	                    		  label: 'global.literals.descriptionCustomField',
	                    		  disposition:'horitzontal',
	                    		  required: false,
	                    		  focus: false
	                    	  },
	                    	  controller: function ($scope, $rootScope) {
	                    		  $scope.languages = $rootScope.app.languagedef;
	                    	  }
	                      },
  						  {
	                    	  key: 'queryParams',
	                    	  className: '',
	                    	  templateOptions: {},
	                    	  fieldGroup: [
                    		  ],
	                    	  hideExpression: function ($viewValue, $modelValue, scope) {
                              	if(scope.model && scope.model.relatedCustomField && scope.model.relatedCustomField.fromQuery && scope.model.relatedCustomField.query && scope.model.relatedCustomField.query.id && scope.model.relatedCustomField.query.parameters && scope.model.relatedCustomField.query.parameters.length > 0){
  									return false;
                                  }else{
  	                                return true;
  								}
	                    	  }
  						  },
  						  {
	                    	  key: 'columnsQuery',
	                    	  className: '',
	                    	  templateOptions: {},
	                    	  fieldGroup: [
	                    		  {
	    	                          key: 'identifier',
	    	                          type: 'annexaSelectRow',
	    	                          className: 'col-xs-3 daughter-label-strong',
	    	                          data:{
	    	                        	  row: true,
	                                      informed: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong',
	                                      clear: function($event,model,key, $select) {
	    	                                  $event.stopPropagation();
	    	                                  model[key] = undefined;
	    	                                  if($select) {
	    	                                      $select.selected = undefined;
	    	                                      $select.search = undefined;
	    	                                  }
	    	                              }
	    	                          },
	    	                          templateOptions: {
	    	                        	  optionsAttr: 'bs-options',
	                                      ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                      label: 'global.literals.identifier',
	                                      valueProp: 'id',
	                                      labelProp: 'description',
	                                      placeholder: '',
	                                      options: [],
	                                      required: true
	    	                          }
	    	                      },
	                              {
	                                  key: 'labelProp',
	                                  type: 'annexaMultipleSelectRow',
	                                  className: 'col-xs-6',
	                                  templateOptions: {
	                                      optionsAttr: 'bs-options',
	                                      ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                      label: 'global.literals.labelFields',
	                                      valueProp: 'id',
	                                      labelProp: 'description',
	                                      placeholder: '',
	                                      options: [],
	                                      required: true
	                                  },
	                                  data: {
	                                      row: true,
	                                      informed: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong'
	                                  }
	    	                      },
	    	                      {
	                                  key: 'labelFieldsSeparator',
	                                  type: 'annexaInputRow',
	                                  className: 'col-sm-3',
	                                  templateOptions: {
	                                      required: false,
	                                      type: 'text',
	                                      label: 'global.literals.labelFieldsSeparator',
	                                      maxlength: 20
	                                  },
	                                  data: {
	                                      row: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong'
	                                  }
	                              },
	                              {
	    	                          key: 'parent',
	    	                          type: 'annexaSelectRow',
	    	                          className: 'col-xs-12 daughter-label-strong',
	    	                          data:{
	    	                        	  row: true,
	                                      informed: true,
	                                      colClass: ' col-xs-12',
	                                      labelClass: 'label-strong',
	                                      clear: function($event,model,key, $select) {
	    	                                  $event.stopPropagation();
	    	                                  model[key] = undefined;
	    	                                  if($select) {
	    	                                      $select.selected = undefined;
	    	                                      $select.search = undefined;
	    	                                  }
	    	                              }
	    	                          },
	    	                          templateOptions: {
	    	                        	  optionsAttr: 'bs-options',
	                                      ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                                      label: 'global.literals.father',
	                                      valueProp: 'id',
	                                      labelProp: 'description',
	                                      placeholder: '',
	                                      options: [],
	                                      required: true
	    	                          },
	                                  hideExpression:function($viewValue, $modelValue, scope){
	                                	  var showList = true;
	                                	  if(!scope.modifyCFFromModal && scope.$parent.model.relatedCustomField && scope.$parent.model.relatedCustomField.frontendType === 'STRUCTURAL_SELECT'){
    	    	                              showList = false;
	                                	  }
	        	                          return showList;
	                                  }
	    	                      }
	                    	  ],
	                    	  hideExpression: function ($viewValue, $modelValue, scope) {
                              	if(scope.model && scope.model.relatedCustomField && scope.model.relatedCustomField.fromQuery && scope.model.relatedCustomField.query && scope.model.relatedCustomField.query.id){
  									return false;
                                  }else{
  	                                return true;
  								}
	                    	  }
  						  },
  						  {
	                    	  key: 'relatedCustomFields',
	                    	  className: '',
	                    	  templateOptions: {},
	                    	  fieldGroup: [
                    		  ],
	                    	  hideExpression: function ($viewValue, $modelValue, scope) {
                              	if(scope.model && scope.model.relatedCustomField && scope.model.relatedCustomField.fromQuery && scope.model.relatedCustomField.query && scope.model.relatedCustomField.query.id){
  									return false;
                                  }else{
  	                                return true;
  								}
	                    	  }
  						  },
                          { 
                              key: 'addRelatedCustomField',
                              type: 'annexaLabelButton',
                              className: 'col-sm-12',
                              templateOptions: {
                              	buttonLabel: 'global.literals.addRelatedCustomFields',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  if(scope.model && scope.model.relatedCustomField && scope.model.relatedCustomField.fromQuery && scope.model.relatedCustomField.query && scope.model.relatedCustomField.query.id){
    									return false;
                                    }else{
    	                                return true;
    								}
                              }
                          },
  						  {
  	                        key: 'configGroup',
  	                        type: 'annexaComponent',
  	                        className: 'col-xs-12',
  	                        templateOptions: {
  	                            type: 'annexa-config-group-custom-field'
  	                        },
  							data: {
  								groups:[],
  								objectGroups:[],
  								type:[],
  								objectParent:undefined,
  								parent:undefined
  	                        },
	                        hideExpression: function ($viewValue, $modelValue, scope) {
	                        	if(scope.model.relatedCustomField && scope.model.relatedCustomField.frontendType === 'CF_GROUP'){
									return false;
	                              }else{
	                                return true;
								}
	                        }
  	                      },
  	                      {
	                          key: 'calculated',
	                          type: 'annexaSelectRow',
	                          className: 'col-xs-6 daughter-label-strong',
	                          data:{
	                        	  row: true,
                                  informed: true,
                                  colClass: ' col-xs-12',
                                  labelClass: 'label-strong',
                                  clear: function($event,model,key, $select) {
	                                  $event.stopPropagation();
	                                  model[key] = undefined;
	                                  if($select) {
	                                      $select.selected = undefined;
	                                      $select.search = undefined;
	                                  }
	                              }
	                          },
	                          templateOptions: {
	                        	  optionsAttr: 'bs-options',
                                  ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                  label: 'global.literals.calculated',
                                  valueProp: 'id',
                                  labelProp: 'description',
                                  placeholder: '',
                                  options: [ { id: true, description: 'global.literals.yes' }, { id: false, description: 'global.literals.no' }],
                                  required: true
	                          },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT'))){
        	                          showList = false;
    	                          }
    	                          return showList;
                              }
    	                  },
  	                      {
  	                          key: 'dynamicallyCalculated',
  	                          type: 'annexaSelectRow',
  	                          className: 'col-xs-6 daughter-label-strong',
  	                          data:{
  	                        	  row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
  	                                  $event.stopPropagation();
  	                                  model[key] = undefined;
  	                                  if($select) {
  	                                      $select.selected = undefined;
  	                                      $select.search = undefined;
  	                                  }
  	                              }
  	                          },
  	                          templateOptions: {
  	                        	  optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.dynamicallyCalculated',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    placeholder: '',
                                    options: [ { id: true, description: 'global.literals.dynamicallyCalculatedDynamically' }, { id: false, description: 'global.literals.dynamicallyCalculatedOnCreate' }],
                                    required: true
  	                          },
                                hideExpression:function($viewValue, $modelValue, scope){
      	                          var showList = true;
      	                          if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT'))){
          	                          showList = false;
      	                          }
      	                          return showList;
                                }
      	                  },
                          {
	                    	  key: 'defaultValue',
	                    	  type: 'annexaComponent',
                              className: 'col-sm-10',
                              templateOptions: {
                            	  type: 'annexa-custom-field-component'
                              },
                              data: {
                            	  key:undefined,
                            	  frontendType:undefined,
                            	  backendType:undefined,
                            	  model:undefined,
                            	  minLength:undefined,
                            	  maxLength:undefined,
                            	  decimalPositions:undefined,
                            	  cfDisabled:undefined,
                            	  optionLabelProp:undefined,
                            	  options:undefined,
                            	  optionOnSelect:undefined,
                            	  label:"",
                            	  required:false,
                            	  optionValueProp:'id',
                            	  optionsInline:false,
                            	  isConfiguration:true,
                            	  extra:undefined
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
                            	  if(scope.model.relatedCustomField && scope.model.relatedCustomField.frontendType !== 'SELECT_LINKED' && scope.model.relatedCustomField && scope.model.relatedCustomField.frontendType !== 'CF_GROUP' && !scope.model.calculated){
	    	                          if(scope.model.relatedCustomField && !scope.model.relatedCustomField.fromQuery){
	    	                              showList = false;
	    	                          }else if(!scope.model.reload && scope.model.relatedCustomField && scope.model.relatedCustomField.fromQuery && scope.model.relatedCustomField.query && scope.model.relatedCustomField.query.id){
	    	                        	  if(scope.model.columnsQuery && scope.model.columnsQuery.identifier && scope.model.columnsQuery.labelProp && scope.model.columnsQuery.labelProp.length > 0){
	    	                        		  showList = false;
	    	                        	  }
	    	                          }
                            	  }
    	                          return showList;
                              },
                              controller: function ($scope, $rootScope) {
                            	  $rootScope.$on('modifyIdentifierForListCF', function(event, args) {
                            		if($scope.model.relatedCustomField && $scope.model.relatedCustomField.listValues && $scope.model.relatedCustomField.originalListValues){
                            			$scope.model.relatedCustomField.listValues.length = 0;
                            			if($scope.model.columnsQuery.identifier && $scope.model.columnsQuery.labelProp && $scope.model.columnsQuery.labelProp.length > 0){
                            				_.forEach($scope.model.relatedCustomField.originalListValues, function(lv){
                            					if(lv[$scope.model.columnsQuery.identifier]){
                            						var label = '';
                            						_.forEach($scope.model.columnsQuery.labelProp, function(lp){
                            							label = label + ((label)?(($scope.model.columnsQuery.labelFieldsSeparator)?$scope.model.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
                            						});
                            						$scope.model.relatedCustomField.listValues.push({id: lv[$scope.model.columnsQuery.identifier], description:label, value:lv[$scope.model.columnsQuery.identifier] });
                            					}
                            				});
                            			}
      								}
                                  });
                              }
                          },
                          {
	                    	  key: 'defaultValueReloadButton',
                              type: 'annexaLabelButton',
                              className: 'col-sm-2 m-t',
                              templateOptions: {
                              	buttonLabel: 'global.literals.reload',
                              	executeFunction: function() {
                              		var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    $rootScope.$broadcast('modifyIdentifierForListCFCall', { });
                                }
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
                            	  if(scope.model.relatedCustomField && scope.model.relatedCustomField.frontendType !== 'SELECT_LINKED' && !scope.model.calculated){
	    	                          if(!scope.model.reload && scope.model.relatedCustomField && scope.model.relatedCustomField.fromQuery && scope.model.relatedCustomField.query && scope.model.relatedCustomField.query.id){
	    	                        	  if(scope.model.columnsQuery && scope.model.columnsQuery.identifier && scope.model.columnsQuery.labelProp && scope.model.columnsQuery.labelProp.length > 0){
	    	                        		  showList = false;
	    	                        	  }
	    	                          }
                            	  }
    	                          return showList;
                              },
                              controller: function ($scope, $rootScope, $http) {
                            	  $scope.$on('modifyIdentifierForListCFCall', function(event, args) {
                            		  $scope.model.reload = true;
                            		  $http({method: 'PUT',url: './api/custom_fields/listValues', data: {
	                  	            	customFieldId: $scope.model.relatedCustomField.id,
	                  	            	parentValueId: -1,
	                  	            	additionalFilter: JSON.stringify($scope.model.queryParams),
	                  	            	objectParentId:$scope.to.objectParentId,
	                	            	objectParentType:$scope.to.objectParentType,
	                	            	inConfig:$scope.to.inConfig
	                            	  }}).then(function(data){
	                            		  if(data){
	                            			  $scope.model.relatedCustomField.originalListValues = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
	                            			  if($scope.model.relatedCustomField.listValues){
	                            				  $scope.model.relatedCustomField.listValues.length = 0;
	                            			  }else{
	                            				  $scope.model.relatedCustomField.listValues = [];
	                            			  }
	                            			  _.forEach($scope.model.relatedCustomField.originalListValues, function(lv){
	                            					if(lv[$scope.model.columnsQuery.identifier]){
	                            						var label = '';
	                            						_.forEach($scope.model.columnsQuery.labelProp, function(lp){
	                            							label = label + ((label)?(($scope.model.columnsQuery.labelFieldsSeparator)?$scope.model.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	                            						});
	                            						if($scope.model.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && $scope.model.columnsQuery.parent && lv[$scope.model.columnsQuery.parent]){
	        												$scope.model.relatedCustomField.listValues.push({id: lv[$scope.model.columnsQuery.identifier], description:label, value:lv[$scope.model.columnsQuery.identifier], parentValue: {id:lv[$scope.model.columnsQuery.parent]}});
	        											}else{
	        												$scope.model.relatedCustomField.listValues.push({id: lv[$scope.model.columnsQuery.identifier], description:label, value:lv[$scope.model.columnsQuery.identifier] });
	        											}
	                            					}
	                            				});
	                            		  }
	                            		  $scope.model.reload = false;
	                            	  }).catch(function(error){
	                            		  $scope.model.reload = false;
	                            	  });
                                  });
                              }
                          },
                          {
                              key: 'spelCalculated',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.conditionCalculated',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
    	                        	  showList = false;
    	                          }else if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE'  && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          { 
                              key: 'executeSpelCalculated',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                                buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue !== 'PROCEDURE'){
    	                        	  showList = false;
    	                          }else if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE'  && ((scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER') || !scope.model.showCustomfFieldParentTypeValue)){
    	                        	  showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          {
                              key: 'spelCalculatedDossier',
                              type: 'annexaTextAreaRow',
                              className: 'col-sm-9 col-xs-9',
                              validation: {
                                  show: true
                              },
                              expressionProperties: {
                                  'validation.show': 'formControl.$submitted'
                              },
                              templateOptions: {
                                  type: 'text',
                                  label: 'global.literals.conditionCalculatedDossier',
                                  rows: 5,
                                  required: true,
                                  focus: false
                              },
								data: {
                                  row: true,
                                  informed: true,
                                  colClass: ' col-sm-12',
                                  labelClass: 'label-strong',
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
    	                          var showList = true;
    	                          if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
    	                              showList = false;
    	                          }
    	                          return showList;
                              }
                          },
                          { 
                              key: 'executeSpelCalculatedDossier',
                              type: 'annexaLabelButton',
                              className: 'col-sm-3 p-t',
                              templateOptions: {
                            	atributes: 'text-sm',
                              	buttonLabel: 'global.literals.expressionEvaluator',
                              	executeFunction: function() {}
                              },
                              hideExpression:function($viewValue, $modelValue, scope){
                            	  var showList = true;
    	                          if(scope.model.calculated && scope.model.relatedCustomField && (scope.model.relatedCustomField.backendType === 'DATETIME' || scope.model.relatedCustomField.backendType === 'DECIMAL' || (scope.model.relatedCustomField.backendType === 'INTEGER' && scope.model.relatedCustomField.frontendType === 'INPUT')) && scope.model.customfFieldParentTypeValue === 'PROCEDURE' && scope.model.showCustomfFieldParentTypeValue && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && scope.model.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && scope.model.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
	                                  showList = false;
	                              }
    	                          return showList;
                              }
                          }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        markHistorical:{
        	title: 'global.literals.markHistorical',
            size: 'modal-md',
            annexaFormly: {
                fields: [
                	 {
                         key: 'type',
                         type: 'annexaSelectRow',
                         className: 'col-xs-12',
                         templateOptions: {
                             optionsAttr: 'bs-options',
                             ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                             label: 'global.literals.type',
                             valueProp: 'id',
                             labelProp: 'name',
                             placeholder: '',
                             options: [],
                             required: true
                         },
                         data: {
                             row: true,
                             informed: true,
                             colClass: ' col-xs-12',
                             labelClass: 'label-strong'
                         },
                         controller:['$scope', '$filter', 'GlobalDataFactory', function($scope, $filter, GlobalDataFactory) {
	                       	$scope.to.options = [{id:"ALL", name:$filter('translate')('global.literals.all')}];
	                       	if(GlobalDataFactory.entryTypes){
	                       		_.forEach(GlobalDataFactory.entryTypes, function(et){
	                       			$scope.to.options.push(angular.copy(et));
	                       		});
	                       	}
	                   	}]
                     },
                    {
                        key: 'startHistoricalDate',
                        type: 'annexaDatepicker',
                        className: 'col-xs-6 p-l-0',
                        templateOptions: {
                            type: 'text',
                            label: 'global.delegate.startDate',
                            required: false,
                            validate: true,
                            changeFunction: 'to.changeFunction',
                            datepickerOptions: {
                                format: 'dd/MM/yyyy',
                                initDate: new Date(),
                                showWeeks: false,
                                startingDay: 1
                            }
                        }
                    },
                    {
                        key: 'endHistoricalDate',
                        type: 'annexaDatepicker',
                        className: 'col-xs-6 p-r-0',
                        templateOptions: {
                            type: 'text',
                            label: 'global.delegate.endDate',
                            required: true,
                            validate: true,
                            changeFunction: 'to.changeFunction',
                            datepickerOptions: {
                                format: 'dd/MM/yyyy',
                                initDate: new Date(),
                                showWeeks: false,
                                startingDay: 1
                            }
                        }
                    }
                ]
            },
            alerts: []
        },
        emgdeMetadataLocationSupportNew: {
            title: 'global.commonAdmin.emgdeMetadataLocationSupport.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'code',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.code',
                                    maxlength: 1024,
                                    focus: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                        		key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition:'horitzontal21',
                                    required: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        emgdeMetadataLocationSupportEdit: {
            title: 'global.commonAdmin.emgdeMetadataLocationSupport.titleEdit',
        	size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.code" model-value="modal.data.code" model-property="code" save="modal.updateEmgdeMetadataLocationSupport(val, prop)" required="true" blur="ignore" model-length="1024"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateEmgdeMetadataLocationSupport(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        modalAuditAutomatism: {
            title: 'global.querys.automatisms.auditAutomatisms',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
	                            key: 'auditAutomatism',
	                            type: 'annexaComponent',
                                className: 'col-sm-12',
	                            templateOptions: {
	                                type: 'annexa-audit-automatism'
	                            },
	                            data: {
	                            	auditAutomatism: undefined
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        } ,
        modalAuditAutomatismRegister: {
            title: 'global.literals.telematicRegEntries',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
	                            key: 'auditAutomatismRegister',
	                            type: 'annexaComponent',
                                className: 'col-sm-12',
	                            templateOptions: {
	                                type: 'annexa-audit-automatism-register'
	                            },
	                            data: {
	                            	register: undefined
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        jobSendSessionAvaDetail: {
            title: '',
            size: 'modal-lg',
            icon: 'fa fa-cog',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                        	{
                                key: 'sessionId',
                                type: 'annexaLabel',
                                className: 'col-sm-3',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.sec.literals.session");
                                    if($scope.model && $scope.model.sessionId){
                                        $scope.to.value = $scope.model.sessionId;
                                    }
                                }
                            },{
                                key: 'sessionNumber',
                                type: 'annexaLabel',
                                className: 'col-sm-3',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.sec.literals.sessionNumber");
                                    if($scope.model && $scope.model.sessionNumber){
                                        $scope.to.value = $scope.model.sessionNumber;
                                    }
                                }
                            },{
                                key: 'lastUpdate',
                                type: 'annexaLabel',
                                className: 'col-sm-3',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.sec.literals.lastUpdate");
                                    if($scope.model && $scope.model.lastUpdate){
                                        $scope.to.value = $scope.model.lastUpdate;
                                    }
                                }
                            },{
                                key: 'attempts',
                                type: 'annexaLabel',
                                className: 'col-sm-3',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.commonAdmin.literals.attemptsTitle");
                                    if($scope.model && $scope.model.attempts){
                                        $scope.to.value = $scope.model.attempts;
                                    }
                                }
                            },{
                                key: 'status',
                                type: 'annexaLabel',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: '',
                                    value:''
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.label = $filter("translate")("global.commonAdmin.literals.statusTitle");
                                    if($scope.model && $scope.model.status){
                                        $scope.to.value = $scope.model.status;
                                    }
                                }
                            },
                            {
                                key: 'errorMessage',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: '',
                                    value:'',
                                    rows: 10,
                                    focus: false,
                                    disabled: true
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
//                                    $scope.to.label = $filter("translate")("global.literals.description");
                                    if($scope.model && $scope.model.errorMessage){
                                        $scope.to.value = $scope.model.errorMessage;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        showErrorJobSendSessionAva: {
            title: 'global.sec.literals.moreInfo',
            size: 'modal-lg',
            icon: 'fa fa-cog',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'errorMessage',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: '',
                                    value:'',
                                    rows: 10,
                                    focus: false,
                                    disabled: true
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $filter) {
                                    if($scope.model && $scope.model.errorMessage){
                                        $scope.to.value = $scope.model.errorMessage;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        dossierReasonsCloseNew: {
            title: 'global.commonAdmin.modal.dossierReasonsClose.titleNew',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: '',
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }

                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function (field, _new) {
                                        if (field.formControl) {
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                            {
                                key: 'acronym',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.literals.acronym',
                                    maxlength: 20
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                    
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        dossierReasonsCloseEdit: {
            title: 'global.commonAdmin.modal.dossierReasonsClose.titleEdit',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-sm-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateDossierReasonsClose(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.acronym" model-value="modal.data.acronym" model-property="acronym" save="modal.updateDossierReasonsClose(val, prop)" required="true" blur="ignore" model-length="20"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        tramTemplateTransactionNew: {
            title: 'global.commonAdmin.modal.tramTemplate.tramTemplateTransactions',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-xs-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id:'language',
                                    modelField:'',
                                    type: 'text',
                                    label: 'global.commonAdmin.modal.tramTemplate.tramTemplateTransactionDescription',
                                    disposition:'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            },
                        	{
                                key: 'keyId',
                                type: 'annexaInputRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    required: true,
                                    type: 'text',
                                    label: 'global.commonAdmin.modal.tramTemplate.tramTemplateTransactionName',
                                    maxlength: 1024
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        automatismRelatedNew: {
            title: 'global.querys.automatisms.related.title',
            size: 'modal-md',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'relatedAutomatism',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.querys.automatisms.related.related',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, RestService) {
                                	RestService.findAll('Automatism', 'adminCreateTransaction').then(function(data) {
                                        if(data.data && data.data.length > 0) {
                                        	var auts = JSOG.decode(data.data);
                                            _.forEach(auts, function(val) {
                                            	if(!$scope.model.actualId || ($scope.model.actualId && val.id !== $scope.model.actualId)){
                                            		if(($scope.model.relatedAutomatism && val.id === $scope.model.relatedAutomatism) || (!$scope.model.relatedIds  || $scope.model.relatedIds.length === 0 ) || ($scope.model.relatedIds  && $scope.model.relatedIds.length > 0 && !_.contains($scope.model.relatedIds, val.id))){
                                            			$scope.to.options.push(val);
                                            		}
                                            	}
                                            });
                                        }
                                    });
                                }
                            },
                            {
                                key: 'viewOrder',
                                type: 'annexaSelectRow',
                                className: 'col-xs-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.querys.automatisms.related.viewOrder',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    maxValue:1,
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: function ($scope, $rootScope, RestService) {
                                	if($scope.to.maxValue && $scope.to.maxValue > 1){
                                		for(i=0; i<$scope.to.maxValue; i++){
                                			$scope.to.options.push({id:i+1,name:(i+1)+''});
                                		}
                                		if(!$scope.model.viewOrder){
                                			$scope.model.viewOrder = $scope.to.maxValue;
                                		}
                                	}else{
                                		$scope.to.options.push({id:1,name:'1'});
                                		if(!$scope.model.viewOrder){
                                			$scope.model.viewOrder = 1;
                                		}
                                	}
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        }
    });
