/**
 * Created by osirvent on 22/07/2016.
 */
var procedure = function(procedure, server, withCouncillor) {
    this.id=procedure.id;
    this.createdDate=procedure.createdDate;
    this.language1=procedure.language1;
    this.language2=procedure.language2;
    this.language3=procedure.language3;
    this.descriptionLanguage1=procedure.descriptionLanguage1;
    this.descriptionLanguage2=procedure.descriptionLanguage2;
    this.descriptionLanguage3=procedure.descriptionLanguage3;
    this.informationLanguage1=procedure.informationLanguage1;
    this.informationLanguage2=procedure.informationLanguage2;
    this.informationLanguage3=procedure.informationLanguage3;
    this.inspectionIntervention=procedure.inspectionIntervention;
    if(this.inspectionIntervention){
    	this.inspectionInterventionType=procedure.inspectionInterventionType;
    }else{
    	this.inspectionInterventionType=undefined;
    }
    this.closeExpireType=procedure.closeExpireType;
    this.transferExpireType=procedure.transferExpireType;
    this.archiveExpireType=procedure.archiveExpireType;
    this.closeExpireQuantity=procedure.closeExpireQuantity;
    this.transferExpireQuantity=procedure.transferExpireQuantity;
    this.archiveExpireQuantity=procedure.archiveExpireQuantity;
    this.automaticClose=procedure.automaticClose;
    this.automaticTransfer=procedure.automaticTransfer;
    this.automaticArchive=procedure.automaticArchive;
    this.emgdeSecurityClassification=procedure.emgdeSecurityClassification;
	this.emgdeSecurityLevel=procedure.emgdeSecurityLevel;
	this.emgdeAccesType=procedure.emgdeAccesType;
	this.emgdeLimitCause=procedure.emgdeLimitCause;
    this.emgdeLegalLimitCause=procedure.emgdeLegalLimitCause;
	this.emgdeReuseCondition=procedure.emgdeReuseCondition;
	this.emgdeValueType=procedure.emgdeValueType;
	this.emgdeRatingTerms=procedure.emgdeRatingTerms;
	this.emgdeSecundaryValue=procedure.emgdeSecundaryValue;
	this.emgdeOpinionType=procedure.emgdeOpinionType;
	this.emgdeOpinionAction=procedure.emgdeOpinionAction;
	this.emgdeOpinionActionTerms=procedure.emgdeOpinionActionTerms;
	this.emgdeTransferArchive=procedure.emgdeTransferArchive;
	this.emgdeTransferTerms=procedure.emgdeTransferTerms;
	this.emgdeEssentialDocument=procedure.emgdeEssentialDocument;
    this.maxDays=procedure.maxDays;
    this.acronym=procedure.acronym;
    this.active=procedure.active;
    this.family=procedureObject(procedure.family, server);
	this.councillor=procedureObject(procedure.councillor, server);
    this.archiveClassification=procedureObject(procedure.archiveClassification, server);
    this.showSubject=procedure.showSubject;
    this.showClassification=procedure.showClassification;
    this.procedureViewProfiles=procedureListObject(procedure.procedureViewProfiles, server, 'profile');
    this.procedureStartProfiles=procedureListObject(procedure.procedureStartProfiles, server, 'profile');
    this.procedureTramitationProfiles=procedureListObject(procedure.procedureTramitationProfiles, server, 'profile');
    this.procedureRoleInterested=procedureListObject(procedure.procedureRoleInterested, server, 'roleInterested');
    this.procedureDocumentTypeCanSee=procedureListObject(procedure.procedureDocumentTypeCanSee, server, 'documentType');
    this.procedureResponsibleProfiles=procedureListObject(procedure.procedureResponsibleProfiles, server, 'profile');
    this.procedureClassifications=procedureListObject(procedure.procedureClassifications, server);
    this.allowUserChangeMaxDays = procedure.allowUserChangeMaxDays;
    this.transactionTypes = procedureListObject(procedure.transactionTypes, server, 'transactionType');
    this.tramitationType = procedureObject(procedure.tramitationType, server);
    this.accessLevelNotification = procedure.accessLevelNotification;
    this.operationsActive = procedure.operationsActive;
    if(this.operationsActive){
    	this.operationTypes = procedureListObject(procedure.operationTypes, server, 'operationType');
    }else{
    	this.operationTypes = undefined;
    }
    this.expireType = procedure.expireType;
    this.expirationDaysNotification = procedure.expirationDaysNotification;
    this.allowUserChangeExpirationDaysNotification = procedure.allowUserChangeExpirationDaysNotification;
    this.allowHaveTerritorialAddress = procedure.allowHaveTerritorialAddress;
    this.daysBeforeAlertTransaction = procedure.daysBeforeAlertTransaction;
    this.daysBeforeAlertDossier = procedure.daysBeforeAlertDossier;
    this.allowManualDossierNumbering = procedure.allowManualDossierNumbering;
    this.forceHaveThird = procedure.forceHaveThird;
    this.footerClaims = procedureListObject(procedure.footerClaims, server, 'footerClaim');
    this.subprocedures = procedureListObject(procedure.subprocedures, server, 'subprocedure');
    this.proposalType = procedureObject(procedure.proposalType, server);
    if(!withCouncillor){
    	this.organsOpinion = procedureListObject(procedure.organsOpinion, server, 'organ');
    }
    this.organsAgreement = procedureObject(procedure.organsAgreement, server);
    if(withCouncillor){
    	this.organsAgreementDelegationType = procedureObject(procedure.organsAgreementDelegationType, server);
    }
    this.proposalTitle = procedure.proposalTitle;
    this.notificatorProfile = procedureObject(procedure.notificatorProfile, server);
    this.internalProfiles = procedureListObject(procedure.internalProfiles, server, 'profile');
    this.alertTramInternalProfiles = procedure.alertTramInternalProfiles;
    this.transactionInternalProfilesAlert = procedureObject(procedure.transactionInternalProfilesAlert, server);
    this.ammendmentTransactionType = procedureObject(procedure.ammendmentTransactionType, server);
    this.transferTransactionType = procedureObject(procedure.transferTransactionType, server);
    this.cancelTransactionType = procedureObject(procedure.cancelTransactionType, server);
    this.spelInspectionIntervention = procedure.spelInspectionIntervention;
    this.proposalTemplate = procedureObject(procedure.proposalTemplate, server);
    this.responsibleUserInternalControl = procedureObject(procedure.responsibleUserInternalControl, server);
    this.predefinedRecords = procedure.predefinedRecords;
	
};


var procedureObject = function(object, server) {
    var obj = undefined;
    if(object) {
        if(server) {
            obj = {id: object};
        }else{
            obj = object.id;
        }
    }
    return obj;
}

var procedureListObject = function(objects, server, name){
    var objs = undefined;
    if(objects) {
        if(name) {
            objs = [];
            angular.forEach(objects, function (value, key) {
                if (server) {
                    var obj = {};
                    obj[name] = {id: value};
                    objs.push(obj);
                } else {
                    objs.push(value);
                }

            });
        }else{
            objs = objects;
        }
    }
    return objs;
}


