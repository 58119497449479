/**
 * Created by osirvent on 05/12/2016.
 */
angular
    .module('annexaApp')
    .controller('GuidedProcedureController', ['$scope', '$state', '$rootScope', 'AdminFactory', 'Language', 'TableFilter', '$filter', 'HeaderService', 'DTColumnBuilder', 'HelperService', 'dialogs', 'CommonAdminService', 'GlobalDataFactory', 'CacheFactory', function ($scope, $state, $rootScope, AdminFactory, Language, TableFilter, $filter, HeaderService, DTColumnBuilder, HelperService, dialogs, CommonAdminService, GlobalDataFactory, CacheFactory) {
        $rootScope.loading(false);

        var procedureStatuses = [
            { id: 'PROCEDURE', name: 'global.literals.PROCEDURE' },
            { id: 'SUBPROCEDURE', name: 'global.literals.SUBPROCEDURE' },
            { id: 'PROCEDURE_SUBPROCEDURE', name: 'global.literals.PROCEDURE_SUBPROCEDURE' }
        ];

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement('family', 'select-tree', 0, 'global.literals.family', undefined, GlobalDataFactory.familyProcedures, undefined, undefined, false, undefined, Language.getActiveColumn());
        $scope.tfilter.addElement('acronym', 'text', 2, 'global.literals.acronym');
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 3, 'global.literals.procedure');
        $scope.tfilter.addElement('procedureType', 'select', 4, 'global.literals.type', 3, HelperService.addAllSelectAngularTranslate(procedureStatuses), true, 'name', true).setFilterCallAux(true);
        $scope.tfilter.loadLocalData([],undefined, undefined, $scope.app.language);

        $scope.filterData = { loggedUser: $rootScope.LoggedUser.id, guided: true };
        
        if(!AdminFactory.usersList || AdminFactory.usersList.length == 0) {
        	AdminFactory.getUsersList('procedure');
        }

        $scope.dtDefGuidedProcedure = new TableData('tram', 'Procedure', './api/tram/byfilter').setSortName('family').setSort([[1, 'asc']]);

        var newState = 'annexa.admin.guided_procedure.new_guided_procedure';

        var FamilyColumn = new DatabaseTranslatedColumn($filter, 'global.literals.family', Language.getActiveColumn());
        var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn());
        var ProcedureTypeColumn = new AngularTranslateColumn($filter, 'global.literals.type', 'global.literals.');
        var ProcedureActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
            new ActionButton('global.literals.see', newState + '({ procedure: \'[data]\'})', 'fa-eye', true),
            [new ActionButton('global.literals.remove', 'deleteProcedure([data])')]);

        var getFilterCall = function(){
            var filterCall = $scope.tfilter.getFilterCall();
            if($scope.filterData.status && $scope.filterData.status != ''){
                filterCall.docStatus = $scope.filterData.status;
            }
            if($scope.filterData.guided && $scope.filterData.guided != ''){
                filterCall.guided = $scope.filterData.guided;
            }
            return filterCall;
        }

        $scope.tableGuidedProcedure = {
            dtColumns: [
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('family.' + FamilyColumn.getColumn()).withTitle(FamilyColumn.getTitle()).renderWith(FamilyColumn.getRender),
                DTColumnBuilder.newColumn('acronym').withTitle($filter('translate')('global.literals.acronym')),
                DTColumnBuilder.newColumn(ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
                DTColumnBuilder.newColumn('procedureType').withTitle(ProcedureTypeColumn.getTitle()).renderWith(ProcedureTypeColumn.getRender),
                DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '130px').withTitle(ProcedureActionsColumn.getTitle()).renderWith(ProcedureActionsColumn.getRender).notSortable()
            ],
            filterCall: getFilterCall(),
            filterCallAux: {loggedUser: $rootScope.LoggedUser.id, applyPermissions: false, langColumn: $scope.languageColumn, admin: true },
            reloadInternalData: function (resetPaging) {
                this.filterCall = getFilterCall();
                this.filterCallAux = $scope.tfilter.getFilterCallAux();
                this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                this.filterCallAux.applyPermissions = false;
                this.filterCallAux.langColumn = $scope.languageColumn;
                this.filterCallAux.admin = true;
                this.table.reloadData(function callbackInstance(json) {}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.guided_procedure' || message.state.name == 'annexa.guided_procedure') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-unguidedProcedure','fa fa-plus','global.commonAdmin.modal.unguidedProcedure.titleNew',undefined,'openNewProcedure')
                ];
            }
        })

        HeaderService.changeState($state.current);

        $scope.deleteProcedure = function(id) {
            if(id) {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.deleteProcedure'),
                    $filter('translate')('global.literals.confirmDeleteProcedureMessage'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );

                dlg.result.then(function (btn) {
                    CommonAdminService.deleteProcedure(id).then(function (data) {
                        $scope.doSearchFilter();
                        if(GlobalDataFactory.procedures){
                        	GlobalDataFactory.procedures.length = 0;
                        }else{
                        	GlobalDataFactory.procedures = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                        var err = dialogs.error(
                            $filter('translate')('DIALOGS_ERROR_MSG'),
                            $filter('translate')(error.message),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                    });
                }, function (btn) {
                });


            }
        };

        $scope.openNewProcedure = function () {
            $state.transitionTo(newState);
        }

        $scope.doSearchFilter=function(queued) {
            $scope.tableGuidedProcedure.reloadInternalData(true);
        }
    }])
    .controller('NewGuidedProcedureController',['$scope', '$rootScope', 'HeaderService', '$state', '$timeout', 'AdminFactory', 'dialogs', '$filter', 'ABMShapesFactory', 'ABMModelFactory', 'ABMValidationFactory', 'GlobalDataFactory', 'CacheFactory', function ($scope, $rootScope, HeaderService, $state, $timeout, AdminFactory, dialogs, $filter, ABMShapesFactory, ABMModelFactory, ABMValidationFactory, GlobalDataFactory, CacheFactory) {
        var cancelState = 'annexa.admin.guided_procedure';
        $scope.classState = '';


        var headButtons = [];
        if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
        	$scope.isActiveCouncillor = true;
        }else{
        	$scope.isActiveCouncillor = false;
		}
        
        if($state.current.name == 'annexa.admin.guided_procedure.new_guided_procedure') {
            headButtons.push(new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#annexa-modeler',undefined,'global.literals.cancel',undefined,'cancelNewGuidedProcedure'));
            headButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#annexa-modeler','fa-floppy-o','global.literals.save',undefined,'saveNewGuidedProcedure'));
            headButtons.push(new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#annexa-modeler',undefined,'global.literals.toggleMenu',undefined,'toggleMenu'));
            headButtons.push(new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#annexa-modeler',undefined,'Graph JSON',undefined,'graphJSON'));
        } else {
            headButtons.push(new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#annexa-modeler',undefined,'global.literals.cancel',undefined,'cancelNewGuidedProcedure'));
            headButtons.push(new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#annexa-modeler','fa-floppy-o','global.literals.save',undefined,'saveNewGuidedProcedure'));
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.guided_procedure.new_guided_procedure' || message.state.name == 'annexa.guided_procedure.new_guided_procedure' ) {
                $rootScope.subHeadButtons = headButtons;
            }
        });

        HeaderService.changeState($state.current);

        $scope.cancelNewGuidedProcedure = function() {
            var dlg = dialogs.confirm(
                $filter('translate')('global.bpm.literals.confirmCancelProcedureTitle'),
                $filter('translate')('global.bpm.literals.configrmCancelProcedureContent'),
                {
                    animation: true,
                    backdrop: 'static',
                    keyboard: true,
                    size: '',
                    backdropClass: 'zMax',
                    windowClass: 'zMax3'
                }
            );

            dlg.result.then(function (btn) {
                $state.go(cancelState);
            }, function (btn) {
            });

        };

        $scope.saveNewGuidedProcedure = function() {
            $scope.$broadcast('saveBPM', { saveFunc: function(json, model) {

                var fixTransactionValues = function(transaction) {
                    transaction.allowExpedientRelations = transaction.allowExpedientRelations == 'YES' ? true : false;
                    transaction.allowThirds = transaction.allowThirds == 'YES' ? true : false;
                    transaction.deleteInitialNode = transaction.deleteInitialNode == 'YES' ? true : false;
                    transaction.deleteFinalNode = transaction.deleteFinalNode == 'YES' ? true : false;
                    transaction.useCustomFieldProfile = transaction.useCustomFieldProfile == 'YES' ? true : false;
                    transaction.useCustomFieldUser = transaction.useCustomFieldUser == 'YES' ? true : false;
                    delete transaction.assignationType;
                    if(transaction.graphNodeType == ABMShapesFactory.SFN){
                        transaction.subprocedure = undefined;
                        transaction.language1 = transaction.subprocedureName1;
                        transaction.language2 = transaction.subprocedureName2;
                        transaction.language3 = transaction.subprocedureName3;
                    }
                    transaction.daysComputeGlobal = transaction.daysComputeGlobal == 'YES' ? true : false;


                    if(transaction.assignationTypeTram){
                        var processProfiles = [];
                        if(transaction.assignationTypeTram == ABMModelFactory.AT_GUIDED){
                            _.forEach(transaction.processProfiles, function (item) {
                                if(transaction.processProfilesOriginal){
                                    var originalProfiles = $linq(transaction.processProfilesOriginal).where("x => x.profile.id == "+item).toArray();
                                    if(originalProfiles && originalProfiles.length > 0){
                                        processProfiles.push(originalProfiles[0]);
                                    }else{
                                        processProfiles.push( { profile: { id: item } });
                                    }
                                }else{
                                    processProfiles.push( { profile: { id: item } });
                                }
                            });
                            if(transaction.processProfilesOriginal){
                                var deleteProfiles = $linq(transaction.processProfilesOriginal).except(processProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                                _.forEach(deleteProfiles, function (item) {
                                    item.deleted = true;
                                    item.removedDate = new Date();
                                    processProfiles.push(item);
                                });
                            }
                            transaction.processProfiles = processProfiles;
                            transaction.userStateInheritance = '';
                            transaction.userInitialStateInheritance = false;
                        }else {
                            if(transaction.processProfilesOriginal){
                                var deleteProfilesAux = $linq(transaction.processProfilesOriginal).except(processProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                                _.forEach(deleteProfilesAux, function (item) {
                                    item.deleted = true;
                                    item.removedDate = new Date();
                                    processProfiles.push(item);
                                });
                            }
                            transaction.processProfiles = processProfiles;
                            if(transaction.assignationTypeTram == ABMModelFactory.AT_NONGUIDED){
                                transaction.userStateInheritance = '';
                                transaction.userInitialStateInheritance = false;
                            }
                        }

                    }
                    
                    if(transaction.useCustomFieldProfile && transaction.customFieldProfile) {
                    	if(transaction.customFieldProfile.id) {
                    		transaction.customFieldProfile = {id: transaction.customFieldProfile.id};
						} else {
							if(transaction.customFieldProfile.customFieldId) {
								transaction.customFieldProfile = {customField: {id: transaction.customFieldProfile.customFieldId}};
							} else {
								transaction.customFieldProfile = {customField: {id: transaction.customFieldProfile}};
							}
						}
                    } else {
                    	transaction.customFieldProfile = undefined;
                    }
                    
                    if(transaction.useCustomFieldUser && transaction.customFieldUser) {
                    	if(transaction.customFieldUser.id) {
                    		transaction.customFieldUser = {id: transaction.customFieldUser.id};
						} else {
							if(transaction.customFieldUser.customFieldId) {
								transaction.customFieldUser = {customField: {id: transaction.customFieldUser.customFieldId}};
							} else {
								transaction.customFieldUser = {customField: {id: transaction.customFieldUser}};
							}
						}
                    } else {
                    	transaction.customFieldUser = undefined;
                    }

                    transaction.showEndProcess = transaction.showEndProcess == 'YES' ? true : false;
                    delete transaction.tramitationType;
                    transaction.userInitialStateInheritance = ((transaction.userInitialStateInheritance == 'YES' || transaction.userInitialStateInheritance == true)? true : false);

                    if(transaction.customFieldsOriginal){
                        var deleteCustomFields = $linq(transaction.customFieldsOriginal).except(transaction.customFields, "(x,y) => x.id == y.id").toArray();
                        _.forEach(deleteCustomFields, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            transaction.customFields.push(item);
                        });
                    }

                    if(transaction.subprocedure) {
                        transaction.subprocedure = { id: transaction.subprocedure };
                    } else {
                        transaction.subprocedure = undefined;
                    }

                    if(transaction.transactionType) {
                        transaction.transactionType = { id: transaction.transactionType };
                    } else {
                        transaction.transactionType = undefined;
                    }
                    
                    if(transaction.phase) {
                        transaction.phase = { id: transaction.phase };
                    } else {
                        transaction.phase = undefined;
                    }
                    
                    var transactionProcedureProposalTypes = [];
                    _.forEach(transaction.transactionProcedureProposalTypes, function (item) {
                        if(transaction.transactionProcedureProposalTypesOriginal){
                            var originalTransactionProcedureProposalTypes = $linq(transaction.transactionProcedureProposalTypesOriginal).where("x => x.procedureProposalType.id == "+item).toArray();
                            if(originalTransactionProcedureProposalTypes && originalTransactionProcedureProposalTypes.length > 0){
                            	transactionProcedureProposalTypes.push(originalTransactionProcedureProposalTypes[0]);
                            }else{
                            	transactionProcedureProposalTypes.push( { procedureProposalType: { id: ((item && item.id)?item.id:item) } });
                            }
                        }else{
                        	transactionProcedureProposalTypes.push( { procedureProposalType: { id: ((item && item.id)?item.id:item) } });
                        }
                    });
                    if(transaction.transactionProcedureProposalTypesOriginal){
                        var deleteTransactionProcedureProposalTypes = $linq(transaction.transactionProcedureProposalTypesOriginal).except(transactionProcedureProposalTypes, "(x,y) => x.procedureProposalType.id == y.procedureProposalType.id").toArray();
                        _.forEach(deleteTransactionProcedureProposalTypes, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            transactionProcedureProposalTypes.push(item);
                        });
                    }
                    transaction.transactionProcedureProposalTypes = transactionProcedureProposalTypes;
                    transaction.transactionProcedureProposalTypesOriginal = undefined;
                    
                    return transaction;
                };

                var fixProcedureValues = function(procedure) {
                    procedure.active = procedure.active == 'YES' ? true : false;
                    if(procedure.family && procedure.family.$selected) {
                        procedure.family = { id: procedure.family.$selected.id };
                    }
                    if(procedure.councillor && procedure.councillor.$selected) {
                        procedure.councillor = { id: procedure.councillor.$selected.id };
                    }
                    if(procedure.archiveClassification && procedure.archiveClassification.$selected) {
                        procedure.archiveClassification = { id: procedure.archiveClassification.$selected.id };
                    }

                    var procedureStartProfiles = [];
                    _.forEach(procedure.procedureStartProfiles, function (item) {
                        if(procedure.procedureStartProfilesOriginal){
                            var originalProfiles = $linq(procedure.procedureStartProfilesOriginal).where("x => x.profile.id == "+item).toArray();
                            if(originalProfiles && originalProfiles.length > 0){
                                procedureStartProfiles.push(originalProfiles[0]);
                            }else{
                                procedureStartProfiles.push( { profile: { id: item } });
                            }
                        }else{
                            procedureStartProfiles.push( { profile: { id: item } });
                        }
                    });
                    if(procedure.procedureStartProfilesOriginal){
                        var deleteProfiles = $linq(procedure.procedureStartProfilesOriginal).except(procedureStartProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        _.forEach(deleteProfiles, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureStartProfiles.push(item);
                        });
                    }
                    procedure.procedureStartProfiles = procedureStartProfiles;

					var procedureTramitationProfiles = [];
                    _.forEach(procedure.procedureTramitationProfiles, function (item) {
                        if(procedure.procedureTramitationProfilesOriginal){
                            var originalProfiles = $linq(procedure.procedureTramitationProfilesOriginal).where("x => x.profile.id == "+item).toArray();
                            if(originalProfiles && originalProfiles.length > 0){
                                procedureTramitationProfiles.push(originalProfiles[0]);
                            }else{
                                procedureTramitationProfiles.push( { profile: { id: item } });
                            }
                        }else{
                            procedureTramitationProfiles.push( { profile: { id: item } });
                        }
                    });
                    if(procedure.procedureTramitationProfilesOriginal){
                        var deleteProfiles = $linq(procedure.procedureTramitationProfilesOriginal).except(procedureTramitationProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        _.forEach(deleteProfiles, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureTramitationProfiles.push(item);
                        });
                    }
                    procedure.procedureTramitationProfiles = procedureTramitationProfiles;

                    var procedureRoleInterested = [];
                    _.forEach(procedure.procedureRoleInterested, function (item) {
                        if(procedure.procedureRoleInterestedOriginal){
                            var originalRoleInterested = $linq(procedure.procedureRoleInterestedOriginal).where("x => x.roleInterested.id == "+item).toArray();
                            if(originalRoleInterested && originalRoleInterested.length > 0){
                            	procedureRoleInterested.push(originalRoleInterested[0]);
                            }else{
                            	procedureRoleInterested.push( { roleInterested: { id: item } });
                            }
                        }else{
                        	procedureRoleInterested.push( { roleInterested: { id: item } });
                        }
                    });
                    if(procedure.procedureRoleInterestedOriginal){
                        var deleteRoleInterested = $linq(procedure.procedureRoleInterestedOriginal).except(procedureRoleInterested, "(x,y) => x.roleInterested.id == y.roleInterested.id").toArray();
                        _.forEach(deleteRoleInterested, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureRoleInterested.push(item);
                        });
                    }
                    procedure.procedureRoleInterested = procedureRoleInterested;

                    var procedureDocumentTypeCanSee = [];
                    _.forEach(procedure.procedureDocumentTypeCanSee, function (item) {
                        if(procedure.procedureDocumentTypeCanSeeOriginal){
                            var originalDocumentTypeCanSee = $linq(procedure.procedureDocumentTypeCanSeeOriginal).where("x => x.documentType.id == "+item).toArray();
                            if(originalDocumentTypeCanSee && originalDocumentTypeCanSee.length > 0){
                            	procedureDocumentTypeCanSee.push(originalDocumentTypeCanSee[0]);
                            }else{
                            	procedureDocumentTypeCanSee.push( { documentType: { id: item } });
                            }
                        }else{
                        	procedureDocumentTypeCanSee.push( { documentType: { id: item } });
                        }
                    });
                    if(procedure.procedureDocumentTypeCanSeeOriginal){
                        var deleteDocumentTypeCanSee = $linq(procedure.procedureDocumentTypeCanSeeOriginal).except(procedureDocumentTypeCanSee, "(x,y) => x.documentType.id == y.documentType.id").toArray();
                        _.forEach(deleteDocumentTypeCanSee, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureDocumentTypeCanSee.push(item);
                        });
                    }
                    procedure.procedureDocumentTypeCanSee = procedureDocumentTypeCanSee;
                    
                    var procedureFooterClaims = [];
                    _.forEach(procedure.procedureFooterClaims, function (item) {
                        if(procedure.footerClaimsOriginal){
                            var originalFooterClaims = $linq(procedure.footerClaimsOriginal).where("x => x.footerClaim.id == "+item).toArray();
                            if(originalFooterClaims && originalFooterClaims.length > 0){
                            	procedureFooterClaims.push(originalFooterClaims[0]);
                            }else{
                            	procedureFooterClaims.push( { footerClaim: { id: item } });
                            }
                        }else{
                        	procedureFooterClaims.push( { footerClaim: { id: item } });
                        }
                    });
                    if(procedure.footerClaimsOriginal){
                        var deleteFooterClaims = $linq(procedure.footerClaimsOriginal).except(procedureFooterClaims, "(x,y) => x.footerClaim.id == y.footerClaim.id").toArray();
                        _.forEach(deleteFooterClaims, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureFooterClaims.push(item);
                        });
                    }
                    procedure.footerClaims = procedureFooterClaims;
                    procedure.footerClaimsOriginal = undefined;
                    
                    var procedureViewProfiles = [];
                    _.forEach(procedure.procedureViewProfiles, function (item) {
                        if(procedure.procedureViewProfilesOriginal){
                            var originalProfiles = $linq(procedure.procedureViewProfilesOriginal).where("x => x.profile.id == "+item).toArray();
                            if(originalProfiles && originalProfiles.length > 0){
                                procedureViewProfiles.push(originalProfiles[0]);
                            }else{
                                procedureViewProfiles.push( { profile: { id: item } });
                            }
                        }else{
                            procedureViewProfiles.push( { profile: { id: item } });
                        }
                    });
                    if(procedure.procedureViewProfilesOriginal){
                        var deleteProfiles = $linq(procedure.procedureViewProfilesOriginal).except(procedureViewProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        _.forEach(deleteProfiles, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureViewProfiles.push(item);
                        });
                    }
                    procedure.procedureViewProfiles = procedureViewProfiles;

                    var procedureClassifications = [];
                    _.forEach(procedure.procedureClassifications, function(item) {
                        var language1 = item.language1;
                        var language2 = item.language2 ? item.language2 : undefined;
                        var langauge3 = item.language3 ? item.language3 : undefined;
                        var idItem = item.id;
                        procedureClassifications.push({ language1: language1, language2: language2, language3: langauge3, id: idItem });
                    });
                    procedure.procedureClassifications = procedureClassifications;


                    var procedureResponsibleProfiles = [];
                    _.forEach(procedure.procedureResponsibleProfiles, function (item) {
                        if(procedure.procedureResponsibleProfilesOriginal){
                            var originalProfiles = $linq(procedure.procedureResponsibleProfilesOriginal).where("x => x.profile.id == "+item).toArray();
                            if(originalProfiles && originalProfiles.length > 0){
                                procedureResponsibleProfiles.push(originalProfiles[0]);
                            }else{
                                procedureResponsibleProfiles.push( { profile: { id: item } });
                            }
                        }else{
                            procedureResponsibleProfiles.push( { profile: { id: item } });
                        }
                    });
                    if(procedure.procedureResponsibleProfilesOriginal){
                        var deleteProfiles = $linq(procedure.procedureResponsibleProfilesOriginal).except(procedureResponsibleProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        _.forEach(deleteProfiles, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureResponsibleProfiles.push(item);
                        });
                    }
                    procedure.procedureResponsibleProfiles = procedureResponsibleProfiles;
                    procedure.allowUserChangeMaxDays = procedure.allowUserChangeMaxDays == 'YES' ? true : false;
                    procedure.allowUserChangeExpirationDaysNotification = procedure.allowUserChangeExpirationDaysNotification == 'YES' ? true : false;
                    procedure.allowHaveTerritorialAddress = procedure.allowHaveTerritorialAddress == 'YES' ? true : false;
                    procedure.allowManualDossierNumbering = procedure.allowManualDossierNumbering == 'YES';
                    procedure.forceHaveThird = procedure.forceHaveThird == 'YES' ? true : false;
                    if(procedure.tramitationTypeProcedure){
                        procedure.tramitationType = {id:procedure.tramitationTypeProcedure};
                    }
                    var procedureOperationTypes = [];
                    _.forEach(procedure.operationTypes, function (item) {
                        if(procedure.operationTypesOriginal){
                            var originaloperationTypes = $linq(procedure.operationTypesOriginal).where("x => x.operationType.id == "+item).toArray();
                            if(originaloperationTypes && originaloperationTypes.length > 0){
                            	procedureOperationTypes.push(originaloperationTypes[0]);
                            }else{
                            	procedureOperationTypes.push( { operationType: { id: item } });
                            }
                        }else{
                        	procedureOperationTypes.push( { operationType: { id: item } });
                        }
                    });
                    if(procedure.operationTypesOriginal){
                        var deleteoperationTypes = $linq(procedure.operationTypesOriginal).except(procedureOperationTypes, "(x,y) => x.operationType.id == y.operationType.id").toArray();
                        _.forEach(deleteoperationTypes, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedureOperationTypes.push(item);
                        });
                    }
                    procedure.operationTypes = procedureOperationTypes;
                    procedure.operationsActive = procedure.operationsActive == 'YES' ? true : false;
                    var transactions = [];
                    _.forOwn(procedure.transactions, function(item) {
                        var idOriginal = undefined;
                        if(procedure.transactionsOriginal && item.id){
                            var originalTransactions = $linq(procedure.transactionsOriginal).where("x => x.transaction.id == "+item.id).toArray();
                            if(originalTransactions && originalTransactions.length > 0){
                                idOriginal = originalTransactions[0].id;
                            }
                        }
                        if(idOriginal) {
                            transactions.push({id:idOriginal, transaction: fixTransactionValues(item)});
                        }else{
                            transactions.push({transaction: fixTransactionValues(item)});
                        }
                    });
                    if(procedure.transactionsOriginal){
                        var deleteTransaction = $linq(procedure.transactionsOriginal).except(transactions, "(x,y) => x.transaction.id == y.transaction.id").toArray();
                        _.forEach(deleteTransaction, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            transactions.push(item);
                        });
                    }
                    procedure.transactions = transactions;
					if(procedure.customFieldsOriginal){
                        var deleteCustomFields = $linq(procedure.customFieldsOriginal).except(procedure.customFields, "(x,y) => x.id == y.id").toArray();
                        _.forEach(deleteCustomFields, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            procedure.customFields.push(item);
                        });
                    }
					if(procedure.proposalType) {
						procedure.proposalType = { id: ((procedure.proposalType.id)?procedure.proposalType.id:procedure.proposalType)};
                    } else {
                    	procedure.proposalType = undefined;
                    }
					if(procedure.responsibleUserInternalControl) {
						if((procedure.responsibleUserInternalControl.id && procedure.responsibleUserInternalControl.id > 0) || procedure.responsibleUserInternalControl > 0) {
							procedure.responsibleUserInternalControl = { id: ((procedure.responsibleUserInternalControl.id)?procedure.responsibleUserInternalControl.id:procedure.responsibleUserInternalControl)};
						} else {
	                    	procedure.responsibleUserInternalControl = undefined;
						}
                    } else {
                    	procedure.responsibleUserInternalControl = undefined;
                    }
					if(!procedure.predefinedRecords || procedure.predefinedRecords == "") {
						procedure.predefinedRecords = undefined;
					}
					procedure.initCfieldWhenSaved = ((procedure.initCfieldWhenSaved)? true : false);
					var organsOpinion = [];
                    _.forEach(procedure.organsOpinion, function (item) {
                    	if($scope.isActiveCouncillor){
                    		if(item.organ && item.organ.id){
	                    		if(procedure.organsOpinionOriginal){
	                    			var originalOrgansOpinion = $linq(procedure.organsOpinionOriginal).where("x => x.organ.id == "+item.organ.id).toArray();
	                    			if(originalOrgansOpinion && originalOrgansOpinion.length > 0){
	                    				if(item.delegationType && item.delegationType.id){
	                    					originalOrgansOpinion[0].delegationType = {id: item.delegationType.id};
	                    				}
	                    				organsOpinion.push(originalOrgansOpinion[0]);
	                    			}else{
	                    				if(item.delegationType && item.delegationType.id){
		                    				organsOpinion.push({ organ: { id: item.organ.id }, delegationType: { id: item.delegationType.id }});
	                    				}
	                    			}
	                    		}else{
	                    			if(item.delegationType && item.delegationType.id){
	                    				organsOpinion.push({ organ: { id: item.organ.id }, delegationType: { id: item.delegationType.id }});
	                    			}
	                    		}
                    		}
                    	}else{
                    		if(procedure.organsOpinionOriginal){
                    			var originalOrgansOpinion = $linq(procedure.organsOpinionOriginal).where("x => x.organ.id == "+item).toArray();
                    			if(originalOrgansOpinion && originalOrgansOpinion.length > 0){
                    				organsOpinion.push(originalOrgansOpinion[0]);
                    			}else{
                    				organsOpinion.push( { organ: { id: ((item && item.id)?item.id:item) } });
                    			}
                    		}else{
                    			organsOpinion.push( { organ: { id: ((item && item.id)?item.id:item) } });
                    		}
                    	}
                    });
                    if(procedure.organsOpinionOriginal){
                        var deleteOrgansOpinion = $linq(procedure.organsOpinionOriginal).except(organsOpinion, "(x,y) => x.organ.id == y.organ.id").toArray();
                        _.forEach(deleteOrgansOpinion, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            organsOpinion.push(item);
                        });
                    }
                    procedure.organsOpinion = organsOpinion;
                    procedure.organsOpinionOriginal = undefined;
					if(procedure.organsAgreement) {
						procedure.organsAgreement = { id: ((procedure.organsAgreement && procedure.organsAgreement.id)?procedure.organsAgreement.id:procedure.organsAgreement) };
                    } else {
                    	procedure.organsAgreement = undefined;
                    }
					if(procedure.organsAgreementDelegationType) {
						procedure.organsAgreementDelegationType = { id: ((procedure.organsAgreementDelegationType && procedure.organsAgreementDelegationType.id)?procedure.organsAgreementDelegationType.id:procedure.organsAgreementDelegationType) };
                    } else {
                    	procedure.organsAgreementDelegationType = undefined;
                    }
					if(procedure.notificatorProfile) {
						procedure.notificatorProfile = { id: ((procedure.notificatorProfile && procedure.notificatorProfile.id)?procedure.notificatorProfile.id:procedure.notificatorProfile) };
                    } else {
                    	procedure.notificatorProfile = undefined;
                    }
					var internalProfiles = [];
                    _.forEach(procedure.internalProfiles, function (item) {
                        if(procedure.internalProfilesOriginal){
                            var originalInternalProfiles = $linq(procedure.internalProfilesOriginal).where("x => x.profile.id == "+item).toArray();
                            if(originalInternalProfiles && originalInternalProfiles.length > 0){
                            	internalProfiles.push(originalInternalProfiles[0]);
                            }else{
                            	internalProfiles.push( { profile: { id: ((item && item.id)?item.id:item) } });
                            }
                        }else{
                        	internalProfiles.push( { profile: { id: ((item && item.id)?item.id:item) } });
                        }
                    });
                    if(procedure.internalProfilesOriginal){
                        var deleteInternalProfiles = $linq(procedure.internalProfilesOriginal).except(internalProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                        _.forEach(deleteInternalProfiles, function (item) {
                            item.deleted = true;
                            item.removedDate = new Date();
                            internalProfiles.push(item);
                        });
                    }
                    procedure.internalProfiles = internalProfiles;
                    procedure.internalProfilesOriginal = undefined;
					if(procedure.ammendmentTransactionType) {
                    	procedure.ammendmentTransactionType = { id: ((procedure.ammendmentTransactionType && procedure.ammendmentTransactionType.id)?procedure.ammendmentTransactionType.id:procedure.ammendmentTransactionType) };
                    } else {
                    	procedure.ammendmentTransactionType = undefined;
                    }
					if(procedure.transferTransactionType) {
						procedure.transferTransactionType = { id: ((procedure.transferTransactionType && procedure.transferTransactionType.id)?procedure.transferTransactionType.id:procedure.transferTransactionType) };
                    } else {
                    	procedure.transferTransactionType = undefined;
                    }
					if(procedure.cancelTransactionType) {
						procedure.cancelTransactionType = { id: ((procedure.cancelTransactionType && procedure.cancelTransactionType.id)?procedure.cancelTransactionType.id:procedure.cancelTransactionType) };
                    } else {
                    	procedure.cancelTransactionType = undefined;
                    }
                    return procedure;
                };
			
                var originalModel = _.clone(model, true);
                if(model.transactions) {
                    originalModel.transactions = [];
                    _.forOwn(model.transactions, function(value, key){
                        originalModel.transactions[key] = _.clone(value, true);
                    }); 
                }
				if(model.transactionTransitionGuideds){
					originalModel.transactionTransitionGuideds = [];
					_.forOwn(model.transactionTransitionGuideds, function(value, key){
                        originalModel.transactionTransitionGuideds.push(_.clone(value, true));
                    }); 
				}
				if(model.documentationToProvide){
					originalModel.documentationToProvide = [];
					_.forOwn(model.documentationToProvide, function(value, key){
                        originalModel.documentationToProvide.push(_.clone(value, true));
                    }); 
				}
				if(model && !model.id && model.secretaryNotificationConfiguration){
					originalModel.secNotification = angular.copy(model.secretaryNotificationConfiguration);
					if(originalModel.secNotification.registerOffice && originalModel.secNotification.registerOffice.id){
						originalModel.secNotification.registerOffice = {id:originalModel.secNotification.registerOffice.id};
					}
					if(originalModel.secNotification.profile && originalModel.secNotification.profile.id){
						originalModel.secNotification.profile = {id:originalModel.secNotification.profile.id};
					}
					if(originalModel.secNotification.archiveClassification && originalModel.secNotification.archiveClassification.id){
						originalModel.secNotification.archiveClassification = {id:originalModel.secNotification.archiveClassification.id};
					}
					if(originalModel.secNotification.docs && originalModel.secNotification.docs.length > 0){
						_.forEach(originalModel.secNotification.docs, function(doc){
							if(doc.type && doc.type.id){
								doc.type = {id:doc.type.id};
							}
							if(doc.template && doc.template.id){
								doc.template = {id:doc.template.id};
							}
							if(doc.archiveClassification && doc.archiveClassification.id){
								doc.archiveClassification = {id:doc.archiveClassification.id};
							}
						});
					}
				}
				if(model && !model.id && model.procedureProposalTypes){
					originalModel.procedureProposalTypes = angular.copy(model.procedureProposalTypes);
				}
				if(model && !model.id && model.proposalSignCircuitConfiguration){
					originalModel.proposalSignCircuit = angular.copy(model.proposalSignCircuitConfiguration);
				}
                originalModel = fixProcedureValues(originalModel);

                originalModel.graphJSON = JSON.stringify(json);
                AdminFactory.saveBPM(originalModel).then(function(data) {
                	if(GlobalDataFactory.procedures){
                    	GlobalDataFactory.procedures.length = 0;
                    }else{
                    	GlobalDataFactory.procedures = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
                    }
                    GlobalDataFactory.loadGlobalData();
                    $state.go(cancelState);
                }).catch(function(error) {
                	var validations = [];
                	if(error && error.data && error.data.exception == 'org.springframework.dao.DataIntegrityViolationException'){
                		if(error.data.message.includes('[acronym]')){
                			validations.push({ type: 'text', result: 'global.errors.existAcronym' });
                		}else{
                			validations.push({ type: 'text', result: 'global.errors.unknownNew' });
                		}
                	}else{
                		validations.push({ type: 'text', result: 'global.errors.unknownNew' });
                	}
                	ABMValidationFactory.validations.push({ type: 'procedure', result: validations });
                	$rootScope.$broadcast('validateBPMErrorToSave', {});
                });
            }});
        };
        

        $scope.editProcedure = AdminFactory.procedure;

        $scope.toggleMenu = function() {
            angular.element('#common-admin-sidebar').toggleClass('common-admin-collapsed hide');
            angular.element('#common-admin-content').toggleClass('com-lg-12 col-lg-10')
            $scope.$broadcast("toggleAdminMenu");
        }

        $scope.graphJSON = function() {
            $scope.$broadcast('graphJSON');
        }
    }]);
